import React from "react";
import State from "../../state/vo/State";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { PARAMS } from "../../router/constants/Params";
import i18next from "i18next";
import { getRelatedCategory } from "../../graphcms/scripts/category";
import Preferences from "./Preferences";

const RightCategory: React.FC = () => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const layoutCartOpen = useSelector((state: State) => state.layout.cartOpen);
  const params = useParams();

  if (layoutCartOpen) {
    return null;
  }

  // no parent category selected
  if (!params[PARAMS.CATEGORY_ID]) {
    return <Preferences category={null} />;
  }

  const parentId = params[PARAMS.PRODUCTGROUP_ID] ?? params[PARAMS.CATEGORY_ID];
  const category = getRelatedCategory(categoriesTree, parentId);

  return (
    <>
      <Preferences category={category ?? null} />
      {category && category.description && (
        <>
          <h2 className="col-title">{i18next.t("MORE INFORMATION")}</h2>
          <div className="block-title">{category.name}</div>
          <div className="cms cms--block">
            <ReactMarkdown>{category.description}</ReactMarkdown>
          </div>
        </>
      )}
    </>
  );
};

export default RightCategory;
