import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import SalesAgent from "../../state/vo/SalesAgent";
import MarketAndState from "../vo/MarketAndState";
import SalesAgentCustomer from "../vo/SalesAgentCustomer";
import Role from "../vo/Role";
import { Exports } from "../../commercelayer/vo/exports";

const name = "salesagent";
const initialState = store.salesagent;

const salesagentSlice = createSlice({
  name,
  initialState,
  reducers: {
    setLogin(state: SalesAgent, action: PayloadAction<MarketAndState | null>) {
      state.login = action.payload;
    },
    setCustomers(state: SalesAgent, action: PayloadAction<SalesAgentCustomer[]>) {
      state.customers = action.payload;
    },
    setRole(state: SalesAgent, action: PayloadAction<Role | null>) {
      state.role = action.payload;
    },
    setExports(state: SalesAgent, action: PayloadAction<Exports[]>) {
      state.exports = action.payload;
    },
  },
});

export const setLogin = salesagentSlice.actions.setLogin;
export const setCustomers = salesagentSlice.actions.setCustomers;
export const setRole = salesagentSlice.actions.setRole;
export const setExports = salesagentSlice.actions.setExports;

export default salesagentSlice.reducer;
