import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Left from "../../layout/components/Col/Left";
import Middle from "../../layout/components/Col/Middle";
import Right from "../../layout/components/Col/Right";
import Footer from "../../layout/components/Footer";
import NavCategory from "./NavCategory";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Landing from "./Landing";
import MidCategory from "./MidCategory";
import RightCategory from "./RightCategory";
import CartOverlay from "../../cart/components/CartOverlay";
import SearchOverlay from "../../search/components/SearchOverlay";
import MobileHeader from "../../layout/components/MobileHeader";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

const Category: React.FC = () => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);

  if (categoriesTree.length === 0) {
    return null;
  }

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeader />
        <Left>
          <NavCategory />
        </Left>
        <Middle>
          <Landing categoriesTree={categoriesTree} />
          <MidCategory />
        </Middle>
        <Right>
          <CartOverlay />
          <RightCategory />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Category;
