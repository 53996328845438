import React from "react";
import DetailRelated from "./DetailRelated";
import i18next from "i18next";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";

interface Props {
  relatedProductGroups: ProductGroup[];
  relatedCategory?: Category;
}

const DetailRelatedWrapper: React.FC<Props> = ({ relatedProductGroups, relatedCategory }) => {
  if (!relatedProductGroups?.length) {
    return null;
  }

  if (relatedProductGroups.length === 0) {
    return null;
  }

  let blockTitle = i18next.t("OTHER PRODUCTS IN CATEGORY");
  if (relatedCategory?.hidden === true) {
    blockTitle = i18next.t("MATCHING PRODUCTS");
  }

  return (
    <>
      <div className="block-title block-title--product">{blockTitle}</div>
      {relatedProductGroups.map((relatedProductGroup, i) => (
        <DetailRelated
          key={relatedProductGroup?.name + " " + i}
          productGroup={relatedProductGroup}
          relatedCategory={relatedCategory}
        />
      ))}
    </>
  );
};

export default DetailRelatedWrapper;
