import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { CellYTd } from "./CellYTd";
import ProductTableHeadLeftCells from "./ProductTableHeadLeftCells";
import ProductTableHeadPfs from "./ProductTableHeadPfs";
import ProductTableHeadXAxis from "./ProductTableHeadXAxis";

interface Props {
  productGroup: ProductGroup;
}

const ProductTableHeadWithPf: React.FC<Props> = ({ productGroup }) => {
  const pfs = productGroup?.pfs ?? [];

  // only renders when pfs are available
  if (pfs?.length === 0) {
    return null;
  }

  return (
    <>
      <tr className="thead__pf-row">
        <CellYTd />
        <ProductTableHeadLeftCells />
        <ProductTableHeadPfs productGroup={productGroup} />
      </tr>
      <ProductTableHeadXAxis productGroup={productGroup} />
    </>
  );
};

export default ProductTableHeadWithPf;
