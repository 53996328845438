import { getState } from "../../../state/scripts/state";
import { Product } from "../../../graphcms/vo/graphCMS";
import SkuQuantityItem from "../../../cart/vo/SkuQuantityItem";
import Cart from "../../../state/vo/Cart";
import { LineItems } from "../../../commercelayer/vo/lineItems";
import { Customer } from "../../../commercelayer/vo/customers";
import { getCountry } from "../../../router/scripts/params";
import Token from "../../../login/vo/Token";
import { OWNER_TYPE } from "../../../login/constants/ownerType";

type DataLayerEventName =
  | "customer_registration"
  | "view_item"
  | "add_to_cart"
  | "add_to_cart_click"
  | "begin_checkout"
  | "purchase"
  | "login";

function gtmPush(dataLayerObject: DataLayerObject) {
  const layout = getState().layout;
  if (layout.cookies === "yes") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(dataLayerObject);
  }
}

interface EcommerceItem {
  item_id: string;
  item_name?: string;
  price?: string;
  quantity?: string;
  item_brand?: string;
  item_category?: string;
  item_category_2?: string;
  item_category_3?: string;
  item_category_4?: string;
  item_variant?: string;
  item_list_name?: string; // If associated with a list selection.
  item_list_id?: string; // If associated with a list selection.
  index?: number; // If associated with a list selection.
}

export interface DataLayerObject {
  event?: DataLayerEventName;
  ecommerce?: {
    transaction_id?: string;
    affiliation?: string;
    value?: string;
    tax?: string;
    shipping?: string;
    currency?: string;
    coupon?: string;
    items?: EcommerceItem[];
  } | null;

  [customKey: string]: any;
}

function getEcommerceItemfromProduct(product): EcommerceItem {
  return {
    item_id: product.sku,
    item_name: product.sku,
    item_category: product.sapProdh,
    item_category_2: product.sapMatkl,
  };
}

function getEcommerceItemFromSkuQuantityItem(skuQuantityItem: SkuQuantityItem): EcommerceItem {
  return {
    item_id: skuQuantityItem.sku,
    item_name: skuQuantityItem.sku,
    quantity: skuQuantityItem.quantity + "",
  };
}

function getEcommerceItemFromLineItem(lineItem: LineItems): EcommerceItem {
  return {
    item_id: lineItem.attributes.sku_code,
    item_name: lineItem.attributes.sku_code,
    price: lineItem.attributes.unit_amount_float.toFixed(2),
    quantity: lineItem.attributes.quantity + "",
  };
}

export function gtmPushCustomerRegistration() {
  gtmPush({
    event: "customer_registration",
  });
}

export function gtmPushViewItem(product: Product) {
  gtmPush({ ecommerce: null });
  gtmPush({
    event: "view_item",
    ecommerce: { items: [getEcommerceItemfromProduct(product)] },
  });
}

export function gtmPushAddToCart(skuQuantityItems: SkuQuantityItem[]) {
  gtmPush({ ecommerce: null });
  gtmPush({
    event: "add_to_cart",
    ecommerce: {
      items: skuQuantityItems.map(skuQuantityItem =>
        getEcommerceItemFromSkuQuantityItem(skuQuantityItem),
      ),
    },
  });
}

export function gtmPushAddToCartClick(skuQuantityItems: SkuQuantityItem[]) {
  gtmPush({ ecommerce: null });
  gtmPush({
    event: "add_to_cart_click",
    ecommerce: {
      items: skuQuantityItems.map(skuQuantityItem =>
        getEcommerceItemFromSkuQuantityItem(skuQuantityItem),
      ),
    },
  });
}

export function gtmPushBeginCheckout(cartState: Cart) {
  gtmPush({ ecommerce: null });
  gtmPush({
    event: "begin_checkout",
    ecommerce: {
      items: cartState.lineItemsSkus.map((lineItem: LineItems) => {
        return getEcommerceItemFromLineItem(lineItem);
      }),
    },
  });
}

export function gtmPushPurchase(cartState: Cart) {
  const dlo: DataLayerObject = {
    event: "purchase",
    ecommerce: {
      transaction_id: cartState.attributes?.number + "",
      value: cartState.attributes?.total_amount_float.toFixed(2),
      tax: cartState.attributes?.total_tax_amount_float.toFixed(2),
      shipping: cartState.attributes?.shipping_taxable_amount_float.toFixed(2),
      currency: cartState.attributes?.currency_code,
      items: cartState.lineItemsSkus.map((lineItem: LineItems) => {
        return getEcommerceItemFromLineItem(lineItem);
      }),
    },
  };

  gtmPush({ ecommerce: null });
  gtmPush(dlo);
}

export function gtmPushLoginCustomer(accountCustomer: Customer | null) {
  if (accountCustomer !== null) {
    gtmPush({
      event: "login",
      authenticationMethod: "email",
      userId: accountCustomer.attributes.email,
      ownerType: OWNER_TYPE.CUSTOMER,
      countryCode: getCountry(),
    });
  }
}

export function gtmPushLoginSalesAgent(token: Token) {
  gtmPush({
    event: "login",
    authenticationMethod: "oAuth2",
    userId: token.owner_id,
    ownerType: token.owner_type,
    countryCode: getCountry(),
  });
}
