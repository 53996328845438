import { ResponseLineItems } from "../vo/lineItems";
import { fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken } from "../scripts/token";

export async function serviceCreateLineItem(
  orderId: string,
  sku: string,
  quantity: number,
): Promise<ResponseLineItems | null> {
  const data = {
    data: {
      type: "line_items",
      attributes: {
        quantity: quantity,
        sku_code: sku,
        _update_quantity: true,
      },
      relationships: getCreateLineItemRelationships(orderId),
    },
  };
  try {
    return await fetchService(HTTP_METHOD.POST, `api/line_items`, await getAccessToken(), data);
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceCreateLineItemConsignmentStock(
  orderId: string,
  sku: string,
  batchNumber: string,
): Promise<ResponseLineItems | null> {
  const data = {
    data: {
      type: "line_items",
      attributes: {
        quantity: 1,
        sku_code: sku,
        metadata: {
          batchNumber: batchNumber,
        },
        _update_quantity: false,
      },
      relationships: getCreateLineItemRelationships(orderId),
    },
  };
  try {
    return await fetchService(HTTP_METHOD.POST, `api/line_items`, await getAccessToken(), data);
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceUpdateLineItemQuantity(
  lineItemId: string,
  quantity: number,
): Promise<ResponseLineItems | null> {
  const data = {
    data: {
      type: "line_items",
      id: lineItemId,
      attributes: {
        quantity: quantity,
      },
    },
  };
  try {
    return await fetchService(
      HTTP_METHOD.PATCH,
      `api/line_items/${lineItemId}`,
      await getAccessToken(),
      data,
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceDeleteLineItem(lineItemId: string): Promise<any> {
  try {
    return await fetchService(
      HTTP_METHOD.DELETE,
      `api/line_items/${lineItemId}`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

function getCreateLineItemRelationships(orderId: string) {
  return {
    order: {
      data: {
        type: "orders",
        id: orderId,
      },
    },
  };
}
