import React, { Dispatch, SetStateAction } from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import BreadcrumbCategoryLast from "./BreadcrumbCategoryLast";
import BreadcrumbMainCategory from "./BreadcrumbMainCategory";
import BreadcrumbSubCategory from "./BreadcrumbSubCategory";

const BreadcrumbCategories: React.FC<{
  mainProductGroup: ProductGroup;
  relatedProductGroups: ProductGroup[];
  setDropDownOpen?: Dispatch<SetStateAction<boolean>>;
}> = ({ mainProductGroup, relatedProductGroups = [], setDropDownOpen }) => {
  if (!setDropDownOpen) {
    console.error("missing setDropDownOpen function passed to BreadcrumbCategories");
    return null;
  }

  const { breadcrumbCategories } = mainProductGroup;

  if (!breadcrumbCategories?.[0]) {
    return null;
  }

  return (
    <>
      <BreadcrumbMainCategory
        mainProductGroup={mainProductGroup}
        setDropDownOpen={setDropDownOpen}
      />
      <BreadcrumbSubCategory
        mainProductGroup={mainProductGroup}
        setDropDownOpen={setDropDownOpen}
      />
      {/* product group or hidden Categories */}
      <BreadcrumbCategoryLast
        mainProductGroup={mainProductGroup}
        relatedProductGroups={relatedProductGroups}
        setDropDownOpen={setDropDownOpen}
      />
    </>
  );
};

export default BreadcrumbCategories;
