import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { Addresses } from "../../commercelayer/vo/addresses";
import Address from "../../common/components/Address";
import { PATH } from "../../router/constants/Path";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { OrderType } from "../../common/constants/OrderType";

interface Props {
  orderId?: string;
  orderType: OrderType;
  shippingAddress: Addresses | null;
  billingAddress: Addresses | null;
  showEditLink: boolean;
  customerId: string | null;
}

const OrderAddressSection: React.FC<Props> = ({
  orderId,
  orderType,
  shippingAddress,
  billingAddress,
  showEditLink,
  customerId,
}) => {
  const langCountry = useLangCountry();
  let editLink;
  if (orderType === OrderType.CST_ORDER) {
    editLink = `${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.CONSIGNMENT_STOCK}/${orderId}/${PATH.ADDRESS}`;
  } else {
    editLink = `${langCountry}/${PATH.CHECKOUT}/${orderId}`;
  }
  if (!orderId) return null;
  return (
    <div className="grid-wrap grid-wrap--checkout">
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("DELIVERY ADDRESS")}</h2>
        {shippingAddress !== null && <Address address={shippingAddress} allowDelete={false} />}
      </div>
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("INVOICE ADDRESS")}</h2>
        {billingAddress !== null && <Address address={billingAddress} allowDelete={false} />}
      </div>
      {showEditLink && (
        <Link
          to={editLink}
          className="icon-btn icon-btn__edit icon-btn__edit__right"
          role="button"
          tabIndex={0}
          onClick={() => scrollTop()}
        >
          <span className="visuallyhidden">{i18next.t("ADDRESS", { count: 2 })}</span>
        </Link>
      )}
    </div>
  );
};

export default OrderAddressSection;
