import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import AvailabilityAlertItem from "./AvailabilityAlertItem";
import { skuIsNotAvailable } from "../../commercelayer/scripts/filter";
import { Customer } from "../../commercelayer/vo/customers";

interface Props {
  sku: string;
  requestQuantity: number;
  customer: Customer | null;
}

const AvailabilityAlert: React.FC<Props> = ({ sku, requestQuantity, customer }) => {
  const commercelayerSkusIndex = useSelector((state: State) => state.commercelayer.skusIndex);

  // See also Verification
  if (skuIsNotAvailable(sku, requestQuantity, commercelayerSkusIndex, customer)) {
    return <AvailabilityAlertItem />;
  }
  return null;
};

export default AvailabilityAlert;
