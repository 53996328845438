import React from "react";
import Layout from "../../layout/components/Layout";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import Body from "../../layout/components/Body";
import Footer from "../../layout/components/Footer";

export const AccountFallback: React.FC = () => (
  <Layout>
    <Header />
    <MobileMenu />
    <SearchOverlay />
    <Body />
    <Footer />
  </Layout>
);
