import React from "react";
import { isProductRelatedView, isScanToCartView } from "../../router/scripts/path";
import CartOverlay from "../../cart/components/CartOverlay";

type Props = {
  children?: React.ReactNode;
};

const Body: React.FC<Props> = ({ children }) => {
  let addClass = "";
  if (isProductRelatedView() || isScanToCartView()) {
    addClass += " col-2-1";
  }

  return (
    <div className={`main-body ${addClass}`}>
      {/* douplicated cart for correct flow. @see https://3.basecamp.com/3101128/buckets/19695186/todos/3728087498 */}
      <div className="hidden-cart">
        <CartOverlay />
      </div>
      {children ?? null}
    </div>
  );
};

export default Body;
