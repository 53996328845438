import { setIsMobile } from "../../../layout/slices/layoutSlice";
import { getIsMobile } from "../../../state/init/layout";

export function handleIsMobile() {
  if ("ResizeObserver" in window) {
    const ro = new ResizeObserver(() => {
      setIsMobile(getIsMobile());
    });

    // defensive disconnect
    ro.disconnect();

    if (!document.body) {
      return;
    }

    ro.observe(document.body);

    // cleanup
    return () => ro.disconnect();
  }
}
