import Token from "../../login/vo/Token";
import { getState } from "../../state/scripts/state";
import { serviceRefreshToken } from "../services/token";
import {
  setTokenMarketAllStateAndStorage,
  setTokenStateAndStorage,
} from "../../login/actions/loginActions";
import { OWNER_TYPE } from "../../login/constants/ownerType";

export async function getAccessToken(): Promise<string | null> {
  const loginState = getState().login;
  if (!loginState.token) {
    return null;
  }
  return checkAndRefresh(loginState.token, false);
}

export async function getAccessTokenMarketAll(): Promise<string | null> {
  const loginState = getState().login;
  if (!loginState.tokenMarketAll) {
    return null;
  }
  return checkAndRefresh(loginState.tokenMarketAll, true);
}

async function checkAndRefresh(token: Token, marketAll: boolean) {
  try {
    const expiresAt = (token.created_at + token.expires_in) * 1000;
    const now = new Date().getTime();

    // Only refresh tokens from CUSTOMERS
    // Sales agent customers must be refreshed by the server with the integration
    // Hint from CommerceLayer: Our docs about the refresh token contains an inaccuracy: you should also pass the customer_secret together with the refresh_token and the client_id.
    if (token.owner_type === OWNER_TYPE.CUSTOMER) {
      // Reduce expired for 2 mins
      if (now > expiresAt - 120 * 1000) {
        const response: Token = await serviceRefreshToken(token.refresh_token);

        if (response) {
          // Refresh was successful

          if (marketAll) {
            await setTokenMarketAllStateAndStorage(response);
          } else {
            await setTokenStateAndStorage(response);
          }

          return response.access_token;
        }
        // Refresh was not successful
        // Do nothing and return the current token
        // HandleError401 will logout()
      }
    }

    return token.access_token;
  } catch (e) {
    return token.access_token;
  }
}
