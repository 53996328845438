import React from "react";
import i18next from "i18next";

const CartHeader: React.FC = () => {
  return (
    <div className="cart__header">
      <p className="cart-header__title">{i18next.t("PRODUCT", { count: 1 })}</p>
      <p className="cart-header__title">{i18next.t("QUANTITY")}</p>
      <p className="cart-header__title">{i18next.t("UNIT PRICE")}</p>
      <p className="cart-header__title">{i18next.t("TOTAL")}</p>
    </div>
  );
};

export default CartHeader;
