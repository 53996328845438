import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { updateLineItemQuantity } from "../actions/cartActions";

interface Props {
  cartId: string;
  lineItemId: string;
  quantity: number;
}

const min = 1;
const max = 99999;

const LineItemInputNumber: React.FC<Props> = ({ cartId, lineItemId, quantity }) => {
  const [count, setCount] = useState(quantity);

  useEffect(() => {
    setCount(quantity);
  }, [quantity]);

  const updateQuantity = function (quantity: number) {
    updateLineItemQuantity(cartId, lineItemId, quantity).then();
  };

  const onChange = function (e) {
    const { value } = e.target;
    const parsedValue = Number.parseInt(value);

    // prevent invalid inputs
    if (isNaN(parsedValue)) {
      setCount(min);
      return;
    }
    if (parsedValue < min) {
      setCount(min);
      return;
    }
    if (parsedValue > max) {
      setCount(max);
      return;
    }

    // process valid input
    setCount(value);
  };

  const onBlur = function (e) {
    updateQuantity(Number(e.target.value));
  };

  const onSubmit = function (e) {
    updateQuantity(Number(count));
    e.preventDefault();
  };

  const onClickPlus = function (e) {
    const quantity = Number(count);

    if (quantity + 1 >= max) {
      return;
    }

    updateLineItemQuantity(cartId, lineItemId, quantity + 1).then();
    setCount(quantity + 1);
  };

  const onClickMinus = function (e) {
    const quantity = Number(count);

    if (quantity <= min) {
      return;
    }

    updateLineItemQuantity(cartId, lineItemId, quantity - 1).then();
    setCount(quantity - 1);
  };

  return (
    <div className="input--number-wrap">
      <form onSubmit={onSubmit}>
        <span
          onClick={onClickMinus}
          className="icon-font icon-font__counter-minus"
          aria-hidden="true"
        />
        <input
          type="number"
          value={count}
          className="input--number"
          aria-required="true"
          aria-label={i18next.t("QUANTITY")}
          autoComplete="off"
          onChange={onChange}
          onBlur={onBlur}
          min={min}
          max={max}
          step={1}
        />
        <span
          onClick={onClickPlus}
          className="icon-font icon-font__counter-plus"
          aria-hidden="true"
        />
      </form>
    </div>
  );
};

export default LineItemInputNumber;
