import React from "react";
import i18next from "i18next";
import { Order } from "../../commercelayer/vo/orders";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import { Link } from "react-router-dom";
import { PATH } from "../../router/constants/Path";
import { getDateFormatted } from "../../common/scripts/format/date";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { OrderType } from "../../common/constants/OrderType";

interface Props {
  order: Order;
}

const OrderListItem: React.FC<Props> = ({ order }) => {
  const langCountry = useLangCountry();
  const orderType = order.attributes?.metadata?.type || OrderType.ORDER;
  return (
    <div className="list-item-single">
      <Link
        to={`${langCountry}/${PATH.ORDER}/${order.id}`}
        className="list-item--link"
        title={`${i18next.t(orderType, { count: 1 })} #${order.attributes.number}`}
        onClick={() => scrollTop()}
      >
        {`${i18next.t(orderType, { count: 1 })} #${order.attributes.number}`}
      </Link>

      <div className="list-item__text-wrap">
        <p className="list-item__text">{getDateFormatted(order.attributes.placed_at)}</p>

        <p className="list-item__text">
          {order.attributes.reference === null && (
            <span>
              {i18next.t(`CL_PAYMENT_STATUS_${order.attributes.payment_status.toUpperCase()}`)}
            </span>
          )}
        </p>

        <p className="list-item__text">
          {getNumberWithCurrency(
            order.attributes.total_amount_with_taxes_float,
            order.attributes.currency_code,
          )}
        </p>
      </div>
    </div>
  );
};

export default OrderListItem;
