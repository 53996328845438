import React from "react";
import i18next from "i18next";
import { Sort, SortBy, SortDirection } from "consignment-stock/components/InventoryCategory";

interface Props {
  title: string;
  sort: Sort;
  sortColumns: (sortBy: SortBy) => void;
}

const ConsignmentColumnTitle: React.FC<Props & { sortsBy: SortBy }> = ({
  title,
  sort: { sortBy, sortDirection },
  sortColumns,
  sortsBy,
}) => {
  const cls = ["icon-btn icon-font__select icon-sorting-cst"];
  if (sortDirection === SortDirection.Descending) cls.push("rot-180");
  if (sortBy !== sortsBy) cls.push("vis-hidden");

  return (
    <div
      className="list-3cols__item pointer icon-container-max flex-vertical-center"
      onClick={() => sortColumns(sortsBy)}
    >
      <span>{title}</span>
      <span className={cls.join(" ")}></span>
    </div>
  );
};

const InventoryTitle: React.FC<Props> = ({ title = "", sort, sortColumns }) => (
  <div className="list-3cols list-3cols--header">
    <ConsignmentColumnTitle
      title={title}
      sort={sort}
      sortColumns={sortColumns}
      sortsBy={SortBy.Pf}
    />
    <ConsignmentColumnTitle
      title={i18next.t("EXPIRATION DATE")}
      sort={sort}
      sortColumns={sortColumns}
      sortsBy={SortBy.ExpiryDate}
    />
    <ConsignmentColumnTitle
      title={i18next.t("BATCH NUMBER")}
      sort={sort}
      sortColumns={sortColumns}
      sortsBy={SortBy.Batch}
    />
  </div>
);

export default InventoryTitle;
