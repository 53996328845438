import React from "react";

interface Props {
  text: string;
  href: string;
}

const NavListItemA: React.FC<Props> = ({ text, href }) => {
  return (
    <li className="nav-list__item ">
      <a href={href} className="nav-list__link" title={text}>
        {text}
      </a>
    </li>
  );
};

export default NavListItemA;
