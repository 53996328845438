import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import { LineItems } from "../../commercelayer/vo/lineItems";
import { OrderAttributes, OrderRelationships } from "../../commercelayer/vo/orders";
import { Shipments } from "../../commercelayer/vo/shipments";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import Order from "../../state/vo/Order";
import { Addresses } from "../../commercelayer/vo/addresses";

const name = "order";
const initialState = store.order;

const orderSlice = createSlice({
  name,
  initialState,
  reducers: {
    setOrderId(state: Order, action: PayloadAction<string | null>) {
      state.id = action.payload;
    },
    setOrderAttributes(state: Order, action: PayloadAction<OrderAttributes | null>) {
      state.attributes = action.payload;
    },
    setOrderRelationships(state: Order, action: PayloadAction<OrderRelationships | null>) {
      state.relationships = action.payload;
    },
    setOrderLineItems(state: Order, action: PayloadAction<LineItems[]>) {
      state.lineItemsSkus = action.payload.filter(
        lineItems => lineItems.attributes.item_type === "skus",
      );
      state.lineItemsNonSkus = action.payload.filter(
        lineItems => lineItems.attributes.item_type !== "skus",
      );
    },
    setOrderShipments(state: Order, action: PayloadAction<Shipments[]>) {
      state.shipments = action.payload;
    },
    setOrderShippingMethods(state: Order, action: PayloadAction<ShippingMethods[]>) {
      state.shippingMethods = action.payload;
    },
    setOrderPaymentMethods(state: Order, action: PayloadAction<PaymentMethods[]>) {
      state.paymentMethods = action.payload;
    },
    setOrderAddresses(state: Order, action: PayloadAction<Addresses[]>) {
      state.addresses = action.payload;
    },
  },
});

export const setOrderId = orderSlice.actions.setOrderId;
export const setOrderAttributes = orderSlice.actions.setOrderAttributes;
export const setOrderRelationships = orderSlice.actions.setOrderRelationships;
export const setOrderLineItems = orderSlice.actions.setOrderLineItems;
export const setOrderShipments = orderSlice.actions.setOrderShipments;
export const setOrderShippingMethods = orderSlice.actions.setOrderShippingMethods;
export const setOrderPaymentMethods = orderSlice.actions.setOrderPaymentMethods;
export const setOrderAddresses = orderSlice.actions.setOrderAddresses;

export default orderSlice.reducer;
