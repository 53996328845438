import qs, { ParsedQuery, ParseOptions } from "query-string";

const parseOptions: ParseOptions = {
  arrayFormat: "comma",
};

function finishHandling(searchObj: ParsedQuery<string>): string {
  return qs.stringify(searchObj, parseOptions);
}

function getSearchObj(): ParsedQuery<string> {
  return qs.parse(window.location.search, parseOptions);
}

function handleToggledArray(searchObj: ParsedQuery<string>, key, value) {
  if (Array.isArray(searchObj[key]) && typeof searchObj[key] === "object") {
    if (searchObj[key]?.includes(value)) {
      let i = searchObj[key]?.indexOf(value);
      // remove
      // @ts-ignore for https://github.com/microsoft/TypeScript/issues/17002
      searchObj[key].splice(i, 1);
    } else {
      // @ts-ignore for https://github.com/microsoft/TypeScript/issues/17002
      // add
      searchObj[key].push(value);
    }
  }
}

function handleToggledString(searchObj: ParsedQuery<string>, key, value) {
  if (typeof searchObj[key] === "string") {
    if (searchObj[key] === value) {
      // remove
      delete searchObj[key];
    } else {
      // add as array
      searchObj[key] = [searchObj[key], value];
    }
  }
}
function handleToggledNew(searchObj: ParsedQuery<string>, key, value) {
  searchObj[key] = value;
  return searchObj;
}

function handleToggledExisting(searchObj, key, value) {
  handleToggledArray(searchObj, key, value);
  handleToggledString(searchObj, key, value);
  return searchObj;
}

export function handleToggledSearch(searchObj: ParsedQuery<string>, key: string, value: string) {
  if (searchObj[key]) {
    return handleToggledExisting(searchObj, key, value);
  } else {
    return handleToggledNew(searchObj, key, value);
  }
}

export function getToggledSearch(key: string, value: string): string {
  const searchObj: ParsedQuery<string> = getSearchObj();
  const searchObjNew = handleToggledSearch(searchObj, key, value);
  return finishHandling(searchObjNew);
}

export function handleValuesInUrlList(searchObj: ParsedQuery<string>, key: string): string[] {
  if (!searchObj[key]) {
    return [];
  }

  if (searchObj[key]) {
    if (typeof searchObj[key] === "string") {
      // @ts-ignore for https://github.com/microsoft/TypeScript/issues/17002
      return [searchObj[key]];
    }

    if (Array.isArray(searchObj[key])) {
      // @ts-ignore for https://github.com/microsoft/TypeScript/issues/17002
      return searchObj?.[key] ?? [];
    }
  }

  return [];
}

export function getValuesInUrlList(key: string): any[] {
  const searchObj: ParsedQuery<string> = getSearchObj();
  return handleValuesInUrlList(searchObj, key);
}
