// https://3.basecamp.com/3101128/buckets/19695186/todos/7802323407
function polyfillObjectHasOwn() {
  // https://github.com/remarkjs/react-markdown/issues/772#issuecomment-1740262567
  if (!Object.hasOwn) {
    Object.hasOwn = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);
  }
}

export default function polyfill() {
  polyfillObjectHasOwn();
}
