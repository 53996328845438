import React from "react";
import Locale from "../../common/constants/Locale";
import Country from "../../common/constants/Country";
import { Helmet } from "react-helmet-async";
import { FALLBACK, PARAMS } from "../../router/constants/Params";
import { getPathnameLangSwitch } from "../../router/scripts/path";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { useCountry } from "../../router/hooks/useCountry";
import { useLang } from "../../router/hooks/useLang";
import { isInCountryCodes } from "../../product/scripts/product";
import { useSelectedProduct } from "../../product/hooks/product";

const Alternate: React.FC = () => {
  const product = useSelectedProduct();
  const lang = useLang();
  const country = useCountry();
  const langCountry = useLangCountry();
  const url: string = window.location.href;

  let indexCountry: string;
  let indexLang: string;
  let href: string;
  let hrefLang: string;
  const links: any = [];

  for (indexCountry in Country) {
    for (indexLang in Locale) {
      hrefLang = Locale[indexLang] + "-" + Country[indexCountry];
      href = url.replace(getPathnameLangSwitch(lang), getPathnameLangSwitch(Locale[indexLang]));
      href = href.replace(country, Country[indexCountry]);

      if (!product || (product && isInCountryCodes(product, Country[indexCountry]))) {
        links.push({ href, hrefLang });
      }
    }
  }
  links.push({
    href: url.replace(langCountry, `/${FALLBACK[PARAMS.LANGUAGE]}/${FALLBACK[PARAMS.COUNTRY]}`),
    hrefLang: "x-default",
  });

  return (
    <Helmet>
      {links.map((item, index) => (
        <link rel="alternate" href={item.href} hrefLang={item.hrefLang} key={index} />
      ))}
    </Helmet>
  );
};

export default Alternate;
