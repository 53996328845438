import React from "react";
import { ProductRow } from "../vo/sku";
import { devLogDebugOnce } from "../../common/scripts/logger/log";
import { Product } from "../../graphcms/vo/graphCMS";

export const CellYImg: React.FC<{ productRow: ProductRow; colSpan?: number }> = ({
  productRow,
  colSpan = 1,
}) => {
  const product = getProductForImg(productRow);

  if (!product) {
    devLogDebugOnce("no product found for displaying an image in productRow: ", productRow);
  }

  const handle = product?.image?.handle ?? null;
  const urlPrefix = "https://media.graphassets.com/auto_image";

  const getResizePart = function (width: number): string {
    return encodeURIComponent(`resize=height:${width},width:${width}`);
  };
  const getCropPart = function (width: number): string {
    const whiteHeight = Math.floor(width / 3);
    const imgHeight = width - whiteHeight - whiteHeight;
    return encodeURIComponent(`crop=dim:[0,${whiteHeight},${width},${imgHeight}]`);
  };

  return (
    <td className="cell-y" colSpan={colSpan}>
      <div className="td__img-wrap">
        {handle !== null && (
          <picture>
            <source
              media="screen and (min-width:1440px)"
              srcSet={`${urlPrefix}/${getResizePart(172)}/${getCropPart(
                172,
              )}/${handle} 1x, ${urlPrefix}/${getResizePart(344)}/${getCropPart(344)}/${handle} 2x`}
            />
            <source
              media="screen and (max-width:1199px)"
              srcSet={`${urlPrefix}/${getResizePart(184)}/${getCropPart(
                184,
              )}/${handle} 1x, ${urlPrefix}/${getResizePart(368)}/${getCropPart(368)}/${handle} 2x`}
            />
            <img
              className="img"
              alt={product?.name ?? ""}
              src={`${urlPrefix}/${getResizePart(172)}/${getCropPart(172)}/${handle}`}
            />
          </picture>
        )}
      </div>
    </td>
  );
};

function getProductForImg(productRow: ProductRow): Product | null {
  // it is defined that the preferred img to be shown is from 'PF 4.0'
  if (productRow["PF 4.0"]) {
    return productRow["PF 4.0"];
  }

  // if that is not available, return the first PF on that has been found
  for (let key in productRow) {
    if (key?.startsWith("PF ")) {
      return productRow[key];
    }
  }

  // for a productRow that has no PF value we return the first one found and has an image.handle
  for (let key in productRow) {
    if (productRow[key]?.image?.handle) {
      return productRow[key];
    }
  }

  return null;
}
