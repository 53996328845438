import { getCountryCodeByApi } from "./countryCode";
import CountryCodeRedirect from "../../constants/CountryCodeRedirect";
import Country from "../../constants/Country";
import { getState } from "../../../state/scripts/state";
import { PARAMS } from "../../../router/constants/Params";
import { StorageKeys } from "../../constants/StorageKeys";
import { getLangWithFallback } from "../../../i18next/scripts/language";

export async function getReRootPathname(): Promise<string> {
  const countryCode =
    getState()?.urlParams?.params?.[PARAMS.COUNTRY] ?? (await getCountryCodeByApi());
  const countryCodeWithRedirect = CountryCodeRedirect[countryCode] ?? countryCode;

  let lang = getLangWithFallback();

  // TODO: remove this fallback when en is standard lang fallback!
  // See Params.ts for lang fallback
  // @ts-ignore
  if (!Object.values(Country).includes(countryCodeWithRedirect)) {
    lang = "en";
  }

  localStorage.setItem(
    StorageKeys.BASE_HREF,
    `${window.location.origin}/${lang}/${countryCodeWithRedirect}`,
  );
  return `/${lang}/${countryCodeWithRedirect}`;
}
