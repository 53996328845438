// display only when displayable items are available
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";
import { PARAMS } from "../../router/constants/Params";

// non hidden categoriesTree
export function getCategoriesVisible(category: Category): Category[] {
  return category.categories.filter(category => !category.hidden);
}

// hidden categoriesTree where parent is main category
export function getHiddenCategoriesFromMainParent(category: Category, params): Category[] {
  const countryCode = params?.[PARAMS.COUNTRY] ?? null;

  if (!countryCode) {
    return [];
  }

  return (
    category.categories
      // child must be hidden
      .filter(childCategory => !!childCategory.hidden)
      // parent must be a main category
      .filter(subCategory => !category?.parentCategory)
      // must have at least one child productGroup
      .filter(childCategory => childCategory.productGroups?.length > 0)
      // countryCode must be included in the first productGroup https://3.basecamp.com/3101128/buckets/19695186/todos/3933875361
      .filter(childCategory =>
        childCategory.productGroups[0]?.countryCodes?.includes(countryCode.toUpperCase()),
      )
  );
}

// hidden categoriesTree where parent is another sub category
export function getHiddenCategoriesFromSubCategoryParent(category: Category, params): Category[] {
  const countryCode = params?.[PARAMS.COUNTRY] ?? null;

  if (!countryCode) {
    return [];
  }

  return (
    category.categories
      // child must be hidden
      .filter(childCategory => !!childCategory.hidden)
      // parent must be a child
      .filter(childCategory => !!category?.parentCategory)
      // must have at least one child productGroup
      .filter(childCategory => childCategory.productGroups?.length > 0)
      // countryCode must be included in the first productGroup https://3.basecamp.com/3101128/buckets/19695186/todos/3933875361
      .filter(childCategory =>
        childCategory.productGroups[0]?.countryCodes?.includes(countryCode.toUpperCase()),
      )
  );
}

// productGroups from category
export function getProductGroupsFromCategory(category: Category, params): ProductGroup[] {
  const countryCode = params?.[PARAMS.COUNTRY] ?? null;

  if (!countryCode) {
    return [];
  }

  return (
    category?.productGroups
      // countryCode must be included in the first productGroup https://3.basecamp.com/3101128/buckets/19695186/todos/3933875361
      .filter(productGroup => productGroup?.countryCodes?.includes(countryCode.toUpperCase()))
  );
}

export function hasDisplayableItems(category: Category, params): boolean {
  // count number of items displayed according to all business rules
  const nrDisplayItems =
    getCategoriesVisible(category)?.length +
    getHiddenCategoriesFromMainParent(category, params)?.length +
    getHiddenCategoriesFromSubCategoryParent(category, params)?.length +
    getProductGroupsFromCategory(category, params)?.length;

  return !!nrDisplayItems;
}

export function hasDisplayableChildCategory(category, params): boolean {
  return category?.categories
    ?.map((category: Category) => {
      return hasDisplayableItems(category, params);
    })
    .includes(true);
}
