import React, { useEffect, useRef, useState } from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "state/vo/State";
import { PATH } from "router/constants/Path";
import { Link } from "react-router-dom";
import { scrollTop } from "../../layout/scripts/scroll";

const Bottom: React.FC = () => {
  const order = useSelector((state: State) => state.consignmentStock.order);
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  const [sticky, setSticky] = useState<boolean>(false);
  const ref = useRef(null);
  const inactive = order.lineItemsSkus.length === 0;

  // handle sticky
  useEffect(() => {
    // only relevant for mobile client
    if (!isMobile) {
      return;
    }

    if (!("IntersectionObserver" in window)) {
      return;
    }

    // setting sticky when not in bottom
    const handleSetSticky = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setSticky(false);
        } else {
          setSticky(true);
        }
      });
    };

    const iObs = new IntersectionObserver(handleSetSticky);

    // defensive disconnect in case already initialized.
    iObs.disconnect();

    // activate when existing
    if (ref.current) iObs.observe(ref.current);

    // cleanup when component unmount
    return () => iObs.disconnect();
  }, [isMobile]);

  return (
    <>
      <span
        id="hidden-reference"
        style={{ visibility: "hidden", padding: "1px 0px" }}
        ref={ref}
      ></span>
      <div className={`btn-wrap btn-wrap--line btn-wrap--to-right ${sticky ? "sticky" : ""}`}>
        {inactive ? (
          <span
            className="btn inactive"
            aria-disabled="true"
            role="button"
            style={{ cursor: "not-allowed" }}
            tabIndex={0}
          >
            <span className="btn__text">{i18next.t("CHECKOUT CONSIGNMENT STOCK")}</span>
          </span>
        ) : (
          <Link
            className="btn"
            aria-disabled="false"
            role="button"
            tabIndex={0}
            to={`${window.location.pathname}/${order.id}/${PATH.ADDRESS}`}
            onClick={() => scrollTop()}
          >
            <span className="btn__text">{i18next.t("CHECKOUT CONSIGNMENT STOCK")}</span>
          </Link>
        )}
      </div>
    </>
  );
};

export default Bottom;
