import LayerIo from "../../common/services/LayerIo";
import { ScannedInventory } from "../vo/ConsignmentStock";

const className = "scan-from-cst";

const scanFromCst = {
  getScanSkus: async (scanId: string) =>
    LayerIo.requestJsonRpc<any, { scanSkus: ScannedInventory[] | null }>(className, "getScanSkus", {
      scanId,
    }),
  setScanSkus: async (scanId: string, scanSkus: ScannedInventory[] = []) =>
    LayerIo.requestJsonRpc<any, { scanSkus: ScannedInventory[] | null }>(className, "setScanSkus", {
      scanId,
      scanSkus,
    }),
  resetScanSkus: async (scanId: string) =>
    LayerIo.requestJsonRpc<any, { scanSkus: null }>(className, "resetScanSkus", {
      scanId,
    }),
};

export default scanFromCst;
