import React, { useEffect } from "react";
import i18next from "i18next";
import OverlayPopup from "../../layout/components/OverlayPopup";
import { setOverlayOpenAction } from "../../layout/actions/layoutActions";
import { scrollTop } from "../../layout/scripts/scroll";
import { useIsCameraCloseable } from "../hooks/device";

interface Props {
  children: React.ReactNode;
  activeButtonOnClick?: () => void;
}

const OverlayPopupCameraWrapper: React.FC<Props> = ({ children, activeButtonOnClick }) => {
  const isCameraCloseable = useIsCameraCloseable();

  // making sure overlay is in viewport
  useEffect(() => scrollTop(), []);

  return (
    <OverlayPopup
      showClose={false}
      activeButtonShow={isCameraCloseable}
      activeButtonLabel={i18next.t("CLOSE")}
      activeButtonOnClick={() =>
        activeButtonOnClick ? activeButtonOnClick() : setOverlayOpenAction(null)
      }
    >
      {children}
    </OverlayPopup>
  );
};

export default OverlayPopupCameraWrapper;
