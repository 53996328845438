import React from "react";
import ProductTableHead from "./ProductTableHead";
import { Category, Product, ProductGroup } from "../../graphcms/vo/graphCMS";
import ProductTableBody from "./ProductTableBody";
import { devLogDebugOnce } from "../../common/scripts/logger/log";
import { ProductRowsMap } from "../vo/sku";
import {
  getAttributeValue,
  getAttributeValueValue,
  getUniqueSortedAttrValues,
} from "../../graphcms/scripts/attribute";
import { getProductGroupViewType } from "../../graphcms/scripts/productGroup";
import { ProductGroupViewType } from "../../graphcms/constants/ProductGroupViewType";
import { getMatchingProducts } from "../scripts/product";

interface Props {
  productGroup: ProductGroup;
  relatedCategory?: Category;
}

const ProductTableAggregator: React.FC<Props> = ({ productGroup, relatedCategory }) => {
  // productRowsMap assignment according to PGVT
  let productRowsMap;

  const PGVT = getProductGroupViewType(productGroup);

  // PGVT without any attributes have to be handled differently
  if (PGVT === ProductGroupViewType["4i"] || PGVT === ProductGroupViewType["4I"]) {
    productRowsMap = getProductRowsMapWithoutAttributes(productGroup, relatedCategory);
  } else {
    productRowsMap = getProductRowsMapFromAttributes(productGroup, relatedCategory);
  }

  devLogDebugOnce("productRowsMap: ", productRowsMap);

  return (
    <>
      <ProductTableHead productGroup={productGroup} />
      <ProductTableBody productGroup={productGroup} productRowsMap={productRowsMap} />
    </>
  );
};

function getProductRowsMapWithoutAttributes(
  productGroup: ProductGroup,
  relatedCategory?: Category,
): ProductRowsMap {
  const matchingProducts = getMatchingProducts(productGroup, relatedCategory);
  const productIndexMap: ProductRowsMap = new Map();

  // handle case with pfs
  if (productGroup?.pfs?.length > 1) {
    // since we have no attributes we assign a pseudo yIndex
    const yIndex = "no-attributes";

    // initialize yIndex on Map
    productIndexMap.set(yIndex, {});

    matchingProducts?.forEach(product => {
      aggregatePf(productIndexMap, product, yIndex);
    });
  }

  // handle case without pfs
  if (productGroup?.pfs?.length === 0) {
    matchingProducts?.forEach(product => {
      const entry = { [product.sku]: product };
      productIndexMap.set(product.sku, entry);
    });
  }

  return productIndexMap;
}

function getProductRowsMapFromAttributes(
  productGroup: ProductGroup,
  relatedCategory?: Category,
): ProductRowsMap {
  const sortingAttr = getSortingAttr(productGroup);
  const uniqueSortedAttrValues = getUniqueSortedAttrValues(productGroup, sortingAttr);
  const matchingProducts = getMatchingProducts(productGroup, relatedCategory);
  const productIndexMap: ProductRowsMap = new Map();

  // init index values
  uniqueSortedAttrValues.forEach(uniqueSortedAttrValue => {
    // assign to index from sorting attribute
    matchingProducts?.forEach((product: Product) => {
      const attributeValue = getAttributeValue(product, sortingAttr);

      if (!attributeValue) {
        return;
      }

      const productAttributeValueValue = getAttributeValueValue(attributeValue);

      if (!productAttributeValueValue) {
        return;
      }

      // we abort here in order to keep the product order in tact with the sortedAttrValues
      if (uniqueSortedAttrValue !== productAttributeValueValue) {
        return;
      }

      const yIndex = getYIndex(productGroup, product);

      // initialize yIndex on Map when it doesn't exist
      if (!productIndexMap.get(yIndex)) {
        productIndexMap.set(yIndex, {});
      }

      aggregatePf(productIndexMap, product, yIndex);
      aggregateYAxis(productGroup, productIndexMap, product, yIndex);
      aggregateXAxis(productGroup, productIndexMap, product, yIndex);
    });
  });

  return productIndexMap;
}

function getSortingAttr(productGroup: ProductGroup): string {
  const yAxisAttributes = productGroup?.yAxisAttributes;

  if (yAxisAttributes?.length === 0) {
    devLogDebugOnce(
      "no yAxisAttributes found for productGroup: ",
      productGroup?.name,
      productGroup?.id,
    );
    return "";
  }

  // it is specified that the leading sortingAttribute is the first on of the yAxis
  const sortingAttr = yAxisAttributes?.[0]?.name;

  if (!sortingAttr) {
    devLogDebugOnce(
      "no sortingAttr for yAxis has been found for productGroup: ",
      productGroup?.name,
      productGroup?.id,
    );
    return "";
  }
  return sortingAttr;
}

function getYIndex(productGroup: ProductGroup, product: Product): string {
  const yIndexes: string[] = [];

  // gather yIndexes for that product
  productGroup?.yAxisAttributes?.forEach(yAxisAttribute => {
    const attributeValue = getAttributeValue(product, yAxisAttribute.name);

    if (!attributeValue) {
      return;
    }

    const attributeValueValue = getAttributeValueValue(attributeValue);

    yIndexes.push(attributeValueValue);
  });

  // finish the yIndex by joining all those attributes i.e. 16-konisch, 16-zylindrisch
  return yIndexes.join("-");
}

function aggregatePf(productIndexMap: ProductRowsMap, product: Product, yIndex: string) {
  product?.pfs?.forEach(pf => {
    const value = productIndexMap.get(yIndex);

    if (!value) {
      return;
    }

    if (!pf?.name) {
      return;
    }

    value[pf.name] = product;
  });
}

function aggregateYAxis(
  productGroup: ProductGroup,
  productIndexMap: ProductRowsMap,
  product: Product,
  yIndex: string,
) {
  // assign for yAxisAttributes
  productGroup?.yAxisAttributes?.forEach(yAxisAttribute => {
    const value = productIndexMap.get(yIndex);

    if (!value) {
      return;
    }

    if (!yAxisAttribute?.name) {
      return;
    }

    value[yAxisAttribute.name] = product;
  });
}

function aggregateXAxis(
  productGroup: ProductGroup,
  productIndexMap: ProductRowsMap,
  product: Product,
  yIndex: string,
) {
  // assign for xAxis when no pfs
  if (productGroup?.pfs?.length === 0 && productGroup?.xAxisAttributes?.length > 0) {
    const value = productIndexMap.get(yIndex);

    if (!value) {
      return;
    }

    const attributeName = productGroup.xAxisAttributes[0]?.name;

    if (!attributeName) {
      return;
    }

    const attributeValue = getAttributeValue(product, attributeName);

    if (!attributeValue) {
      return;
    }

    const attributeValueValue = getAttributeValueValue(attributeValue);

    const index = attributeName + "-" + attributeValueValue;

    value[index] = product;
  }
}

export default ProductTableAggregator;
