import { ResponseShipmentsListWithIncludes, ResponseShipmentsWithIncludes } from "../vo/shipments";
import { fetchAllService, fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken, getAccessTokenMarketAll } from "../scripts/token";

const include = "available_shipping_methods,shipping_method";

export async function serviceGetOrderShipments(
  orderId: string,
): Promise<ResponseShipmentsListWithIncludes | null> {
  try {
    return await fetchAllService(
      HTTP_METHOD.GET,
      `api/orders/${orderId}/shipments?include=${include}`,
      await getAccessTokenMarketAll(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceAddShippingMethodToShipments(
  shipmentId: string,
  shippingMethodId: string,
): Promise<ResponseShipmentsWithIncludes | null> {
  const data = {
    data: {
      type: "shipments",
      id: shipmentId,
      relationships: {
        shipping_method: {
          data: {
            type: "shipping_methods",
            id: shippingMethodId,
          },
        },
      },
    },
  };
  try {
    return await fetchService(
      HTTP_METHOD.PATCH,
      `api/shipments/${shipmentId}?include=${include}`,
      await getAccessToken(),
      data,
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}
