import React, { useEffect, useMemo } from "react";
import State from "../../state/vo/State";
import { useSelector } from "react-redux";
import i18next from "i18next";
import { getCustomerPreferences } from "../../account/actions/accountActions";
import SearchListProductGroupItem from "../../search/components/SearchListProductGroupItem";
import { Category } from "../../graphcms/vo/graphCMS";

interface Props {
  category: Category | null;
}

const Preferences: React.FC<Props> = ({ category }) => {
  const layoutCartOpen = useSelector((state: State) => state.layout.cartOpen);
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const accountPreferences = useSelector((state: State) => state.account.preferences);
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const graphcmsProductGroupsIndex = useSelector(
    (state: State) => state.graphcms.productGroupsIndex,
  );
  const graphcmsProductsIndex = useSelector((state: State) => state.graphcms.productsIndex);

  useEffect(() => {
    getCustomerPreferences();
  }, [accountCustomer]);

  const filteredProductGroupIds = useMemo(() => {
    // unique skus
    const skus = [...new Set(accountPreferences)];
    const productGroupIds = skus.map(sku => {
      return graphcmsProductsIndex[sku]?.productGroup?.id ?? "";
    });

    // unique product group ids
    const uniqueProductGroupIds: string[] = [...new Set(productGroupIds)];

    let filteredProductGroupIds: string[] = [];
    // filter productGroups not in category
    if (category === null) {
      filteredProductGroupIds = uniqueProductGroupIds;
    } else {
      filteredProductGroupIds = uniqueProductGroupIds.filter(productGroupId => {
        const breadcrumbCategories: string[] =
          graphcmsProductGroupsIndex[productGroupId]?.breadcrumbCategories ?? [];
        return breadcrumbCategories.includes(category.id);
      });
    }

    return filteredProductGroupIds;
  }, [accountPreferences, category, graphcmsProductsIndex, graphcmsProductGroupsIndex]);

  if (layoutCartOpen) {
    return null;
  }

  if (accountCustomer === null) {
    return null;
  }

  if (accountPreferences === null) {
    return null;
  }

  if (filteredProductGroupIds.length === 0) {
    return null;
  }

  return (
    <>
      <h2 className="col-title">{i18next.t("YOUR PREFERENCES")}</h2>
      {categoriesTree.map((cat, index) => {
        const categoryProductGroupIds: string[] = filteredProductGroupIds.filter(productGroupId => {
          if (productGroupId === "") {
            return false;
          }
          if (!graphcmsProductGroupsIndex[productGroupId]) {
            return false;
          }
          return graphcmsProductGroupsIndex[productGroupId].breadcrumbCategories[0] === cat.id;
        });

        if (categoryProductGroupIds.length === 0) {
          return null;
        }

        return (
          <React.Fragment key={cat.id}>
            <div className="block-title">{cat.name}</div>
            <div className="list-wrap">
              {categoryProductGroupIds.map((productGroupId: string) => {
                return (
                  <SearchListProductGroupItem
                    key={productGroupId}
                    productGroup={graphcmsProductGroupsIndex[productGroupId]}
                  />
                );
              })}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Preferences;
