import React from "react";
import { ThommenLogoFooter } from "../../common/components/logo/Thommen";
import i18next from "i18next";
import CountrySpecific from "../../common/constants/CountrySpecific";
import { useSupportedCountry } from "../../router/hooks/useCountry";

const Footer: React.FC = () => {
  const country = useSupportedCountry();
  return (
    <footer className="footer">
      <div className="footer-main-area">
        <div className="footer-top">
          <div className="container">
            <ThommenLogoFooter />
            <div className="row">
              <div className="footer__col">
                <div className="ce-bodytext">
                  <p>
                    <strong>{CountrySpecific[country].addressCompany}</strong>
                  </p>
                  <p>
                    {CountrySpecific[country].addressLine}
                    <br />
                    {CountrySpecific[country].addressCity}
                    <br />
                    {i18next.t(CountrySpecific[country].addressCountry)}
                  </p>
                  <p>
                    <a href={CountrySpecific[country].phoneHref}>
                      {CountrySpecific[country].phoneFormatted}
                    </a>
                    <br />
                    <a href={`mailto:${CountrySpecific[country].email}`}>
                      {CountrySpecific[country].email}
                    </a>
                  </p>
                  <p>
                    <a
                      href={
                        i18next.t("URL WEB ROOT") +
                        i18next.t("URL PATH ABOUT US") +
                        i18next.t("URL PATH CONTACT")
                      }
                      className="link-with-arrow"
                    >
                      {i18next.t("CONTACT FORM")}
                    </a>
                  </p>
                </div>
              </div>
              <div className="footer__col footer-page-links">
                <div className="ce-bodytext">
                  <p>
                    <strong>{i18next.t("IMPORTANT PAGES")}</strong>
                  </p>
                  <ul>
                    <li>
                      <a
                        href={i18next.t("URL WEB ROOT") + i18next.t("URL PATH ABOUT US")}
                        className="link-with-arrow"
                      >
                        {i18next.t("ABOUT US")}
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          i18next.t("URL WEB ROOT") +
                          i18next.t("URL PATH DENTAL PROS") +
                          i18next.t("URL PATH PRODUCTS")
                        }
                        className="link-with-arrow"
                      >
                        {i18next.t("OUR PRODUCTS")}
                      </a>
                    </li>
                    <li>
                      <a
                        href={i18next.t("URL WEB ROOT") + i18next.t("URL PATH PATIENTS")}
                        className="link-with-arrow"
                      >
                        {i18next.t("FOR PATIENTS")}
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          i18next.t("URL WEB ROOT") +
                          i18next.t("URL PATH ABOUT US") +
                          i18next.t("URL PATH NEWS")
                        }
                        className="link-with-arrow"
                      >
                        {i18next.t("NEWS")}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="footer__col">
                <div className="ce-bodytext">
                  <p>
                    <strong>{i18next.t("SOCIAL MEDIA")}</strong>
                  </p>
                  <p>{i18next.t("FOLLOW US ON SOCIAL MEDIA")}</p>
                  <ul className="social-media">
                    <li>
                      <a
                        href="https://www.facebook.com/thommenmedical/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="visuallyhidden">Facebook</span>
                        <span className="icon-font icon-font__facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/thommenmedical/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="visuallyhidden">Instagram</span>
                        <span className="icon-font icon-font__instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/1023320/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="visuallyhidden">LinkedIn</span>
                        <span className="icon-font icon-font__linkedin" />
                      </a>
                    </li>
                    <li>
                      <a href="https://vimeo.com/user101518418" target="_blank" rel="noreferrer">
                        <span className="visuallyhidden">Vimeo</span>
                        <span className="icon-font icon-font__vimeo" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/user/thommenimplantsystem"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="visuallyhidden">YouTube</span>
                        <span className="icon-font icon-font__youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="ce-bodytext">
              <p>
                <a href={i18next.t("URL WEB ROOT") + i18next.t("URL PATH ABOUT US")}>
                  Thommen Medical AG&nbsp;| Driven by science, not trends.
                </a>
              </p>
            </div>
            <ul>
              <li>
                <a
                  href={i18next.t("URL PATH FAQ")}
                  target="_blank"
                  rel="noreferrer"
                  title={i18next.t("FAQ")}
                >
                  <span>{i18next.t("FAQ")}</span>
                </a>
              </li>
              {i18next.exists(CountrySpecific[country].termsOfUseKey) ? (
                <li>
                  <a
                    href={i18next.t(CountrySpecific[country].termsOfUseKey)}
                    target="_blank"
                    rel="noreferrer"
                    title={i18next.t("TERMS OF USE")}
                  >
                    <span>{i18next.t("TERMS OF USE")}</span>
                  </a>
                </li>
              ) : null}
              {i18next.exists(CountrySpecific[country].privacyPolicyKey) ? (
                <li>
                  <a
                    href={i18next.t(CountrySpecific[country].privacyPolicyKey)}
                    target="_blank"
                    rel="noreferrer"
                    title={i18next.t("PRIVACY POLICY")}
                  >
                    <span>{i18next.t("PRIVACY POLICY")}</span>
                  </a>
                </li>
              ) : null}
              {i18next.exists(CountrySpecific[country].gtcUrlKey) ? (
                <li>
                  <a
                    href={i18next.t(CountrySpecific[country].gtcUrlKey)}
                    target="_blank"
                    rel="noreferrer"
                    title={i18next.t("GTC")}
                  >
                    <span>{i18next.t("GTC")}</span>
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
