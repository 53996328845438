import React from "react";
import { Pf, Product } from "../../graphcms/vo/graphCMS";
import { getAttributeValueWithUnit } from "../../graphcms/scripts/attribute";
import { devLogDebugOnce } from "../../common/scripts/logger/log";

function getPfText(pfs: Pf[]): string {
  const _pfsValue = pfs?.map(pf => pf.name.split(" ")[1]);
  return _pfsValue?.join(", ");
}

interface Props {
  product: Product;
}

const ProductAttributes: React.FC<Props> = ({ product }) => {
  devLogDebugOnce(product);

  return (
    <dl className="dl-sml">
      {product?.pfs?.length ? (
        <>
          <dt className="dl-sml__dt">PF:</dt>
          <dd className="dl-sml__dd">{getPfText(product.pfs)}</dd>
        </>
      ) : null}
      {product?.attributeValues?.map((attributeValue, i) => {
        return (
          <React.Fragment key={attributeValue?.attribute?.name + `-` + i}>
            <dt className="dl-sml__dt">{`${attributeValue?.attribute?.label ?? ""}:`}</dt>
            <dd className="dl-sml__dd">
              {getAttributeValueWithUnit(product, attributeValue?.attribute?.name ?? "")}
            </dd>
          </React.Fragment>
        );
      })}
    </dl>
  );
};

export default ProductAttributes;
