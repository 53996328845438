export enum ProductGroupViewType {
  "3I-1d" = "3I-1d", // 3 Spalten für ein grosses Bild, 1 Spalte für ein zusätzliches Attribut pro Zeile
  "3i-1d" = "3i-1d", // 3 Spalten für ein Bild pro Zeile, 1 Spalte für ein zusätzliches Attribut pro Zeile
  "2I-2d" = "2I-2d", // 2 Spalten für ein grosses Bild, 2 Spalten für ein zusätzliches Attribut pro Zeile
  "2i-2d" = "2i-2d", // 2 Spalten für ein Bild pro Zeile, 2 Spalten für zusätzliche Attribute pro Zeile
  "1I-3d" = "1I-3d", // 1 Spalte für ein grosses Bild, 3 Spalten für ein zusätzliches Attribut pro Zeile
  "1i-3d" = "1i-3d", // 1 Spalte für ein Bild pro Zeile, 3 Spalten für zusätzliche Attribute pro Zeile
  "4d" = "4d", // 4 Spalten für Attribute pro Zeile
  "4i" = "4i", // 4 Spalten ohne Attribute pro Zeile
  "4I" = "4I", // 4 Spalten für ein grosses Bild und ohne Attribute
}
