import { useEffect } from "react";
import { scrollTop } from "../scripts/scroll";
import { useNavigationType, useParams } from "react-router-dom";

export function useScrollTop() {
  const navType = useNavigationType();
  const params = useParams();

  // UX scroll top
  useEffect(() => {
    // Prevents Browser Back / Forward to trigger scroll
    if (navType === "PUSH") {
      scrollTop();
    }
  }, [navType, params]);
}
