import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Product from "../../state/vo/Product";

const name = "product";
const initialState = store.product;

const productSlice = createSlice({
  name,
  initialState,
  reducers: {
    setActiveSkuColumn(state: Product, action: PayloadAction<number>) {
      state.activeSkuColumn = action.payload;
    },
  },
});

export const setActiveSkuColumn = productSlice.actions.setActiveSkuColumn;

export default productSlice.reducer;
