import React from "react";
import i18next from "i18next";
import { useSelectedProduct } from "../hooks/product";

const ProductManual: React.FC = () => {
  const product = useSelectedProduct();

  let url = product?.sapUrlIfu ?? null;
  if (url === null) {
    return null;
  }

  // https://stackoverflow.com/questions/3543187/prepending-http-to-a-url-that-doesnt-already-contain-http
  if (!/^https?:\/\//i.test(url)) {
    url = "http://" + url;
  }

  return (
    <>
      <li>
        <a
          href={url}
          className="icon-link"
          target="_blank"
          rel="noreferrer"
          title={i18next.t("OPERATING MANUAL")}
        >
          <span className="icon-link-font icon-font icon-font__file" />
          {i18next.t("OPERATING MANUAL")}
        </a>
      </li>
    </>
  );
};

export default ProductManual;
