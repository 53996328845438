import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import Layout from "../../state/vo/Layout";
import State from "../../state/vo/State";

const SapMaintenance: React.FC = props => {
  const layout: Layout = useSelector((state: State) => state.layout);

  if (!layout.sapMaintenanceEnabled) {
    return null;
  }

  return (
    <p className="item-alert" style={{ color: "red" }}>
      {i18next.t("SAP_MAINTENANCE")}
      <span className="item-alert__icon icon-font icon-font__alert" aria-hidden="true"></span>
    </p>
  );
};

export default SapMaintenance;
