import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Search from "../../state/vo/Search";
import SearchResult from "../vo/SearchResult";

const name = "search";
const initialState = store.search;

const searchSlice = createSlice({
  name,
  initialState,
  reducers: {
    setQ(state: Search, action: PayloadAction<string>) {
      state.q = action.payload;
    },
    setResult(state: Search, action: PayloadAction<SearchResult>) {
      state.result = action.payload;
    },
    setResultLive(state: Search, action: PayloadAction<SearchResult>) {
      state.resultLive = action.payload;
    },
  },
});

export const setQ = searchSlice.actions.setQ;
export const setResult = searchSlice.actions.setResult;
export const setResultLive = searchSlice.actions.setResultLive;

export default searchSlice.reducer;
