import React from "react";
import i18next from "i18next";
import { OrderAttributes } from "../../commercelayer/vo/orders";
import { startPaymentProcess } from "../actions/orderActions";
import OrderDocument from "./OrderDocument";

interface Props {
  orderId: string | null;
  orderAttributes: OrderAttributes | null;
  showPaymentButton: boolean;
}

const OrderStatusSection: React.FC<Props> = ({ orderAttributes, orderId, showPaymentButton }) => {
  if (orderAttributes === null || orderId === null) {
    return null;
  }

  const orderDocuments = orderAttributes.metadata.documentflow ?? [];

  return (
    <div className="grid-wrap grid-wrap--checkout">
      <div className="grid-1-2">
        <dl className="dl-account">
          {showPaymentButton && (
            <>
              <dt className="dl-account__dt">{`${i18next.t("PAYMENT STATUS")}:`}</dt>
              <dd className="dl-account__dd">
                <strong>
                  {i18next.t(`CL_PAYMENT_STATUS_${orderAttributes.payment_status.toUpperCase()}`)}
                </strong>
              </dd>
            </>
          )}
          {orderAttributes.status !== "placed" &&
            orderAttributes.status !== "cancelled" &&
            !showPaymentButton && (
              <>
                <dt className="dl-account__dt">{`${i18next.t("STATUS")}:`}</dt>
                <dd className="dl-account__dd">
                  <strong>
                    {i18next.t(
                      `CL_FULFILLMENT_STATUS_${orderAttributes.fulfillment_status.toUpperCase()}`,
                    )}
                  </strong>
                </dd>
              </>
            )}
          {orderAttributes.status === "cancelled" && (
            <>
              <dt className="dl-account__dt">{`${i18next.t("STATUS")}:`}</dt>
              <dd className="dl-account__dd">
                <strong>{i18next.t(`CL_STATUS_${orderAttributes.status.toUpperCase()}`)}</strong>
              </dd>
            </>
          )}
          {orderAttributes.reference && (
            <>
              <dt className="dl-account__dt">{`${i18next.t("VBLN")}:`}</dt>
              <dd className="dl-account__dd">
                <strong>{orderAttributes.reference}</strong>
              </dd>
            </>
          )}
          {orderAttributes.metadata["s_sdo_hdr-bstnk"] && (
            <>
              <dt className="dl-account__dt">{i18next.t("ORDER REFERENCE")}</dt>
              <dd className="dl-account__dd">
                <strong>{orderAttributes.metadata["s_sdo_hdr-bstnk"]}</strong>
              </dd>
            </>
          )}
        </dl>
      </div>
      <div className="grid-1-2">
        {showPaymentButton && (
          <>
            <div
              className="btn "
              role="button"
              onClick={e => startPaymentProcess(orderId)}
              tabIndex={0}
            >
              <span className="btn__text">{i18next.t("PAY NOW")}</span>
            </div>
            <span className="form-error">{i18next.t("UNPAID CC ORDER MESSAGE")}</span>
          </>
        )}
        {orderDocuments && (
          <dl className="dl-account">
            {orderDocuments.map(document => {
              return (
                <OrderDocument
                  key={document.documentNumber}
                  orderId={orderId}
                  documentType={document.documentType}
                  documentNumber={document.documentNumber}
                />
              );
            })}
          </dl>
        )}
      </div>
    </div>
  );
};

export default OrderStatusSection;
