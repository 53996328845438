import React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  pageTitle: string;
  imageHandle: null | string;
  ogType: string;
  url: string;
}

const OgTagsAndTwitterCards: React.FC<Props> = ({ pageTitle, imageHandle, ogType, url }) => {
  return (
    <Helmet>
      <meta property="og:site_name" content="Thommen Medical Shop" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageTitle} />
      <meta property="og:url" content={url} />
      {imageHandle && <meta property="og:image" content={imageHandle} />}
      {ogType && <meta property="og:type" content={ogType} />}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageTitle} />
      {imageHandle && <meta name="twitter:image" content={imageHandle} />}
    </Helmet>
  );
};
export default OgTagsAndTwitterCards;
