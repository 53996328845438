import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import i18next from "i18next";
import { Trans } from "react-i18next";
import React from "react";

const InfoLink: React.FC<{ children?: string; infoText?: string }> = ({ children, infoText }) => {
  if (!children) return null;

  return (
    <a href={children} target="_blank" rel="noreferrer">
      <strong>{infoText ?? children}</strong>
    </a>
  );
};

const MethodInfoText: React.FC<{ method: ShippingMethods | PaymentMethods | null }> = ({
  method,
}) => {
  if (method !== null) {
    if (i18next.exists(`CL_METHOD_${method.attributes.reference}_INFO`)) {
      switch (method.attributes.reference) {
        case "I3":
        case "I4":
          return (
            <Trans i18nKey={`CL_METHOD_${method.attributes.reference}_INFO`}>
              <InfoLink infoText={i18next.t("LIST OF LOCATIONS")} />
            </Trans>
          );
        default:
          return <span>{i18next.t(`CL_METHOD_${method.attributes.reference}_INFO`)}</span>;
      }
    }
  }
  return null;
};

export default MethodInfoText;
