import React from "react";
import { Link } from "react-router-dom";
import { scrollTop } from "../scripts/scroll";

interface Props {
  text: string;
  to: string;
  icon: string;
  additionalLinkClass?: string;
}

const NavListItemIconLink: React.FC<Props> = ({ text, to, icon, additionalLinkClass = "" }) => {
  return (
    <li className="nav-list__item">
      <Link
        to={to}
        className={`icon-link ${additionalLinkClass} nav-list__link icon-link--underline`}
        title={text}
        onClick={() => scrollTop()}
      >
        {icon && (
          <>
            <span className={"icon-link-font icon-font icon-font__" + icon} />
            &nbsp;
          </>
        )}
        {text}
      </Link>
    </li>
  );
};

export default NavListItemIconLink;
