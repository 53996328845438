import ElstrIo from "elstr-frontend-4/dist/ElstrIo";

export default class LayerIo {
  static async requestJsonRpc<P, D>(
    className: string,
    methodName: string,
    params: P,
    requestMethod = "POST",
  ): Promise<ElstrJsonRpcResult<D>> {
    try {
      return ElstrIo.requestJsonRpc<P, ElstrJsonRpcResult<D>>(
        className,
        methodName,
        params,
        requestMethod,
      );
    } catch (e) {
      throw e;
    }
  }
}

export interface ElstrJsonRpcResult<D> {
  success: boolean;
  data: D | null;
  messages: any[];
  meta: any;
}
