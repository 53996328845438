import React from "react";
import i18next from "i18next";

const ConsignmentStockOrderHeader: React.FC = () => {
  return (
    <div className="cart__header">
      <p className="cart-header__title">{i18next.t("PRODUCT", { count: 1 })}</p>
      <p className="cart-header__title">{i18next.t("BATCH NUMBER")}</p>
      <p className="cart-header__title">{i18next.t("TOTAL")}</p>
    </div>
  );
};

export default ConsignmentStockOrderHeader;
