import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import ChangePassword from "../../common/components/ChangePassword";

const ResetPassword: React.FC = () => {
  const params = useParams();
  const customerPasswordResetsId: string = params[PARAMS.CUSTOMER_PASSWORD_RESETS_ID] ?? "";
  const token: string = params[PARAMS.TOKEN] ?? "";

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <Middle>
          <div className="grid-wrap grid-wrap--line">
            <div className="grid-1-2">
              <ChangePassword customerPasswordResetsId={customerPasswordResetsId} token={token} />
            </div>

            <div className="grid-1-2" />
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default ResetPassword;
