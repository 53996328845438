import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { goBack, push, replace } from "redux-first-history";
import { getToggledSearch } from "../scripts/search";
import { Path } from "react-router-dom";

export function boundPushLocObj(path: Partial<Path>) {
  dispatch(push(path));
}

export function boundReplaceLocObj(path: Partial<Path>) {
  dispatch(replace(path));
}

export function boundGoBack() {
  dispatch(goBack());
}

export function toggleSearchReplace(key: string, value: string) {
  const search = getToggledSearch(key, value);
  boundReplaceLocObj({ search });
}
