import React from "react";
import State from "../../state/vo/State";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Focus from "./Focus";
import GridText from "./GridText";
import GridImg from "./GridImg";
import { PARAMS } from "../../router/constants/Params";
import { getLinkToCategory, getLinkToProductGroup } from "../scripts/link";
import { getRelatedCategory } from "../../graphcms/scripts/category";
import { scrollTop } from "../../layout/scripts/scroll";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";
import { getValuesInUrlList } from "../../router/scripts/search";
import { SEARCH } from "../../router/constants/Search";
import {
  getCategoriesVisible,
  getHiddenCategoriesFromMainParent,
  getHiddenCategoriesFromSubCategoryParent,
  getProductGroupsFromCategory,
  hasDisplayableItems,
} from "../scripts/filter";

const MidCategory: React.FC = () => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const params = useParams();
  const parentId = params?.[PARAMS.CATEGORY_ID] ?? null;
  const countryCode = params?.[PARAMS.COUNTRY] ?? null;

  // no parent category selected
  if (!parentId) {
    return null;
  }

  // no category selected
  if (!countryCode) {
    return null;
  }

  const category = getRelatedCategory(categoriesTree, parentId);

  // abort on invalid input
  if (!category?.categories?.filter) {
    return null;
  }

  return (
    <>
      <Focus />

      <h1 className="col-title mobile-only">{category?.name ?? ""}</h1>

      <div className="grid-wrap">
        {/* non hidden categoriesTree */}
        {getCategoriesVisible(category)
          .filter(category => hasDisplayableItems(category, params))
          .map(category => {
            const isBlur = getIsBlurFromCategories(category);

            return (
              <Link
                key={category.id}
                className={`grid grid-1-3 ${isBlur ? "is-blur" : ""}`}
                to={getLinkToCategory(category)}
                onClick={() => scrollTop()}
              >
                <GridImg handle={category?.image?.handle} alt={category?.name} />
                <GridText item={category} />
              </Link>
            );
          })}

        {getHiddenCategoriesFromMainParent(category, params).map(childCategory => {
          const isBlur = getIsBlurFromCategories(childCategory);

          return (
            <Link
              key={childCategory.id}
              className={`grid grid-1-3 ${isBlur ? "is-blur" : ""}`}
              to={getLinkToCategory(childCategory)}
              onClick={() => scrollTop()}
            >
              <GridImg
                handle={childCategory?.productGroups?.[0]?.tileImage?.handle ?? ""}
                alt={childCategory?.productGroups?.[0]?.name}
              />
              <GridText item={childCategory?.productGroups[0] ?? ""} />
            </Link>
          );
        })}

        {getHiddenCategoriesFromSubCategoryParent(category, params).map(childCategory => {
          const isBlur = getIsBlurFromCategories(childCategory);

          return (
            <Link
              key={childCategory.id}
              className={`grid grid-1-3 ${isBlur ? "is-blur" : ""}`}
              to={getLinkToProductGroup(childCategory?.id, childCategory?.productGroups[0] ?? {})}
              onClick={() => scrollTop()}
            >
              <GridImg
                handle={childCategory?.productGroups?.[0]?.tileImage?.handle ?? ""}
                alt={childCategory?.productGroups?.[0]?.name}
              />
              <GridText item={childCategory?.productGroups[0] ?? {}} />
            </Link>
          );
        })}
        {/* productGroups from category */}
        {getProductGroupsFromCategory(category, params).map(productGroup => {
          const isBlur = getIsBlurFromProductGroup(productGroup);

          return (
            <Link
              key={productGroup.id}
              className={`grid grid-1-3 ${isBlur ? "is-blur" : ""}`}
              to={getLinkToProductGroup(category?.id, productGroup)}
              onClick={() => scrollTop()}
            >
              <GridImg handle={productGroup?.tileImage?.handle ?? ""} alt={productGroup?.name} />
              <GridText item={productGroup} />
            </Link>
          );
        })}
      </div>
    </>
  );
};

function getAllFoundPfsFromCategory(category: Category) {
  const allFoundPfs: string[] = [];

  // when hidden, only look in first productGroup
  if (category?.hidden) {
    if (category?.productGroups?.[0]) {
      category.productGroups[0]?.pfs?.forEach(pf => {
        allFoundPfs.push(pf?.name);
      });
    }
  }

  // case when not hidden, check all productGroups
  if (!category?.hidden) {
    if (category?.productGroups) {
      category?.productGroups?.forEach(productGroup => {
        productGroup?.pfs?.forEach(pf => {
          allFoundPfs.push(pf?.name);
        });
      });
    }
  }

  if (category?.categories?.length > 0) {
    category?.categories?.forEach(category => {
      allFoundPfs.push(...getAllFoundPfsFromCategory(category));
    });
  }

  return allFoundPfs;
}

function getIsBlurFromCategories(category: Category): boolean {
  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);

  if (focus?.length === 0) {
    return false;
  }

  // make default to blur when at this stage
  let isBlur = true;

  // get all pfs down the category tree
  // get only unique values
  const allFoundPfs = [...new Set(getAllFoundPfsFromCategory(category))];

  focus?.forEach(pfName => {
    if (allFoundPfs?.includes(pfName)) {
      isBlur = false;
    }
  });

  return isBlur;
}

function getIsBlurFromProductGroup(productGroup: ProductGroup) {
  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);

  if (focus?.length === 0) {
    return false;
  }

  // make default to blur when at this stage
  let isBlur = true;

  const allFoundPfs = productGroup?.pfs?.map(pf => pf.name);

  focus.forEach(pfName => {
    if (allFoundPfs?.includes(pfName)) {
      isBlur = false;
    }
  });

  return isBlur;
}

export default MidCategory;
