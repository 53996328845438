import React from "react";
import { useInventory } from "../hooks";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import InventoryCategory from "./InventoryCategory";

const Inventory: React.FC = () => {
  const { inventoryItems } = useInventory();
  const productsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const overlayOpen = useSelector((state: State) => state.layout.overlayOpen);
  const orderId = useSelector((state: State) => state.consignmentStock.order.id);

  if (inventoryItems.length === 0) {
    return null;
  }

  // no orderId means not initialized which means no lineItems available to show already selected items
  if (!orderId) {
    return null;
  }

  // force rerender when getting back from Scan in order to show state changes
  if (overlayOpen !== null) {
    return null;
  }

  const inventoryCategoryIds = inventoryItems
    // make sure sku_code exist and valid
    .filter(item => !!item?.attributes?.sku_code)
    .map(item => item.attributes.sku_code)
    .map(sku => productsIndex[sku]?.productGroup?.breadcrumbCategories[0]);

  if (!categoriesTree) return null;

  return (
    <>
      {categoriesTree
        .filter(category => inventoryCategoryIds.includes(category.id))
        .map(category => (
          <InventoryCategory key={category.id} category={category} />
        ))}
    </>
  );
};

export default Inventory;
