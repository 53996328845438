import { Category, ProductGroup, Product } from "../../graphcms/vo/graphCMS";
import { PATH } from "../../router/constants/Path";
import { getLangCountry } from "../../router/scripts/link";
import { FALLBACK, PARAMS } from "../../router/constants/Params";

export function getLinkToCategory(category: Category): string {
  return `${getLangCountry()}/${PATH.CATEGORY}/${category.id}/${encodeURIComponent(category.name)}`;
}

export function getLinkToProductGroup(
  categoryId = FALLBACK[PARAMS.CATEGORY_ID],
  productGroup: ProductGroup,
): string {
  return `${getLangCountry()}/${PATH.CATEGORY}/${categoryId}/${PATH.PRODUCTGROUP}/${
    productGroup?.id || FALLBACK[PARAMS.PRODUCTGROUP_ID]
  }/${encodeURIComponent(productGroup?.slug || FALLBACK[PARAMS.PRODUCTGROUP_SLUG])}`;
}

export function getLinkToDirectProduct(product: Product): string {
  return `${getLangCountry()}/${PATH.PRODUCT}/${product?.sku}/${encodeURIComponent(
    product?.slug || FALLBACK[PARAMS.PRODUCT_SLUG],
  )}`;
}
