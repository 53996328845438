import React, { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PARAMS } from "../constants/Params";
import ReRoot from "./redirect/ReRoot";
import { PATH } from "../constants/Path";
import Category from "../../category/components/Category";
import ProductMain from "../../product/components/ProductMain";
import Login from "../../login/components/Login";
import LoadingPage from "../../layout/components/LoadingPage";
import Account from "../../account/components/Account";
import Cart from "../../cart/components/Cart";
import Signup from "../../signup/components/Signup";
import AccountOrders from "../../account/components/AccountOrders";
import Search from "../../search/components/Search";
import Checkout from "../../checkout/components/Checkout";
import ShippingPayment from "../../checkout/components/ShippingPayment";
import Verification from "../../checkout/components/Verification";
import OrderDetail from "../../order/components/OrderDetail";
import ResetPassword from "../../login/components/ResetPassword";
import Onboarding from "../../signup/components/Onboarding";
import { useInitGraphCMS } from "../../graphcms/hooks/init";
import AccountReport from "../../account/components/AccountReport";
import AccountAdvanceSale from "../../account/components/AccountAdvanceSale";
import ConsignmentStock from "../../consignment-stock/components/ConsignmentStock";
import ConsignmentStockSending from "consignment-stock/components/ConsignmentStockSending";
import ConsignmentStockVerification from "../../consignment-stock/components/ConsignmentStockVerification";
import ConsignmentStockAddresses from "../../consignment-stock/components/ConsignmentStockAddresses";
import ConsignmentStockShippingPayment from "../../consignment-stock/components/ConsignmentStockShippingPayment";

// https://blog.logrocket.com/speed-up-react-app-dynamic-imports-route-centric-code-splitting/
const ScanToCart = lazy(() => import("../../scan/components/ScanToCart"));
const Configurator = lazy(() => import("../../configurator/components/Configurator"));

const SalesAgentLogin = lazy(() => import("../../salesagent/components/SalesAgentLogin"));
const SalesAgentCustomers = lazy(() => import("../../salesagent/components/SalesAgentCustomers"));
const SalesAgentReport = lazy(() => import("../../salesagent/components/SalesAgentReport"));

const Router: React.FC = () => {
  useInitGraphCMS();

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        {/* Standard routes */}
        <Route path={`/`} element={<Category />} />
        <Route
          path={`/${PATH.CATEGORY}/:${PARAMS.CATEGORY_ID}/:${PARAMS.CATEGORY_SLUG}`}
          element={<Category />}
        />

        {/* ProductGroup only */}
        <Route
          path={`/${PATH.CATEGORY}/:${PARAMS.CATEGORY_ID}/${PATH.PRODUCTGROUP}/:${PARAMS.PRODUCTGROUP_ID}/:${PARAMS.PRODUCTGROUP_SLUG}/`}
          element={<ProductMain />}
        />
        {/* Indirect product link */}
        <Route
          path={`/${PATH.CATEGORY}/:${PARAMS.CATEGORY_ID}/${PATH.PRODUCTGROUP}/:${PARAMS.PRODUCTGROUP_ID}/${PATH.PRODUCT}/:${PARAMS.SKU}/:${PARAMS.PRODUCT_SLUG}?`}
          element={<ProductMain />}
        />
        {/* Direct product link */}
        <Route
          path={`/${PATH.PRODUCT}/:${PARAMS.SKU}/:${PARAMS.PRODUCT_SLUG}?`}
          element={<ProductMain />}
        />

        <Route path={`/${PATH.LOGIN}`} element={<Login />} />
        <Route path={`/${PATH.SIGNUP}`} element={<Signup />} />
        <Route
          path={`/${PATH.RESET_PASSWORD}/:${PARAMS.CUSTOMER_PASSWORD_RESETS_ID}/:${PARAMS.TOKEN}`}
          element={<ResetPassword />}
        />
        <Route path={`/${PATH.ONBOARDING}/:${PARAMS.EMAIL}`} element={<Onboarding />} />

        <Route path={`/${PATH.CONFIGURATOR}`} element={<Configurator />} />
        <Route path={`/${PATH.SCANTOCART}/:${PARAMS.SCAN_ID}?`} element={<ScanToCart />} />

        <Route path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}`} element={<Account />} />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.ORDERS}`}
          element={<AccountOrders />}
        />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.REPORT}`}
          element={<AccountReport />}
        />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.ADVANCE_SALE}`}
          element={<AccountAdvanceSale />}
        />
        {/*consignment-stock routes*/}
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.CONSIGNMENT_STOCK}`}
          element={<ConsignmentStock />}
        />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.CONSIGNMENT_STOCK}/:${PARAMS.ORDER_ID}/${PATH.ADDRESS}`}
          element={<ConsignmentStockAddresses />}
        />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.CONSIGNMENT_STOCK}/:${PARAMS.ORDER_ID}/${PATH.SHIPPING_PAYMENT}`}
          element={<ConsignmentStockShippingPayment />}
        />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.CONSIGNMENT_STOCK}/:${PARAMS.ORDER_ID}/${PATH.VERIFICATION}`}
          element={<ConsignmentStockVerification />}
        />
        <Route
          path={`/${PATH.ACCOUNT}/:${PARAMS.ACCOUNT_ID}/${PATH.CONSIGNMENT_STOCK}/:${PARAMS.SCAN_ID}`}
          element={<ConsignmentStockSending />}
        />

        <Route path={`/${PATH.CART}/:${PARAMS.ORDER_ID}?`} element={<Cart />} />

        <Route path={`/${PATH.SEARCH}/:${PARAMS.SEARCH_TERM}?`} element={<Search />} />

        <Route path={`/${PATH.CHECKOUT}/:${PARAMS.ORDER_ID}`} element={<Checkout />} />
        <Route
          path={`/${PATH.CHECKOUT}/:${PARAMS.ORDER_ID}/${PATH.SHIPPING_PAYMENT}`}
          element={<ShippingPayment />}
        />
        <Route
          path={`/${PATH.CHECKOUT}/:${PARAMS.ORDER_ID}/${PATH.VERIFICATION}`}
          element={<Verification />}
        />

        <Route path={`/${PATH.ORDER}/:${PARAMS.ORDER_ID}`} element={<OrderDetail />} />

        <Route path={`/${PATH.SALES_AGENT}/${PATH.LOGIN}`} element={<SalesAgentLogin />} />
        <Route path={`/${PATH.SALES_AGENT}/${PATH.CUSTOMERS}`} element={<SalesAgentCustomers />} />
        <Route path={`/${PATH.SALES_AGENT}/${PATH.REPORT}`} element={<SalesAgentReport />} />

        {/* Fallback when no route matched */}
        <Route path="*" element={<ReRoot />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
