import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getNumberWithCurrency } from "../scripts/format/number";
import { Product } from "../../graphcms/vo/graphCMS";

interface Props {
  product: Product;
}

const PriceTag: React.FC<Props> = ({ product }) => {
  const commercelayerSkusIndex = useSelector((state: State) => state.commercelayer.skusIndex);

  const { sku } = product;

  const amount_float: number = commercelayerSkusIndex[sku]?.price?.attributes.amount_float ?? -1;
  const currency_code = commercelayerSkusIndex[sku]?.price?.attributes.currency_code ?? "";

  return (
    <div className="price">
      {commercelayerSkusIndex[sku] && amount_float >= 0 && (
        <>
          <span className="price__label">{i18next.t("UNIT PRICE")}</span>
          <p className="price__text">{getNumberWithCurrency(amount_float, currency_code)}</p>
        </>
      )}
    </div>
  );
};

// kept for historical reasons
export const NotAvailable: React.FC<Props> = () => {
  return (
    <p className="item-alert">
      {i18next.t("NOT AVAILABLE ANYMORE")}
      <span className="item-alert__icon icon-font icon-font__alert" aria-hidden="true" />
    </p>
  );
};

export default PriceTag;
