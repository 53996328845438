import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import MainHeader from "../../layout/components/Col/MainHeader";
import i18next from "i18next";
import CheckoutBreadcrumb from "./CheckoutBreadcrumb";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { initCheckout } from "../actions/checkoutActions";
import { getCheckoutLinks } from "../scripts/links";
import Addresses from "./Addresses";
import { scrollTop } from "layout/scripts/scroll";
import Cart from "../../state/vo/Cart";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import Account from "../../state/vo/Account";
import { setSapMaintenance } from "../../layout/actions/layoutActions";
import SapMaintenance from "../../layout/components/SapMaintenance";
import UserNotifications from "../../layout/components/UserNotifications";

const Checkout: React.FC = () => {
  const params = useParams();
  const cartId = params[PARAMS.ORDER_ID];
  const cart: Cart = useSelector((state: State) => state.cart);
  const account: Account = useSelector((state: State) => state.account);
  const checkoutLinks = getCheckoutLinks(cartId);
  const [showForm, setShowForm] = React.useState(false);

  useEffect(() => {
    if (cartId !== null) {
      initCheckout(cartId);
    }
  }, [cartId]);

  useEffect(() => {
    setSapMaintenance();
  }, []);

  const onClickNewAddress = function (e) {
    setShowForm(true);
    scrollTop();
  };

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <MainHeader title={i18next.t("CHECKOUT")} desktopShowClose={false} toHomeOnClose={true} />
      <Body>
        <Middle>
          <CheckoutBreadcrumb activeStep={0} links={checkoutLinks} />
          <SapMaintenance />
          <UserNotifications />
          <Addresses cart={cart} account={account} showForm={showForm} setShowForm={setShowForm} />
          <div className="btn-wrap btn-wrap--line">
            <div className="grid-1-2">
              {!showForm && (
                <div className="btn transparent" onClick={onClickNewAddress}>
                  <span className="btn__text">{i18next.t("ADD NEW DELIVERY ADDRESS")}</span>
                </div>
              )}
            </div>
            <div className="grid-1-2 grid-1-2--right">
              <Link
                to={checkoutLinks[1]}
                className="btn "
                role="button"
                tabIndex={0}
                onClick={() => scrollTop()}
              >
                <span className="btn__text">{`${i18next.t("SHIPPING")} & ${i18next.t(
                  "PAYMENT",
                )}`}</span>
              </Link>
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Checkout;
