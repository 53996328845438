import { Product } from "../../graphcms/vo/graphCMS";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { PARAMS } from "../../router/constants/Params";
import { devLogDebugOnce } from "../../common/scripts/logger/log";

export function useSelectedProduct(): Product | null {
  const [product, setProduct] = useState<Product | null>(null);

  const params = useParams();
  const productsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const sku = params[PARAMS.SKU];

  useEffect(() => {
    if (!sku) {
      setProduct(null);
      return;
    }

    const _product = productsIndex?.[sku];

    if (!_product) {
      setProduct(null);
      return;
    }

    setProduct(_product);
  }, [params, productsIndex, sku]);

  if (!product && sku && productsIndex) {
    devLogDebugOnce(`no product found with sku: (${sku})`);
  }

  return product;
}
