import React from "react";
import { logSentryAndConsole } from "../scripts/logger/log";
import { Link } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

export default class AppErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logSentryAndConsole(error.message, "error");
  }

  render() {
    if (this.state.hasError) {
      return (
        <Link to="/" onClick={() => this.setState({ hasError: false })}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            Please try again later. If this error occurs again please contact us by e-mail:{" "}
            <a href="mailto:info@thommenmedical.com">info@thommenmedical.com</a>.
          </p>
        </Link>
      );
    }

    return this.props.children;
  }
}
