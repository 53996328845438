export const PfClassName = {
  "PF 3.0": "3-0",
  "PF 3.5": "3-5",
  "PF 4.0": "4-0",
  "PF 4.5": "4-5",
  "PF 5.0": "5-0",
  "PF 6.0": "6-0",
};

export default PfClassName;
