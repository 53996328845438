import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { HeaderYLeft } from "./HeaderYLeft";
import { HeaderYRight } from "./HeaderYRight";
import { HeaderYContent } from "./HeaderYContent";

interface Props {
  productGroup: ProductGroup;
}

export const HeaderY: React.FC<Props> = ({ productGroup }) => {
  return (
    <tr className="tbody__th-row ">
      <HeaderYLeft productGroup={productGroup} />
      <HeaderYContent productGroup={productGroup} />
      <HeaderYRight productGroup={productGroup} />
    </tr>
  );
};
