import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Checkout from "../../state/vo/Checkout";

const name = "checkout";
const initialState = store.checkout;

const checkoutSlice = createSlice({
  name,
  initialState,
  reducers: {
    setGtc(state: Checkout, action: PayloadAction<boolean>) {
      state.gtc = action.payload;
    },
    setGtcError(state: Checkout, action: PayloadAction<string | null>) {
      state.gtcError = action.payload;
    },
    setBstnk(state: Checkout, action: PayloadAction<string>) {
      state.bstnk = action.payload;
    },
    setCouponCode(state: Checkout, action: PayloadAction<string>) {
      state.couponCode = action.payload;
    },
    setCouponCodeError(state: Checkout, action: PayloadAction<string | null>) {
      state.couponCodeError = action.payload;
    },
    setOrderProcessing(state: Checkout, action: PayloadAction<boolean>) {
      state.orderProcessing = action.payload;
    },
    setOrderUpdating(state: Checkout, action: PayloadAction<boolean>) {
      state.orderUpdating = action.payload;
    },
  },
});

export const setGtc = checkoutSlice.actions.setGtc;
export const setGtcError = checkoutSlice.actions.setGtcError;
export const setBstnk = checkoutSlice.actions.setBstnk;
export const setCouponCode = checkoutSlice.actions.setCouponCode;
export const setCouponCodeError = checkoutSlice.actions.setCouponCodeError;
export const setOrderProcessing = checkoutSlice.actions.setOrderProcessing;
export const setOrderUpdating = checkoutSlice.actions.setOrderUpdating;

export default checkoutSlice.reducer;
