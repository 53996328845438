import React, { useCallback, useEffect, useState } from "react";
import Layout from "../../layout/components/Layout";
import Footer from "../../layout/components/Footer";
import i18next from "i18next";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { OVERLAY } from "../../layout/constants/Overlay";
import { toast } from "react-toastify";
import { logSentryAndConsole } from "../../common/scripts/logger/log";
import scanFromCst from "../../consignment-stock/services/scanFromCst";
import { setOverlayOpenAction } from "../../layout/actions/layoutActions";
import { ScannedInventory } from "../vo/ConsignmentStock";
import OverlayPopupScanFromCstMobile from "../../scan/components/OverlayPopupScanFromCstMobile";
import { extractParsedCodeItems, getParsedCodeItems } from "../scripts/scan";

// this should belong to a mobile device with a camera for scanning
let intervalScanningSendingDevice;

const ConsignmentStockSending: React.FC = () => {
  const scanId = useParams()[PARAMS.SCAN_ID];
  const [scannedInventory, setScannedInventory] = useState<ScannedInventory[]>([]);
  const productsIndex = useSelector((state: State) => state.graphcms.productsIndex);

  useEffect(
    function initCamera() {
      // don't start the scan function when the index isn't ready.
      // otherwise the productsIndex will always remain uninitialized
      if (Object.getOwnPropertyNames(productsIndex).length === 0) {
        return;
      }

      setOverlayOpenAction(OVERLAY.CAMERA);
    },
    [productsIndex],
  );

  // this is the effect that will send scanned productSkus to the application backend
  useEffect(() => {
    async function handleScanningDevice() {
      async function handleScanningDeviceUserNavigation() {
        if (!scanId) return;
        const response = await scanFromCst.getScanSkus(scanId);

        if (!response) {
          return;
        }

        if (!response?.success) {
          return;
        }

        // scanSkus is null here means the receiving device has aborted the scanning process
        if (response?.data?.scanSkus === null) {
          window.close();
          clearInterval(intervalScanningSendingDevice);
          toast.warning(i18next.t("CONNECTION LOST CLOSE TAB"), {
            position: "top-center",
          });
        }
      }

      // update backend with scanned productSkus
      // an empty array will serve as init
      // it indicates for all devices that the scanning process has initialized and all devices are ready
      if (!scanId) return;
      await scanFromCst.setScanSkus(scanId, scannedInventory ?? []);

      // create polling function to detect if scanning is still active on host or otherwise handle a user navigation
      if (!intervalScanningSendingDevice) {
        intervalScanningSendingDevice = setInterval(() => {
          handleScanningDeviceUserNavigation().then();
        }, 2500);
      }
    }

    handleScanningDevice().then();
  }, [scanId, scannedInventory]);

  const onScan = useCallback(
    function (scanResult: string) {
      const parsedCodeItems = getParsedCodeItems(scanResult);

      if (!parsedCodeItems) {
        toast.error("Something went wrong while scanning. Please reconnect and try again.", {
          position: "top-center",
          autoClose: 3330,
        });

        return;
      }

      const { gtin, batchNumber, expiry } = extractParsedCodeItems(parsedCodeItems);

      if (!batchNumber || !gtin) {
        logSentryAndConsole("Barcode decoding went wrong", "error");
        return;
      }

      const products = Object.values(productsIndex);
      const product = products.find(product => product.gtin13 === gtin);

      if (!product) {
        logSentryAndConsole(`No Product Found with gtin ${gtin} while scanning`, "error");
        return;
      }

      const { sku } = product;

      setScannedInventory(scannedInventory => [...scannedInventory, { sku, batchNumber, expiry }]);

      toast.info(i18next.t("PRODUCT DETECTED"), {
        position: "top-center",
        autoClose: 2000,
      });
    },
    [productsIndex],
  );

  useEffect(
    function updateBackend() {
      if (!scanId) return;
      scanFromCst.setScanSkus(scanId, scannedInventory).then();
    },
    [scannedInventory, scanId],
  );

  const shutdownScanning = useCallback(async () => {
    // clear and reset all intervals
    clearInterval(intervalScanningSendingDevice);
    intervalScanningSendingDevice = null;

    setScannedInventory([]);
  }, []);

  // handle shutdown on scan page leaving
  useEffect(
    function cleanup() {
      return () => {
        // shutdown when leaving consignment stock
        if (!window.location.pathname.includes(PATH.CONSIGNMENT_STOCK)) {
          shutdownScanning().then();
        }
      };
    },
    [shutdownScanning],
  );

  return (
    <Layout>
      <OverlayPopupScanFromCstMobile onScan={onScan} scannedInventory={scannedInventory} />
      <Footer />
    </Layout>
  );
};

export default ConsignmentStockSending;
