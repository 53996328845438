import React from "react";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";
import { getNrOfPfs } from "../../graphcms/scripts/productGroup";
import ProductTableAggregator from "./ProductTableAggregator";
import { getUniqueSortedAttrValues } from "../../graphcms/scripts/attribute";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

interface Props {
  productGroup: ProductGroup;
  relatedCategory?: Category;
}

const ProductTable: React.FC<Props> = ({ productGroup, relatedCategory }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  return (
    <div className={`product-table-wrap`}>
      <table className={`product-table ${getColSpacing(productGroup, isMobile)}`}>
        <ProductTableAggregator productGroup={productGroup} relatedCategory={relatedCategory} />
      </table>
    </div>
  );
};

function getColSpacing(productGroup: ProductGroup, isMobile: boolean): string {
  const nrOfPfs = getNrOfPfs(productGroup);

  let indicator;

  if (nrOfPfs >= 0) {
    indicator = nrOfPfs;
  }

  if (nrOfPfs === 0) {
    productGroup?.xAxisAttributes?.forEach((xAttribute, i) => {
      const xAttrName = xAttribute?.name;

      if (!xAttrName) {
        return null;
      }

      const sortedValuesWithUnit = getUniqueSortedAttrValues(productGroup, xAttrName);

      indicator = sortedValuesWithUnit.length;
    });
  }

  if (indicator === 6) return "";
  if (indicator === 5) return "pf-col-5";
  if (indicator === 4) return "pf-col-4";
  if (indicator === 3) return "pf-col-3";
  if (indicator === 2) return "pf-col-2";
  // leave out pf-col-1 on mobile since it takes much of the screen space
  if (indicator === 1 && !isMobile) return "pf-col-1";

  return "";
}

export default ProductTable;
