import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import i18next from "i18next";
import LineItemList from "./LineItemList";
import CartCheckout from "./CartCheckout";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SearchOverlay from "../../search/components/SearchOverlay";
import CartHeader from "./CartHeader";
import CartEmpty from "./CartEmpty";
import MainHeader from "../../layout/components/Col/MainHeader";

const Cart: React.FC = () => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const cart = useSelector((state: State) => state.cart);

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <MainHeader title={i18next.t("CART")} desktopShowClose={true} toHomeOnClose={false} />
      <Body>
        <Middle>
          <div className="cart main__cart">
            <div className="form cart__form">
              <div className="cart-wrap">
                {cart.counter > 0 ? (
                  <div className="cart cart--checkout ">
                    <div className="cart-wrap">
                      <CartHeader />
                      {categoriesTree.map((category, index) => (
                        <LineItemList
                          cartId={cart.id}
                          category={category}
                          lineItems={cart.lineItemsSkus}
                          showUnitAmount={true}
                          showBatchNumber={false}
                          showAvailabilityAlert={true}
                          quantityIsEditable={true}
                          key={index}
                        />
                      ))}
                      <LineItemList
                        cartId={cart.id}
                        category={null}
                        lineItems={cart.lineItemsSkus}
                        showUnitAmount={true}
                        showBatchNumber={false}
                        showAvailabilityAlert={true}
                        quantityIsEditable={true}
                        key={-1}
                      />
                    </div>
                  </div>
                ) : (
                  <CartEmpty />
                )}
              </div>
              <CartCheckout cart={cart} />
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Cart;
