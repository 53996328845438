import React from "react";
import { Link } from "react-router-dom";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { getLinkToProductGroup } from "../../category/scripts/link";
import { scrollTop } from "../../layout/scripts/scroll";

interface Props {
  productGroup: ProductGroup;
}

const SearchListProductGroupItem: React.FC<Props> = ({ productGroup }) => {
  const handle = productGroup.tileImage?.handle;

  return (
    <Link
      to={getLinkToProductGroup(productGroup.categories[0]?.id, productGroup)}
      className="list-item list-item--link"
      onClick={() => scrollTop()}
    >
      <div className="img-wrap list-item__img-wrap">
        {handle !== undefined && (
          <picture data-crop="false" data-quality="high" data-upscale="false">
            <source
              media="screen and (min-width:1023px)"
              srcSet={`https://media.graphassets.com/auto_image/resize=height:50,width:50/${handle}`}
            />
            <source
              media="screen and (max-width:1023px)"
              srcSet={`https://media.graphassets.com/auto_image/resize=height:50,width:50/${handle}`}
            />
            <source
              media="screen and (max-width:400px)"
              srcSet={`https://media.graphassets.com/auto_image/resize=height:50,width:50/${handle}`}
            />
            <img
              className="img"
              alt={productGroup.name ?? ""}
              src={`https://media.graphassets.com/auto_image/resize=height:50,width:50/${handle}`}
            />
          </picture>
        )}
      </div>

      <div className="list-item__text-wrap">
        <h3 className="item-title">{productGroup.name}</h3>
      </div>
    </Link>
  );
};

export default SearchListProductGroupItem;
