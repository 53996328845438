import { useNavigate, useParams } from "react-router-dom";
import React, { useLayoutEffect } from "react";
import { PATH } from "../../constants/Path";
import { FALLBACK, PARAMS } from "../../constants/Params";
import { useLangCountry } from "../../hooks/useLangCountry";

const ReOrder: React.FC = () => {
  const langCountry = useLangCountry();
  const params = useParams();
  const navigate = useNavigate();
  const orderId = params?.[PARAMS.ORDER_ID] ?? FALLBACK[PARAMS.ORDER_ID];

  useLayoutEffect(() => {
    navigate(`${langCountry}/${PATH.ORDER}/${orderId}`);
  }, [langCountry, navigate, orderId]);

  return null;
};

export default ReOrder;
