import React from "react";
import { getAccessTokenMarketAll } from "../../commercelayer/scripts/token";
import i18next from "i18next";

interface Props {
  orderId: string;
  documentType: string;
  documentNumber: string;
}

const OrderDocument: React.FC<Props> = ({ orderId, documentType, documentNumber }) => {
  const downloadUrl = `${window.location.origin}/api/order/document/download?order_id=${orderId}&document_number=${documentNumber}`;
  const documentTypeLabel = i18next.t(`SAP_DOCUMENT_TYPE ${documentType.toUpperCase()}`);

  const onClickDocument = async e => {
    e.preventDefault();
    const accessToken = await getAccessTokenMarketAll();

    // https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
    fetch(downloadUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
        Authorization: `Bearer ${accessToken ?? "NO-ACCESS-TOKEN"}`,
      },
    })
      .then(response => response.blob())
      .then(blob => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${documentTypeLabel} ${documentNumber}.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        document.body.removeChild(link);
      });
  };

  return (
    <>
      <dt className="dl-account__dt">{documentTypeLabel}</dt>
      <dd className="dl-account__dd">
        <strong>
          <a href={downloadUrl} target="_blank" rel="noreferrer" onClick={onClickDocument}>
            {documentNumber}
          </a>
        </strong>
      </dd>
    </>
  );
};

export default OrderDocument;
