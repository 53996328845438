import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { setOverlayOpenAction } from "../actions/layoutActions";

interface Props {
  showClose: boolean;
  children: React.ReactNode;
  activeButtonShow?: boolean;
  activeButtonLabel?: string;
  activeButtonOnClick?: (any) => any;
  transparentButtonShow?: boolean;
  transparentButtonLabel?: string;
  transparentButtonOnClick?: (any) => any;
}

const OverlayPopup: React.FC<Props> = ({
  showClose = true,
  children,
  activeButtonShow = true,
  activeButtonLabel = "",
  activeButtonOnClick = () => {},
  transparentButtonShow = false,
  transparentButtonLabel = "",
  transparentButtonOnClick = () => {},
}) => {
  const layoutOverlayOpen = useSelector((state: State) => state.layout.overlayOpen);
  const overlayStyle = {
    top: "12rem",
    transform: "translate3d(-50%, 0, 0)",
  };

  if (layoutOverlayOpen === null) {
    return null;
  }

  return (
    <div className="overlay-popup" style={overlayStyle}>
      {showClose && (
        <div
          className="icon-btn icon-btn__close"
          role="button"
          tabIndex={0}
          onClick={() => setOverlayOpenAction(null)}
        >
          <span className="visuallyhidden">{i18next.t("CLOSE")}</span>
        </div>
      )}

      <div className="overlay-popup__body">{children}</div>

      <div className="overlay-popup__footer">
        {/* btn-wrap--to-center when only one button is shown */}
        <div
          className={`btn-wrap ${
            transparentButtonShow !== activeButtonShow ? "btn-wrap--to-center" : ""
          }`}
        >
          {transparentButtonShow && (
            <div className="btn transparent" onClick={transparentButtonOnClick}>
              <span className="btn__text">{transparentButtonLabel}</span>
            </div>
          )}
          {activeButtonShow && (
            <div className="btn " onClick={activeButtonOnClick}>
              <span className="btn__text">{activeButtonLabel}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OverlayPopup;
