import Country from "./Country";

type MetaData = {
  addressCompany: string;
  addressLine: string;
  addressCity: string;
  addressCountry: string;
  phoneHref: string;
  phoneFormatted: string;
  email: string;
  addressFormat?: "US";
  gtcUrlKey: string;
  termsOfUseKey: string;
  privacyPolicyKey: string;
  checkoutHideTax?: boolean;
  checkoutHideShippingCost?: boolean;
  checkoutCheckAddress?: boolean;
};

const CountrySpecific: Record<Country, MetaData> = {
  [Country.SWITZERLAND]: {
    addressCompany: "Thommen Medical AG",
    addressLine: "Neckarsulmstrasse 28",
    addressCity: "2540 Grenchen",
    addressCountry: "ISO_3166-1 CH",
    phoneHref: "tel:+41326443020",
    phoneFormatted: "T +41 32 644 30 20",
    email: "info@thommenmedical.com",
    gtcUrlKey: "URL PATH GTC CH",
    termsOfUseKey: "URL PATH TERMS OF USE",
    privacyPolicyKey: "URL PATH PRIVACY POLICY",
  },
  [Country.GERMANY]: {
    addressCompany: "Thommen Medical Deutschland GmbH",
    addressLine: "Am Rathaus 2",
    addressCity: "79576 Weil am Rhein",
    addressCountry: "ISO_3166-1 DE",
    phoneHref: "tel:+4976214225830",
    phoneFormatted: "T +49 7621 - 4 22 58 30",
    email: "info@thommenmedical.de",
    gtcUrlKey: "URL PATH GTC DE",
    termsOfUseKey: "URL PATH TERMS OF USE",
    privacyPolicyKey: "URL PATH PRIVACY POLICY",
  },
  [Country.SPAIN]: {
    addressCompany: "Thommen Medical Deutschland GmbH",
    addressLine: "Am Rathaus 2",
    addressCity: "79576 Weil am Rhein",
    addressCountry: "ISO_3166-1 DE",
    phoneHref: "tel:+TBD",
    phoneFormatted: "T +49 7621 - 4 22 58 30",
    email: "info@thommenmedical.de",
    gtcUrlKey: "URL PATH GTC ES",
    termsOfUseKey: "URL PATH TERMS OF USE",
    privacyPolicyKey: "URL PATH PRIVACY POLICY",
  },
  [Country.ITALY]: {
    addressCompany: "Thommen Medical Italia S.r.l.",
    addressLine: "Via Caduti di Amola 11/2",
    addressCity: "40132 Bologna (BO)",
    addressCountry: "ISO_3166-1 IT",
    phoneHref: "tel:+390513546196",
    phoneFormatted: "T +39 051 354 61 96",
    email: "sales.it@thommenmedical.com",
    gtcUrlKey: "URL PATH GTC IT",
    termsOfUseKey: "URL PATH TERMS OF USE",
    privacyPolicyKey: "URL PATH PRIVACY POLICY",
  },
  [Country.USA]: {
    addressCompany: "Thommen Medical USA L.L.C.",
    addressLine: "1375 Euclid Avenue / Suite 450",
    addressCity: "Cleveland",
    addressCountry: "ISO_3166-1 US",
    phoneHref: "tel:+18663199800",
    phoneFormatted: "T +1 866 319 9800",
    email: "orders@thommenmedical.us",
    addressFormat: "US",
    gtcUrlKey: "URL PATH GTC US",
    termsOfUseKey: "URL PATH TERMS OF USE US",
    privacyPolicyKey: "URL PATH PRIVACY POLICY US",
    checkoutHideTax: true,
    checkoutHideShippingCost: true,
    checkoutCheckAddress: true,
  },
};

export default CountrySpecific;
