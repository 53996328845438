import { getCountry, getLang } from "./params";
import { PATH } from "../constants/Path";
import { getLangCountry } from "./link";

export function getPathnameLangSwitch(newLang: string) {
  const lang = getLang();
  const country = getCountry();

  return window.location.pathname.replace(`/${lang}/${country}`, `/${newLang}/${country}`);
}

export function getPathnameCountrySwitch(newCountry: string) {
  const lang = getLang();
  const country = getCountry();

  const searchMask = `/${lang}/${country}`;
  const regEx = new RegExp(searchMask, "ig");
  const replaceMask = `/${lang}/${newCountry}`;

  return window.location.pathname.replace(regEx, replaceMask);
}

export function isAccountView(): boolean {
  return window.location.pathname.includes(`/${PATH.ACCOUNT}`);
}

export function isCartView(): boolean {
  return window.location.pathname.includes(`/${PATH.CART}`);
}

export function isCategoryView(): boolean {
  return window.location.pathname.includes(`/${PATH.CATEGORY}`);
}

export function isCheckoutView(): boolean {
  return window.location.pathname.includes(`/${PATH.CHECKOUT}`);
}

export function isConfiguratorView(): boolean {
  return window.location.pathname.includes(`/${PATH.CONFIGURATOR}`);
}

export function isLandingView(): boolean {
  return window.location.pathname.endsWith(getLangCountry());
}

export function isLoginView(): boolean {
  return window.location.pathname.includes(`/${PATH.LOGIN}`);
}

export function isOrderView(): boolean {
  return window.location.pathname.includes(`/${PATH.ORDER}/`);
}

export function isProductView(): boolean {
  return window.location.pathname.includes(`/${PATH.PRODUCT}/`);
}

export function isProductGroupView(): boolean {
  const pathname = window.location.pathname;
  return pathname.includes(`/${PATH.PRODUCTGROUP}/`) && !pathname.includes(`/${PATH.PRODUCT}/`);
}

export function isDirectProductView(): boolean {
  const pathname = window.location.pathname;
  // direct link does not include 'category' in pathname
  return pathname.includes(`/${PATH.PRODUCT}/`) && !pathname.includes(`/${PATH.CATEGORY}/`);
}

export function isIndirectProductView(): boolean {
  const pathname = window.location.pathname;
  // indirect link does include 'category' in pathname
  return pathname.includes(`/${PATH.PRODUCT}/`) && pathname.includes(`/${PATH.CATEGORY}/`);
}

export function isProductRelatedView(): boolean {
  return isProductView() || isProductGroupView();
}

export function isScanToCartView(): boolean {
  return window.location.pathname.includes(`/${PATH.SCANTOCART}`);
}

export function isConsignmentStockView(): boolean {
  return window.location.pathname.includes(`/${PATH.CONSIGNMENT_STOCK}`);
}

export function isSearchView(): boolean {
  return window.location.pathname.includes(`/${PATH.SEARCH}`);
}

export function isSignupView(): boolean {
  return window.location.pathname.includes(`/${PATH.SIGNUP}`);
}

export function isPasswordResetView(): boolean {
  return window.location.pathname.includes(`/${PATH.RESET_PASSWORD}/`);
}

export function isCartInRightView(): boolean {
  return (
    isAccountView() ||
    isCategoryView() ||
    isLandingView() ||
    isProductRelatedView() ||
    isScanToCartView() ||
    isSearchView() ||
    isConfiguratorView()
  );
}
