import {
  ResponseOrders,
  ResponseOrdersList,
  ResponseOrdersListWithIncludes,
  ResponseOrdersWithIncludes,
} from "../vo/orders";
import { fetchAllService, fetchPageService, fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken, getAccessTokenMarketAll } from "../scripts/token";
import { OrderType } from "../../common/constants/OrderType";

const include =
  "line_items,billing_address,shipping_address,available_payment_methods,shipments,payment_method";

export async function serviceCreateOrder(
  lang: string,
  customerId: string,
  orderType: OrderType,
): Promise<ResponseOrders | null> {
  const data = {
    data: {
      type: "orders",
      attributes: {
        language_code: lang,
        metadata: {
          type: orderType,
        },
      },
      relationships: {
        customer: {
          data: {
            type: "customers",
            id: customerId,
          },
        },
      },
    },
  };
  try {
    return await fetchService(HTTP_METHOD.POST, `api/orders`, await getAccessToken(), data);
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceGetOrder(orderId: string): Promise<ResponseOrdersWithIncludes | null> {
  try {
    return await fetchService(
      HTTP_METHOD.GET,
      `api/orders/${orderId}?include=${include}`,
      await getAccessTokenMarketAll(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceGetCustomerOrders(
  customerId: string,
): Promise<ResponseOrdersList | null> {
  try {
    return await fetchAllService(
      HTTP_METHOD.GET,
      `api/customers/${customerId}/orders/?filter[q][status_in]=placed,approved,cancelled&sort=-number`,
      await getAccessTokenMarketAll(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceGetCustomerOrdersPreferences(
  customerId: string,
): Promise<ResponseOrdersListWithIncludes | null> {
  try {
    return await fetchPageService(
      HTTP_METHOD.GET,
      `api/customers/${customerId}/orders/?filter[q][status_in]=placed,approved&include=line_items`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceAddRelationToOrder(
  orderId: string,
  addressId: string,
  relationship: string,
  relationshipType: string,
): Promise<ResponseOrdersWithIncludes | null> {
  const relationshipData = {
    data: {
      type: relationshipType,
      id: addressId,
    },
  };
  const relationships = {};
  relationships[relationship] = relationshipData;
  const data = {
    data: {
      type: "orders",
      id: orderId,
      relationships,
    },
  };
  try {
    return await fetchService(
      HTTP_METHOD.PATCH,
      `api/orders/${orderId}?include=${include}`,
      await getAccessToken(),
      data,
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceUpdateOrder(
  orderId: string,
  attributesFragment: any,
): Promise<ResponseOrders> {
  const data = {
    data: {
      type: "orders",
      id: orderId,
      attributes: attributesFragment,
    },
  };
  return await fetchService(
    HTTP_METHOD.PATCH,
    `api/orders/${orderId}`,
    await getAccessToken(),
    data,
  );
}
