import React from "react";
import { useSelectedProduct } from "../hooks/product";

const ProductDownloads: React.FC = () => {
  const product = useSelectedProduct();

  return (
    <>
      {product?.downloads.map((download, i) => {
        return (
          <li key={i}>
            <a
              href={download?.url}
              className="icon-link "
              target="_blank"
              rel="noreferrer"
              title={download?.name ?? ""}
            >
              <span className="icon-link-font icon-font icon-font__video" />
              {download?.name}
            </a>
          </li>
        );
      })}
    </>
  );
};

export default ProductDownloads;
