import { Environment } from "../../constants/Environment";

export const getAppEnv = (): Environment => {
  if (process?.env?.REACT_APP_ENVIRONMENT) {
    if (Environment[process.env.REACT_APP_ENVIRONMENT.toUpperCase()]) {
      return Environment[process.env.REACT_APP_ENVIRONMENT.toUpperCase()];
    }

    console.info(
      "Invalid entry for process.env.REACT_APP_ENVIRONMENT. Must be inside the defined enum Environment",
    );
  }

  const fallback = Environment.DEVELOPMENT;

  console.info(
    `Error in process.env.REACT_APP_ENVIRONMENT. Returning a fallback Environment which is (${fallback})`,
  );

  return Environment.DEVELOPMENT;
};
