import { ResponseSkus, ResponseSkusListWithIncludes } from "../vo/skus";
import { fetchAllService, fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken } from "../scripts/token";

export async function serviceGetSkuByCode(
  skuCode: string,
): Promise<ResponseSkusListWithIncludes | null> {
  try {
    return await fetchAllService(
      HTTP_METHOD.GET,
      `api/skus?include=prices&filter[q][code_eq]=${skuCode}`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceGetSkuById(skuId: string): Promise<ResponseSkus | null> {
  try {
    return await fetchService(HTTP_METHOD.GET, `api/skus/${skuId}`, await getAccessToken());
  } catch (e) {
    handleError401(e);
    return null;
  }
}
