import { StorageKeys } from "../../common/constants/StorageKeys";

export function getUseCookies(): string | null {
  const cookies = localStorage.getItem(StorageKeys.COOKIES);
  if (cookies === "yes") {
    initCookies().then();
  }
  return cookies;
}

export async function initCookies() {
  // do nothing when disabled. i.e. for local development
  if (!process.env.REACT_APP_GTMID) {
    return;
  }

  const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GTMID,
  };

  // https://webpack.js.org/api/module-methods/#magic-comments
  const TagManager = await import(/* webpackChunkName: "gtm-module" */ "react-gtm-module");

  TagManager.initialize(tagManagerArgs);
}
