import React from "react";

interface Props {
  text: string;
  onClick: (key: string) => void;
  value: string;
}

const DropdownListItem: React.FC<Props> = ({ text, onClick, value }) => (
  <li className="dropdown-list__item" onClick={() => onClick(value)}>
    <span className="dropdown-list__link" title={text}>
      {text}
    </span>
  </li>
);

export default DropdownListItem;
