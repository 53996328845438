import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { getPaymentMethod } from "../../checkout/scripts/shippingpayment";
import { paymentMethodIdIsOfCreditcardPaymentMethod } from "../../commercelayer/scripts/filter";

export const showPaymentButton = function (order) {
  const paymentMethod: PaymentMethods | null = getPaymentMethod(order);
  if (
    paymentMethod !== null &&
    order.attributes.payment_status !== "paid" &&
    order.attributes.payment_status !== "voided"
  ) {
    const creditcardPaymentMethod = paymentMethodIdIsOfCreditcardPaymentMethod(
      paymentMethod.id,
      order.paymentMethods,
    );
    if (creditcardPaymentMethod) {
      return true;
    }
  }
  return false;
};
