import React from "react";
import imgThommenFooterLogo from "../../../templates/public/images/layout/footer-logo.svg";
import imgThommenHeaderLogo from "../../../templates/public/images/layout/logo.svg";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { scrollTop } from "../../../layout/scripts/scroll";
import { useLangCountry } from "../../../router/hooks/useLangCountry";

export const ThommenLogoHeader: React.FC = () => {
  const langCountry = useLangCountry();

  return (
    <div className="logo header__logo">
      <Link
        to={langCountry}
        onClick={() => scrollTop()}
        className="logo__link"
        title={"Thommen Medical | " + i18next.t("SHOP")}
      >
        <ThommenLogo
          img={imgThommenHeaderLogo}
          alt={"Thommen Medical | " + i18next.t("SHOP")}
          width="166"
          height="49"
        />
      </Link>
    </div>
  );
};

export const ThommenLogoFooter: React.FC = () => (
  <a className="footer-logo" href={i18next.t("URL WEB ROOT")} title={"Thommen Medical"}>
    <ThommenLogo img={imgThommenFooterLogo} alt={"Thommen Medical"} width="204" height="57" />
  </a>
);

interface ThommenLogoProps {
  img: string;
  alt: string;
  width: string;
  height: string;
}

const ThommenLogo: React.FC<ThommenLogoProps> = ({ img, alt, width, height }) => {
  return <img src={img} alt={alt} className={"logo__image"} width={width} height={height} />;
};
