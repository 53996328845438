import React from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { PATH } from "../../router/constants/Path";
import OptionShippingPaymentDisplay from "../../checkout/components/OptionShippingPaymentDisplay";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { OrderType } from "../../common/constants/OrderType";

interface Props {
  orderId?: string;
  orderType: OrderType;
  shippingMethod: ShippingMethods | null;
  paymentMethod: PaymentMethods | null;
  showEditLink: boolean;
  customerId: string | null;
}

const OrderShippingPaymentSection: React.FC<Props> = ({
  orderId,
  orderType,
  shippingMethod,
  paymentMethod,
  showEditLink,
  customerId,
}) => {
  const langCountry = useLangCountry();
  let editLink;
  if (orderType === OrderType.CST_ORDER) {
    editLink = `${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.CONSIGNMENT_STOCK}/${orderId}/${PATH.SHIPPING_PAYMENT}`;
  } else {
    editLink = `${langCountry}/${PATH.CHECKOUT}/${orderId}/${PATH.SHIPPING_PAYMENT}`;
  }
  if (!orderId) return null;
  return (
    <div className="grid-wrap grid-wrap--checkout">
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("SHIPPING")}</h2>
        <OptionShippingPaymentDisplay method={shippingMethod} />
      </div>
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("PAYMENT")}</h2>
        <OptionShippingPaymentDisplay method={paymentMethod} />
      </div>
      {showEditLink && (
        <Link
          to={editLink}
          className="icon-btn icon-btn__edit icon-btn__edit__right "
          role="button"
          tabIndex={0}
          onClick={() => scrollTop()}
        >
          <span className="visuallyhidden">
            {`${i18next.t("SHIPPING")} & ${i18next.t("PAYMENT")}`}
          </span>
        </Link>
      )}
    </div>
  );
};

export default OrderShippingPaymentSection;
