import React from "react";
import { Link } from "react-router-dom";
import { scrollTop } from "../../layout/scripts/scroll";

interface Props {
  label: string;
  title: string;
  button: string;
  linkTo: string;
  imgTeaserIcon: string;
  imgTeaser1x: string;
  imgTeaser2x: string;
  imgTeaserMobile1x: string;
  imgTeaserMobile2x: string;
}

const LandingTeaserIconImg: React.FC<Props> = ({
  label,
  title,
  button,
  linkTo,
  imgTeaserIcon,
  imgTeaser1x,
  imgTeaser2x,
  imgTeaserMobile1x,
  imgTeaserMobile2x,
}) => {
  return (
    <Link to={linkTo} className="grid-1-2 teaser-icon-img" onClick={() => scrollTop()}>
      <div className="teaser__label">{label}</div>
      <div className="teaser-icon-img__icon-wrap">
        <img
          src={imgTeaserIcon}
          className="teaser-icon__icon"
          alt={title}
          width="120"
          height="120"
        />
      </div>
      <div className="img-wrap teaser__img-wrap">
        <picture data-crop="false" data-quality="high" data-upscale="false">
          <source
            data-width=""
            media="screen and (min-width:768px)"
            srcSet={`${imgTeaser1x} 1x, ${imgTeaser2x} 2x`}
          />
          <source
            data-width=""
            media="screen and (max-width:767px)"
            srcSet={`${imgTeaserMobile1x} 1x, ${imgTeaserMobile2x} 2x`}
          />
          <img className="img" alt={title} data-title={title} src={imgTeaser1x} width="153" />
        </picture>
      </div>

      <div className="teaser__text-wrap">
        <h2 className="teaser__title">{title}</h2>
        <div className="btn teaser__btn btn--arrow">
          <span className="btn__text">{button}</span>
        </div>
      </div>
    </Link>
  );
};

export default LandingTeaserIconImg;
