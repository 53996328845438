import React from "react";
import i18next from "i18next";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { Link } from "react-router-dom";
import { setCartOpenAction } from "../actions/layoutActions";
import { isCartInRightView } from "../../router/scripts/path";
import { scrollTop } from "../scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const CartButton: React.FC = () => {
  const langCountry = useLangCountry();
  const layoutCartOpen = useSelector((state: State) => state.layout.cartOpen);
  const layoutIsMobile = useSelector((state: State) => state.layout.isMobile);
  const cartCounter = useSelector((state: State) => state.cart.counter);
  const cartAnimate = useSelector((state: State) => state.cart.animate);

  const onClickCart = function (e) {
    if (!layoutIsMobile && isCartInRightView()) {
      setCartOpenAction(!layoutCartOpen).then();
      scrollTop();
      e.preventDefault();
    }
    if (layoutIsMobile) {
      scrollTop();
    }
  };

  return (
    <Link
      to={`${langCountry}/${PATH.CART}`}
      onClick={onClickCart}
      className={`icon-btn icon-btn__cart ${
        layoutIsMobile ? "icon-btn__cart__primary" : "icon-link--underline"
      } ${cartAnimate ? "animate" : ""}`}
      tabIndex={0}
    >
      {cartCounter > 0 && <span className="icon-btn__counter">{cartCounter}</span>}
      <span className="visuallyhidden">{i18next.t("CART")}</span>
    </Link>
  );
};

export default CartButton;
