import { initLogin } from "../../../../login/actions/loginActions";
import { StorageKeys } from "../../../constants/StorageKeys";
import { initCart } from "../../../../cart/actions/cartActions";
import { getStorageToken } from "../../../../state/init/login";

async function handleUpdateCartId(e) {
  if (e.key !== StorageKeys.CART_ID) {
    return;
  }
  await initCart();
  return;
}

async function handleUpdateCartUpdate(e) {
  if (e.key !== StorageKeys.CART_UPDATED_AT) {
    return;
  }
  await initCart();
  return;
}

async function handleUpdateLoginToken(e) {
  if (e.key !== StorageKeys.LOGIN_TOKEN && e.key !== StorageKeys.LOGIN_TOKEN_MARKET_ALL) {
    return;
  }
  const storageToken = getStorageToken(StorageKeys.LOGIN_TOKEN);
  const storageTokenMarketAll = getStorageToken(StorageKeys.LOGIN_TOKEN_MARKET_ALL);
  // Only handle cases if both tokens are set or not set (this is to prevent async problems)
  if (
    (storageToken === null && storageTokenMarketAll === null) ||
    (storageToken !== null && storageTokenMarketAll !== null)
  ) {
    await initLogin();
  }
  return;
}

export function setStorageEventListeners() {
  window.addEventListener("storage", e => {
    // prevent doing anything when the oldValue equals the newValue solved
    // an infinity loop problem with IE11
    if (e.oldValue !== e.newValue) {
      handleUpdateCartId(e);
      handleUpdateCartUpdate(e);
      handleUpdateLoginToken(e);
    }
  });
}
