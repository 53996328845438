import React, { useEffect } from "react";
import LineItemInputNumber from "./LineItemInputNumber";
import { LineItems } from "../../commercelayer/vo/lineItems";
import { Product } from "../../graphcms/vo/graphCMS";
import i18next from "i18next";
import PfClassName from "../../product/const/PfClassName";
import { Link } from "react-router-dom";
import { deleteLineItem } from "../actions/cartActions";
import AvailabilityAlert from "../../common/components/AvailabilityAlert";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { loadSku } from "../../commercelayer/actions/commercelayerActions";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import { getProductDirectLocationObject } from "../../product/scripts/link";
import { scrollTop } from "../../layout/scripts/scroll";
import { Customer } from "../../commercelayer/vo/customers";

interface Props {
  cartId: string;
  lineItem: LineItems;
  product: Product;
  showUnitAmount: boolean;
  showBatchNumber: boolean;
  showAvailabilityAlert: boolean;
  quantityIsEditable: boolean;
  customer: Customer | null;
}

const LineItem: React.FC<Props> = ({
  cartId,
  lineItem,
  product,
  showUnitAmount,
  showBatchNumber,
  showAvailabilityAlert,
  quantityIsEditable,
  customer,
}) => {
  const commercelayerSkusIndex = useSelector((state: State) => state.commercelayer.skusIndex);

  useEffect(() => {
    if (showAvailabilityAlert) {
      if (!commercelayerSkusIndex[product.sku]) {
        loadSku(product.sku).then();
      }
    }
  });

  let discountCents = 0;
  if (
    lineItem.attributes.discount_cents !== 0 &&
    lineItem.attributes.hasOwnProperty("discount_breakdown")
  ) {
    const discountBreakdown = lineItem.attributes.discount_breakdown;
    Object.keys(discountBreakdown).forEach((promotionId: string) => {
      let promotion: any = discountBreakdown[promotionId];
      if (!promotion.hasOwnProperty("coupon_code") && promotion.hasOwnProperty("cents")) {
        discountCents += promotion.cents;
      }
    });
  }

  return (
    <li className="cart-list__item">
      <Link
        to={getProductDirectLocationObject(product)}
        className="list-item__text-wrap"
        onClick={() => scrollTop()}
      >
        <h3 className={`item-title  pf-dot pf-${PfClassName?.[product?.pfs?.[0]?.name]}`}>
          {product.sku} {product.name}
        </h3>
        <div className="list-item__text">{product.nameSuffix}</div>
      </Link>

      {showAvailabilityAlert && (
        <AvailabilityAlert
          sku={product.sku}
          requestQuantity={lineItem.attributes.quantity}
          customer={customer}
        />
      )}

      <div className="cart-price-wrap">
        {quantityIsEditable && (
          <LineItemInputNumber
            cartId={cartId}
            lineItemId={lineItem.id}
            quantity={lineItem.attributes.quantity}
          />
        )}
        {!quantityIsEditable && !showBatchNumber && (
          <p className="cart-list__quantity">{lineItem.attributes.quantity}</p>
        )}

        {showUnitAmount && (
          <div className="price">
            <p className="price__text">
              {getNumberWithCurrency(
                lineItem.attributes.unit_amount_float,
                lineItem.attributes.currency_code,
              )}
            </p>
          </div>
        )}

        {showBatchNumber && (
          <div className="price">
            <p className="price__text">{`${i18next.t("BATCH")} ${
              lineItem.attributes.metadata?.batchNumber || "-"
            }`}</p>
          </div>
        )}

        <div className="price">
          <p className="price__text">
            {getNumberWithCurrency(
              lineItem.attributes.total_amount_float,
              lineItem.attributes.currency_code,
            )}
          </p>
          {discountCents < 0 ? (
            <p className="price__text__discount">
              {getNumberWithCurrency(discountCents / 100, lineItem.attributes.currency_code)}
            </p>
          ) : null}
        </div>

        {quantityIsEditable && (
          <div
            className="icon-btn icon-btn__delete"
            onClick={() => deleteLineItem(cartId, lineItem.id)}
            role="button"
            aria-label={`${product.sku} ${product.name} ${i18next.t("REMOVE")}`}
            tabIndex={0}
          >
            <span className="visuallyhidden">
              {`${product.sku} ${product.name} ${i18next.t("REMOVE")}`}
            </span>
          </div>
        )}
      </div>
    </li>
  );
};

export default LineItem;
