import React from "react";
import NavListItemIconLink from "./NavListItemIconLink";
import i18next from "i18next";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const NavListItemAccount: React.FC = () => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const login = useSelector((state: State) => state.login);
  const langCountry = useLangCountry();

  let text = `${i18next.t("LOGIN")}`;
  let to = `${langCountry}/${PATH.LOGIN}`;
  let additionalLinkClass = "";

  if (login.token !== null) {
    additionalLinkClass = "nav-login-account";
    if (login.token.owner_type === OWNER_TYPE.CUSTOMER && accountCustomer !== null) {
      text = accountCustomer.attributes.email;
      to = `${langCountry}/${PATH.ACCOUNT}/${accountCustomer.id}`;
    }
    if (login.token.owner_type === OWNER_TYPE.USER) {
      if (accountCustomer === null) {
        text = i18next.t("SALES AGENT");
        to = `${langCountry}/${PATH.SALES_AGENT}/${PATH.CUSTOMERS}`;
      } else {
        text = accountCustomer.attributes.email;
        to = `${langCountry}/${PATH.ACCOUNT}/${accountCustomer.id}`;
      }
    }
  }

  // handle when initial logged in as sales agent
  if (login.tokenMarketAll && !login.token) {
    additionalLinkClass = "nav-login-account";
    if (login.tokenMarketAll.owner_type === OWNER_TYPE.CUSTOMER && accountCustomer !== null) {
      text = accountCustomer.attributes.email;
      to = `${langCountry}/${PATH.ACCOUNT}/${accountCustomer.id}`;
    }
    if (login.tokenMarketAll.owner_type === OWNER_TYPE.USER) {
      if (accountCustomer === null) {
        text = i18next.t("SALES AGENT");
        to = `${langCountry}/${PATH.SALES_AGENT}/${PATH.CUSTOMERS}`;
      } else {
        text = accountCustomer.attributes.email;
        to = `${langCountry}/${PATH.ACCOUNT}/${accountCustomer.id}`;
      }
    }
  }

  return (
    <NavListItemIconLink
      text={text}
      to={to}
      icon={"account"}
      additionalLinkClass={additionalLinkClass}
    />
  );
};

export default NavListItemAccount;
