import React from "react";
import i18next from "i18next";

const AvailabilityAlertItem: React.FC = () => {
  return (
    <p className="item-alert">
      {i18next.t("AVAILABILITY UPON REQUEST")}
      <span className="item-alert__icon icon-font icon-font__alert" aria-hidden="true" />
    </p>
  );
};

export default AvailabilityAlertItem;
