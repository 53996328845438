import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import UrlParams from "../../state/vo/UrlParams";
import { CountryCode } from "../../graphcms/vo/graphCMS";
import { PARAMS } from "../constants/Params";

const name = "urlParams";
const initialState = store.urlParams;

const urlParamsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setUrlParams(state: UrlParams, action: PayloadAction<{}>) {
      state.params = action.payload;
    },
    setCountry(state: UrlParams, action: PayloadAction<CountryCode | string>) {
      state.params[PARAMS.COUNTRY] = action.payload;
    },
  },
});

export const setUrlParams = urlParamsSlice.actions.setUrlParams;
export const setCountry = urlParamsSlice.actions.setCountry;

export default urlParamsSlice.reducer;
