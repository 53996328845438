import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";

export const useIsScanningSendingDevice = (): boolean => {
  const params = useParams();
  // whether this param is in the url decides if the scanned result is send to the application backend
  // 1) on a remote device when using a desktop that scanned a QR code (true)
  // 2) on a mobile app where the user scans on his smartphone directly locally (false)
  return !!params?.[PARAMS.SCAN_ID];
};

export const useIsCameraCloseable = (): boolean => {
  const isScanningSendingDevice = useIsScanningSendingDevice();
  // disallow user to close when used as sending device
  return !isScanningSendingDevice;
};
