import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { setQ, setResult, setResultLive } from "../slices/searchSlice";
import { search } from "../../algolia/services/search";
import { setLiveSearchOpenAction } from "../../layout/actions/layoutActions";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import ProductHit from "../vo/ProductHit";
import { getState } from "../../state/scripts/state";
import SearchResult from "../vo/SearchResult";
import { ProductGroup } from "../../graphcms/vo/graphCMS";

export async function searchForQLive(q: string = "") {
  const searchResult = await searchResultForQ(q, 200);
  dispatch(setResultLive(searchResult));
}

export async function searchForQPage(q: string = "") {
  const searchResult = await searchResultForQ(q, 1000);
  dispatch(setResult(searchResult));
}

export async function searchResultForQ(q: string = "", hitsPerPage: number): Promise<SearchResult> {
  const graphcmsState = getState().graphcms;
  const searchResult: SearchResult = {
    nbHits: 0,
    productHits: [],
    productGroupHits: [],
  };
  dispatch(setQ(q));
  if (q.length === 0) {
    return searchResult;
  }
  const searchResultAlgolia = await search(q, hitsPerPage);

  const productGroupIds: string[] = searchResultAlgolia.hits.map((hit: ProductHit) => {
    return hit.productGroupId;
  });
  const uniqueProductGroupIds = [...new Set(productGroupIds)];
  const productGroupHits: ProductGroup[] = uniqueProductGroupIds
    .filter(productGroupId => graphcmsState.productGroupsIndex[productGroupId])
    .map(productGroupId => {
      return graphcmsState.productGroupsIndex[productGroupId];
    });

  searchResult.nbHits = searchResultAlgolia.nbHits;
  searchResult.productHits = searchResultAlgolia.hits;
  searchResult.productGroupHits = productGroupHits;
  return searchResult;
}

export async function submitSearch(q: string) {
  boundPushLocObj({ pathname: `${getLangCountry()}/${PATH.SEARCH}/${q}` });
  await setLiveSearchOpenAction(false);
}
