import React, { Dispatch, SetStateAction, useState } from "react";
import BreadcrumbDropdown, { BreadcrumbDropdownValue } from "./BreadcrumbDropdown";

const ArrowRight: React.FC = () => <span className="icon-font icon-font__arrow-right" />;

interface Props {
  title: string;
  setDropDownOpen: Dispatch<SetStateAction<boolean>>;
  dropdownValues: BreadcrumbDropdownValue[];
}

const BreadcrumbItem: React.FC<Props> = ({ title, setDropDownOpen, dropdownValues }) => {
  const [open, setOpen] = useState(false);
  const ref = React.useRef<HTMLLIElement>(null);

  const onClick = () => {
    setOpen(!open);
    setDropDownOpen(!open);
  };

  const onBlur = () => {
    // blur event gets fired before Link Component can perform the page change
    setTimeout(() => {
      // component might be unmounted when Link Component performs the page change
      if (ref.current) {
        setOpen(false);
        setDropDownOpen(false);
      }
    }, 150);
  };

  return (
    <li
      className={
        "breadcrumb-inline-list__item breadcrumb-inline-list__item--nav has-dropdown" +
        `${open ? " open" : ""}`
      }
      onClick={onClick}
      onBlur={onBlur}
      tabIndex={0}
      ref={ref}
    >
      <span className="breadcrumb-inline__text">{title}</span>
      <ArrowRight />
      <BreadcrumbDropdown dropdownValues={dropdownValues} />
    </li>
  );
};

export default BreadcrumbItem;
