import { ProductGroup } from "../vo/graphCMS";
import { PARAMS } from "../../router/constants/Params";
import { getState } from "../../state/scripts/state";
import { ProductGroupViewType } from "../constants/ProductGroupViewType";

export function getNrOfPfs(productGroup: ProductGroup): number {
  if (productGroup?.pfs?.length) {
    return productGroup.pfs.length;
  }

  return 0;
}

export function getProductGroupIdWhileDirectLink(params): string {
  const sku = params?.[PARAMS.SKU] ?? "";

  if (!sku) {
    return "";
  }

  const productsIndex = getState()?.graphcms?.productsIndex;

  return productsIndex?.[sku]?.productGroup?.id ?? "";
}

export function getProductGroupViewType(productGroup: ProductGroup): string {
  return productGroup?.productGroupViewType?.name ?? ProductGroupViewType["4d"];
}

export function getProductGroupTitle(productGroup: ProductGroup): string {
  const nameSuffix: string = productGroup?.nameSuffix ?? "";
  let space = "";
  if (nameSuffix.match(/^[A-Z0-9]/i) !== null) {
    space = " ";
  }

  return `${productGroup?.name}${space}${nameSuffix}`;
}
