import React from "react";
import State from "../../state/vo/State";
import { useSelector } from "react-redux";
import NavListItem from "./NavListItem";
import UserNotifications from "../../layout/components/UserNotifications";
import { hasDisplayableChildCategory } from "../scripts/filter";
import { useParams } from "react-router-dom";

const NavCategory: React.FC = () => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const params = useParams();

  return (
    <nav className="nav-category">
      <ul className="nav-list">
        {categoriesTree
          .filter(category => hasDisplayableChildCategory(category, params))
          .map((category, i) => (
            <NavListItem key={i} category={category} />
          ))}
      </ul>
      <UserNotifications />
    </nav>
  );
};

export default NavCategory;
