import React from "react";
import PfClassName from "../../product/const/PfClassName";
import { LineItems } from "../../commercelayer/vo/lineItems";
import { Link } from "react-router-dom";
import { Product } from "../../graphcms/vo/graphCMS";
import Price, { PriceUsage } from "../../common/components/Price";
import { getProductDirectLocationObject } from "../../product/scripts/link";

interface Props {
  product: Product;
  cartLineItems: LineItems[];
}

const SearchListItem: React.FC<Props> = ({ product, cartLineItems }) => {
  return (
    <div className="list-item list-item--3-3">
      <Link to={getProductDirectLocationObject(product)} className="list-item__link">
        <div className="list-item__text-wrap">
          <h3
            className={`item-title  pf-dot pf-${PfClassName?.[product?.pfs?.[0]?.name]}`}
          >{`${product.sku} ${product.name}`}</h3>
          <div className="list-item__text">{product.nameSuffix}</div>
        </div>
      </Link>

      <Price usage={PriceUsage.LIST_ITEM} product={product} cartLineItems={cartLineItems} />
    </div>
  );
};

export default SearchListItem;
