import parser from "gs1-barcode-parser-mod";

export function extractParsedCodeItems(parsedCodeItems): {
  gtin: string;
  batchNumber: string;
  expiry: Date | undefined;
} {
  const gtin = parsedCodeItems.find(item => item?.ai === "01")?.data;
  const batchNumber = parsedCodeItems.find(item => item?.ai === "10")?.data;
  const expiry = new Date(parsedCodeItems.find(item => item?.ai === "17")?.data) ?? undefined;
  return { gtin, batchNumber, expiry };
}

export function getParsedCodeItems(scanResult: string) {
  const parsed = parser.parseBarcode(scanResult);
  const { parsedCodeItems } = parsed;
  return parsedCodeItems;
}
