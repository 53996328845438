import React, { Dispatch, SetStateAction } from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import BreadcrumbProductGroup from "./BreadcrumbProductGroup";
import BreadcrumbHiddenCategory from "./BreadcrumbHiddenCategory";

interface Props {
  mainProductGroup: ProductGroup;
  relatedProductGroups: ProductGroup[];
  setDropDownOpen: Dispatch<SetStateAction<boolean>>;
}

const BreadcrumbCategoryLast: React.FC<Props> = ({
  mainProductGroup,
  relatedProductGroups = [],
  setDropDownOpen,
}) => {
  const hasHiddenParent = mainProductGroup?.categories?.[0]?.hidden ?? false;

  return (
    <>
      {hasHiddenParent ? (
        <BreadcrumbHiddenCategory
          mainProductGroup={mainProductGroup}
          setDropDownOpen={setDropDownOpen}
        />
      ) : (
        <BreadcrumbProductGroup
          mainProductGroup={mainProductGroup}
          relatedProductGroups={relatedProductGroups}
          setDropDownOpen={setDropDownOpen}
        />
      )}
    </>
  );
};

export default BreadcrumbCategoryLast;
