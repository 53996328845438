import { ProductGroup } from "../../../graphcms/vo/graphCMS";
import React, { useEffect, useState } from "react";
import { boundReplaceLocObj } from "../../actions/routesActions";
import { useLocation, useParams } from "react-router-dom";
import { devLogInfo } from "../../../common/scripts/logger/log";
import { isProductGroupView } from "../../scripts/path";
import { getProductLink } from "../../scripts/link";

interface Props {
  mainProductGroup: ProductGroup;
}

// todo: useNavigate instead of boundReplaceLocObj
const RepSingleProduct: React.FC<Props> = ({ mainProductGroup }) => {
  const params = useParams();
  const location = useLocation();
  const [currentPathname, setCurrentPathname] = useState(location.pathname);

  useEffect(() => {
    // only relevant for this view
    if (!isProductGroupView()) {
      return;
    }

    if (!mainProductGroup) {
      return;
    }

    // only perform when productGroup has a single product
    if (mainProductGroup.products.length > 1) {
      return;
    }

    const product = mainProductGroup.products[0];
    const productLocationObj = getProductLink(product, params);

    if (typeof productLocationObj === "string") {
      return;
    }

    if (!productLocationObj?.pathname) {
      return;
    }

    // abort replacing same one, prevents infinite loop
    if (productLocationObj.pathname === currentPathname) {
      return;
    }

    setCurrentPathname(productLocationObj.pathname);
    boundReplaceLocObj(productLocationObj);
    devLogInfo("RepSingleProduct", "replace", productLocationObj);
  }, [currentPathname, location, mainProductGroup, params]);

  return null;
};

export default RepSingleProduct;
