import { gql } from "graphql-request";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import {
  setCategoriesTree,
  setPfs,
  setProductGroups,
  setProductGroupsHasInit,
  setProductGroupsIndex,
  setProductHasInit,
  setProducts,
  setProductsIndex,
} from "../slices/graphcmsSlice";
import { graphCMSQuery } from "graphcms/services/client";
import { CountryCode } from "../vo/graphCMS";
import { getCountry } from "../../router/scripts/params";

const pfsFields = gql`
  fragment pfsFields on Pf {
    colour {
      hex
    }
    name
  }
`;

export async function initCategoriesTree() {
  const productGroupFields = gql`
    fragment productGroupFields on ProductGroup {
      id
      name
      slug
      tileDescription
      countryCodes
      image {
        handle
      }
      tileImage {
        handle
      }
      pfs(orderBy: name_ASC) {
        ...pfsFields
      }
    }
    ${pfsFields}
  `;

  const categoryFields = gql`
    fragment categoryFields on Category {
      id
      name
      description
      tileTitle
      tileDescription
      hidden
      matchingRestrictions
      image {
        handle
      }
      parentCategory {
        id
      }
      productGroups {
        ...productGroupFields
      }
    }
    ${productGroupFields}
  `;

  const query = gql`
    query InitCategoriesTree {
      categories(where: { parentCategory: null, hidden: false }) {
        ...categoryFields
        categories {
          ...categoryFields
          categories {
            ...categoryFields
          }
        }
      }
    }
    ${categoryFields}
  `;

  const data = await graphCMSQuery(query);

  if (!data?.categories) {
    return;
  }

  dispatch(setCategoriesTree(data.categories));
}

export async function initProductGroups() {
  const variables = {
    countryCodes: getCountryCodesFilter(),
  };

  const query = gql`
    query InitProductGroups($countryCodes: [CountryCode!]) {
      productGroups(first: 100000, where: { countryCodes_contains_some: $countryCodes }) {
        id
        name
        nameSuffix
        slug
        countryCodes
        breadcrumbCategories
        productGroupViewType {
          name
        }
        xAxisAttributes {
          label
          name
        }
        yAxisAttributes {
          label
          name
        }
        paragraphs {
          description
          image {
            handle
            name
          }
        }
        products(where: { countryCodes_contains_some: $countryCodes }) {
          sku
          slug
          countryCodes
          productGroup {
            id
            breadcrumbCategories
          }
          image {
            handle
          }
          attributeValues {
            numberValue
            stringValue
            valueLabeled
            attribute {
              name
              unit
            }
          }
          pfs(orderBy: name_ASC) {
            ...pfsFields
          }
        }
        pfs(orderBy: name_ASC) {
          ...pfsFields
        }
        categories {
          id
          name
          hidden
          parentCategory {
            id
            name
            hidden
            parentCategory {
              id
              name
            }
          }
        }
        tileImage {
          handle
        }
        image {
          handle
        }
      }
    }
    ${pfsFields}
  `;

  const data = await graphCMSQuery(query, variables);

  if (!data?.productGroups) {
    dispatch(setProductGroupsHasInit(true));
    return;
  }

  dispatch(setProductGroupsHasInit(false));
  dispatch(setProductGroups(data.productGroups));
  dispatch(setProductGroupsIndex(data.productGroups));
  dispatch(setProductGroupsHasInit(true));
}

export async function initProducts() {
  const variables = {
    countryCodes: getCountryCodesFilter(),
  };

  const query = gql`
    query InitProducts($countryCodes: [CountryCode!]) {
      products(first: 100000, where: { countryCodes_contains_some: $countryCodes }) {
        sku
        name
        nameSuffix
        slug
        sapUrlIfu
        countryCodes
        gtin13
        attributeValues {
          stringValue
          numberValue
          attribute {
            name
            label
            unit
          }
        }
        paragraphs {
          description
          image {
            handle
            name
          }
        }
        image {
          handle
        }
        productGroup {
          id
          breadcrumbCategories
        }
        pfs(orderBy: name_ASC) {
          ...pfsFields
        }
        downloads {
          url
          name
        }
        links {
          url
          name
        }
      }
    }
    ${pfsFields}
  `;

  const data = await graphCMSQuery(query, variables);

  if (!data?.products) {
    dispatch(setProductHasInit(true));
    return;
  }

  dispatch(setProductHasInit(false));
  dispatch(setProducts(data.products));
  dispatch(setProductsIndex(data.products));
  dispatch(setProductHasInit(true));
}

export async function initPfs() {
  const query = gql`
    {
      pfs(orderBy: name_ASC) {
        ...pfsFields
      }
    }
    ${pfsFields}
  `;

  const data = await graphCMSQuery(query);

  if (!data?.pfs) {
    return;
  }

  dispatch(setPfs(data.pfs));
}

function getCountryCodesFilter(): string[] {
  const country = getCountry();

  if (country) {
    return [country];
  }

  // fallback
  return Object.values(CountryCode);
}
