import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { setCartOpenAction } from "../../layout/actions/layoutActions";
import LineItemList from "./LineItemList";
import CartCheckout from "./CartCheckout";
import CartEmpty from "./CartEmpty";
import { Link } from "react-router-dom";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { PATH } from "../../router/constants/Path";
import { scrollTop } from "../../layout/scripts/scroll";

const CartOverlay: React.FC = () => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const cart = useSelector((state: State) => state.cart);
  const layoutCartOpen = useSelector((state: State) => state.layout.cartOpen);
  const langCountry = useLangCountry();
  if (!layoutCartOpen) {
    return null;
  }

  const onClickClose = function (e) {
    setCartOpenAction(false);
  };

  return (
    <div className="cart cart--overlay open">
      <div className="form cart__form">
        <div className={`cart-wrap ${cart.counter === 0 ? "cart-empty" : ""}`}>
          <h2 className="cart__title">{i18next.t("CART")}</h2>
          <div
            onClick={onClickClose}
            className="icon-btn icon-btn__close"
            role="button"
            tabIndex={0}
          >
            <span className="visuallyhidden">{i18next.t("CLOSE")}</span>
          </div>
          <Link
            to={`${langCountry}/${PATH.CART}`}
            onClick={() => scrollTop()}
            className="icon-btn icon-btn__fullscreen"
            role="button"
            tabIndex={0}
          >
            <span className="visuallyhidden">{i18next.t("ENLARGED VIEW")}</span>
          </Link>

          {cart.counter > 0 ? (
            <>
              {categoriesTree.map((category, index) => (
                <LineItemList
                  cartId={cart.id}
                  category={category}
                  lineItems={cart.lineItemsSkus}
                  showUnitAmount={false}
                  showBatchNumber={false}
                  showAvailabilityAlert={true}
                  quantityIsEditable={true}
                  key={index}
                />
              ))}
              <LineItemList
                cartId={cart.id}
                category={null}
                lineItems={cart.lineItemsSkus}
                showUnitAmount={false}
                showBatchNumber={false}
                showAvailabilityAlert={true}
                quantityIsEditable={true}
                key={-1}
              />
            </>
          ) : (
            <CartEmpty />
          )}
        </div>
        <CartCheckout cart={cart} />
      </div>
    </div>
  );
};

export default CartOverlay;
