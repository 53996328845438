import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setUrlParams } from "../slices/urlParamsSlice";

// the purpose for this component is to update urlParams for redux-state
// as way to access it more directly in different places in the application
const UrlParamsUpdater: React.FC = () => {
  const params = useParams();

  useEffect(() => {
    dispatch(setUrlParams(params));
  }, [params]);

  return null;
};

export default UrlParamsUpdater;
