import React, { useState } from "react";
import i18next from "i18next";
import { useInterval } from "../../common/scripts/hooks/useInterval";

const OrderProcessingButton: React.FC = () => {
  const [count, setCount] = useState<number>(0);
  useInterval(() => {
    // Your custom logic here
    setCount(count + 1);
  }, 1000);

  const getButtonText = function (seconds) {
    let buttonText = "";
    if (seconds < 2) {
      buttonText += `1. ${i18next.t("ORDER IS PROCESSED 0")}`;
    } else if (seconds < 4) {
      buttonText += `2. ${i18next.t("ORDER IS PROCESSED 1")}`;
    } else if (seconds < 8) {
      buttonText += `3. ${i18next.t("ORDER IS PROCESSED 2")}`;
    } else if (seconds < 11) {
      buttonText += `4. ${i18next.t("ORDER IS PROCESSED 3")}`;
    } else if (seconds < 14) {
      buttonText += `5. ${i18next.t("ORDER IS PROCESSED 4")}`;
    } else {
      buttonText += `5. ${i18next.t("ORDER IS PROCESSED 4")} (${seconds}s) `;
    }
    return buttonText;
  };

  return (
    <button className="btn transparent" tabIndex={0}>
      <span className="btn__text">{`${getButtonText(count)}...`}</span>
    </button>
  );
};

export default OrderProcessingButton;
