import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import i18next from "i18next";
import OnboardingSteps from "./OnboardingSteps";

import { resetPassword } from "../../login/actions/loginActions";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { PATH } from "../../router/constants/Path";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const Onboarding: React.FC = () => {
  const params = useParams();
  const langCountry = useLangCountry();
  const email = params[PARAMS.EMAIL] ?? "";

  const handleClick = () => {
    resetPassword(email);
    boundPushLocObj({ pathname: `${langCountry}/${PATH.LOGIN}` });
  };

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <Middle>
          <div className="grid-wrap grid-wrap--line">
            <div className="grid-1-2">
              <h2 className="col-title">
                {i18next.t("SHOP TITLE")} - {i18next.t("WELCOME ONBOARDING HEADING")}
              </h2>
              <div className="grid__text">{i18next.t("WELCOME TEXT ONBOARDING", { email })}</div>
              <button type="submit" className="btn btn--top" onClick={handleClick}>
                {i18next.t("REQUEST PASSWORD")}
              </button>
            </div>
            <div className="grid-1-2">
              <h2 className="col-title">{i18next.t("SIGNUP")}</h2>

              <OnboardingSteps email={email} />
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Onboarding;
