import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";

export const getCheckoutLinks = function (cartId): string[] {
  return [
    `${getLangCountry()}/${PATH.CHECKOUT}/${cartId}`,
    `${getLangCountry()}/${PATH.CHECKOUT}/${cartId}/${PATH.SHIPPING_PAYMENT}`,
    `${getLangCountry()}/${PATH.CHECKOUT}/${cartId}/${PATH.VERIFICATION}`,
  ];
};
