import React from "react";
import i18next from "i18next";
import { boundGoBack, boundReplaceLocObj } from "../../../router/actions/routesActions";
import { useLangCountry } from "../../../router/hooks/useLangCountry";

interface Props {
  title: string | null;
  desktopShowClose: boolean;
  toHomeOnClose: boolean;
}

const MainHeader: React.FC<Props> = ({ title, desktopShowClose, toHomeOnClose }) => {
  const langCountry = useLangCountry();
  if (title === null) {
    return null;
  }

  const onClickClose = function (e) {
    if (toHomeOnClose) {
      boundReplaceLocObj({ pathname: langCountry });
    } else {
      boundGoBack();
    }
  };

  return (
    <div className="main-header">
      <div className="col-10">
        <h1 className="col-title">{title}</h1>
        <div
          className={`icon-btn icon-btn__close ${desktopShowClose ? "desktop-show" : ""}`}
          role="button"
          tabIndex={0}
          onClick={onClickClose}
        >
          <span className="visuallyhidden">{i18next.t("CLOSE")}</span>
        </div>
      </div>
    </div>
  );
};

export default MainHeader;
