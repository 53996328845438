import React from "react";
import { Link } from "react-router-dom";
import { scrollTop } from "../scripts/scroll";

interface Props {
  text: string;
  to: string;
  active?: boolean;
}

const NavListItemLink: React.FC<Props> = ({ text, to, active = false }) => {
  return (
    <li className={"nav-list__item " + (active ? "active" : "")}>
      <Link to={to} className="nav-list__link" title={text} onClick={() => scrollTop()}>
        {text}
      </Link>
    </li>
  );
};

export default NavListItemLink;
