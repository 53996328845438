import { ProductGroup } from "../../graphcms/vo/graphCMS";
import React, { ReactElement } from "react";
import { SkuDataRow } from "./SkuDataRow";
import { ProductRowsMap } from "../vo/sku";

interface Props {
  productGroup: ProductGroup;
  productRowsMap: ProductRowsMap;
}

export const SkuData: React.FC<Props> = ({ productGroup, productRowsMap }) => {
  const skuDataRows: ReactElement[] = [];

  productRowsMap.forEach((productRow, index) => {
    skuDataRows.push(
      <SkuDataRow key={index} productGroup={productGroup} productRow={productRow} />,
    );
  });

  return <>{skuDataRows}</>;
};
