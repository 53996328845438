import React, { useLayoutEffect } from "react";
import { getReRootPathname } from "../../../common/scripts/locale/reRootPathname";
import { useNavigate } from "react-router-dom";

const ReRoot: React.FC = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    // ReRoot of "/" is handled in index.tsx
    if (window.location.pathname !== "/") {
      async function getPathname() {
        const pathname = await getReRootPathname();
        if (pathname) {
          navigate(pathname);
        }
      }

      getPathname().then();
    }
  }, [navigate]);

  return null;
};

export default ReRoot;
