import { getCountry } from "./params";
import { Product } from "../../graphcms/vo/graphCMS";
import { isDirectProductView } from "./path";
import { getProductGroupIdWhileDirectLink } from "../../graphcms/scripts/productGroup";
import { getRelatedCategoryIdFromProductGroup } from "../../graphcms/scripts/category";
import { getProductIndirectLink } from "../../product/scripts/link";
import { FALLBACK, PARAMS } from "../constants/Params";
import { getLangWithFallback } from "../../i18next/scripts/language";
import { To } from "react-router-dom";

// because this is a very core part of the URL the `/lang/country`
// combination is provided as a shorthand here
export function getLangCountry() {
  const lang = getLangWithFallback();
  const country = getCountry();
  return `/${lang}/${country}`;
}

export function getProductLink(product: Product, params: any): To {
  const fallback = { pathname: "", search: window.location.search };

  // multiple cases to handle when indirect
  // case currently having selected an direct product
  if (isDirectProductView()) {
    const productGroupId = getProductGroupIdWhileDirectLink(params);

    if (!productGroupId) {
      return fallback;
    }

    const relatedCategoryId = getRelatedCategoryIdFromProductGroup(productGroupId);

    return getProductIndirectLink(relatedCategoryId, productGroupId, product);
  }

  // case when in an indirect product link
  // case when coming from categories pages. same as having selected no product at all or using breadcrumb to switch
  const categoryId = params[PARAMS.CATEGORY_ID] ?? FALLBACK[PARAMS.CATEGORY_ID];
  const productGroupId = params[PARAMS.PRODUCTGROUP_ID] ?? FALLBACK[PARAMS.PRODUCTGROUP_ID];

  return getProductIndirectLink(categoryId, productGroupId, product);
}
