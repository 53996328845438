import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Right from "../../layout/components/Col/Right";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Left from "../../layout/components/Col/Left";
import SearchOverlay from "../../search/components/SearchOverlay";
import LoadingText from "./LoadingText";

const LoadingPage: React.FC = () => {
  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <Left />
        <Middle>
          <LoadingText />
        </Middle>
        <Right />
      </Body>
      <Footer />
    </Layout>
  );
};

export default LoadingPage;
