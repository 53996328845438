import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import PfClassName from "../const/PfClassName";
import { getValuesInUrlList } from "../../router/scripts/search";
import { SEARCH } from "../../router/constants/Search";

interface Props {
  productGroup: ProductGroup;
}

const ProductTableHeadPfs: React.FC<Props> = ({ productGroup }) => {
  const pfs = productGroup?.pfs ?? [];
  const pfsNames = pfs?.map(pf => pf?.name);
  const thPfs: any[] = [];

  pfsNames.forEach(pfName => {
    const className = PfClassName[pfName] ?? "";

    thPfs.push(
      <th key={pfName} className={`th-pf-${className} ${getClassNameIsBlur(pfName)}`}>
        {pfName ?? ""}
      </th>,
    );
  });

  return <>{thPfs}</>;
};

const classNameIsBlur = "is-blur";

function getClassNameIsBlur(pfName: string): string {
  return getIsPfNameInFocus(pfName) ? "" : classNameIsBlur;
}

function getIsPfNameInFocus(pfName: string): boolean {
  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);

  // no focus filter means everything is in focus
  if (focus.length === 0) {
    return true;
  }

  return focus.includes(pfName);
}

export default ProductTableHeadPfs;
