import { ResponseWireTransfers } from "../vo/wireTransfers";
import { fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken } from "../scripts/token";

export async function serviceCreateWireTransfer(
  orderId: string,
): Promise<ResponseWireTransfers | null> {
  const data = {
    data: {
      type: "wire_transfers",
      attributes: {},
      relationships: {
        order: {
          data: {
            type: "orders",
            id: orderId,
          },
        },
      },
    },
  };
  try {
    return await fetchService(HTTP_METHOD.POST, `api/wire_transfers`, await getAccessToken(), data);
  } catch (e) {
    handleError401(e);
    return null;
  }
}
