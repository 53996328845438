import {
  ResponseCustomerAddresses,
  ResponseCustomerAddressesListWithIncludes,
} from "../vo/customerAddresses";
import { AddressesAttributes, ResponseAddresses } from "../vo/addresses";
import { fetchAllService, fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken, getAccessTokenMarketAll } from "../scripts/token";

export async function serviceCreateAddresses(
  addressAttributes: AddressesAttributes,
): Promise<ResponseAddresses | null> {
  const data = {
    data: {
      type: "addresses",
      attributes: addressAttributes,
    },
  };
  try {
    return await fetchService(HTTP_METHOD.POST, `api/addresses`, await getAccessToken(), data);
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceUpdateAddresses(
  addressId: string,
  addressAttributes: AddressesAttributes,
): Promise<ResponseAddresses | null> {
  const data = {
    data: {
      type: "addresses",
      id: addressId,
      attributes: addressAttributes,
    },
  };
  try {
    return await fetchService(
      HTTP_METHOD.PATCH,
      `api/addresses/${addressId}`,
      await getAccessToken(),
      data,
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceGetCustomerAddresses(
  customerId: string,
): Promise<ResponseCustomerAddressesListWithIncludes | null> {
  try {
    return await fetchAllService(
      HTTP_METHOD.GET,
      `api/customers/${customerId}/customer_addresses?include=address,customer`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceGetCustomerAddressesSalesAgent(
  filterStrings: string[],
  rolename: string,
): Promise<ResponseCustomerAddressesListWithIncludes | null> {
  let filterCountry = "";
  if (rolename.includes("CH")) {
    filterCountry = `&filter[q][address_country_code_eq]=CH`;
  }
  if (rolename.includes("DE")) {
    filterCountry = `&filter[q][address_country_code_eq]=DE`;
  }
  if (rolename.includes(" ES")) {
    filterCountry = `&filter[q][address_country_code_eq]=ES`;
  }
  if (rolename.includes("IT")) {
    filterCountry = `&filter[q][address_country_code_eq]=IT`;
  }
  if (rolename.includes("US")) {
    filterCountry = `&filter[q][address_country_code_eq]=US`;
  }
  const apiUrl = `api/customer_addresses?include=address,customer&filter[q][reference_origin_eq]=SAP${filterCountry}&filter[q][customer_reference_or_customer_email_or_address_company_or_address_notes_or_address_zip_code_or_address_city_cont_any]=${filterStrings.join(
    ",",
  )}`;
  try {
    /*
    if (filterStrings.join(",").length > 3) {
      return await fetchAllService(HTTP_METHOD.GET, apiUrl, await getAccessTokenMarketAll());
    } else {
      return await fetchPageService(HTTP_METHOD.GET, apiUrl, await getAccessTokenMarketAll());
    }
    */
    // Load all anyway
    return await fetchAllService(HTTP_METHOD.GET, apiUrl, await getAccessTokenMarketAll());
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceCreateCustomerAddresses(
  customerId: string,
  addressId: string,
): Promise<ResponseCustomerAddresses | null> {
  const data = {
    data: {
      type: "customer_addresses",
      relationships: {
        customer: {
          data: {
            type: "customers",
            id: customerId,
          },
        },
        address: {
          data: {
            type: "addresses",
            id: addressId,
          },
        },
      },
    },
  };
  try {
    return await fetchService(
      HTTP_METHOD.POST,
      `api/customer_addresses`,
      await getAccessToken(),
      data,
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceDeleteCustomerAddresses(customerAddressesId: string): Promise<any> {
  try {
    return await fetchService(
      HTTP_METHOD.DELETE,
      `api/customer_addresses/${customerAddressesId}`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}
