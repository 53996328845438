import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "state/vo/State";
import { setOverlayOpenAction } from "../../layout/actions/layoutActions";
import { OVERLAY } from "../../layout/constants/Overlay";
import { setScanId } from "../slices/consignmentStockSlice";
import { createUUID } from "elstr-frontend-4/dist/ElstrId";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";

const Scan: React.FC = () => {
  const layoutIsMobile = useSelector((state: State) => state.layout.isMobile);
  const scanId = useSelector((state: State) => state.consignmentStock.scanId);

  const onClick = () => {
    if (layoutIsMobile) {
      setOverlayOpenAction(OVERLAY.CAMERA);
    } else {
      if (!scanId) {
        const scanId = createUUID();

        if (process.env.NODE_ENV === "development") {
          console.log("scanId", scanId);
          try {
            navigator.clipboard.writeText(scanId).then();
          } catch (e) {}
        }

        dispatch(setScanId(scanId));
      }

      setOverlayOpenAction(OVERLAY.QRSCAN);
    }
  };

  return (
    <span className="btn " role="button" tabIndex={0} onClick={onClick}>
      {layoutIsMobile ? (
        <span className="btn__text">{i18next.t("SCAN WITH CAMERA")}</span>
      ) : (
        <span className="btn__text">{i18next.t("SCAN WITH SMARTPHONE")}</span>
      )}
    </span>
  );
};

export default Scan;
