import React from "react";
import i18next from "i18next";
import OverlayPopupCameraWrapper from "./OverlayPopupCameraWrapper";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { ScannedInventory } from "../../consignment-stock/vo/ConsignmentStock";
import { OVERLAY } from "../../layout/constants/Overlay";
import { getScannedInventoryQuantityMap } from "../../consignment-stock/scripts/inventory";
import { SKU_BATCH_DELIMITER } from "../../consignment-stock/scripts/id";
import Scanner from "./Scanner";

interface Props {
  onScan: (scanResult: string) => void;
  scannedInventory: ScannedInventory[];
  shutdownScanning?: () => void;
}

const OverlayPopupScanFromCstMobile: React.FC<Props> = ({
  onScan,
  scannedInventory,
  shutdownScanning,
}) => {
  const layoutIsMobile = useSelector((state: State) => state.layout.isMobile);
  const layoutOverlayOpen = useSelector((state: State) => state.layout.overlayOpen);

  if (layoutOverlayOpen !== OVERLAY.CAMERA) {
    return null;
  }

  return (
    <OverlayPopupCameraWrapper activeButtonOnClick={shutdownScanning}>
      <>
        <div className="overlay-popup__subtitle sticky">
          {layoutIsMobile ? i18next.t("SCAN WITH CAMERA") : i18next.t("SCAN WITH SMARTPHONE")}
        </div>
        <div className="img-wrap">
          <Scanner onScan={onScan} />
        </div>

        {scannedInventory.length !== 0 ? (
          <div className="overlay-popup__content-wrap">
            <div className="list-wrap stock-list">
              <>
                {Object.entries(getScannedInventoryQuantityMap(scannedInventory)).map(
                  ([id, quantity]) => {
                    const [sku, batchNumber] = id.split(SKU_BATCH_DELIMITER);

                    return (
                      <div className="scan__result-wrap open" key={id}>
                        <div className="scan-result__text-wrap">
                          <p>
                            <strong>{`${quantity} ${i18next.t("PRODUCT", {
                              count: quantity,
                            })}: ${sku} ${i18next.t("LOT")} ${batchNumber}`}</strong>
                          </p>
                        </div>
                      </div>
                    );
                  },
                )}
              </>
            </div>
          </div>
        ) : null}
      </>
    </OverlayPopupCameraWrapper>
  );
};

export default OverlayPopupScanFromCstMobile;
