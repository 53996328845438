import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { HeaderY } from "./HeaderY";
import { SkuData } from "./SkuData";
import { ProductRowsMap } from "../vo/sku";

interface Props {
  productGroup: ProductGroup;
  productRowsMap: ProductRowsMap;
}

const ProductTableBody: React.FC<Props> = ({ productGroup, productRowsMap }) => {
  return (
    <tbody>
      <HeaderY productGroup={productGroup} />
      <SkuData productGroup={productGroup} productRowsMap={productRowsMap} />
    </tbody>
  );
};

export default ProductTableBody;
