import React from "react";
import ProductManual from "./ProductManual";
import ProductDownloads from "./ProductDownloads";
import ProductLinks from "./ProductLinks";

const ProductLinkList: React.FC = () => {
  return (
    <ul className="icon-link-list">
      <ProductManual />
      <ProductDownloads />
      <ProductLinks />
    </ul>
  );
};

export default ProductLinkList;
