export function elementOrAncestorHasClass(element, className: string) {
  if (!element || element.length === 0) {
    return false;
  }
  var parent = element;
  do {
    if (parent === document) {
      break;
    }
    if (parent.className.indexOf(className) >= 0) {
      return true;
    }
  } while ((parent = parent.parentNode));
  return false;
}

export function elementOrAncestorHasTagName(element, tagName: string) {
  if (!element || element.length === 0) {
    return false;
  }
  var parent = element;
  do {
    if (parent === document) {
      break;
    }
    if (parent.tagName.toUpperCase() === tagName.toUpperCase()) {
      return true;
    }
  } while ((parent = parent.parentNode));
  return false;
}
