import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import Left from "../../layout/components/Col/Left";
import Right from "../../layout/components/Col/Right";
import i18next from "i18next";
import CartOverlay from "../../cart/components/CartOverlay";
import NavAccount from "./NavAccount";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SearchOverlay from "../../search/components/SearchOverlay";
import MobileHeaderAccount from "./MobileHeaderAccount";
import { getPromotions } from "../actions/accountActions";
import { Promotion } from "../../commercelayer/vo/promotions";
import { AccountFallback } from "./AccountFallback";

const AccountAdvanceSale: React.FC = () => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const accountAdvanceSalePromotions = useSelector(
    (state: State) => state.account.advanceSalePromotions,
  );

  useEffect(() => {
    // TODO: External Promotion cannot fulfil the requirement
    // TODO: Replace promotions with contracts
    getPromotions();
  }, [accountCustomer]);

  if (accountCustomer === null) {
    return <AccountFallback />;
  }

  const customerId = accountCustomer.id;
  // TODO: Replace promotions with contracts
  const vbelns = accountAdvanceSalePromotions.map((promotion: Promotion, index: number) =>
    parseInt(promotion.attributes.metadata.vbeln, 10),
  );
  const uniqueVbelns = [...new Set(vbelns)];

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <MobileHeaderAccount activeLi={PATH.ADVANCE_SALE} customerId={accountCustomer.id} />
        <Left>
          <NavAccount activeLi={PATH.ADVANCE_SALE} customerId={customerId} />
        </Left>
        <Middle>
          <h1 className="col-title">{i18next.t("ADVANCE SALE")}</h1>
          <div className="hr" />
          {accountAdvanceSalePromotions.length === 0 && (
            <h2 className="list-top-title">{i18next.t("NO ADVANCE SALE CONTRACTS")}</h2>
          )}
          {uniqueVbelns.map(vbeln => {
            return (
              <React.Fragment key={vbeln}>
                <h2 className="list-top-title">{`${i18next.t("CONTRACT")} ${vbeln}`}</h2>
                <div className="list-wrap">
                  <div className="list-3cols list-3cols--header">
                    <div className="list-3cols__item">{i18next.t("PRODUCTGROUP")}</div>
                    <div className="list-3cols__item">{i18next.t("QUANTITY")}</div>
                    <div className="list-3cols__item">{i18next.t("REMAINING")}</div>
                  </div>

                  {accountAdvanceSalePromotions
                    .filter((promotion: Promotion) => {
                      return parseInt(promotion.attributes.metadata.vbeln, 10) === vbeln;
                    })
                    .map((promotion: Promotion, i) => {
                      const quantity = promotion.attributes.metadata.quantity;
                      const open = promotion.attributes.metadata.open;
                      return (
                        <div className="list-3cols " key={i}>
                          <div className="list-3cols__item">
                            <strong>
                              {i18next.t(`CL_SKULIST_${promotion.attributes.metadata.prodh}`)}
                            </strong>
                          </div>
                          <div className="list-3cols__item">{`${quantity} ${i18next.t(
                            "SAP_UNIT ST",
                            {
                              count: quantity,
                            },
                          )}`}</div>
                          <div className="list-3cols__item">
                            <strong>{`${open} ${i18next.t("SAP_UNIT ST", {
                              count: open,
                            })}`}</strong>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </React.Fragment>
            );
          })}
        </Middle>
        <Right removeOnCartClosed={true}>
          <CartOverlay />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default AccountAdvanceSale;
