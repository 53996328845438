import React from "react";
import Layout from "../../layout/components/Layout";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import ProductMainBody from "./ProductMainBody";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

const ProductMain: React.FC = () => {
  const productGroupsHasInit = useSelector((state: State) => state.graphcms.productGroupsHasInit);
  const productsHasInit = useSelector((state: State) => state.graphcms.productsHasInit);

  // this means the graphql data hasn't been initialized until now
  // the rest of the program flow has to be aborted here to not trigger toast and redirects
  if (!productGroupsHasInit) {
    return null;
  }
  if (!productsHasInit) {
    return null;
  }

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <ProductMainBody />
      <Footer />
    </Layout>
  );
};

export default ProductMain;
