import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import i18next from "i18next";
import SearchListItem from "./SearchListItem";
import ProductHit from "../vo/ProductHit";
import SearchListProductGroupItem from "./SearchListProductGroupItem";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { PATH } from "../../router/constants/Path";
import { Link } from "react-router-dom";
import { setLiveSearchOpenAction } from "../../layout/actions/layoutActions";
import { useLangCountry } from "../../router/hooks/useLangCountry";

interface Props {
  maxProductResults: number;
  maxProductGroupResults: number;
  searchNbHits: number;
  searchProductHits: ProductHit[];
  searchProductGroupHits: ProductGroup[];
}

const SearchHit: React.FC<Props> = ({
  maxProductResults,
  maxProductGroupResults,
  searchNbHits,
  searchProductHits,
  searchProductGroupHits,
}) => {
  const searchQ = useSelector((state: State) => state.search.q);
  const cartLineItemsSkus = useSelector((state: State) => state.cart.lineItemsSkus);
  const graphcmsProductsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const langCountry = useLangCountry();

  let productHits = searchProductHits;
  if (maxProductResults > 0) {
    productHits = searchProductHits.slice(0, maxProductResults);
  }

  let productGroupHits = searchProductGroupHits;
  if (maxProductGroupResults > 0) {
    productGroupHits = searchProductGroupHits.slice(0, maxProductGroupResults);
  }

  const onClickSearchHit = function (e) {
    setLiveSearchOpenAction(false);
  };

  const fixedTypo3SearchQueryParams =
    "?tx_indexedsearch_pi2[action]=search&tx_indexedsearch_pi2[controller]=Search&tx_indexedsearch_pi2[search][sword]=";
  const toMoreSearchResults = `${i18next.t("URL WEB ROOT")}${i18next.t(
    "URL PATH ABOUT US",
  )}${i18next.t("URL PATH SEARCH")}${fixedTypo3SearchQueryParams}${searchQ}`;

  return (
    <div className="search-hit" onClick={onClickSearchHit}>
      <div className="search-hit__col1">
        <div className="block-title">{i18next.t("CATEGORY", { count: 0 })}</div>
        <div className="list-wrap">
          {productGroupHits.map((productGroup: ProductGroup) => {
            return <SearchListProductGroupItem key={productGroup.id} productGroup={productGroup} />;
          })}
        </div>

        <a
          href={toMoreSearchResults}
          className="link-arrow"
          title={i18next.t("SHOW MORE SEARCH RESULTS")}
        >
          {i18next.t("SHOW MORE SEARCH RESULTS")}
        </a>
      </div>
      <div className="search-hit__col2">
        <div className="block-title">
          {i18next.t("PRODUCT", { count: searchProductHits.length })}
        </div>
        <div className="list-wrap">
          {productHits.map((hit: ProductHit) => {
            const product = graphcmsProductsIndex[hit.sku];
            if (product === null || product === undefined) {
              return null;
            }
            return (
              <SearchListItem key={hit.sku} product={product} cartLineItems={cartLineItemsSkus} />
            );
          })}
        </div>
        {maxProductResults > 0 && searchNbHits - maxProductResults > 0 && (
          <Link
            to={`${langCountry}/${PATH.SEARCH}/${searchQ}`}
            className="link-arrow"
            title={i18next.t("SHOW N MORE PRODUCT", { count: searchNbHits - maxProductResults })}
          >
            {i18next.t("SHOW N MORE PRODUCT", { count: searchNbHits - maxProductResults })}
          </Link>
        )}
      </div>
    </div>
  );
};

export default SearchHit;
