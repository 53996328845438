import React, { useCallback } from "react";
import { toggleSearchReplace } from "../../router/actions/routesActions";
import { SEARCH } from "../../router/constants/Search";
import PfClassName from "../../product/const/PfClassName";

const PfSelectOption: React.FC<{ pfName: string }> = ({ pfName }) => {
  const className = PfClassName[pfName] ?? "";

  const handleToggle = useCallback(() => {
    toggleSearchReplace(SEARCH.FOCUS, pfName);
  }, [pfName]);

  return (
    <li className="select-dropdown__option" role="treeitem" aria-selected="false">
      <span className={`pf-dot pf-${className}`} onClick={handleToggle} />
      <span className="select-dropdown__text" onClick={handleToggle}>
        {pfName}
      </span>
    </li>
  );
};

export default PfSelectOption;
