import React from "react";
import { Environment } from "../../common/constants/Environment";

const EnvironmentFlag: React.FC = () => {
  if (process.env.REACT_APP_ENVIRONMENT === Environment.PRODUCTION) {
    return null;
  }
  const reactAppEnvironment: string = process.env.REACT_APP_ENVIRONMENT ?? "";
  return (
    <div
      style={{
        color: "white",
        backgroundColor: "red",
        padding: "0 25px",
        position: "absolute",
        top: "0px",
        left: "45px",
        fontSize: "13px",
        fontWeight: "bold",
      }}
    >
      {reactAppEnvironment.toUpperCase()}
    </div>
  );
};

export default EnvironmentFlag;
