import React from "react";
import { useSelector } from "react-redux";
import State from "../../../state/vo/State";

interface Props {
  children?: React.ReactNode;
  removeOnCartClosed?: boolean;
}

const Right: React.FC<Props> = ({ removeOnCartClosed = false, children = null }) => {
  const cartOpen = useSelector((state: State) => state.layout.cartOpen);

  // removes taking part of the screen when a cart is closed
  if (removeOnCartClosed && !cartOpen) return null;

  return <aside className="col-right">{children}</aside>;
};

export default Right;
