import React from "react";
import { CommonImg, CommonImgProps } from "../../common/components/CommonImg";

const GridImg: React.FC<CommonImgProps> = ({ handle = "", alt = "" }) => {
  return (
    <div className="img-wrap grid__img-wrap">
      <CommonImg handle={handle} alt={alt} />
    </div>
  );
};

export default GridImg;
