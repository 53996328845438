import React from "react";

export interface CommonImgProps {
  handle?: string;
  alt?: string | null;
}

// this is the most common img used in many different places throughout the application
export const CommonImg: React.FC<CommonImgProps> = ({ handle = "", alt = "" }) => {
  return (
    <picture>
      <source
        media="screen and (min-width:1440px)"
        srcSet={`https://media.graphassets.com/auto_image/resize=height:237,width:237/${handle} 1x, https://media.graphassets.com/auto_image/resize=height:474,width:474/${handle} 2x`}
      />
      <source
        media="screen and (min-width:1200px) and (max-width:1439px)"
        srcSet={`https://media.graphassets.com/auto_image/resize=height:194,width:194/${handle} 1x, https://media.graphassets.com/auto_image/resize=height:388,width:388/${handle} 2x`}
      />
      <source
        media="screen and (max-width:1199px)"
        srcSet={`https://media.graphassets.com/auto_image/resize=height:280,width:280/${handle} 1x, https://media.graphassets.com/auto_image/resize=height:560,width:560/${handle} 2x`}
      />
      <img
        className="img"
        alt={alt ?? ""}
        src={`https://media.graphassets.com/auto_image/resize=height:237,width:237/${handle}`}
      />
    </picture>
  );
};
