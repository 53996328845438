import React, { ComponentProps, ReactElement, useState } from "react";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { getIsMobile } from "../../state/init/layout";
import BreadcrumbCategories from "./BreadcrumbCategories";

const ArrowRight: React.FC = () => <span className="icon-font icon-font__arrow-right" />;

const BreadcrumbHome: React.FC = () => {
  const langCountry = useLangCountry();

  return (
    <li className="breadcrumb-inline-list__item breadcrumb-inline-list__item--home">
      <Link
        to={langCountry}
        onClick={() => scrollTop()}
        title={"Thommen Medical | " + i18next.t("SHOP")}
      >
        <span className="breadcrumb-inline__text">{i18next.t("SHOP")}</span>
      </Link>
    </li>
  );
};

const BreadcrumbExpander: React.FC = () => (
  <li className="breadcrumb-inline-list__item breadcrumb-inline-list__item--expander">
    <span className="visuallyhidden" />
    <span className="breadcrumb-inline__text">...</span>
    <ArrowRight />
  </li>
);

const BreadcrumbInline: React.FC<{
  children: ReactElement<ComponentProps<typeof BreadcrumbCategories>>;
}> = ({ children }) => {
  const [dropDownOpen, setDropDownOpen] = useState(false);

  if (!React.isValidElement(children)) return null;

  return (
    <nav className="breadcrumb-inline">
      <div
        className={
          "breadcrumb-inline__inner" +
          `${getIsMobile() ? " is-expanded" : ""}` +
          `${dropDownOpen ? " is-dropdown-open" : ""}`
        }
      >
        <ul className="breadcrumb-inline__list">
          <BreadcrumbHome />
          <BreadcrumbExpander />
          {React.cloneElement(children, { setDropDownOpen })}
        </ul>
      </div>
    </nav>
  );
};

export default BreadcrumbInline;
