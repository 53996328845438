import { fetchAllService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken } from "../scripts/token";
import { ResponsePromotionsList } from "../vo/promotions";

export async function serviceGetPromotions(): Promise<ResponsePromotionsList | null> {
  try {
    return await fetchAllService(
      HTTP_METHOD.GET,
      `api/promotions/?sort=-reference`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}
