import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { getState } from "../../state/scripts/state";
import { StorageKeys } from "../../common/constants/StorageKeys";
import {
  serviceAddRelationToOrder,
  serviceCreateOrder,
  serviceGetOrder,
  serviceUpdateOrder,
} from "../../commercelayer/services/orders";
import { ResponseOrders, ResponseOrdersWithIncludes } from "../../commercelayer/vo/orders";
import {
  serviceCreateLineItemConsignmentStock,
  serviceDeleteLineItem,
} from "../../commercelayer/services/lineItems";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import { setPathnameAfterLogin } from "../../login/slices/loginSlice";
import { ResponseShipmentsListWithIncludes } from "../../commercelayer/vo/shipments";
import {
  serviceAddShippingMethodToShipments,
  serviceGetOrderShipments,
} from "../../commercelayer/services/shipments";
import {
  filterAllowedPaymentMethods,
  filterIncludedAddresses,
  filterIncludedLineItems,
  filterIncludedPaymentMethods,
  filterIncludedShippingMethods,
} from "../../commercelayer/scripts/filter";
import { getLang } from "../../router/scripts/params";
import { scrollTop } from "../../layout/scripts/scroll";
import {
  setConsignmentStockCheckoutBstnk,
  setConsignmentStockCheckoutCouponCode,
  setConsignmentStockCheckoutCouponCodeError,
  setConsignmentStockCheckoutGtc,
  setConsignmentStockCheckoutGtcError,
  setConsignmentStockCheckoutOrderProcessing,
  setConsignmentStockCheckoutOrderUpdating,
  setConsignmentStockOrderAddresses,
  setConsignmentStockOrderAttributes,
  setConsignmentStockOrderChangeInProgress,
  setConsignmentStockOrderId,
  setConsignmentStockOrderLineItems,
  setConsignmentStockOrderPaymentMethods,
  setConsignmentStockOrderRelationships,
  setConsignmentStockOrderShipments,
  setConsignmentStockOrderShippingMethods,
  setScannedReceivedInventory,
} from "../slices/consignmentStockSlice";
import { ScannedInventory } from "../vo/ConsignmentStock";
import { OrderType } from "../../common/constants/OrderType";
import { loadCustomerAddresses } from "../../account/actions/accountActions";
import { toast } from "react-toastify";
import i18next from "i18next";
import { Addresses } from "../../commercelayer/vo/addresses";
import { getMainAddress } from "../../account/scripts/addresses";
import { getPaymentMethod, getShippingMethod } from "../../checkout/scripts/shippingpayment";
import { syncShippingMethod } from "../../checkout/scripts/shippingMethods";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { serviceCreateWireTransfer } from "../../commercelayer/services/wireTransfers";
import LayerIo from "../../common/services/LayerIo";
import { getAccessToken } from "../../commercelayer/scripts/token";
import { logSentryAndConsole, logSentryAndConsoleError } from "../../common/scripts/logger/log";

export async function initConsignmentStockOrder() {
  const cst = getState().account?.customer?.attributes?.metadata?.cst;
  // only init when customer has cst
  if (!cst) return;

  const loginState = getState().login;
  if (loginState.token) {
    const consignmentStockOrderId = localStorage.getItem(StorageKeys.CST_ORDER_ID);
    if (consignmentStockOrderId === null) {
      // create new draft order
      await createConsignmentStockOrder();
    } else {
      await loadConsignmentStockOrder(consignmentStockOrderId, true);
      const consignmentStockOrderState = getState().consignmentStock.order;
      // create a new ConsignmentStockOrder if it is already ordered
      if (
        consignmentStockOrderState.attributes?.status !== "pending" &&
        consignmentStockOrderState.attributes?.status !== "draft"
      ) {
        await resetConsignmentStockOrder();
        await createConsignmentStockOrder();
      }
    }
  }
}

async function setConsignmentStockOrderIdStateAndStorage(consignmentStockOrderId: string | null) {
  dispatch(setConsignmentStockOrderId(consignmentStockOrderId));
  if (consignmentStockOrderId === null) {
    localStorage.removeItem(StorageKeys.CST_ORDER_ID);
  } else {
    localStorage.setItem(StorageKeys.CST_ORDER_ID, consignmentStockOrderId);
  }
}

async function createConsignmentStockOrder() {
  const consignmentStockOrder = getState().consignmentStock.order;
  // prevent recreating when already existent
  if (consignmentStockOrder.id) {
    console.info("consignment order already exists");
    return;
  }

  const loginState = getState().login;
  const accountState = getState().account;
  if (!loginState.token) {
    return;
  }

  const response: ResponseOrders | null = await serviceCreateOrder(
    getLang(),
    accountState.customer?.id ?? "",
    OrderType.CST_ORDER,
  );
  if (response === null) {
    return;
  }
  await setConsignmentStockOrderIdStateAndStorage(response.data.id);
  dispatch(setConsignmentStockOrderAttributes(response.data.attributes));
}

async function loadConsignmentStockOrder(consignmentStockOrderId: string, onInit: boolean = false) {
  const responseOrder: ResponseOrdersWithIncludes | null =
    await serviceGetOrder(consignmentStockOrderId);
  if (responseOrder === null) {
    return;
  }
  await setConsignmentStockOrderData(responseOrder, onInit);
  const shipmentsData = responseOrder.data.relationships.shipments.data ?? [];
  if (shipmentsData.length) {
    const responseShipments: ResponseShipmentsListWithIncludes | null =
      await serviceGetOrderShipments(consignmentStockOrderId);
    if (responseShipments === null) {
      return;
    }
    dispatch(setConsignmentStockOrderShipments(responseShipments.data));
    dispatch(
      setConsignmentStockOrderShippingMethods(filterIncludedShippingMethods(responseShipments)),
    );
  }
}

async function setConsignmentStockOrderData(
  order: ResponseOrdersWithIncludes,
  onInit: boolean = false,
) {
  dispatch(setConsignmentStockOrderLineItems(filterIncludedLineItems(order)));
  // !!! similar function setOrderData and setCartData
  if (onInit) {
    dispatch(setConsignmentStockOrderId(order.data.id));
  } else {
    await setConsignmentStockOrderIdStateAndStorage(order.data.id);
  }
  dispatch(setConsignmentStockOrderAttributes(order.data.attributes));
  dispatch(setConsignmentStockOrderRelationships(order.data.relationships));
  const paymentMethods = filterIncludedPaymentMethods(order);
  const allowedPaymentMethods = filterAllowedPaymentMethods(paymentMethods);
  dispatch(setConsignmentStockOrderPaymentMethods(allowedPaymentMethods));
  dispatch(setConsignmentStockOrderAddresses(filterIncludedAddresses(order)));
}

export async function addLineItem(sku: string, batchNumber: string) {
  dispatch(setConsignmentStockOrderChangeInProgress(true));
  const accountState = getState().account;
  const consignmentStockOrder = getState().consignmentStock.order;

  if (accountState.customer === null) {
    dispatch(setPathnameAfterLogin(window.location.pathname));
    scrollTop();
    boundReplaceLocObj({ pathname: `${getLangCountry()}/${PATH.LOGIN}` });
  } else {
    // User is logged in
    if (consignmentStockOrder.id == null) {
      console.error("user is logged in but not in a customer group / not assigned in SAP");
      return;
    }
    await serviceCreateLineItemConsignmentStock(consignmentStockOrder.id, sku, batchNumber);
    await loadConsignmentStockOrder(consignmentStockOrder.id);
  }
  dispatch(setConsignmentStockOrderChangeInProgress(false));
}

export async function deleteLineItem(consignmentStockOrderId: string, lineItemId: string) {
  dispatch(setConsignmentStockOrderChangeInProgress(true));
  await serviceDeleteLineItem(lineItemId);
  await loadConsignmentStockOrder(consignmentStockOrderId);
  dispatch(setConsignmentStockOrderChangeInProgress(false));
}

export async function resetConsignmentStockOrder() {
  await setConsignmentStockOrderIdStateAndStorage(null);
  dispatch(setConsignmentStockOrderAttributes(null));
  dispatch(setConsignmentStockOrderRelationships(null));
  dispatch(setConsignmentStockOrderLineItems([]));
  dispatch(setConsignmentStockOrderShipments([]));
  dispatch(setConsignmentStockOrderShippingMethods([]));
  dispatch(setConsignmentStockOrderPaymentMethods([]));
  dispatch(setConsignmentStockOrderAddresses([]));
}

export function addNewScanSkus(scanSkus: ScannedInventory[]) {
  const scannedReceivedInventory = getState().consignmentStock.scannedReceivedInventory;
  // only add when different
  if (JSON.stringify(scanSkus) !== JSON.stringify(scannedReceivedInventory)) {
    dispatch(setScannedReceivedInventory(scanSkus));
  }
}

export async function setConsignmentStockOrderShippingAddress(addressId: string) {
  dispatch(setConsignmentStockCheckoutOrderUpdating(true));
  const consignmentStockState = getState().consignmentStock;
  if (consignmentStockState.order.id) {
    await serviceAddRelationToOrder(
      consignmentStockState.order.id,
      addressId,
      "shipping_address",
      "addresses",
    );
    await loadConsignmentStockOrder(consignmentStockState.order.id);
  }
  dispatch(setConsignmentStockCheckoutOrderUpdating(false));
}

// Checkout of Consignment Stock

export async function initConsignmentStockCheckout(consignmentStockOrderId: string) {
  dispatch(setConsignmentStockCheckoutOrderUpdating(true));
  const loginState = getState().login;
  if (loginState.token === null) {
    dispatch(setPathnameAfterLogin(window.location.pathname));
    boundReplaceLocObj({ pathname: `${getLangCountry()}/${PATH.LOGIN}` });
    return;
  }

  await loadConsignmentStockOrder(consignmentStockOrderId, false);
  await loadCustomerAddresses();

  // set addresses only if no addresses are set
  let consignmentStockState = getState().consignmentStock;

  // check of id is still pending
  if (consignmentStockState.order.attributes?.status !== "pending") {
    const orderNumber: number | undefined = consignmentStockState.order.attributes?.number;
    toast.warning(i18next.t("ORDER CAN NOT BE CHANGED ANYMORE", { orderNumber }), {
      position: "top-center",
    });
    boundReplaceLocObj({ pathname: `${getLangCountry()}` });
    await resetConsignmentStockCheckout();
    await resetConsignmentStockOrder();
    await createConsignmentStockOrder();
  }

  const mainAddress: Addresses | null = getMainAddress(getState().account.addresses);
  if (mainAddress && consignmentStockState.order.relationships) {
    let didInitAddressData = false;
    if (!consignmentStockState.order.relationships.shipping_address.data) {
      await serviceAddRelationToOrder(
        consignmentStockOrderId,
        mainAddress.id,
        "shipping_address",
        "addresses",
      );
      didInitAddressData = true;
    }
    if (!consignmentStockState.order.relationships.billing_address.data) {
      await serviceAddRelationToOrder(
        consignmentStockOrderId,
        mainAddress.id,
        "billing_address",
        "addresses",
      );
      didInitAddressData = true;
    }
    if (didInitAddressData) {
      await loadConsignmentStockOrder(consignmentStockOrderId);
    }
  }

  // set payment method only if no payment method is set
  if (
    consignmentStockState.order.paymentMethods.length > 0 &&
    consignmentStockState.order.relationships
  ) {
    let responsePaymentMethod: ResponseOrdersWithIncludes | null = null;
    if (!consignmentStockState.order.relationships.payment_method.data) {
      let paymentMethodsIndexToSet: null | number = null;
      // only INVOICE allowed in general - for US only US_PAYMENT allowed
      for (let i = 0; i < consignmentStockState.order.paymentMethods.length; i++) {
        if (consignmentStockState.order.paymentMethods[i].attributes.reference === "INVOICE") {
          paymentMethodsIndexToSet = i;
          break;
        }
        if (consignmentStockState.order.paymentMethods[i].attributes.reference === "US_PAYMENT") {
          paymentMethodsIndexToSet = i;
          break;
        }
      }
      if (paymentMethodsIndexToSet !== null) {
        responsePaymentMethod = await serviceAddRelationToOrder(
          consignmentStockOrderId,
          consignmentStockState.order.paymentMethods[paymentMethodsIndexToSet].id,
          "payment_method",
          "payment_methods",
        );
      }
    }
    if (responsePaymentMethod !== null) {
      await setConsignmentStockOrderData(responsePaymentMethod, false);
    }
  }

  // set initial shipping method only if none are set
  consignmentStockState = getState().consignmentStock;
  if (
    consignmentStockState.order.shipments &&
    consignmentStockState.order.shippingMethods.length > 0
  ) {
    if (getShippingMethod(consignmentStockState.order) === null) {
      await syncShippingMethod(OrderType.CST_ORDER);
    }
  }

  dispatch(setConsignmentStockCheckoutOrderUpdating(false));
}

export async function setShippingMethodToShipmentsOfConsignmentStockOrder(
  shippingMethodId: string,
) {
  dispatch(setConsignmentStockCheckoutOrderUpdating(true));
  const consignmentStockState = getState().consignmentStock;
  if (consignmentStockState.order.id) {
    const backorderShippingMethod = consignmentStockState.order.shippingMethods.filter(
      shippingMethod => shippingMethod.attributes.name.toUpperCase().includes("BACKORDERS"),
    )[0];
    const shipments = consignmentStockState.order.shipments;
    for (let i = 0; i < shipments.length; i++) {
      if (i === 0) {
        await serviceAddShippingMethodToShipments(shipments[i].id, shippingMethodId);
      } else {
        await serviceAddShippingMethodToShipments(shipments[i].id, backorderShippingMethod.id);
      }
    }
    await loadConsignmentStockOrder(consignmentStockState.order.id);
  }
  dispatch(setConsignmentStockCheckoutOrderUpdating(false));
}

export function setGtcAction(gtcChecked: boolean) {
  dispatch(setConsignmentStockCheckoutGtc(gtcChecked));
}

export async function placeConsignmentStockOrder() {
  const loginState = getState().login;
  const consignmentStockState = getState().consignmentStock;
  const paymentMethod: PaymentMethods | null = getPaymentMethod(consignmentStockState.order);

  if (!consignmentStockState.checkout.gtc) {
    dispatch(setConsignmentStockCheckoutGtcError(i18next.t("GTC MANDATORY")));
    return;
  }

  if (paymentMethod === null) {
    console.error("no payment_method defined");
    return;
  }

  await syncShippingMethod(OrderType.CST_ORDER);

  dispatch(setConsignmentStockCheckoutOrderProcessing(true));
  if (consignmentStockState.order.id) {
    let metadata: any = Object.assign({}, consignmentStockState.order.attributes?.metadata ?? {});

    // Save owner id of sales agent
    if (loginState.token?.owner_type === OWNER_TYPE.USER) {
      metadata["sales-agent"] = loginState.token.owner_id;
    }

    // Add metadata
    await serviceUpdateOrder(consignmentStockState.order.id, {
      metadata: metadata,
      language_code: getLang(),
    });

    // Add wire transfer object
    await serviceCreateWireTransfer(consignmentStockState.order.id);

    try {
      const orderResponse = await LayerIo.requestJsonRpc<any, any>("order", "create", {
        orderId: consignmentStockState.order.id,
        token: await getAccessToken(),
      });

      if (orderResponse.success) {
        // onSuccess of order
        await resetConsignmentStockCheckout();
        await resetConsignmentStockOrder();
        await createConsignmentStockOrder();
        scrollTop();

        // Redirect to order page. No credit card payment redirect
        boundReplaceLocObj({
          pathname: `${getLangCountry()}/${PATH.ORDER}/${consignmentStockState.order.id}`,
        });
      } else {
        const message = "orderResponse.success was not true";
        logSentryAndConsole(message, "fatal");
        throw new Error(message);
      }
    } catch (e: any) {
      const orderNumber = consignmentStockState.order.attributes?.number ?? "-";
      const message = i18next.t("ORDER CREATION FAILED", { orderNumber });
      toast.error(message, { position: "top-center" });

      logSentryAndConsole(message, "warning");
      logSentryAndConsoleError(e, "fatal");

      boundReplaceLocObj({
        pathname: `${getLangCountry()}/${PATH.ORDER}/${consignmentStockState.order.id}`,
      });
    }
  }
  dispatch(setConsignmentStockCheckoutOrderProcessing(false));
}

export async function resetConsignmentStockCheckout() {
  dispatch(setConsignmentStockCheckoutGtc(false));
  dispatch(setConsignmentStockCheckoutGtcError(null));
  dispatch(setConsignmentStockCheckoutBstnk(""));
  dispatch(setConsignmentStockCheckoutCouponCode(""));
  dispatch(setConsignmentStockCheckoutCouponCodeError(null));
  dispatch(setConsignmentStockCheckoutOrderProcessing(false));
}
