import algoliasearch from "algoliasearch/lite";
import Locale from "../../common/constants/Locale";
import { getCountry, getLang } from "../../router/scripts/params";
import { getAppEnv } from "../../common/scripts/config/app";

const client = algoliasearch("TM8JOGS2X7", "ca3586879df62e842310a907e8e36b18");
const index = {};

// Search in Algolia
export async function search(q: string, hitsPerPage = 500): Promise<any> {
  return await index[getLang()].search(q, {
    facetFilters: [`countryCodes:${getCountry()}`],
    hitsPerPage,
  });
}

export function initSearchIndex() {
  for (const locale of Object.values(Locale)) {
    index[locale] = client.initIndex(`${getAppEnv()}_product_${locale}`);
  }
}
