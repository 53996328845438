import { Addresses } from "../../commercelayer/vo/addresses";
import Order from "../../state/vo/Order";

export function getShippingAddress(order: Order, addresses: Addresses[]): Addresses | null {
  const addressId = order.relationships?.shipping_address?.data?.id ?? null;
  if (addressId) {
    const accountStateAddressesFiltered: Addresses[] = addresses.filter(function (data) {
      return data.id === addressId;
    });
    if (accountStateAddressesFiltered.length > 0) {
      return accountStateAddressesFiltered[0];
    }
  }
  return null;
}

export function getBillingAddress(order: Order, addresses: Addresses[]): Addresses | null {
  const addressId = order.relationships?.billing_address?.data?.id ?? null;
  if (addressId) {
    const accountStateAddressesFiltered: Addresses[] = addresses.filter(function (data) {
      return data.id === addressId;
    });
    if (accountStateAddressesFiltered.length > 0) {
      return accountStateAddressesFiltered[0];
    }
  }
  return null;
}
