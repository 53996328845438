import React, { useState } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import i18next from "i18next";
import { Link } from "react-router-dom";
import { PATH } from "../../router/constants/Path";
import { useForm } from "react-hook-form";
import { login } from "../actions/loginActions";
import SignupBenefits from "../../signup/components/SignupBenefits";
import SearchOverlay from "../../search/components/SearchOverlay";
import { scrollTop } from "../../layout/scripts/scroll";
import ForgotPassword from "./ForgotPassword";
import { useLangCountry } from "../../router/hooks/useLangCountry";

type LoginData = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const { register, handleSubmit, setValue } = useForm<LoginData>();
  const [showFormForgotPassword, setShowFormForgotPassword] = React.useState(false);
  const langCountry = useLangCountry();

  const onSubmit = handleSubmit((data: LoginData) => {
    setValue("password", "");
    login(data.email, data.password).then();
  });
  const handleClickForgotPassword = () => {
    setShowFormForgotPassword(false);
  };

  const [pwdInputType, setPwdInputType] = useState("password");
  const onClickEye = function () {
    pwdInputType === "password" ? setPwdInputType("text") : setPwdInputType("password");
  };

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <Middle>
          <div className="grid-wrap grid-wrap--line">
            {!showFormForgotPassword && (
              <div className="grid-1-2">
                <h1 className="col-title">{i18next.t("LOGIN")}</h1>
                <div className="grid__text">{i18next.t("LOGIN WITH YOUR EMAIL ADDRESS")}</div>
                <form className="form register-form" onSubmit={onSubmit} autoComplete="off">
                  <label className="label " htmlFor="email">
                    {`${i18next.t("EMAIL")} *`}
                  </label>
                  <input
                    type="email"
                    className="input--text"
                    id="email"
                    defaultValue=""
                    placeholder={i18next.t("EMAIL")}
                    required
                    aria-required="true"
                    aria-label={i18next.t("EMAIL")}
                    autoComplete="off"
                    {...register("email", { required: true })}
                  />

                  <label className="label " htmlFor="password">
                    {`${i18next.t("PASSWORD")} *`}
                  </label>
                  <div className="input--password-wrap">
                    <input
                      type={pwdInputType}
                      className="input--password"
                      id="password"
                      defaultValue=""
                      placeholder={i18next.t("PASSWORD")}
                      required
                      aria-required="true"
                      aria-label={i18next.t("PASSWORD")}
                      autoComplete="off"
                      {...register("password", { required: true })}
                    />
                    <span
                      onClick={onClickEye}
                      className="input--password__icon icon-font icon-font__eye"
                    />
                  </div>

                  <Link
                    className="link-bold"
                    title={`${i18next.t("FORGOT PASSWORD")}?`}
                    to="#"
                    tabIndex={0}
                    onClick={() => setShowFormForgotPassword(true)}
                  >
                    {i18next.t("FORGOT PASSWORD")}?
                  </Link>

                  <button type="submit" className="btn ">
                    {i18next.t("LOGIN")}
                  </button>
                </form>
              </div>
            )}

            {showFormForgotPassword && (
              <div className="grid-1-2">
                <ForgotPassword
                  onSubmit={() => setShowFormForgotPassword(false)}
                  handleClickForgotPassword={handleClickForgotPassword}
                />
              </div>
            )}

            <div className="grid-1-2">
              <h2 className="col-title">{i18next.t("SIGNUP")}</h2>
              <SignupBenefits />
              <Link
                to={`${langCountry}/${PATH.SIGNUP}`}
                className="btn"
                onClick={() => scrollTop()}
                role="button"
                tabIndex={0}
                title={i18next.t("SIGNUP AS A BUSINESS CUSTOMER")}
              >
                <span className="btn__text">{i18next.t("SIGNUP AS A BUSINESS CUSTOMER")}</span>
              </Link>
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Login;
