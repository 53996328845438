import React from "react";
import i18next from "i18next";
import OrderDocument from "../../state/vo/OrderDocument";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import { getDateFormatted } from "../../common/scripts/format/date";

interface Props {
  customerId: string;
  orderDocument: OrderDocument;
}

const AccountReportOrderDocument: React.FC<Props> = ({ customerId, orderDocument }) => {
  const downloadUrl = `/api/customer/${customerId}/billing-document/${orderDocument.salesDocumentNumber}`;

  return (
    <div className="list-4cols ">
      <div className="list__col list__col--link">
        <a href={downloadUrl} target="_blank" rel="noreferrer">
          {`${i18next.t(`SAP_ORDER_DOCUMENT ${orderDocument.billingDocumentType}`)} ${
            orderDocument.salesDocumentNumber
          }`}
        </a>
      </div>
      <div className="list__col">{getDateFormatted(orderDocument.billingDocumentDate)}</div>
      <div className="list__col">
        <span className="phone-only">{i18next.t("VAT")}</span>
        {getNumberWithCurrency(orderDocument.taxAmount, orderDocument.salesDocumentCurrency)}
      </div>
      <div className="list__col">
        {getNumberWithCurrency(orderDocument.totalAmount, orderDocument.salesDocumentCurrency)}
      </div>
    </div>
  );
};

export default AccountReportOrderDocument;
