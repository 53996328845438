import { fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessTokenMarketAll } from "../scripts/token";
import { ResponseExports, ResponseExportsList } from "../vo/exports";

export async function serviceGetExports(): Promise<ResponseExportsList | null> {
  try {
    return await fetchService(
      HTTP_METHOD.GET,
      `api/exports?sort=-created_at`,
      await getAccessTokenMarketAll(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}

export async function serviceCreateExportOrders(
  dateFrom: Date,
  dateTo: Date,
): Promise<ResponseExports | null> {
  const data = {
    data: {
      type: "exports",
      attributes: {
        resource_type: "orders",
        format: "csv",
        includes: ["customer"],
        filters: {
          status_in: ["approved"],
          placed_at_gteq: dateFrom.toISOString(),
          placed_at_lteq: dateTo.toISOString(),
        },
      },
    },
  };
  try {
    return await fetchService(
      HTTP_METHOD.POST,
      `api/exports`,
      await getAccessTokenMarketAll(),
      data,
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}
