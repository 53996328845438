import { getIdFromScannedInventory, getIdFromLineItem, getIdFromSkuAndBatchNumber } from "./id";
import { LineItems } from "commercelayer/vo/lineItems";
import { ConsignmentStockResponse, ScannedInventory } from "../vo/ConsignmentStock";

export type QuantityRecord = { [sku_batchNumber: string]: number };

export function getLineItemsQuantityMap(lineItems: LineItems[]): QuantityRecord {
  const map = {};

  if (!lineItems) {
    return map;
  }

  lineItems.forEach((lineItem: LineItems) => {
    const id = getIdFromLineItem(lineItem);

    if (map[id]) {
      map[id] += 1;
    } else {
      map[id] = 1;
    }
  });

  return map;
}

export function getConsignmentStockQuantityRecord(
  consignmentStock: ConsignmentStockResponse,
): QuantityRecord {
  const map = {};

  consignmentStock.forEach(pos => {
    const { attributes } = pos;
    const sku = attributes.sku_code;

    attributes.metadata.batches.forEach(batch => {
      const batchNumber = batch.batch_number;
      const id = getIdFromSkuAndBatchNumber(sku, batchNumber);

      map[id] = batch.quantity;
    });
  });

  return map;
}

export function getScannedInventoryQuantityMap(
  scannedInventory: ScannedInventory[],
): QuantityRecord {
  const map = {};

  if (!scannedInventory) {
    return map;
  }

  scannedInventory.forEach(scannedPos => {
    const id = getIdFromScannedInventory(scannedPos);

    if (map[id]) {
      map[id] += 1;
    } else {
      map[id] = 1;
    }
  });

  return map;
}
