import React from "react";
import NavListItemA from "./NavListItemA";
import NavListItemLink from "./NavListItemLink";
import i18next from "i18next";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const NavService: React.FC = props => {
  const langCountry = useLangCountry();
  return (
    <nav className="nav-service">
      <ul className="nav-list">
        <NavListItemA
          text={i18next.t("DENTAL PROFESSIONALS")}
          href={i18next.t("URL WEB ROOT") + i18next.t("URL PATH DENTAL PROS")}
        />
        <NavListItemLink text={i18next.t("SHOP")} to={langCountry} active={true} />
        <NavListItemA
          text={i18next.t("PATIENTS")}
          href={i18next.t("URL WEB ROOT") + i18next.t("URL PATH PATIENTS")}
        />
        <NavListItemA
          text={i18next.t("ABOUT US")}
          href={i18next.t("URL WEB ROOT") + i18next.t("URL PATH ABOUT US")}
        />
      </ul>
    </nav>
  );
};

export default NavService;
