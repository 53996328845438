import React from "react";
import i18next from "i18next";
import { ScannedInventory } from "../../consignment-stock/vo/ConsignmentStock";
import OverlayPopup from "layout/components/OverlayPopup";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getIdFromSkuAndBatchNumber } from "../../consignment-stock/scripts/id";
import PfClassName from "../../product/const/PfClassName";
import { OVERLAY } from "../../layout/constants/Overlay";

interface Props {
  scannedInventory: ScannedInventory[];
  shutdownScanning: () => void;
}

const OverlayPopupScanFromCstDesktop: React.FC<Props> = ({
  scannedInventory,
  shutdownScanning,
}) => {
  const productsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const layoutOverlayOpen = useSelector((state: State) => state.layout.overlayOpen);

  if (layoutOverlayOpen !== OVERLAY.CST_DESKTOP_RESULTS) {
    return null;
  }

  return (
    <OverlayPopup
      showClose={false}
      activeButtonShow={true}
      activeButtonLabel={i18next.t("CLOSE")}
      activeButtonOnClick={shutdownScanning}
    >
      <>
        <div className="overlay-popup__subtitle sticky">
          {i18next.t("SCAN TO CART QR CODE TILE")}
        </div>
        {scannedInventory.length !== 0 ? (
          <div className="overlay-popup__content-wrap">
            <div className="list-wrap stock-list">
              <>
                {scannedInventory.map((entry, i) => {
                  const product = productsIndex[entry.sku];
                  if (!product) {
                    return null;
                  }

                  const id = getIdFromSkuAndBatchNumber(entry.sku, entry.batchNumber) + "-" + i;
                  const expiry = entry.expiry ? new Date(entry.expiry) : null;

                  return (
                    <div className="list-3cols " key={i}>
                      <div className="list-3cols__item">
                        <div className="list-item__text-wrap">
                          <h3
                            className={`item-title  pf-dot pf-${
                              PfClassName?.[product?.pfs?.[0]?.name]
                            }`}
                          >
                            {`${product.sku} ${product.name}`}
                          </h3>

                          <div className="list-item__text">{product.nameSuffix}</div>
                        </div>
                      </div>
                      {expiry ? (
                        <div className="list-3cols__item">
                          <span className="label--expiry-date">{i18next.t("EXPIRES AT")}:</span>
                          &nbsp;
                          {`${("0" + (expiry.getUTCMonth() + 1)).slice(
                            -2,
                          )}.${expiry.getUTCFullYear()}`}
                        </div>
                      ) : null}
                      <div className="list-3cols__item">
                        <div className="input--checkbox-wrap reverse">
                          <input
                            type="checkbox"
                            className="input--checkbox"
                            id={id}
                            name={id}
                            aria-required="false"
                            aria-checked="true"
                          />
                          <label className="label label--checkbox checkbox--fill" htmlFor={id}>
                            {i18next.t("LOT")} {entry.batchNumber}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            </div>
          </div>
        ) : null}
      </>
    </OverlayPopup>
  );
};

export default OverlayPopupScanFromCstDesktop;
