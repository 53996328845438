import React, { useState } from "react";
import { ThommenLogoHeader } from "../../common/components/logo/Thommen";
import Languages from "./Languages";
import Countries from "./Countries";
import Search from "./Search";
import NavService from "./NavService";
import NavListItemIconA from "./NavListItemIconA";
import NavMetaBig from "./NavMetaBig";
import i18next from "i18next";
import CartButton from "./CartButton";
import NavListItemAccount from "./NavListItemAccount";

const MobileMenu: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <div className={"mobile-menu " + (open ? "open" : "")}>
      <div className="mobile-menu__header">
        <ThommenLogoHeader />
        <div className="mobile-menu__cart">
          <CartButton />
        </div>

        <div
          className={"mobile-menu__toggle " + (open ? "open" : "")}
          title={i18next.t("MOBILE NAVIGATION")}
          onClick={() => setOpen(!open)}
        >
          <div className="mobile-menu__link">
            <div className="bars" />
            <div className="bars" />
            <div className="bars" />
            <span className="visuallyhidden">{i18next.t("MOBILE NAVIGATION")}</span>
          </div>
        </div>
      </div>
      <div className="mobile-menu__body">
        <Search setOpen={setOpen} />
        <div className="nav-meta--account">
          <ul className="nav-list">
            <NavListItemAccount />
            <Languages />
            <Countries />
          </ul>
        </div>
        <div onClick={e => setOpen(false)}>
          <NavService />
          <NavMetaBig />
        </div>
        <nav className="nav-meta">
          <ul className="nav-list">
            <NavListItemIconA
              text={i18next.t("DOWNLOAD CENTER")}
              href={
                i18next.t("URL WEB ROOT") +
                i18next.t("URL PATH ABOUT US") +
                i18next.t("URL PATH DOWNLOAD")
              }
              icon={"download"}
            />
            <NavListItemIconA
              text={i18next.t("CONTACT", { count: 1 })}
              href={
                i18next.t("URL WEB ROOT") +
                i18next.t("URL PATH ABOUT US") +
                i18next.t("URL PATH CONTACT")
              }
              icon={"contact"}
            />
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default MobileMenu;
