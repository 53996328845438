export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Hex: any;
  Json: any;
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  RichTextAST: any;
};

export type Aggregate = {
  __typename?: "Aggregate";
  count: Scalars["Int"];
};

/** Asset system model */
export type Asset = Node & {
  __typename?: "Asset";
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Asset>;
  downloadsProduct: Array<Product>;
  downloadsProductGroup: Array<ProductGroup>;
  /** The file name */
  fileName: Scalars["String"];
  /** The file handle */
  handle: Scalars["String"];
  /** The height of the file */
  height?: Maybe<Scalars["Float"]>;
  /** List of Asset versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  imageCategory: Array<Category>;
  imageParagraph: Array<Paragraph>;
  imageProduct: Array<Product>;
  imageProductGroup: Array<ProductGroup>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Asset>;
  /** The mime type of the file */
  mimeType?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** The file size */
  size?: Maybe<Scalars["Float"]>;
  /** System stage field */
  stage: Stage;
  tags: Array<Scalars["String"]>;
  tileImageProductGroup: Array<ProductGroup>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars["String"];
  /** The file width */
  width?: Maybe<Scalars["Float"]>;
};

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

/** Asset system model */
export type AssetDownloadsProductArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetDownloadsProductGroupArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

/** Asset system model */
export type AssetImageCategoryArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryWhereInput>;
};

/** Asset system model */
export type AssetImageParagraphArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ParagraphOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ParagraphWhereInput>;
};

/** Asset system model */
export type AssetImageProductArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

/** Asset system model */
export type AssetImageProductGroupArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Asset system model */
export type AssetTileImageProductGroupArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: "AssetConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  downloadsProduct?: InputMaybe<ProductCreateManyInlineInput>;
  downloadsProductGroup?: InputMaybe<ProductGroupCreateManyInlineInput>;
  fileName: Scalars["String"];
  handle: Scalars["String"];
  height?: InputMaybe<Scalars["Float"]>;
  imageCategory?: InputMaybe<CategoryCreateManyInlineInput>;
  imageParagraph?: InputMaybe<ParagraphCreateManyInlineInput>;
  imageProduct?: InputMaybe<ProductCreateManyInlineInput>;
  imageProductGroup?: InputMaybe<ProductGroupCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  mimeType?: InputMaybe<Scalars["String"]>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Float"]>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  tileImageProductGroup?: InputMaybe<ProductGroupCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  fileName: Scalars["String"];
  handle: Scalars["String"];
  height?: InputMaybe<Scalars["Float"]>;
  mimeType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Float"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: "AssetEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  downloadsProductGroup_every?: InputMaybe<ProductGroupWhereInput>;
  downloadsProductGroup_none?: InputMaybe<ProductGroupWhereInput>;
  downloadsProductGroup_some?: InputMaybe<ProductGroupWhereInput>;
  downloadsProduct_every?: InputMaybe<ProductWhereInput>;
  downloadsProduct_none?: InputMaybe<ProductWhereInput>;
  downloadsProduct_some?: InputMaybe<ProductWhereInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  imageCategory_every?: InputMaybe<CategoryWhereInput>;
  imageCategory_none?: InputMaybe<CategoryWhereInput>;
  imageCategory_some?: InputMaybe<CategoryWhereInput>;
  imageParagraph_every?: InputMaybe<ParagraphWhereInput>;
  imageParagraph_none?: InputMaybe<ParagraphWhereInput>;
  imageParagraph_some?: InputMaybe<ParagraphWhereInput>;
  imageProductGroup_every?: InputMaybe<ProductGroupWhereInput>;
  imageProductGroup_none?: InputMaybe<ProductGroupWhereInput>;
  imageProductGroup_some?: InputMaybe<ProductGroupWhereInput>;
  imageProduct_every?: InputMaybe<ProductWhereInput>;
  imageProduct_none?: InputMaybe<ProductWhereInput>;
  imageProduct_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: InputMaybe<Array<Scalars["String"]>>;
  tileImageProductGroup_every?: InputMaybe<ProductGroupWhereInput>;
  tileImageProductGroup_none?: InputMaybe<ProductGroupWhereInput>;
  tileImageProductGroup_some?: InputMaybe<ProductGroupWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AssetOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  FileNameAsc = "fileName_ASC",
  FileNameDesc = "fileName_DESC",
  HandleAsc = "handle_ASC",
  HandleDesc = "handle_DESC",
  HeightAsc = "height_ASC",
  HeightDesc = "height_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  MimeTypeAsc = "mimeType_ASC",
  MimeTypeDesc = "mimeType_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  SizeAsc = "size_ASC",
  SizeDesc = "size_DESC",
  TagsAsc = "tags_ASC",
  TagsDesc = "tags_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  WidthAsc = "width_ASC",
  WidthDesc = "width_DESC",
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars["Boolean"]>;
};

export type AssetUpdateInput = {
  downloadsProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  downloadsProductGroup?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  imageCategory?: InputMaybe<CategoryUpdateManyInlineInput>;
  imageParagraph?: InputMaybe<ParagraphUpdateManyInlineInput>;
  imageProduct?: InputMaybe<ProductUpdateManyInlineInput>;
  imageProductGroup?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  mimeType?: InputMaybe<Scalars["String"]>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Float"]>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  tileImageProductGroup?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetUpdateLocalizationDataInput = {
  fileName?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  mimeType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Float"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  fileName?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  mimeType?: InputMaybe<Scalars["String"]>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Float"]>;
  tags?: InputMaybe<Array<Scalars["String"]>>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  mimeType?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  size?: InputMaybe<Scalars["Float"]>;
  width?: InputMaybe<Scalars["Float"]>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  downloadsProductGroup_every?: InputMaybe<ProductGroupWhereInput>;
  downloadsProductGroup_none?: InputMaybe<ProductGroupWhereInput>;
  downloadsProductGroup_some?: InputMaybe<ProductGroupWhereInput>;
  downloadsProduct_every?: InputMaybe<ProductWhereInput>;
  downloadsProduct_none?: InputMaybe<ProductWhereInput>;
  downloadsProduct_some?: InputMaybe<ProductWhereInput>;
  fileName?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  fileName_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars["String"]>;
  handle?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  handle_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars["String"]>;
  height?: InputMaybe<Scalars["Float"]>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars["Float"]>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars["Float"]>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars["Float"]>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars["Float"]>;
  /** All values that are not equal to given value. */
  height_not?: InputMaybe<Scalars["Float"]>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  imageCategory_every?: InputMaybe<CategoryWhereInput>;
  imageCategory_none?: InputMaybe<CategoryWhereInput>;
  imageCategory_some?: InputMaybe<CategoryWhereInput>;
  imageParagraph_every?: InputMaybe<ParagraphWhereInput>;
  imageParagraph_none?: InputMaybe<ParagraphWhereInput>;
  imageParagraph_some?: InputMaybe<ParagraphWhereInput>;
  imageProductGroup_every?: InputMaybe<ProductGroupWhereInput>;
  imageProductGroup_none?: InputMaybe<ProductGroupWhereInput>;
  imageProductGroup_some?: InputMaybe<ProductGroupWhereInput>;
  imageProduct_every?: InputMaybe<ProductWhereInput>;
  imageProduct_none?: InputMaybe<ProductWhereInput>;
  imageProduct_some?: InputMaybe<ProductWhereInput>;
  mimeType?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  mimeType_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  size?: InputMaybe<Scalars["Float"]>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars["Float"]>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars["Float"]>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars["Float"]>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars["Float"]>;
  /** All values that are not equal to given value. */
  size_not?: InputMaybe<Scalars["Float"]>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  tags_contains_all?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  tags_contains_none?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains at least one item provided to the filter */
  tags_contains_some?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  tags_not?: InputMaybe<Array<Scalars["String"]>>;
  tileImageProductGroup_every?: InputMaybe<ProductGroupWhereInput>;
  tileImageProductGroup_none?: InputMaybe<ProductGroupWhereInput>;
  tileImageProductGroup_some?: InputMaybe<ProductGroupWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  width?: InputMaybe<Scalars["Float"]>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars["Float"]>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars["Float"]>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars["Float"]>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars["Float"]>;
  /** All values that are not equal to given value. */
  width_not?: InputMaybe<Scalars["Float"]>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type Attribute = Node & {
  __typename?: "Attribute";
  attributeValues: Array<AttributeValue>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  dataType?: Maybe<DataType>;
  /** Get the document in other stages */
  documentInStages: Array<Attribute>;
  /** List of Attribute versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  label?: Maybe<Scalars["String"]>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Attribute>;
  name: Scalars["String"];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  unit?: Maybe<Scalars["String"]>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  xProductGroups: Array<ProductGroup>;
  yProductGroups: Array<ProductGroup>;
};

export type AttributeAttributeValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AttributeValueOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeValueWhereInput>;
};

export type AttributeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttributeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type AttributeHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type AttributeLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type AttributePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttributePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttributeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttributeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeXProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type AttributeYProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type AttributeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttributeWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttributeConnection = {
  __typename?: "AttributeConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttributeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttributeCreateInput = {
  attributeValues?: InputMaybe<AttributeValueCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  dataType?: InputMaybe<DataType>;
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars["String"]>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttributeCreateLocalizationsInput>;
  name: Scalars["String"];
  unit?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  xProductGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
  yProductGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
};

export type AttributeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  label?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type AttributeCreateLocalizationInput = {
  /** Localization input */
  data: AttributeCreateLocalizationDataInput;
  locale: Locale;
};

export type AttributeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttributeCreateLocalizationInput>>;
};

export type AttributeCreateManyInlineInput = {
  /** Connect multiple existing Attribute documents */
  connect?: InputMaybe<Array<AttributeWhereUniqueInput>>;
  /** Create and connect multiple existing Attribute documents */
  create?: InputMaybe<Array<AttributeCreateInput>>;
};

export type AttributeCreateOneInlineInput = {
  /** Connect one existing Attribute document */
  connect?: InputMaybe<AttributeWhereUniqueInput>;
  /** Create and connect one Attribute document */
  create?: InputMaybe<AttributeCreateInput>;
};

/** An edge in a connection. */
export type AttributeEdge = {
  __typename?: "AttributeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Attribute;
};

/** Identifies documents */
export type AttributeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttributeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttributeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttributeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  attributeValues_every?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_none?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_some?: InputMaybe<AttributeValueWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dataType?: InputMaybe<DataType>;
  /** All values that are contained in given list. */
  dataType_in?: InputMaybe<Array<InputMaybe<DataType>>>;
  /** All values that are not equal to given value. */
  dataType_not?: InputMaybe<DataType>;
  /** All values that are not contained in given list. */
  dataType_not_in?: InputMaybe<Array<InputMaybe<DataType>>>;
  documentInStages_every?: InputMaybe<AttributeWhereStageInput>;
  documentInStages_none?: InputMaybe<AttributeWhereStageInput>;
  documentInStages_some?: InputMaybe<AttributeWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  unit?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  unit_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  unit_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  unit_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  unit_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  unit_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  unit_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  unit_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  unit_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  unit_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  xProductGroups_every?: InputMaybe<ProductGroupWhereInput>;
  xProductGroups_none?: InputMaybe<ProductGroupWhereInput>;
  xProductGroups_some?: InputMaybe<ProductGroupWhereInput>;
  yProductGroups_every?: InputMaybe<ProductGroupWhereInput>;
  yProductGroups_none?: InputMaybe<ProductGroupWhereInput>;
  yProductGroups_some?: InputMaybe<ProductGroupWhereInput>;
};

export enum AttributeOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DataTypeAsc = "dataType_ASC",
  DataTypeDesc = "dataType_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  LabelAsc = "label_ASC",
  LabelDesc = "label_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  UnitAsc = "unit_ASC",
  UnitDesc = "unit_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type AttributeUpdateInput = {
  attributeValues?: InputMaybe<AttributeValueUpdateManyInlineInput>;
  dataType?: InputMaybe<DataType>;
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars["String"]>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttributeUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars["String"]>;
  unit?: InputMaybe<Scalars["String"]>;
  xProductGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  yProductGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
};

export type AttributeUpdateLocalizationDataInput = {
  label?: InputMaybe<Scalars["String"]>;
};

export type AttributeUpdateLocalizationInput = {
  data: AttributeUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttributeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttributeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttributeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttributeUpsertLocalizationInput>>;
};

export type AttributeUpdateManyInlineInput = {
  /** Connect multiple existing Attribute documents */
  connect?: InputMaybe<Array<AttributeConnectInput>>;
  /** Create and connect multiple Attribute documents */
  create?: InputMaybe<Array<AttributeCreateInput>>;
  /** Delete multiple Attribute documents */
  delete?: InputMaybe<Array<AttributeWhereUniqueInput>>;
  /** Disconnect multiple Attribute documents */
  disconnect?: InputMaybe<Array<AttributeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Attribute documents */
  set?: InputMaybe<Array<AttributeWhereUniqueInput>>;
  /** Update multiple Attribute documents */
  update?: InputMaybe<Array<AttributeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Attribute documents */
  upsert?: InputMaybe<Array<AttributeUpsertWithNestedWhereUniqueInput>>;
};

export type AttributeUpdateManyInput = {
  dataType?: InputMaybe<DataType>;
  /** label input for default locale (de) */
  label?: InputMaybe<Scalars["String"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttributeUpdateManyLocalizationsInput>;
  unit?: InputMaybe<Scalars["String"]>;
};

export type AttributeUpdateManyLocalizationDataInput = {
  label?: InputMaybe<Scalars["String"]>;
};

export type AttributeUpdateManyLocalizationInput = {
  data: AttributeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttributeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttributeUpdateManyLocalizationInput>>;
};

export type AttributeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttributeUpdateManyInput;
  /** Document search */
  where: AttributeWhereInput;
};

export type AttributeUpdateOneInlineInput = {
  /** Connect existing Attribute document */
  connect?: InputMaybe<AttributeWhereUniqueInput>;
  /** Create and connect one Attribute document */
  create?: InputMaybe<AttributeCreateInput>;
  /** Delete currently connected Attribute document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Attribute document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Attribute document */
  update?: InputMaybe<AttributeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Attribute document */
  upsert?: InputMaybe<AttributeUpsertWithNestedWhereUniqueInput>;
};

export type AttributeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttributeUpdateInput;
  /** Unique document search */
  where: AttributeWhereUniqueInput;
};

export type AttributeUpsertInput = {
  /** Create document if it didn't exist */
  create: AttributeCreateInput;
  /** Update document if it exists */
  update: AttributeUpdateInput;
};

export type AttributeUpsertLocalizationInput = {
  create: AttributeCreateLocalizationDataInput;
  locale: Locale;
  update: AttributeUpdateLocalizationDataInput;
};

export type AttributeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttributeUpsertInput;
  /** Unique document search */
  where: AttributeWhereUniqueInput;
};

export type AttributeValue = Node & {
  __typename?: "AttributeValue";
  attribute?: Maybe<Attribute>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<AttributeValue>;
  /** List of AttributeValue versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<AttributeValue>;
  numberValue?: Maybe<Scalars["Float"]>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  stringValue?: Maybe<Scalars["String"]>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  /**
   * This field ist filled automatically on save.
   * Do not edit this value manually.
   */
  valueLabeled?: Maybe<Scalars["String"]>;
};

export type AttributeValueAttributeArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeValueCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttributeValueCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeValueDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type AttributeValueHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type AttributeValueLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type AttributeValueProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

export type AttributeValuePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttributeValuePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeValueScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type AttributeValueUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type AttributeValueUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AttributeValueConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AttributeValueWhereUniqueInput;
};

/** A connection to a list of items. */
export type AttributeValueConnection = {
  __typename?: "AttributeValueConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AttributeValueEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AttributeValueCreateInput = {
  attribute?: InputMaybe<AttributeCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AttributeValueCreateLocalizationsInput>;
  numberValue?: InputMaybe<Scalars["Float"]>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  /** stringValue input for default locale (de) */
  stringValue?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** valueLabeled input for default locale (de) */
  valueLabeled?: InputMaybe<Scalars["String"]>;
};

export type AttributeValueCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  stringValue?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  valueLabeled?: InputMaybe<Scalars["String"]>;
};

export type AttributeValueCreateLocalizationInput = {
  /** Localization input */
  data: AttributeValueCreateLocalizationDataInput;
  locale: Locale;
};

export type AttributeValueCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AttributeValueCreateLocalizationInput>>;
};

export type AttributeValueCreateManyInlineInput = {
  /** Connect multiple existing AttributeValue documents */
  connect?: InputMaybe<Array<AttributeValueWhereUniqueInput>>;
  /** Create and connect multiple existing AttributeValue documents */
  create?: InputMaybe<Array<AttributeValueCreateInput>>;
};

export type AttributeValueCreateOneInlineInput = {
  /** Connect one existing AttributeValue document */
  connect?: InputMaybe<AttributeValueWhereUniqueInput>;
  /** Create and connect one AttributeValue document */
  create?: InputMaybe<AttributeValueCreateInput>;
};

/** An edge in a connection. */
export type AttributeValueEdge = {
  __typename?: "AttributeValueEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: AttributeValue;
};

/** Identifies documents */
export type AttributeValueManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttributeValueWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttributeValueWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttributeValueWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  attribute?: InputMaybe<AttributeWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttributeValueWhereStageInput>;
  documentInStages_none?: InputMaybe<AttributeValueWhereStageInput>;
  documentInStages_some?: InputMaybe<AttributeValueWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  numberValue?: InputMaybe<Scalars["Float"]>;
  /** All values greater than the given value. */
  numberValue_gt?: InputMaybe<Scalars["Float"]>;
  /** All values greater than or equal the given value. */
  numberValue_gte?: InputMaybe<Scalars["Float"]>;
  /** All values that are contained in given list. */
  numberValue_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** All values less than the given value. */
  numberValue_lt?: InputMaybe<Scalars["Float"]>;
  /** All values less than or equal the given value. */
  numberValue_lte?: InputMaybe<Scalars["Float"]>;
  /** All values that are not equal to given value. */
  numberValue_not?: InputMaybe<Scalars["Float"]>;
  /** All values that are not contained in given list. */
  numberValue_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AttributeValueOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NumberValueAsc = "numberValue_ASC",
  NumberValueDesc = "numberValue_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  StringValueAsc = "stringValue_ASC",
  StringValueDesc = "stringValue_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  ValueLabeledAsc = "valueLabeled_ASC",
  ValueLabeledDesc = "valueLabeled_DESC",
}

export type AttributeValueUpdateInput = {
  attribute?: InputMaybe<AttributeUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AttributeValueUpdateLocalizationsInput>;
  numberValue?: InputMaybe<Scalars["Float"]>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  /** stringValue input for default locale (de) */
  stringValue?: InputMaybe<Scalars["String"]>;
  /** valueLabeled input for default locale (de) */
  valueLabeled?: InputMaybe<Scalars["String"]>;
};

export type AttributeValueUpdateLocalizationDataInput = {
  stringValue?: InputMaybe<Scalars["String"]>;
  valueLabeled?: InputMaybe<Scalars["String"]>;
};

export type AttributeValueUpdateLocalizationInput = {
  data: AttributeValueUpdateLocalizationDataInput;
  locale: Locale;
};

export type AttributeValueUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AttributeValueCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AttributeValueUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AttributeValueUpsertLocalizationInput>>;
};

export type AttributeValueUpdateManyInlineInput = {
  /** Connect multiple existing AttributeValue documents */
  connect?: InputMaybe<Array<AttributeValueConnectInput>>;
  /** Create and connect multiple AttributeValue documents */
  create?: InputMaybe<Array<AttributeValueCreateInput>>;
  /** Delete multiple AttributeValue documents */
  delete?: InputMaybe<Array<AttributeValueWhereUniqueInput>>;
  /** Disconnect multiple AttributeValue documents */
  disconnect?: InputMaybe<Array<AttributeValueWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AttributeValue documents */
  set?: InputMaybe<Array<AttributeValueWhereUniqueInput>>;
  /** Update multiple AttributeValue documents */
  update?: InputMaybe<Array<AttributeValueUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AttributeValue documents */
  upsert?: InputMaybe<Array<AttributeValueUpsertWithNestedWhereUniqueInput>>;
};

export type AttributeValueUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<AttributeValueUpdateManyLocalizationsInput>;
  numberValue?: InputMaybe<Scalars["Float"]>;
  /** stringValue input for default locale (de) */
  stringValue?: InputMaybe<Scalars["String"]>;
  /** valueLabeled input for default locale (de) */
  valueLabeled?: InputMaybe<Scalars["String"]>;
};

export type AttributeValueUpdateManyLocalizationDataInput = {
  stringValue?: InputMaybe<Scalars["String"]>;
  valueLabeled?: InputMaybe<Scalars["String"]>;
};

export type AttributeValueUpdateManyLocalizationInput = {
  data: AttributeValueUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AttributeValueUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AttributeValueUpdateManyLocalizationInput>>;
};

export type AttributeValueUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AttributeValueUpdateManyInput;
  /** Document search */
  where: AttributeValueWhereInput;
};

export type AttributeValueUpdateOneInlineInput = {
  /** Connect existing AttributeValue document */
  connect?: InputMaybe<AttributeValueWhereUniqueInput>;
  /** Create and connect one AttributeValue document */
  create?: InputMaybe<AttributeValueCreateInput>;
  /** Delete currently connected AttributeValue document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected AttributeValue document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single AttributeValue document */
  update?: InputMaybe<AttributeValueUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AttributeValue document */
  upsert?: InputMaybe<AttributeValueUpsertWithNestedWhereUniqueInput>;
};

export type AttributeValueUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AttributeValueUpdateInput;
  /** Unique document search */
  where: AttributeValueWhereUniqueInput;
};

export type AttributeValueUpsertInput = {
  /** Create document if it didn't exist */
  create: AttributeValueCreateInput;
  /** Update document if it exists */
  update: AttributeValueUpdateInput;
};

export type AttributeValueUpsertLocalizationInput = {
  create: AttributeValueCreateLocalizationDataInput;
  locale: Locale;
  update: AttributeValueUpdateLocalizationDataInput;
};

export type AttributeValueUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AttributeValueUpsertInput;
  /** Unique document search */
  where: AttributeValueWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttributeValueWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type AttributeValueWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttributeValueWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttributeValueWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttributeValueWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  attribute?: InputMaybe<AttributeWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AttributeValueWhereStageInput>;
  documentInStages_none?: InputMaybe<AttributeValueWhereStageInput>;
  documentInStages_some?: InputMaybe<AttributeValueWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  numberValue?: InputMaybe<Scalars["Float"]>;
  /** All values greater than the given value. */
  numberValue_gt?: InputMaybe<Scalars["Float"]>;
  /** All values greater than or equal the given value. */
  numberValue_gte?: InputMaybe<Scalars["Float"]>;
  /** All values that are contained in given list. */
  numberValue_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  /** All values less than the given value. */
  numberValue_lt?: InputMaybe<Scalars["Float"]>;
  /** All values less than or equal the given value. */
  numberValue_lte?: InputMaybe<Scalars["Float"]>;
  /** All values that are not equal to given value. */
  numberValue_not?: InputMaybe<Scalars["Float"]>;
  /** All values that are not contained in given list. */
  numberValue_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]>>>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  stringValue?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  stringValue_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  stringValue_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  stringValue_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  stringValue_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  stringValue_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  stringValue_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  stringValue_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  stringValue_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  stringValue_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  valueLabeled?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  valueLabeled_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  valueLabeled_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  valueLabeled_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  valueLabeled_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  valueLabeled_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  valueLabeled_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  valueLabeled_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  valueLabeled_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  valueLabeled_starts_with?: InputMaybe<Scalars["String"]>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttributeValueWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttributeValueWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttributeValueWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttributeValueWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttributeValueWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AttributeValue record uniquely */
export type AttributeValueWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** This contains a set of filters that can be used to compare values internally */
export type AttributeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type AttributeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttributeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttributeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttributeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  attributeValues_every?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_none?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_some?: InputMaybe<AttributeValueWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  dataType?: InputMaybe<DataType>;
  /** All values that are contained in given list. */
  dataType_in?: InputMaybe<Array<InputMaybe<DataType>>>;
  /** All values that are not equal to given value. */
  dataType_not?: InputMaybe<DataType>;
  /** All values that are not contained in given list. */
  dataType_not_in?: InputMaybe<Array<InputMaybe<DataType>>>;
  documentInStages_every?: InputMaybe<AttributeWhereStageInput>;
  documentInStages_none?: InputMaybe<AttributeWhereStageInput>;
  documentInStages_some?: InputMaybe<AttributeWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  label?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  label_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  label_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  label_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  label_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  label_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  label_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  label_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  label_starts_with?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  unit?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  unit_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  unit_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  unit_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  unit_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  unit_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  unit_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  unit_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  unit_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  unit_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  xProductGroups_every?: InputMaybe<ProductGroupWhereInput>;
  xProductGroups_none?: InputMaybe<ProductGroupWhereInput>;
  xProductGroups_some?: InputMaybe<ProductGroupWhereInput>;
  yProductGroups_every?: InputMaybe<ProductGroupWhereInput>;
  yProductGroups_none?: InputMaybe<ProductGroupWhereInput>;
  yProductGroups_some?: InputMaybe<ProductGroupWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AttributeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AttributeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AttributeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AttributeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AttributeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Attribute record uniquely */
export type AttributeWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type BatchPayload = {
  __typename?: "BatchPayload";
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars["Long"];
};

export type Category = Node & {
  __typename?: "Category";
  categories: Array<Category>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Description for sidebar */
  description?: Maybe<Scalars["String"]>;
  /** Get the document in other stages */
  documentInStages: Array<Category>;
  hidden: Scalars["Boolean"];
  /** List of Category versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** Image for tiles */
  image?: Maybe<Asset>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Category>;
  matchingRestrictions: Array<Scalars["Json"]>;
  name: Scalars["String"];
  parentCategory?: Maybe<Category>;
  productGroups: Array<ProductGroup>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  slug?: Maybe<Scalars["String"]>;
  /** System stage field */
  stage: Stage;
  tileDescription?: Maybe<Scalars["String"]>;
  tileTitle?: Maybe<Scalars["String"]>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type CategoryCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type CategoryCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CategoryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type CategoryHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type CategoryImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type CategoryParentCategoryArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type CategoryPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CategoryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CategoryUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type CategoryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CategoryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CategoryWhereUniqueInput;
};

/** A connection to a list of items. */
export type CategoryConnection = {
  __typename?: "CategoryConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CategoryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CategoryCreateInput = {
  categories?: InputMaybe<CategoryCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  hidden: Scalars["Boolean"];
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<CategoryCreateLocalizationsInput>;
  matchingRestrictions?: InputMaybe<Array<Scalars["Json"]>>;
  /** name input for default locale (de) */
  name: Scalars["String"];
  parentCategory?: InputMaybe<CategoryCreateOneInlineInput>;
  productGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
  /** tileDescription input for default locale (de) */
  tileDescription?: InputMaybe<Scalars["String"]>;
  /** tileTitle input for default locale (de) */
  tileTitle?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CategoryCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  slug?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
  tileTitle?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CategoryCreateLocalizationInput = {
  /** Localization input */
  data: CategoryCreateLocalizationDataInput;
  locale: Locale;
};

export type CategoryCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<CategoryCreateLocalizationInput>>;
};

export type CategoryCreateManyInlineInput = {
  /** Connect multiple existing Category documents */
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Create and connect multiple existing Category documents */
  create?: InputMaybe<Array<CategoryCreateInput>>;
};

export type CategoryCreateOneInlineInput = {
  /** Connect one existing Category document */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create and connect one Category document */
  create?: InputMaybe<CategoryCreateInput>;
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: "CategoryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Category;
};

/** Identifies documents */
export type CategoryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  categories_every?: InputMaybe<CategoryWhereInput>;
  categories_none?: InputMaybe<CategoryWhereInput>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_none?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_some?: InputMaybe<CategoryWhereStageInput>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  hidden_not?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  /** All values containing the given json path. */
  matchingRestrictions_json_path_exists?: InputMaybe<Scalars["String"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  matchingRestrictions_value_recursive?: InputMaybe<Scalars["Json"]>;
  parentCategory?: InputMaybe<CategoryWhereInput>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CategoryOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  HiddenAsc = "hidden_ASC",
  HiddenDesc = "hidden_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  TileDescriptionAsc = "tileDescription_ASC",
  TileDescriptionDesc = "tileDescription_DESC",
  TileTitleAsc = "tileTitle_ASC",
  TileTitleDesc = "tileTitle_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type CategoryUpdateInput = {
  categories?: InputMaybe<CategoryUpdateManyInlineInput>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<CategoryUpdateLocalizationsInput>;
  matchingRestrictions?: InputMaybe<Array<Scalars["Json"]>>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  parentCategory?: InputMaybe<CategoryUpdateOneInlineInput>;
  productGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
  /** tileDescription input for default locale (de) */
  tileDescription?: InputMaybe<Scalars["String"]>;
  /** tileTitle input for default locale (de) */
  tileTitle?: InputMaybe<Scalars["String"]>;
};

export type CategoryUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
  tileTitle?: InputMaybe<Scalars["String"]>;
};

export type CategoryUpdateLocalizationInput = {
  data: CategoryUpdateLocalizationDataInput;
  locale: Locale;
};

export type CategoryUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<CategoryCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<CategoryUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<CategoryUpsertLocalizationInput>>;
};

export type CategoryUpdateManyInlineInput = {
  /** Connect multiple existing Category documents */
  connect?: InputMaybe<Array<CategoryConnectInput>>;
  /** Create and connect multiple Category documents */
  create?: InputMaybe<Array<CategoryCreateInput>>;
  /** Delete multiple Category documents */
  delete?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Disconnect multiple Category documents */
  disconnect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Category documents */
  set?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Update multiple Category documents */
  update?: InputMaybe<Array<CategoryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Category documents */
  upsert?: InputMaybe<Array<CategoryUpsertWithNestedWhereUniqueInput>>;
};

export type CategoryUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<CategoryUpdateManyLocalizationsInput>;
  matchingRestrictions?: InputMaybe<Array<Scalars["Json"]>>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** tileDescription input for default locale (de) */
  tileDescription?: InputMaybe<Scalars["String"]>;
  /** tileTitle input for default locale (de) */
  tileTitle?: InputMaybe<Scalars["String"]>;
};

export type CategoryUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
  tileTitle?: InputMaybe<Scalars["String"]>;
};

export type CategoryUpdateManyLocalizationInput = {
  data: CategoryUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type CategoryUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<CategoryUpdateManyLocalizationInput>>;
};

export type CategoryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CategoryUpdateManyInput;
  /** Document search */
  where: CategoryWhereInput;
};

export type CategoryUpdateOneInlineInput = {
  /** Connect existing Category document */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create and connect one Category document */
  create?: InputMaybe<CategoryCreateInput>;
  /** Delete currently connected Category document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Category document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Category document */
  update?: InputMaybe<CategoryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Category document */
  upsert?: InputMaybe<CategoryUpsertWithNestedWhereUniqueInput>;
};

export type CategoryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CategoryUpdateInput;
  /** Unique document search */
  where: CategoryWhereUniqueInput;
};

export type CategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: CategoryCreateInput;
  /** Update document if it exists */
  update: CategoryUpdateInput;
};

export type CategoryUpsertLocalizationInput = {
  create: CategoryCreateLocalizationDataInput;
  locale: Locale;
  update: CategoryUpdateLocalizationDataInput;
};

export type CategoryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CategoryUpsertInput;
  /** Unique document search */
  where: CategoryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CategoryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type CategoryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  categories_every?: InputMaybe<CategoryWhereInput>;
  categories_none?: InputMaybe<CategoryWhereInput>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  documentInStages_every?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_none?: InputMaybe<CategoryWhereStageInput>;
  documentInStages_some?: InputMaybe<CategoryWhereStageInput>;
  hidden?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  hidden_not?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  /** All values containing the given json path. */
  matchingRestrictions_json_path_exists?: InputMaybe<Scalars["String"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  matchingRestrictions_value_recursive?: InputMaybe<Scalars["Json"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  parentCategory?: InputMaybe<CategoryWhereInput>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  slug_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  tileDescription_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  tileDescription_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  tileDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  tileDescription_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  tileDescription_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  tileDescription_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  tileDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  tileDescription_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  tileDescription_starts_with?: InputMaybe<Scalars["String"]>;
  tileTitle?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  tileTitle_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  tileTitle_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  tileTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  tileTitle_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  tileTitle_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  tileTitle_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  tileTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  tileTitle_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  tileTitle_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CategoryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CategoryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CategoryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CategoryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CategoryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Category record uniquely */
export type CategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: "Color";
  css: Scalars["String"];
  hex: Scalars["Hex"];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars["Hex"]>;
  rgba?: InputMaybe<RgbaInput>;
};

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars["ID"]>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars["ID"]>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars["Boolean"]>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars["Boolean"]>;
};

/** https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
export enum CountryCode {
  Ch = "CH",
  De = "DE",
  It = "IT",
}

export enum DataType {
  Boolean = "boolean",
  Date = "date",
  Datetime = "datetime",
  Float = "float",
  Number = "number",
  String = "string",
}

export enum DocumentFileTypes {
  Doc = "doc",
  Docx = "docx",
  Html = "html",
  Jpg = "jpg",
  Odp = "odp",
  Ods = "ods",
  Odt = "odt",
  Pdf = "pdf",
  Png = "png",
  Ppt = "ppt",
  Pptx = "pptx",
  Svg = "svg",
  Txt = "txt",
  Webp = "webp",
  Xls = "xls",
  Xlsx = "xlsx",
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: "DocumentVersion";
  createdAt: Scalars["DateTime"];
  data?: Maybe<Scalars["Json"]>;
  id: Scalars["ID"];
  revision: Scalars["Int"];
  stage: Stage;
};

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = "clip",
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = "crop",
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = "max",
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = "scale",
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars["Int"]>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars["Int"]>;
};

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
};

export type Link = Node & {
  __typename?: "Link";
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Link>;
  /** List of Link versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Link>;
  name: Scalars["String"];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  type?: Maybe<Scalars["String"]>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  url: Scalars["String"];
};

export type LinkCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type LinkCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LinkDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type LinkHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type LinkLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type LinkProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type LinkProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

export type LinkPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type LinkPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LinkScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type LinkUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type LinkUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type LinkConnection = {
  __typename?: "LinkConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LinkCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<LinkCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name: Scalars["String"];
  productGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  type?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** url input for default locale (de) */
  url: Scalars["String"];
};

export type LinkCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  url: Scalars["String"];
};

export type LinkCreateLocalizationInput = {
  /** Localization input */
  data: LinkCreateLocalizationDataInput;
  locale: Locale;
};

export type LinkCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<LinkCreateLocalizationInput>>;
};

export type LinkCreateManyInlineInput = {
  /** Connect multiple existing Link documents */
  connect?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Create and connect multiple existing Link documents */
  create?: InputMaybe<Array<LinkCreateInput>>;
};

export type LinkCreateOneInlineInput = {
  /** Connect one existing Link document */
  connect?: InputMaybe<LinkWhereUniqueInput>;
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
};

/** An edge in a connection. */
export type LinkEdge = {
  __typename?: "LinkEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Link;
};

/** Identifies documents */
export type LinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<LinkWhereStageInput>;
  documentInStages_none?: InputMaybe<LinkWhereStageInput>;
  documentInStages_some?: InputMaybe<LinkWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  type_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  type_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  type_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  type_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  type_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  type_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  type_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum LinkOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  TypeAsc = "type_ASC",
  TypeDesc = "type_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
  UrlAsc = "url_ASC",
  UrlDesc = "url_DESC",
}

export type LinkUpdateInput = {
  /** Manage document localizations */
  localizations?: InputMaybe<LinkUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  productGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  type?: InputMaybe<Scalars["String"]>;
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars["String"]>;
};

export type LinkUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type LinkUpdateLocalizationInput = {
  data: LinkUpdateLocalizationDataInput;
  locale: Locale;
};

export type LinkUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<LinkCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<LinkUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<LinkUpsertLocalizationInput>>;
};

export type LinkUpdateManyInlineInput = {
  /** Connect multiple existing Link documents */
  connect?: InputMaybe<Array<LinkConnectInput>>;
  /** Create and connect multiple Link documents */
  create?: InputMaybe<Array<LinkCreateInput>>;
  /** Delete multiple Link documents */
  delete?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Disconnect multiple Link documents */
  disconnect?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Link documents */
  set?: InputMaybe<Array<LinkWhereUniqueInput>>;
  /** Update multiple Link documents */
  update?: InputMaybe<Array<LinkUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Link documents */
  upsert?: InputMaybe<Array<LinkUpsertWithNestedWhereUniqueInput>>;
};

export type LinkUpdateManyInput = {
  /** Optional updates to localizations */
  localizations?: InputMaybe<LinkUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
  /** url input for default locale (de) */
  url?: InputMaybe<Scalars["String"]>;
};

export type LinkUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars["String"]>;
  url?: InputMaybe<Scalars["String"]>;
};

export type LinkUpdateManyLocalizationInput = {
  data: LinkUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type LinkUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<LinkUpdateManyLocalizationInput>>;
};

export type LinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LinkUpdateManyInput;
  /** Document search */
  where: LinkWhereInput;
};

export type LinkUpdateOneInlineInput = {
  /** Connect existing Link document */
  connect?: InputMaybe<LinkWhereUniqueInput>;
  /** Create and connect one Link document */
  create?: InputMaybe<LinkCreateInput>;
  /** Delete currently connected Link document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Link document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Link document */
  update?: InputMaybe<LinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Link document */
  upsert?: InputMaybe<LinkUpsertWithNestedWhereUniqueInput>;
};

export type LinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LinkUpdateInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

export type LinkUpsertInput = {
  /** Create document if it didn't exist */
  create: LinkCreateInput;
  /** Update document if it exists */
  update: LinkUpdateInput;
};

export type LinkUpsertLocalizationInput = {
  create: LinkCreateLocalizationDataInput;
  locale: Locale;
  update: LinkUpdateLocalizationDataInput;
};

export type LinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LinkUpsertInput;
  /** Unique document search */
  where: LinkWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type LinkWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type LinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<LinkWhereStageInput>;
  documentInStages_none?: InputMaybe<LinkWhereStageInput>;
  documentInStages_some?: InputMaybe<LinkWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  type?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  type_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  type_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  type_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  type_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  type_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  type_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  type_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  type_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  url?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  url_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  url_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  url_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  url_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  url_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  url_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  url_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  url_starts_with?: InputMaybe<Scalars["String"]>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type LinkWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LinkWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LinkWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LinkWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<LinkWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Link record uniquely */
export type LinkWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** Locale system enumeration */
export enum Locale {
  De = "de",
  /** System locale */
  En = "en",
  Es = "es",
  Fr = "fr",
  It = "it",
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: "Location";
  distance: Scalars["Float"];
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars["Float"];
  longitude: Scalars["Float"];
};

export type Mutation = {
  __typename?: "Mutation";
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>;
  /** Create one attribute */
  createAttribute?: Maybe<Attribute>;
  /** Create one attributeValue */
  createAttributeValue?: Maybe<AttributeValue>;
  /** Create one category */
  createCategory?: Maybe<Category>;
  /** Create one link */
  createLink?: Maybe<Link>;
  /** Create one paragraph */
  createParagraph?: Maybe<Paragraph>;
  /** Create one pf */
  createPf?: Maybe<Pf>;
  /** Create one product */
  createProduct?: Maybe<Product>;
  /** Create one productGroup */
  createProductGroup?: Maybe<ProductGroup>;
  /** Create one productGroupViewType */
  createProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>;
  /** Create one userNotification */
  createUserNotification?: Maybe<UserNotification>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Delete one attribute from _all_ existing stages. Returns deleted document. */
  deleteAttribute?: Maybe<Attribute>;
  /** Delete one attributeValue from _all_ existing stages. Returns deleted document. */
  deleteAttributeValue?: Maybe<AttributeValue>;
  /** Delete one category from _all_ existing stages. Returns deleted document. */
  deleteCategory?: Maybe<Category>;
  /** Delete one link from _all_ existing stages. Returns deleted document. */
  deleteLink?: Maybe<Link>;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many AttributeValue documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttributeValuesConnection)
   */
  deleteManyAttributeValues: BatchPayload;
  /** Delete many AttributeValue documents, return deleted documents */
  deleteManyAttributeValuesConnection: AttributeValueConnection;
  /**
   * Delete many Attribute documents
   * @deprecated Please use the new paginated many mutation (deleteManyAttributesConnection)
   */
  deleteManyAttributes: BatchPayload;
  /** Delete many Attribute documents, return deleted documents */
  deleteManyAttributesConnection: AttributeConnection;
  /**
   * Delete many Category documents
   * @deprecated Please use the new paginated many mutation (deleteManyCategoriesConnection)
   */
  deleteManyCategories: BatchPayload;
  /** Delete many Category documents, return deleted documents */
  deleteManyCategoriesConnection: CategoryConnection;
  /**
   * Delete many Link documents
   * @deprecated Please use the new paginated many mutation (deleteManyLinksConnection)
   */
  deleteManyLinks: BatchPayload;
  /** Delete many Link documents, return deleted documents */
  deleteManyLinksConnection: LinkConnection;
  /**
   * Delete many Paragraph documents
   * @deprecated Please use the new paginated many mutation (deleteManyParagraphsConnection)
   */
  deleteManyParagraphs: BatchPayload;
  /** Delete many Paragraph documents, return deleted documents */
  deleteManyParagraphsConnection: ParagraphConnection;
  /**
   * Delete many Pf documents
   * @deprecated Please use the new paginated many mutation (deleteManyPfsConnection)
   */
  deleteManyPfs: BatchPayload;
  /** Delete many Pf documents, return deleted documents */
  deleteManyPfsConnection: PfConnection;
  /**
   * Delete many ProductGroupViewType documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductGroupViewTypesConnection)
   */
  deleteManyProductGroupViewTypes: BatchPayload;
  /** Delete many ProductGroupViewType documents, return deleted documents */
  deleteManyProductGroupViewTypesConnection: ProductGroupViewTypeConnection;
  /**
   * Delete many ProductGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductGroupsConnection)
   */
  deleteManyProductGroups: BatchPayload;
  /** Delete many ProductGroup documents, return deleted documents */
  deleteManyProductGroupsConnection: ProductGroupConnection;
  /**
   * Delete many Product documents
   * @deprecated Please use the new paginated many mutation (deleteManyProductsConnection)
   */
  deleteManyProducts: BatchPayload;
  /** Delete many Product documents, return deleted documents */
  deleteManyProductsConnection: ProductConnection;
  /**
   * Delete many UserNotification documents
   * @deprecated Please use the new paginated many mutation (deleteManyUserNotificationsConnection)
   */
  deleteManyUserNotifications: BatchPayload;
  /** Delete many UserNotification documents, return deleted documents */
  deleteManyUserNotificationsConnection: UserNotificationConnection;
  /** Delete one paragraph from _all_ existing stages. Returns deleted document. */
  deleteParagraph?: Maybe<Paragraph>;
  /** Delete one pf from _all_ existing stages. Returns deleted document. */
  deletePf?: Maybe<Pf>;
  /** Delete one product from _all_ existing stages. Returns deleted document. */
  deleteProduct?: Maybe<Product>;
  /** Delete one productGroup from _all_ existing stages. Returns deleted document. */
  deleteProductGroup?: Maybe<ProductGroup>;
  /** Delete one productGroupViewType from _all_ existing stages. Returns deleted document. */
  deleteProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>;
  /** Delete one userNotification from _all_ existing stages. Returns deleted document. */
  deleteUserNotification?: Maybe<UserNotification>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Publish one attribute */
  publishAttribute?: Maybe<Attribute>;
  /** Publish one attributeValue */
  publishAttributeValue?: Maybe<AttributeValue>;
  /** Publish one category */
  publishCategory?: Maybe<Category>;
  /** Publish one link */
  publishLink?: Maybe<Link>;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many AttributeValue documents
   * @deprecated Please use the new paginated many mutation (publishManyAttributeValuesConnection)
   */
  publishManyAttributeValues: BatchPayload;
  /** Publish many AttributeValue documents */
  publishManyAttributeValuesConnection: AttributeValueConnection;
  /**
   * Publish many Attribute documents
   * @deprecated Please use the new paginated many mutation (publishManyAttributesConnection)
   */
  publishManyAttributes: BatchPayload;
  /** Publish many Attribute documents */
  publishManyAttributesConnection: AttributeConnection;
  /**
   * Publish many Category documents
   * @deprecated Please use the new paginated many mutation (publishManyCategoriesConnection)
   */
  publishManyCategories: BatchPayload;
  /** Publish many Category documents */
  publishManyCategoriesConnection: CategoryConnection;
  /**
   * Publish many Link documents
   * @deprecated Please use the new paginated many mutation (publishManyLinksConnection)
   */
  publishManyLinks: BatchPayload;
  /** Publish many Link documents */
  publishManyLinksConnection: LinkConnection;
  /**
   * Publish many Paragraph documents
   * @deprecated Please use the new paginated many mutation (publishManyParagraphsConnection)
   */
  publishManyParagraphs: BatchPayload;
  /** Publish many Paragraph documents */
  publishManyParagraphsConnection: ParagraphConnection;
  /**
   * Publish many Pf documents
   * @deprecated Please use the new paginated many mutation (publishManyPfsConnection)
   */
  publishManyPfs: BatchPayload;
  /** Publish many Pf documents */
  publishManyPfsConnection: PfConnection;
  /**
   * Publish many ProductGroupViewType documents
   * @deprecated Please use the new paginated many mutation (publishManyProductGroupViewTypesConnection)
   */
  publishManyProductGroupViewTypes: BatchPayload;
  /** Publish many ProductGroupViewType documents */
  publishManyProductGroupViewTypesConnection: ProductGroupViewTypeConnection;
  /**
   * Publish many ProductGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyProductGroupsConnection)
   */
  publishManyProductGroups: BatchPayload;
  /** Publish many ProductGroup documents */
  publishManyProductGroupsConnection: ProductGroupConnection;
  /**
   * Publish many Product documents
   * @deprecated Please use the new paginated many mutation (publishManyProductsConnection)
   */
  publishManyProducts: BatchPayload;
  /** Publish many Product documents */
  publishManyProductsConnection: ProductConnection;
  /**
   * Publish many UserNotification documents
   * @deprecated Please use the new paginated many mutation (publishManyUserNotificationsConnection)
   */
  publishManyUserNotifications: BatchPayload;
  /** Publish many UserNotification documents */
  publishManyUserNotificationsConnection: UserNotificationConnection;
  /** Publish one paragraph */
  publishParagraph?: Maybe<Paragraph>;
  /** Publish one pf */
  publishPf?: Maybe<Pf>;
  /** Publish one product */
  publishProduct?: Maybe<Product>;
  /** Publish one productGroup */
  publishProductGroup?: Maybe<ProductGroup>;
  /** Publish one productGroupViewType */
  publishProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Publish one userNotification */
  publishUserNotification?: Maybe<UserNotification>;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>;
  /** Schedule to publish one attribute */
  schedulePublishAttribute?: Maybe<Attribute>;
  /** Schedule to publish one attributeValue */
  schedulePublishAttributeValue?: Maybe<AttributeValue>;
  /** Schedule to publish one category */
  schedulePublishCategory?: Maybe<Category>;
  /** Schedule to publish one link */
  schedulePublishLink?: Maybe<Link>;
  /** Schedule to publish one paragraph */
  schedulePublishParagraph?: Maybe<Paragraph>;
  /** Schedule to publish one pf */
  schedulePublishPf?: Maybe<Pf>;
  /** Schedule to publish one product */
  schedulePublishProduct?: Maybe<Product>;
  /** Schedule to publish one productGroup */
  schedulePublishProductGroup?: Maybe<ProductGroup>;
  /** Schedule to publish one productGroupViewType */
  schedulePublishProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Schedule to publish one userNotification */
  schedulePublishUserNotification?: Maybe<UserNotification>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>;
  /** Unpublish one attribute from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttribute?: Maybe<Attribute>;
  /** Unpublish one attributeValue from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAttributeValue?: Maybe<AttributeValue>;
  /** Unpublish one category from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCategory?: Maybe<Category>;
  /** Unpublish one link from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLink?: Maybe<Link>;
  /** Unpublish one paragraph from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishParagraph?: Maybe<Paragraph>;
  /** Unpublish one pf from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPf?: Maybe<Pf>;
  /** Unpublish one product from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProduct?: Maybe<Product>;
  /** Unpublish one productGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProductGroup?: Maybe<ProductGroup>;
  /** Unpublish one productGroupViewType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Unpublish one userNotification from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishUserNotification?: Maybe<UserNotification>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Unpublish one attribute from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttribute?: Maybe<Attribute>;
  /** Unpublish one attributeValue from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAttributeValue?: Maybe<AttributeValue>;
  /** Unpublish one category from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCategory?: Maybe<Category>;
  /** Unpublish one link from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLink?: Maybe<Link>;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many AttributeValue documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttributeValuesConnection)
   */
  unpublishManyAttributeValues: BatchPayload;
  /** Find many AttributeValue documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttributeValuesConnection: AttributeValueConnection;
  /**
   * Unpublish many Attribute documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAttributesConnection)
   */
  unpublishManyAttributes: BatchPayload;
  /** Find many Attribute documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAttributesConnection: AttributeConnection;
  /**
   * Unpublish many Category documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCategoriesConnection)
   */
  unpublishManyCategories: BatchPayload;
  /** Find many Category documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCategoriesConnection: CategoryConnection;
  /**
   * Unpublish many Link documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLinksConnection)
   */
  unpublishManyLinks: BatchPayload;
  /** Find many Link documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLinksConnection: LinkConnection;
  /**
   * Unpublish many Paragraph documents
   * @deprecated Please use the new paginated many mutation (unpublishManyParagraphsConnection)
   */
  unpublishManyParagraphs: BatchPayload;
  /** Find many Paragraph documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyParagraphsConnection: ParagraphConnection;
  /**
   * Unpublish many Pf documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPfsConnection)
   */
  unpublishManyPfs: BatchPayload;
  /** Find many Pf documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPfsConnection: PfConnection;
  /**
   * Unpublish many ProductGroupViewType documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductGroupViewTypesConnection)
   */
  unpublishManyProductGroupViewTypes: BatchPayload;
  /** Find many ProductGroupViewType documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductGroupViewTypesConnection: ProductGroupViewTypeConnection;
  /**
   * Unpublish many ProductGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductGroupsConnection)
   */
  unpublishManyProductGroups: BatchPayload;
  /** Find many ProductGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductGroupsConnection: ProductGroupConnection;
  /**
   * Unpublish many Product documents
   * @deprecated Please use the new paginated many mutation (unpublishManyProductsConnection)
   */
  unpublishManyProducts: BatchPayload;
  /** Find many Product documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyProductsConnection: ProductConnection;
  /**
   * Unpublish many UserNotification documents
   * @deprecated Please use the new paginated many mutation (unpublishManyUserNotificationsConnection)
   */
  unpublishManyUserNotifications: BatchPayload;
  /** Find many UserNotification documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyUserNotificationsConnection: UserNotificationConnection;
  /** Unpublish one paragraph from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishParagraph?: Maybe<Paragraph>;
  /** Unpublish one pf from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPf?: Maybe<Pf>;
  /** Unpublish one product from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProduct?: Maybe<Product>;
  /** Unpublish one productGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProductGroup?: Maybe<ProductGroup>;
  /** Unpublish one productGroupViewType from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Unpublish one userNotification from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishUserNotification?: Maybe<UserNotification>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Update one attribute */
  updateAttribute?: Maybe<Attribute>;
  /** Update one attributeValue */
  updateAttributeValue?: Maybe<AttributeValue>;
  /** Update one category */
  updateCategory?: Maybe<Category>;
  /** Update one link */
  updateLink?: Maybe<Link>;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many attributeValues
   * @deprecated Please use the new paginated many mutation (updateManyAttributeValuesConnection)
   */
  updateManyAttributeValues: BatchPayload;
  /** Update many AttributeValue documents */
  updateManyAttributeValuesConnection: AttributeValueConnection;
  /**
   * Update many attributes
   * @deprecated Please use the new paginated many mutation (updateManyAttributesConnection)
   */
  updateManyAttributes: BatchPayload;
  /** Update many Attribute documents */
  updateManyAttributesConnection: AttributeConnection;
  /**
   * Update many categories
   * @deprecated Please use the new paginated many mutation (updateManyCategoriesConnection)
   */
  updateManyCategories: BatchPayload;
  /** Update many Category documents */
  updateManyCategoriesConnection: CategoryConnection;
  /**
   * Update many links
   * @deprecated Please use the new paginated many mutation (updateManyLinksConnection)
   */
  updateManyLinks: BatchPayload;
  /** Update many Link documents */
  updateManyLinksConnection: LinkConnection;
  /**
   * Update many paragraphs
   * @deprecated Please use the new paginated many mutation (updateManyParagraphsConnection)
   */
  updateManyParagraphs: BatchPayload;
  /** Update many Paragraph documents */
  updateManyParagraphsConnection: ParagraphConnection;
  /**
   * Update many pfs
   * @deprecated Please use the new paginated many mutation (updateManyPfsConnection)
   */
  updateManyPfs: BatchPayload;
  /** Update many Pf documents */
  updateManyPfsConnection: PfConnection;
  /**
   * Update many productGroupViewTypes
   * @deprecated Please use the new paginated many mutation (updateManyProductGroupViewTypesConnection)
   */
  updateManyProductGroupViewTypes: BatchPayload;
  /** Update many ProductGroupViewType documents */
  updateManyProductGroupViewTypesConnection: ProductGroupViewTypeConnection;
  /**
   * Update many productGroups
   * @deprecated Please use the new paginated many mutation (updateManyProductGroupsConnection)
   */
  updateManyProductGroups: BatchPayload;
  /** Update many ProductGroup documents */
  updateManyProductGroupsConnection: ProductGroupConnection;
  /**
   * Update many products
   * @deprecated Please use the new paginated many mutation (updateManyProductsConnection)
   */
  updateManyProducts: BatchPayload;
  /** Update many Product documents */
  updateManyProductsConnection: ProductConnection;
  /**
   * Update many userNotifications
   * @deprecated Please use the new paginated many mutation (updateManyUserNotificationsConnection)
   */
  updateManyUserNotifications: BatchPayload;
  /** Update many UserNotification documents */
  updateManyUserNotificationsConnection: UserNotificationConnection;
  /** Update one paragraph */
  updateParagraph?: Maybe<Paragraph>;
  /** Update one pf */
  updatePf?: Maybe<Pf>;
  /** Update one product */
  updateProduct?: Maybe<Product>;
  /** Update one productGroup */
  updateProductGroup?: Maybe<ProductGroup>;
  /** Update one productGroupViewType */
  updateProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>;
  /** Update one userNotification */
  updateUserNotification?: Maybe<UserNotification>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Upsert one attribute */
  upsertAttribute?: Maybe<Attribute>;
  /** Upsert one attributeValue */
  upsertAttributeValue?: Maybe<AttributeValue>;
  /** Upsert one category */
  upsertCategory?: Maybe<Category>;
  /** Upsert one link */
  upsertLink?: Maybe<Link>;
  /** Upsert one paragraph */
  upsertParagraph?: Maybe<Paragraph>;
  /** Upsert one pf */
  upsertPf?: Maybe<Pf>;
  /** Upsert one product */
  upsertProduct?: Maybe<Product>;
  /** Upsert one productGroup */
  upsertProductGroup?: Maybe<ProductGroup>;
  /** Upsert one productGroupViewType */
  upsertProductGroupViewType?: Maybe<ProductGroupViewType>;
  /** Upsert one userNotification */
  upsertUserNotification?: Maybe<UserNotification>;
};

export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};

export type MutationCreateAttributeArgs = {
  data: AttributeCreateInput;
};

export type MutationCreateAttributeValueArgs = {
  data: AttributeValueCreateInput;
};

export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput;
};

export type MutationCreateLinkArgs = {
  data: LinkCreateInput;
};

export type MutationCreateParagraphArgs = {
  data: ParagraphCreateInput;
};

export type MutationCreatePfArgs = {
  data: PfCreateInput;
};

export type MutationCreateProductArgs = {
  data: ProductCreateInput;
};

export type MutationCreateProductGroupArgs = {
  data: ProductGroupCreateInput;
};

export type MutationCreateProductGroupViewTypeArgs = {
  data: ProductGroupViewTypeCreateInput;
};

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};

export type MutationCreateUserNotificationArgs = {
  data: UserNotificationCreateInput;
};

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type MutationDeleteAttributeArgs = {
  where: AttributeWhereUniqueInput;
};

export type MutationDeleteAttributeValueArgs = {
  where: AttributeValueWhereUniqueInput;
};

export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput;
};

export type MutationDeleteLinkArgs = {
  where: LinkWhereUniqueInput;
};

export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAttributeValuesArgs = {
  where?: InputMaybe<AttributeValueManyWhereInput>;
};

export type MutationDeleteManyAttributeValuesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeValueManyWhereInput>;
};

export type MutationDeleteManyAttributesArgs = {
  where?: InputMaybe<AttributeManyWhereInput>;
};

export type MutationDeleteManyAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeManyWhereInput>;
};

export type MutationDeleteManyCategoriesArgs = {
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationDeleteManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationDeleteManyLinksArgs = {
  where?: InputMaybe<LinkManyWhereInput>;
};

export type MutationDeleteManyLinksConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LinkManyWhereInput>;
};

export type MutationDeleteManyParagraphsArgs = {
  where?: InputMaybe<ParagraphManyWhereInput>;
};

export type MutationDeleteManyParagraphsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ParagraphManyWhereInput>;
};

export type MutationDeleteManyPfsArgs = {
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationDeleteManyPfsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationDeleteManyProductGroupViewTypesArgs = {
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
};

export type MutationDeleteManyProductGroupViewTypesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
};

export type MutationDeleteManyProductGroupsArgs = {
  where?: InputMaybe<ProductGroupManyWhereInput>;
};

export type MutationDeleteManyProductGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupManyWhereInput>;
};

export type MutationDeleteManyProductsArgs = {
  where?: InputMaybe<ProductManyWhereInput>;
};

export type MutationDeleteManyProductsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductManyWhereInput>;
};

export type MutationDeleteManyUserNotificationsArgs = {
  where?: InputMaybe<UserNotificationManyWhereInput>;
};

export type MutationDeleteManyUserNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserNotificationManyWhereInput>;
};

export type MutationDeleteParagraphArgs = {
  where: ParagraphWhereUniqueInput;
};

export type MutationDeletePfArgs = {
  where: PfWhereUniqueInput;
};

export type MutationDeleteProductArgs = {
  where: ProductWhereUniqueInput;
};

export type MutationDeleteProductGroupArgs = {
  where: ProductGroupWhereUniqueInput;
};

export type MutationDeleteProductGroupViewTypeArgs = {
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationDeleteUserNotificationArgs = {
  where: UserNotificationWhereUniqueInput;
};

export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishAttributeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: AttributeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishAttributeValueArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: AttributeValueWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: CategoryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: LinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyAttributeValuesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AttributeValueManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyAttributeValuesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AttributeValueManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyAttributesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AttributeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AttributeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyCategoriesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<CategoryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<CategoryManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyLinksArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<LinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyLinksConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<LinkManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyParagraphsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ParagraphManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyParagraphsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ParagraphManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyPfsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationPublishManyPfsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationPublishManyProductGroupViewTypesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyProductGroupViewTypesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyProductGroupsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ProductGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyProductGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ProductGroupManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyProductsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ProductManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyProductsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ProductManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyUserNotificationsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where?: InputMaybe<UserNotificationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishManyUserNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  to?: Array<Stage>;
  where?: InputMaybe<UserNotificationManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishParagraphArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: ParagraphWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishPfArgs = {
  to?: Array<Stage>;
  where: PfWhereUniqueInput;
};

export type MutationPublishProductArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: ProductWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishProductGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: ProductGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishProductGroupViewTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: ProductGroupViewTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationPublishUserNotificationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  to?: Array<Stage>;
  where: UserNotificationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishAttributeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: AttributeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishAttributeValueArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: AttributeValueWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishCategoryArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: CategoryWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishLinkArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: LinkWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishParagraphArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: ParagraphWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishPfArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: PfWhereUniqueInput;
};

export type MutationSchedulePublishProductArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: ProductWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishProductGroupArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: ProductGroupWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishProductGroupViewTypeArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: ProductGroupViewTypeWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSchedulePublishUserNotificationArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  to?: Array<Stage>;
  where: UserNotificationWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: AssetWhereUniqueInput;
};

export type MutationScheduleUnpublishAttributeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: AttributeWhereUniqueInput;
};

export type MutationScheduleUnpublishAttributeValueArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: AttributeValueWhereUniqueInput;
};

export type MutationScheduleUnpublishCategoryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: CategoryWhereUniqueInput;
};

export type MutationScheduleUnpublishLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: LinkWhereUniqueInput;
};

export type MutationScheduleUnpublishParagraphArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ParagraphWhereUniqueInput;
};

export type MutationScheduleUnpublishPfArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  where: PfWhereUniqueInput;
};

export type MutationScheduleUnpublishProductArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ProductWhereUniqueInput;
};

export type MutationScheduleUnpublishProductGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ProductGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishProductGroupViewTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type MutationScheduleUnpublishUserNotificationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  releaseId?: InputMaybe<Scalars["String"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: UserNotificationWhereUniqueInput;
};

export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: AssetWhereUniqueInput;
};

export type MutationUnpublishAttributeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: AttributeWhereUniqueInput;
};

export type MutationUnpublishAttributeValueArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: AttributeValueWhereUniqueInput;
};

export type MutationUnpublishCategoryArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: CategoryWhereUniqueInput;
};

export type MutationUnpublishLinkArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: LinkWhereUniqueInput;
};

export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAttributeValuesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<AttributeValueManyWhereInput>;
};

export type MutationUnpublishManyAttributeValuesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<AttributeValueManyWhereInput>;
};

export type MutationUnpublishManyAttributesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<AttributeManyWhereInput>;
};

export type MutationUnpublishManyAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<AttributeManyWhereInput>;
};

export type MutationUnpublishManyCategoriesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUnpublishManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUnpublishManyLinksArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<LinkManyWhereInput>;
};

export type MutationUnpublishManyLinksConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<LinkManyWhereInput>;
};

export type MutationUnpublishManyParagraphsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ParagraphManyWhereInput>;
};

export type MutationUnpublishManyParagraphsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ParagraphManyWhereInput>;
};

export type MutationUnpublishManyPfsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationUnpublishManyPfsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationUnpublishManyProductGroupViewTypesArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
};

export type MutationUnpublishManyProductGroupViewTypesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
};

export type MutationUnpublishManyProductGroupsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ProductGroupManyWhereInput>;
};

export type MutationUnpublishManyProductGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ProductGroupManyWhereInput>;
};

export type MutationUnpublishManyProductsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ProductManyWhereInput>;
};

export type MutationUnpublishManyProductsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<ProductManyWhereInput>;
};

export type MutationUnpublishManyUserNotificationsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<UserNotificationManyWhereInput>;
};

export type MutationUnpublishManyUserNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  first?: InputMaybe<Scalars["Int"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where?: InputMaybe<UserNotificationManyWhereInput>;
};

export type MutationUnpublishParagraphArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ParagraphWhereUniqueInput;
};

export type MutationUnpublishPfArgs = {
  from?: Array<Stage>;
  where: PfWhereUniqueInput;
};

export type MutationUnpublishProductArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ProductWhereUniqueInput;
};

export type MutationUnpublishProductGroupArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ProductGroupWhereUniqueInput;
};

export type MutationUnpublishProductGroupViewTypeArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type MutationUnpublishUserNotificationArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]>;
  where: UserNotificationWhereUniqueInput;
};

export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpdateAttributeArgs = {
  data: AttributeUpdateInput;
  where: AttributeWhereUniqueInput;
};

export type MutationUpdateAttributeValueArgs = {
  data: AttributeValueUpdateInput;
  where: AttributeValueWhereUniqueInput;
};

export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpdateLinkArgs = {
  data: LinkUpdateInput;
  where: LinkWhereUniqueInput;
};

export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: AssetUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAttributeValuesArgs = {
  data: AttributeValueUpdateManyInput;
  where?: InputMaybe<AttributeValueManyWhereInput>;
};

export type MutationUpdateManyAttributeValuesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: AttributeValueUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeValueManyWhereInput>;
};

export type MutationUpdateManyAttributesArgs = {
  data: AttributeUpdateManyInput;
  where?: InputMaybe<AttributeManyWhereInput>;
};

export type MutationUpdateManyAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: AttributeUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeManyWhereInput>;
};

export type MutationUpdateManyCategoriesArgs = {
  data: CategoryUpdateManyInput;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUpdateManyCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: CategoryUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryManyWhereInput>;
};

export type MutationUpdateManyLinksArgs = {
  data: LinkUpdateManyInput;
  where?: InputMaybe<LinkManyWhereInput>;
};

export type MutationUpdateManyLinksConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: LinkUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LinkManyWhereInput>;
};

export type MutationUpdateManyParagraphsArgs = {
  data: ParagraphUpdateManyInput;
  where?: InputMaybe<ParagraphManyWhereInput>;
};

export type MutationUpdateManyParagraphsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: ParagraphUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ParagraphManyWhereInput>;
};

export type MutationUpdateManyPfsArgs = {
  data: PfUpdateManyInput;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationUpdateManyPfsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: PfUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PfManyWhereInput>;
};

export type MutationUpdateManyProductGroupViewTypesArgs = {
  data: ProductGroupViewTypeUpdateManyInput;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
};

export type MutationUpdateManyProductGroupViewTypesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: ProductGroupViewTypeUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupViewTypeManyWhereInput>;
};

export type MutationUpdateManyProductGroupsArgs = {
  data: ProductGroupUpdateManyInput;
  where?: InputMaybe<ProductGroupManyWhereInput>;
};

export type MutationUpdateManyProductGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: ProductGroupUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupManyWhereInput>;
};

export type MutationUpdateManyProductsArgs = {
  data: ProductUpdateManyInput;
  where?: InputMaybe<ProductManyWhereInput>;
};

export type MutationUpdateManyProductsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: ProductUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductManyWhereInput>;
};

export type MutationUpdateManyUserNotificationsArgs = {
  data: UserNotificationUpdateManyInput;
  where?: InputMaybe<UserNotificationManyWhereInput>;
};

export type MutationUpdateManyUserNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]>;
  before?: InputMaybe<Scalars["ID"]>;
  data: UserNotificationUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<UserNotificationManyWhereInput>;
};

export type MutationUpdateParagraphArgs = {
  data: ParagraphUpdateInput;
  where: ParagraphWhereUniqueInput;
};

export type MutationUpdatePfArgs = {
  data: PfUpdateInput;
  where: PfWhereUniqueInput;
};

export type MutationUpdateProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};

export type MutationUpdateProductGroupArgs = {
  data: ProductGroupUpdateInput;
  where: ProductGroupWhereUniqueInput;
};

export type MutationUpdateProductGroupViewTypeArgs = {
  data: ProductGroupViewTypeUpdateInput;
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationUpdateUserNotificationArgs = {
  data: UserNotificationUpdateInput;
  where: UserNotificationWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpsertAttributeArgs = {
  upsert: AttributeUpsertInput;
  where: AttributeWhereUniqueInput;
};

export type MutationUpsertAttributeValueArgs = {
  upsert: AttributeValueUpsertInput;
  where: AttributeValueWhereUniqueInput;
};

export type MutationUpsertCategoryArgs = {
  upsert: CategoryUpsertInput;
  where: CategoryWhereUniqueInput;
};

export type MutationUpsertLinkArgs = {
  upsert: LinkUpsertInput;
  where: LinkWhereUniqueInput;
};

export type MutationUpsertParagraphArgs = {
  upsert: ParagraphUpsertInput;
  where: ParagraphWhereUniqueInput;
};

export type MutationUpsertPfArgs = {
  upsert: PfUpsertInput;
  where: PfWhereUniqueInput;
};

export type MutationUpsertProductArgs = {
  upsert: ProductUpsertInput;
  where: ProductWhereUniqueInput;
};

export type MutationUpsertProductGroupArgs = {
  upsert: ProductGroupUpsertInput;
  where: ProductGroupWhereUniqueInput;
};

export type MutationUpsertProductGroupViewTypeArgs = {
  upsert: ProductGroupViewTypeUpsertInput;
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type MutationUpsertUserNotificationArgs = {
  upsert: UserNotificationUpsertInput;
  where: UserNotificationWhereUniqueInput;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"];
  /** The Stage of an object */
  stage: Stage;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars["Int"]>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

export type Paragraph = Node & {
  __typename?: "Paragraph";
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  /**
   * This field ist filled automatically on save.
   * Do not edit this value manually.
   */
  descriptionStringified?: Maybe<Scalars["String"]>;
  /** Get the document in other stages */
  documentInStages: Array<Paragraph>;
  /** List of Paragraph versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  image?: Maybe<Asset>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Paragraph>;
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type ParagraphCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ParagraphCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ParagraphDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type ParagraphHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type ParagraphImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ParagraphLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type ParagraphProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type ParagraphProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

export type ParagraphPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ParagraphPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ParagraphScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type ParagraphUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ParagraphUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ParagraphConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ParagraphWhereUniqueInput;
};

/** A connection to a list of items. */
export type ParagraphConnection = {
  __typename?: "ParagraphConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ParagraphEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ParagraphCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  /** descriptionStringified input for default locale (de) */
  descriptionStringified?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ParagraphCreateLocalizationsInput>;
  productGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParagraphCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  descriptionStringified?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ParagraphCreateLocalizationInput = {
  /** Localization input */
  data: ParagraphCreateLocalizationDataInput;
  locale: Locale;
};

export type ParagraphCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ParagraphCreateLocalizationInput>>;
};

export type ParagraphCreateManyInlineInput = {
  /** Connect multiple existing Paragraph documents */
  connect?: InputMaybe<Array<ParagraphWhereUniqueInput>>;
  /** Create and connect multiple existing Paragraph documents */
  create?: InputMaybe<Array<ParagraphCreateInput>>;
};

export type ParagraphCreateOneInlineInput = {
  /** Connect one existing Paragraph document */
  connect?: InputMaybe<ParagraphWhereUniqueInput>;
  /** Create and connect one Paragraph document */
  create?: InputMaybe<ParagraphCreateInput>;
};

/** An edge in a connection. */
export type ParagraphEdge = {
  __typename?: "ParagraphEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Paragraph;
};

/** Identifies documents */
export type ParagraphManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ParagraphWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ParagraphWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ParagraphWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ParagraphWhereStageInput>;
  documentInStages_none?: InputMaybe<ParagraphWhereStageInput>;
  documentInStages_some?: InputMaybe<ParagraphWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ParagraphOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionStringifiedAsc = "descriptionStringified_ASC",
  DescriptionStringifiedDesc = "descriptionStringified_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type ParagraphUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  /** descriptionStringified input for default locale (de) */
  descriptionStringified?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ParagraphUpdateLocalizationsInput>;
  productGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
};

export type ParagraphUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars["String"]>;
  descriptionStringified?: InputMaybe<Scalars["String"]>;
};

export type ParagraphUpdateLocalizationInput = {
  data: ParagraphUpdateLocalizationDataInput;
  locale: Locale;
};

export type ParagraphUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ParagraphCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ParagraphUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ParagraphUpsertLocalizationInput>>;
};

export type ParagraphUpdateManyInlineInput = {
  /** Connect multiple existing Paragraph documents */
  connect?: InputMaybe<Array<ParagraphConnectInput>>;
  /** Create and connect multiple Paragraph documents */
  create?: InputMaybe<Array<ParagraphCreateInput>>;
  /** Delete multiple Paragraph documents */
  delete?: InputMaybe<Array<ParagraphWhereUniqueInput>>;
  /** Disconnect multiple Paragraph documents */
  disconnect?: InputMaybe<Array<ParagraphWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Paragraph documents */
  set?: InputMaybe<Array<ParagraphWhereUniqueInput>>;
  /** Update multiple Paragraph documents */
  update?: InputMaybe<Array<ParagraphUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Paragraph documents */
  upsert?: InputMaybe<Array<ParagraphUpsertWithNestedWhereUniqueInput>>;
};

export type ParagraphUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  /** descriptionStringified input for default locale (de) */
  descriptionStringified?: InputMaybe<Scalars["String"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ParagraphUpdateManyLocalizationsInput>;
};

export type ParagraphUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars["String"]>;
  descriptionStringified?: InputMaybe<Scalars["String"]>;
};

export type ParagraphUpdateManyLocalizationInput = {
  data: ParagraphUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ParagraphUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ParagraphUpdateManyLocalizationInput>>;
};

export type ParagraphUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ParagraphUpdateManyInput;
  /** Document search */
  where: ParagraphWhereInput;
};

export type ParagraphUpdateOneInlineInput = {
  /** Connect existing Paragraph document */
  connect?: InputMaybe<ParagraphWhereUniqueInput>;
  /** Create and connect one Paragraph document */
  create?: InputMaybe<ParagraphCreateInput>;
  /** Delete currently connected Paragraph document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Paragraph document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Paragraph document */
  update?: InputMaybe<ParagraphUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Paragraph document */
  upsert?: InputMaybe<ParagraphUpsertWithNestedWhereUniqueInput>;
};

export type ParagraphUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ParagraphUpdateInput;
  /** Unique document search */
  where: ParagraphWhereUniqueInput;
};

export type ParagraphUpsertInput = {
  /** Create document if it didn't exist */
  create: ParagraphCreateInput;
  /** Update document if it exists */
  update: ParagraphUpdateInput;
};

export type ParagraphUpsertLocalizationInput = {
  create: ParagraphCreateLocalizationDataInput;
  locale: Locale;
  update: ParagraphUpdateLocalizationDataInput;
};

export type ParagraphUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ParagraphUpsertInput;
  /** Unique document search */
  where: ParagraphWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ParagraphWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type ParagraphWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ParagraphWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ParagraphWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ParagraphWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  descriptionStringified?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  descriptionStringified_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  descriptionStringified_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  descriptionStringified_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  descriptionStringified_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  descriptionStringified_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  descriptionStringified_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  descriptionStringified_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  descriptionStringified_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  descriptionStringified_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  documentInStages_every?: InputMaybe<ParagraphWhereStageInput>;
  documentInStages_none?: InputMaybe<ParagraphWhereStageInput>;
  documentInStages_some?: InputMaybe<ParagraphWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ParagraphWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ParagraphWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ParagraphWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ParagraphWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ParagraphWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Paragraph record uniquely */
export type ParagraphWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type Pf = Node & {
  __typename?: "Pf";
  colour: Color;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Pf>;
  /** List of Pf versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  name: Scalars["String"];
  productGroups: Array<ProductGroup>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type PfCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PfDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type PfHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type PfProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type PfProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

export type PfPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PfScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PfUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PfConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PfWhereUniqueInput;
};

/** A connection to a list of items. */
export type PfConnection = {
  __typename?: "PfConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PfEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PfCreateInput = {
  colour: ColorInput;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  name: Scalars["String"];
  productGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type PfCreateManyInlineInput = {
  /** Connect multiple existing Pf documents */
  connect?: InputMaybe<Array<PfWhereUniqueInput>>;
  /** Create and connect multiple existing Pf documents */
  create?: InputMaybe<Array<PfCreateInput>>;
};

export type PfCreateOneInlineInput = {
  /** Connect one existing Pf document */
  connect?: InputMaybe<PfWhereUniqueInput>;
  /** Create and connect one Pf document */
  create?: InputMaybe<PfCreateInput>;
};

/** An edge in a connection. */
export type PfEdge = {
  __typename?: "PfEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Pf;
};

/** Identifies documents */
export type PfManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PfWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PfWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PfWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PfWhereStageInput>;
  documentInStages_none?: InputMaybe<PfWhereStageInput>;
  documentInStages_some?: InputMaybe<PfWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PfOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type PfUpdateInput = {
  colour?: InputMaybe<ColorInput>;
  name?: InputMaybe<Scalars["String"]>;
  productGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
};

export type PfUpdateManyInlineInput = {
  /** Connect multiple existing Pf documents */
  connect?: InputMaybe<Array<PfConnectInput>>;
  /** Create and connect multiple Pf documents */
  create?: InputMaybe<Array<PfCreateInput>>;
  /** Delete multiple Pf documents */
  delete?: InputMaybe<Array<PfWhereUniqueInput>>;
  /** Disconnect multiple Pf documents */
  disconnect?: InputMaybe<Array<PfWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Pf documents */
  set?: InputMaybe<Array<PfWhereUniqueInput>>;
  /** Update multiple Pf documents */
  update?: InputMaybe<Array<PfUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Pf documents */
  upsert?: InputMaybe<Array<PfUpsertWithNestedWhereUniqueInput>>;
};

export type PfUpdateManyInput = {
  colour?: InputMaybe<ColorInput>;
};

export type PfUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PfUpdateManyInput;
  /** Document search */
  where: PfWhereInput;
};

export type PfUpdateOneInlineInput = {
  /** Connect existing Pf document */
  connect?: InputMaybe<PfWhereUniqueInput>;
  /** Create and connect one Pf document */
  create?: InputMaybe<PfCreateInput>;
  /** Delete currently connected Pf document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Pf document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Pf document */
  update?: InputMaybe<PfUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Pf document */
  upsert?: InputMaybe<PfUpsertWithNestedWhereUniqueInput>;
};

export type PfUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PfUpdateInput;
  /** Unique document search */
  where: PfWhereUniqueInput;
};

export type PfUpsertInput = {
  /** Create document if it didn't exist */
  create: PfCreateInput;
  /** Update document if it exists */
  update: PfUpdateInput;
};

export type PfUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PfUpsertInput;
  /** Unique document search */
  where: PfWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PfWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type PfWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PfWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PfWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PfWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PfWhereStageInput>;
  documentInStages_none?: InputMaybe<PfWhereStageInput>;
  documentInStages_some?: InputMaybe<PfWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PfWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PfWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PfWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PfWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PfWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Pf record uniquely */
export type PfWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
};

export type Product = Node & {
  __typename?: "Product";
  attributeValues: Array<AttributeValue>;
  countryCodes: Array<CountryCode>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<Product>;
  downloads: Array<Asset>;
  gtin13?: Maybe<Scalars["String"]>;
  /** List of Product versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  image?: Maybe<Asset>;
  links: Array<Link>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<Product>;
  /** Do not edit this value manually. */
  name?: Maybe<Scalars["String"]>;
  /** Do not edit this value manually. */
  nameSuffix?: Maybe<Scalars["String"]>;
  paragraphs: Array<Paragraph>;
  pfs: Array<Pf>;
  productGroup?: Maybe<ProductGroup>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  ranking?: Maybe<Scalars["Int"]>;
  sapEtiDurchmesser?: Maybe<Scalars["String"]>;
  sapEtiHoehe?: Maybe<Scalars["String"]>;
  sapEtiLaenge?: Maybe<Scalars["String"]>;
  sapEtiQty?: Maybe<Scalars["String"]>;
  sapMaktx?: Maybe<Scalars["String"]>;
  sapMatkl?: Maybe<Scalars["String"]>;
  sapMstae?: Maybe<Scalars["String"]>;
  sapPdbEg?: Maybe<Scalars["String"]>;
  sapPdbPf?: Maybe<Scalars["String"]>;
  sapProdh?: Maybe<Scalars["String"]>;
  sapUrlIfu?: Maybe<Scalars["String"]>;
  scheduledIn: Array<ScheduledOperation>;
  /** Do not edit this value manually. */
  sku: Scalars["String"];
  slug?: Maybe<Scalars["String"]>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type ProductAttributeValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AttributeValueOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeValueWhereInput>;
};

export type ProductCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type ProductDownloadsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type ProductHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type ProductImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductLinksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LinkOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LinkWhereInput>;
};

export type ProductLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type ProductParagraphsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ParagraphOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ParagraphWhereInput>;
};

export type ProductPfsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PfOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PfWhereInput>;
};

export type ProductProductGroupArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductConnection = {
  __typename?: "ProductConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductCreateInput = {
  attributeValues?: InputMaybe<AttributeValueCreateManyInlineInput>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  downloads?: InputMaybe<AssetCreateManyInlineInput>;
  gtin13?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  links?: InputMaybe<LinkCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProductCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** nameSuffix input for default locale (de) */
  nameSuffix?: InputMaybe<Scalars["String"]>;
  paragraphs?: InputMaybe<ParagraphCreateManyInlineInput>;
  pfs?: InputMaybe<PfCreateManyInlineInput>;
  productGroup?: InputMaybe<ProductGroupCreateOneInlineInput>;
  ranking?: InputMaybe<Scalars["Int"]>;
  sapEtiDurchmesser?: InputMaybe<Scalars["String"]>;
  sapEtiHoehe?: InputMaybe<Scalars["String"]>;
  sapEtiLaenge?: InputMaybe<Scalars["String"]>;
  sapEtiQty?: InputMaybe<Scalars["String"]>;
  /** sapMaktx input for default locale (de) */
  sapMaktx?: InputMaybe<Scalars["String"]>;
  sapMatkl?: InputMaybe<Scalars["String"]>;
  sapMstae?: InputMaybe<Scalars["String"]>;
  sapPdbEg?: InputMaybe<Scalars["String"]>;
  sapPdbPf?: InputMaybe<Scalars["String"]>;
  sapProdh?: InputMaybe<Scalars["String"]>;
  sapUrlIfu?: InputMaybe<Scalars["String"]>;
  sku: Scalars["String"];
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ProductCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  sapMaktx?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ProductCreateLocalizationInput = {
  /** Localization input */
  data: ProductCreateLocalizationDataInput;
  locale: Locale;
};

export type ProductCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProductCreateLocalizationInput>>;
};

export type ProductCreateManyInlineInput = {
  /** Connect multiple existing Product documents */
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Create and connect multiple existing Product documents */
  create?: InputMaybe<Array<ProductCreateInput>>;
};

export type ProductCreateOneInlineInput = {
  /** Connect one existing Product document */
  connect?: InputMaybe<ProductWhereUniqueInput>;
  /** Create and connect one Product document */
  create?: InputMaybe<ProductCreateInput>;
};

/** An edge in a connection. */
export type ProductEdge = {
  __typename?: "ProductEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: Product;
};

export type ProductGroup = Node & {
  __typename?: "ProductGroup";
  breadcrumbCategories: Array<Scalars["String"]>;
  categories: Array<Category>;
  countryCodes: Array<CountryCode>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<ProductGroup>;
  downloads: Array<Asset>;
  /** List of ProductGroup versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** Image for content (next to table) */
  image?: Maybe<Asset>;
  links: Array<Link>;
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ProductGroup>;
  name?: Maybe<Scalars["String"]>;
  nameSuffix?: Maybe<Scalars["String"]>;
  paragraphs: Array<Paragraph>;
  pfs: Array<Pf>;
  productGroupViewType?: Maybe<ProductGroupViewType>;
  products: Array<Product>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  slug?: Maybe<Scalars["String"]>;
  /** System stage field */
  stage: Stage;
  tileDescription?: Maybe<Scalars["String"]>;
  /** Image for tiles */
  tileImage?: Maybe<Asset>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
  xAxisAttributes: Array<Attribute>;
  yAxisAttributes: Array<Attribute>;
};

export type ProductGroupCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<CategoryWhereInput>;
};

export type ProductGroupCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type ProductGroupDownloadsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type ProductGroupHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type ProductGroupImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupLinksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LinkOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<LinkWhereInput>;
};

export type ProductGroupLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type ProductGroupParagraphsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ParagraphOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ParagraphWhereInput>;
};

export type ProductGroupPfsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PfOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<PfWhereInput>;
};

export type ProductGroupProductGroupViewTypeArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductWhereInput>;
};

export type ProductGroupPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductGroupTileImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupXAxisAttributesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AttributeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeWhereInput>;
};

export type ProductGroupYAxisAttributesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AttributeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<AttributeWhereInput>;
};

export type ProductGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductGroupConnection = {
  __typename?: "ProductGroupConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductGroupCreateInput = {
  breadcrumbCategories?: InputMaybe<Array<Scalars["String"]>>;
  categories?: InputMaybe<CategoryCreateManyInlineInput>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  downloads?: InputMaybe<AssetCreateManyInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  links?: InputMaybe<LinkCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProductGroupCreateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** nameSuffix input for default locale (de) */
  nameSuffix?: InputMaybe<Scalars["String"]>;
  paragraphs?: InputMaybe<ParagraphCreateManyInlineInput>;
  pfs?: InputMaybe<PfCreateManyInlineInput>;
  productGroupViewType?: InputMaybe<ProductGroupViewTypeCreateOneInlineInput>;
  products?: InputMaybe<ProductCreateManyInlineInput>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
  /** tileDescription input for default locale (de) */
  tileDescription?: InputMaybe<Scalars["String"]>;
  tileImage?: InputMaybe<AssetCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  xAxisAttributes?: InputMaybe<AttributeCreateManyInlineInput>;
  yAxisAttributes?: InputMaybe<AttributeCreateManyInlineInput>;
};

export type ProductGroupCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ProductGroupCreateLocalizationInput = {
  /** Localization input */
  data: ProductGroupCreateLocalizationDataInput;
  locale: Locale;
};

export type ProductGroupCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProductGroupCreateLocalizationInput>>;
};

export type ProductGroupCreateManyInlineInput = {
  /** Connect multiple existing ProductGroup documents */
  connect?: InputMaybe<Array<ProductGroupWhereUniqueInput>>;
  /** Create and connect multiple existing ProductGroup documents */
  create?: InputMaybe<Array<ProductGroupCreateInput>>;
};

export type ProductGroupCreateOneInlineInput = {
  /** Connect one existing ProductGroup document */
  connect?: InputMaybe<ProductGroupWhereUniqueInput>;
  /** Create and connect one ProductGroup document */
  create?: InputMaybe<ProductGroupCreateInput>;
};

/** An edge in a connection. */
export type ProductGroupEdge = {
  __typename?: "ProductGroupEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ProductGroup;
};

/** Identifies documents */
export type ProductGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  breadcrumbCategories?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  breadcrumbCategories_contains_all?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  breadcrumbCategories_contains_none?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains at least one item provided to the filter */
  breadcrumbCategories_contains_some?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  breadcrumbCategories_not?: InputMaybe<Array<Scalars["String"]>>;
  categories_every?: InputMaybe<CategoryWhereInput>;
  categories_none?: InputMaybe<CategoryWhereInput>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countryCodes_contains_all?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countryCodes_contains_none?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countryCodes_contains_some?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countryCodes_not?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductGroupWhereStageInput>;
  downloads_every?: InputMaybe<AssetWhereInput>;
  downloads_none?: InputMaybe<AssetWhereInput>;
  downloads_some?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  links_every?: InputMaybe<LinkWhereInput>;
  links_none?: InputMaybe<LinkWhereInput>;
  links_some?: InputMaybe<LinkWhereInput>;
  paragraphs_every?: InputMaybe<ParagraphWhereInput>;
  paragraphs_none?: InputMaybe<ParagraphWhereInput>;
  paragraphs_some?: InputMaybe<ParagraphWhereInput>;
  pfs_every?: InputMaybe<PfWhereInput>;
  pfs_none?: InputMaybe<PfWhereInput>;
  pfs_some?: InputMaybe<PfWhereInput>;
  productGroupViewType?: InputMaybe<ProductGroupViewTypeWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tileImage?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  xAxisAttributes_every?: InputMaybe<AttributeWhereInput>;
  xAxisAttributes_none?: InputMaybe<AttributeWhereInput>;
  xAxisAttributes_some?: InputMaybe<AttributeWhereInput>;
  yAxisAttributes_every?: InputMaybe<AttributeWhereInput>;
  yAxisAttributes_none?: InputMaybe<AttributeWhereInput>;
  yAxisAttributes_some?: InputMaybe<AttributeWhereInput>;
};

export enum ProductGroupOrderByInput {
  BreadcrumbCategoriesAsc = "breadcrumbCategories_ASC",
  BreadcrumbCategoriesDesc = "breadcrumbCategories_DESC",
  CountryCodesAsc = "countryCodes_ASC",
  CountryCodesDesc = "countryCodes_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameSuffixAsc = "nameSuffix_ASC",
  NameSuffixDesc = "nameSuffix_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  TileDescriptionAsc = "tileDescription_ASC",
  TileDescriptionDesc = "tileDescription_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type ProductGroupUpdateInput = {
  breadcrumbCategories?: InputMaybe<Array<Scalars["String"]>>;
  categories?: InputMaybe<CategoryUpdateManyInlineInput>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  downloads?: InputMaybe<AssetUpdateManyInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  links?: InputMaybe<LinkUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProductGroupUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** nameSuffix input for default locale (de) */
  nameSuffix?: InputMaybe<Scalars["String"]>;
  paragraphs?: InputMaybe<ParagraphUpdateManyInlineInput>;
  pfs?: InputMaybe<PfUpdateManyInlineInput>;
  productGroupViewType?: InputMaybe<ProductGroupViewTypeUpdateOneInlineInput>;
  products?: InputMaybe<ProductUpdateManyInlineInput>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
  /** tileDescription input for default locale (de) */
  tileDescription?: InputMaybe<Scalars["String"]>;
  tileImage?: InputMaybe<AssetUpdateOneInlineInput>;
  xAxisAttributes?: InputMaybe<AttributeUpdateManyInlineInput>;
  yAxisAttributes?: InputMaybe<AttributeUpdateManyInlineInput>;
};

export type ProductGroupUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
};

export type ProductGroupUpdateLocalizationInput = {
  data: ProductGroupUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProductGroupUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProductGroupCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProductGroupUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProductGroupUpsertLocalizationInput>>;
};

export type ProductGroupUpdateManyInlineInput = {
  /** Connect multiple existing ProductGroup documents */
  connect?: InputMaybe<Array<ProductGroupConnectInput>>;
  /** Create and connect multiple ProductGroup documents */
  create?: InputMaybe<Array<ProductGroupCreateInput>>;
  /** Delete multiple ProductGroup documents */
  delete?: InputMaybe<Array<ProductGroupWhereUniqueInput>>;
  /** Disconnect multiple ProductGroup documents */
  disconnect?: InputMaybe<Array<ProductGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProductGroup documents */
  set?: InputMaybe<Array<ProductGroupWhereUniqueInput>>;
  /** Update multiple ProductGroup documents */
  update?: InputMaybe<Array<ProductGroupUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProductGroup documents */
  upsert?: InputMaybe<Array<ProductGroupUpsertWithNestedWhereUniqueInput>>;
};

export type ProductGroupUpdateManyInput = {
  breadcrumbCategories?: InputMaybe<Array<Scalars["String"]>>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProductGroupUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** nameSuffix input for default locale (de) */
  nameSuffix?: InputMaybe<Scalars["String"]>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
  /** tileDescription input for default locale (de) */
  tileDescription?: InputMaybe<Scalars["String"]>;
};

export type ProductGroupUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
};

export type ProductGroupUpdateManyLocalizationInput = {
  data: ProductGroupUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProductGroupUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProductGroupUpdateManyLocalizationInput>>;
};

export type ProductGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductGroupUpdateManyInput;
  /** Document search */
  where: ProductGroupWhereInput;
};

export type ProductGroupUpdateOneInlineInput = {
  /** Connect existing ProductGroup document */
  connect?: InputMaybe<ProductGroupWhereUniqueInput>;
  /** Create and connect one ProductGroup document */
  create?: InputMaybe<ProductGroupCreateInput>;
  /** Delete currently connected ProductGroup document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected ProductGroup document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single ProductGroup document */
  update?: InputMaybe<ProductGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProductGroup document */
  upsert?: InputMaybe<ProductGroupUpsertWithNestedWhereUniqueInput>;
};

export type ProductGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductGroupUpdateInput;
  /** Unique document search */
  where: ProductGroupWhereUniqueInput;
};

export type ProductGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductGroupCreateInput;
  /** Update document if it exists */
  update: ProductGroupUpdateInput;
};

export type ProductGroupUpsertLocalizationInput = {
  create: ProductGroupCreateLocalizationDataInput;
  locale: Locale;
  update: ProductGroupUpdateLocalizationDataInput;
};

export type ProductGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductGroupUpsertInput;
  /** Unique document search */
  where: ProductGroupWhereUniqueInput;
};

export type ProductGroupViewType = Node & {
  __typename?: "ProductGroupViewType";
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars["String"]>;
  /** Get the document in other stages */
  documentInStages: Array<ProductGroupViewType>;
  /** List of ProductGroupViewType versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<ProductGroupViewType>;
  name?: Maybe<Scalars["String"]>;
  productGroups: Array<ProductGroup>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type ProductGroupViewTypeCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductGroupViewTypeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupViewTypeDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type ProductGroupViewTypeHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type ProductGroupViewTypeLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type ProductGroupViewTypeProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type ProductGroupViewTypePublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductGroupViewTypePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupViewTypeScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type ProductGroupViewTypeUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type ProductGroupViewTypeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ProductGroupViewTypeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ProductGroupViewTypeWhereUniqueInput;
};

/** A connection to a list of items. */
export type ProductGroupViewTypeConnection = {
  __typename?: "ProductGroupViewTypeConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ProductGroupViewTypeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProductGroupViewTypeCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<ProductGroupViewTypeCreateLocalizationsInput>;
  name?: InputMaybe<Scalars["String"]>;
  productGroups?: InputMaybe<ProductGroupCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ProductGroupViewTypeCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ProductGroupViewTypeCreateLocalizationInput = {
  /** Localization input */
  data: ProductGroupViewTypeCreateLocalizationDataInput;
  locale: Locale;
};

export type ProductGroupViewTypeCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<ProductGroupViewTypeCreateLocalizationInput>>;
};

export type ProductGroupViewTypeCreateManyInlineInput = {
  /** Connect multiple existing ProductGroupViewType documents */
  connect?: InputMaybe<Array<ProductGroupViewTypeWhereUniqueInput>>;
  /** Create and connect multiple existing ProductGroupViewType documents */
  create?: InputMaybe<Array<ProductGroupViewTypeCreateInput>>;
};

export type ProductGroupViewTypeCreateOneInlineInput = {
  /** Connect one existing ProductGroupViewType document */
  connect?: InputMaybe<ProductGroupViewTypeWhereUniqueInput>;
  /** Create and connect one ProductGroupViewType document */
  create?: InputMaybe<ProductGroupViewTypeCreateInput>;
};

/** An edge in a connection. */
export type ProductGroupViewTypeEdge = {
  __typename?: "ProductGroupViewTypeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ProductGroupViewType;
};

/** Identifies documents */
export type ProductGroupViewTypeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductGroupViewTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductGroupViewTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductGroupViewTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductGroupViewTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductGroupViewTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductGroupViewTypeWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ProductGroupViewTypeOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type ProductGroupViewTypeUpdateInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProductGroupViewTypeUpdateLocalizationsInput>;
  name?: InputMaybe<Scalars["String"]>;
  productGroups?: InputMaybe<ProductGroupUpdateManyInlineInput>;
};

export type ProductGroupViewTypeUpdateLocalizationDataInput = {
  description?: InputMaybe<Scalars["String"]>;
};

export type ProductGroupViewTypeUpdateLocalizationInput = {
  data: ProductGroupViewTypeUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProductGroupViewTypeUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProductGroupViewTypeCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProductGroupViewTypeUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProductGroupViewTypeUpsertLocalizationInput>>;
};

export type ProductGroupViewTypeUpdateManyInlineInput = {
  /** Connect multiple existing ProductGroupViewType documents */
  connect?: InputMaybe<Array<ProductGroupViewTypeConnectInput>>;
  /** Create and connect multiple ProductGroupViewType documents */
  create?: InputMaybe<Array<ProductGroupViewTypeCreateInput>>;
  /** Delete multiple ProductGroupViewType documents */
  delete?: InputMaybe<Array<ProductGroupViewTypeWhereUniqueInput>>;
  /** Disconnect multiple ProductGroupViewType documents */
  disconnect?: InputMaybe<Array<ProductGroupViewTypeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ProductGroupViewType documents */
  set?: InputMaybe<Array<ProductGroupViewTypeWhereUniqueInput>>;
  /** Update multiple ProductGroupViewType documents */
  update?: InputMaybe<Array<ProductGroupViewTypeUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ProductGroupViewType documents */
  upsert?: InputMaybe<Array<ProductGroupViewTypeUpsertWithNestedWhereUniqueInput>>;
};

export type ProductGroupViewTypeUpdateManyInput = {
  /** description input for default locale (de) */
  description?: InputMaybe<Scalars["String"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProductGroupViewTypeUpdateManyLocalizationsInput>;
  name?: InputMaybe<Scalars["String"]>;
};

export type ProductGroupViewTypeUpdateManyLocalizationDataInput = {
  description?: InputMaybe<Scalars["String"]>;
};

export type ProductGroupViewTypeUpdateManyLocalizationInput = {
  data: ProductGroupViewTypeUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProductGroupViewTypeUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProductGroupViewTypeUpdateManyLocalizationInput>>;
};

export type ProductGroupViewTypeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductGroupViewTypeUpdateManyInput;
  /** Document search */
  where: ProductGroupViewTypeWhereInput;
};

export type ProductGroupViewTypeUpdateOneInlineInput = {
  /** Connect existing ProductGroupViewType document */
  connect?: InputMaybe<ProductGroupViewTypeWhereUniqueInput>;
  /** Create and connect one ProductGroupViewType document */
  create?: InputMaybe<ProductGroupViewTypeCreateInput>;
  /** Delete currently connected ProductGroupViewType document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected ProductGroupViewType document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single ProductGroupViewType document */
  update?: InputMaybe<ProductGroupViewTypeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ProductGroupViewType document */
  upsert?: InputMaybe<ProductGroupViewTypeUpsertWithNestedWhereUniqueInput>;
};

export type ProductGroupViewTypeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductGroupViewTypeUpdateInput;
  /** Unique document search */
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type ProductGroupViewTypeUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductGroupViewTypeCreateInput;
  /** Update document if it exists */
  update: ProductGroupViewTypeUpdateInput;
};

export type ProductGroupViewTypeUpsertLocalizationInput = {
  create: ProductGroupViewTypeCreateLocalizationDataInput;
  locale: Locale;
  update: ProductGroupViewTypeUpdateLocalizationDataInput;
};

export type ProductGroupViewTypeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductGroupViewTypeUpsertInput;
  /** Unique document search */
  where: ProductGroupViewTypeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductGroupViewTypeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type ProductGroupViewTypeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductGroupViewTypeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductGroupViewTypeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductGroupViewTypeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  documentInStages_every?: InputMaybe<ProductGroupViewTypeWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductGroupViewTypeWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductGroupViewTypeWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  productGroups_every?: InputMaybe<ProductGroupWhereInput>;
  productGroups_none?: InputMaybe<ProductGroupWhereInput>;
  productGroups_some?: InputMaybe<ProductGroupWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductGroupViewTypeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductGroupViewTypeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductGroupViewTypeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductGroupViewTypeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductGroupViewTypeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ProductGroupViewType record uniquely */
export type ProductGroupViewTypeWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type ProductGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  breadcrumbCategories?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  breadcrumbCategories_contains_all?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  breadcrumbCategories_contains_none?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array contains at least one item provided to the filter */
  breadcrumbCategories_contains_some?: InputMaybe<Array<Scalars["String"]>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  breadcrumbCategories_not?: InputMaybe<Array<Scalars["String"]>>;
  categories_every?: InputMaybe<CategoryWhereInput>;
  categories_none?: InputMaybe<CategoryWhereInput>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countryCodes_contains_all?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countryCodes_contains_none?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countryCodes_contains_some?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countryCodes_not?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductGroupWhereStageInput>;
  downloads_every?: InputMaybe<AssetWhereInput>;
  downloads_none?: InputMaybe<AssetWhereInput>;
  downloads_some?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  links_every?: InputMaybe<LinkWhereInput>;
  links_none?: InputMaybe<LinkWhereInput>;
  links_some?: InputMaybe<LinkWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  nameSuffix_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  nameSuffix_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  nameSuffix_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  nameSuffix_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  nameSuffix_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  nameSuffix_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  nameSuffix_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  nameSuffix_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  nameSuffix_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  paragraphs_every?: InputMaybe<ParagraphWhereInput>;
  paragraphs_none?: InputMaybe<ParagraphWhereInput>;
  paragraphs_some?: InputMaybe<ParagraphWhereInput>;
  pfs_every?: InputMaybe<PfWhereInput>;
  pfs_none?: InputMaybe<PfWhereInput>;
  pfs_some?: InputMaybe<PfWhereInput>;
  productGroupViewType?: InputMaybe<ProductGroupViewTypeWhereInput>;
  products_every?: InputMaybe<ProductWhereInput>;
  products_none?: InputMaybe<ProductWhereInput>;
  products_some?: InputMaybe<ProductWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  slug?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  slug_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]>;
  tileDescription?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  tileDescription_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  tileDescription_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  tileDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  tileDescription_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  tileDescription_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  tileDescription_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  tileDescription_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  tileDescription_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  tileDescription_starts_with?: InputMaybe<Scalars["String"]>;
  tileImage?: InputMaybe<AssetWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
  xAxisAttributes_every?: InputMaybe<AttributeWhereInput>;
  xAxisAttributes_none?: InputMaybe<AttributeWhereInput>;
  xAxisAttributes_some?: InputMaybe<AttributeWhereInput>;
  yAxisAttributes_every?: InputMaybe<AttributeWhereInput>;
  yAxisAttributes_none?: InputMaybe<AttributeWhereInput>;
  yAxisAttributes_some?: InputMaybe<AttributeWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References ProductGroup record uniquely */
export type ProductGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** Identifies documents */
export type ProductManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  attributeValues_every?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_none?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_some?: InputMaybe<AttributeValueWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countryCodes_contains_all?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countryCodes_contains_none?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countryCodes_contains_some?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countryCodes_not?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductWhereStageInput>;
  downloads_every?: InputMaybe<AssetWhereInput>;
  downloads_none?: InputMaybe<AssetWhereInput>;
  downloads_some?: InputMaybe<AssetWhereInput>;
  gtin13?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  gtin13_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  gtin13_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  gtin13_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  gtin13_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  gtin13_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  gtin13_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  gtin13_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  gtin13_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  gtin13_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  links_every?: InputMaybe<LinkWhereInput>;
  links_none?: InputMaybe<LinkWhereInput>;
  links_some?: InputMaybe<LinkWhereInput>;
  paragraphs_every?: InputMaybe<ParagraphWhereInput>;
  paragraphs_none?: InputMaybe<ParagraphWhereInput>;
  paragraphs_some?: InputMaybe<ParagraphWhereInput>;
  pfs_every?: InputMaybe<PfWhereInput>;
  pfs_none?: InputMaybe<PfWhereInput>;
  pfs_some?: InputMaybe<PfWhereInput>;
  productGroup?: InputMaybe<ProductGroupWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  ranking?: InputMaybe<Scalars["Int"]>;
  /** All values greater than the given value. */
  ranking_gt?: InputMaybe<Scalars["Int"]>;
  /** All values greater than or equal the given value. */
  ranking_gte?: InputMaybe<Scalars["Int"]>;
  /** All values that are contained in given list. */
  ranking_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** All values less than the given value. */
  ranking_lt?: InputMaybe<Scalars["Int"]>;
  /** All values less than or equal the given value. */
  ranking_lte?: InputMaybe<Scalars["Int"]>;
  /** All values that are not equal to given value. */
  ranking_not?: InputMaybe<Scalars["Int"]>;
  /** All values that are not contained in given list. */
  ranking_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  sapEtiDurchmesser?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiDurchmesser_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiDurchmesser_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiDurchmesser_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiDurchmesser_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiDurchmesser_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiDurchmesser_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiDurchmesser_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiDurchmesser_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiDurchmesser_starts_with?: InputMaybe<Scalars["String"]>;
  sapEtiHoehe?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiHoehe_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiHoehe_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiHoehe_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiHoehe_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiHoehe_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiHoehe_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiHoehe_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiHoehe_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiHoehe_starts_with?: InputMaybe<Scalars["String"]>;
  sapEtiLaenge?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiLaenge_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiLaenge_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiLaenge_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiLaenge_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiLaenge_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiLaenge_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiLaenge_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiLaenge_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiLaenge_starts_with?: InputMaybe<Scalars["String"]>;
  sapEtiQty?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiQty_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiQty_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiQty_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiQty_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiQty_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiQty_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiQty_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiQty_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiQty_starts_with?: InputMaybe<Scalars["String"]>;
  sapMatkl?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapMatkl_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapMatkl_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapMatkl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapMatkl_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapMatkl_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapMatkl_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapMatkl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapMatkl_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapMatkl_starts_with?: InputMaybe<Scalars["String"]>;
  sapMstae?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapMstae_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapMstae_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapMstae_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapMstae_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapMstae_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapMstae_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapMstae_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapMstae_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapMstae_starts_with?: InputMaybe<Scalars["String"]>;
  sapPdbEg?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapPdbEg_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapPdbEg_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapPdbEg_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapPdbEg_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapPdbEg_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapPdbEg_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapPdbEg_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapPdbEg_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapPdbEg_starts_with?: InputMaybe<Scalars["String"]>;
  sapPdbPf?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapPdbPf_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapPdbPf_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapPdbPf_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapPdbPf_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapPdbPf_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapPdbPf_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapPdbPf_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapPdbPf_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapPdbPf_starts_with?: InputMaybe<Scalars["String"]>;
  sapProdh?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapProdh_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapProdh_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapProdh_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapProdh_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapProdh_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapProdh_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapProdh_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapProdh_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapProdh_starts_with?: InputMaybe<Scalars["String"]>;
  sapUrlIfu?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapUrlIfu_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapUrlIfu_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapUrlIfu_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapUrlIfu_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapUrlIfu_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapUrlIfu_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapUrlIfu_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapUrlIfu_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapUrlIfu_starts_with?: InputMaybe<Scalars["String"]>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sku?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sku_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sku_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sku_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sku_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sku_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sku_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sku_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sku_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sku_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ProductOrderByInput {
  CountryCodesAsc = "countryCodes_ASC",
  CountryCodesDesc = "countryCodes_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  Gtin13Asc = "gtin13_ASC",
  Gtin13Desc = "gtin13_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  NameSuffixAsc = "nameSuffix_ASC",
  NameSuffixDesc = "nameSuffix_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  RankingAsc = "ranking_ASC",
  RankingDesc = "ranking_DESC",
  SapEtiDurchmesserAsc = "sapEtiDurchmesser_ASC",
  SapEtiDurchmesserDesc = "sapEtiDurchmesser_DESC",
  SapEtiHoeheAsc = "sapEtiHoehe_ASC",
  SapEtiHoeheDesc = "sapEtiHoehe_DESC",
  SapEtiLaengeAsc = "sapEtiLaenge_ASC",
  SapEtiLaengeDesc = "sapEtiLaenge_DESC",
  SapEtiQtyAsc = "sapEtiQty_ASC",
  SapEtiQtyDesc = "sapEtiQty_DESC",
  SapMaktxAsc = "sapMaktx_ASC",
  SapMaktxDesc = "sapMaktx_DESC",
  SapMatklAsc = "sapMatkl_ASC",
  SapMatklDesc = "sapMatkl_DESC",
  SapMstaeAsc = "sapMstae_ASC",
  SapMstaeDesc = "sapMstae_DESC",
  SapPdbEgAsc = "sapPdbEg_ASC",
  SapPdbEgDesc = "sapPdbEg_DESC",
  SapPdbPfAsc = "sapPdbPf_ASC",
  SapPdbPfDesc = "sapPdbPf_DESC",
  SapProdhAsc = "sapProdh_ASC",
  SapProdhDesc = "sapProdh_DESC",
  SapUrlIfuAsc = "sapUrlIfu_ASC",
  SapUrlIfuDesc = "sapUrlIfu_DESC",
  SkuAsc = "sku_ASC",
  SkuDesc = "sku_DESC",
  SlugAsc = "slug_ASC",
  SlugDesc = "slug_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type ProductUpdateInput = {
  attributeValues?: InputMaybe<AttributeValueUpdateManyInlineInput>;
  countryCodes?: InputMaybe<Array<CountryCode>>;
  downloads?: InputMaybe<AssetUpdateManyInlineInput>;
  gtin13?: InputMaybe<Scalars["String"]>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  links?: InputMaybe<LinkUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<ProductUpdateLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** nameSuffix input for default locale (de) */
  nameSuffix?: InputMaybe<Scalars["String"]>;
  paragraphs?: InputMaybe<ParagraphUpdateManyInlineInput>;
  pfs?: InputMaybe<PfUpdateManyInlineInput>;
  productGroup?: InputMaybe<ProductGroupUpdateOneInlineInput>;
  ranking?: InputMaybe<Scalars["Int"]>;
  sapEtiDurchmesser?: InputMaybe<Scalars["String"]>;
  sapEtiHoehe?: InputMaybe<Scalars["String"]>;
  sapEtiLaenge?: InputMaybe<Scalars["String"]>;
  sapEtiQty?: InputMaybe<Scalars["String"]>;
  /** sapMaktx input for default locale (de) */
  sapMaktx?: InputMaybe<Scalars["String"]>;
  sapMatkl?: InputMaybe<Scalars["String"]>;
  sapMstae?: InputMaybe<Scalars["String"]>;
  sapPdbEg?: InputMaybe<Scalars["String"]>;
  sapPdbPf?: InputMaybe<Scalars["String"]>;
  sapProdh?: InputMaybe<Scalars["String"]>;
  sapUrlIfu?: InputMaybe<Scalars["String"]>;
  sku?: InputMaybe<Scalars["String"]>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
};

export type ProductUpdateLocalizationDataInput = {
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  sapMaktx?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type ProductUpdateLocalizationInput = {
  data: ProductUpdateLocalizationDataInput;
  locale: Locale;
};

export type ProductUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<ProductCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<ProductUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<ProductUpsertLocalizationInput>>;
};

export type ProductUpdateManyInlineInput = {
  /** Connect multiple existing Product documents */
  connect?: InputMaybe<Array<ProductConnectInput>>;
  /** Create and connect multiple Product documents */
  create?: InputMaybe<Array<ProductCreateInput>>;
  /** Delete multiple Product documents */
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Disconnect multiple Product documents */
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Product documents */
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  /** Update multiple Product documents */
  update?: InputMaybe<Array<ProductUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Product documents */
  upsert?: InputMaybe<Array<ProductUpsertWithNestedWhereUniqueInput>>;
};

export type ProductUpdateManyInput = {
  countryCodes?: InputMaybe<Array<CountryCode>>;
  gtin13?: InputMaybe<Scalars["String"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<ProductUpdateManyLocalizationsInput>;
  /** name input for default locale (de) */
  name?: InputMaybe<Scalars["String"]>;
  /** nameSuffix input for default locale (de) */
  nameSuffix?: InputMaybe<Scalars["String"]>;
  ranking?: InputMaybe<Scalars["Int"]>;
  sapEtiDurchmesser?: InputMaybe<Scalars["String"]>;
  sapEtiHoehe?: InputMaybe<Scalars["String"]>;
  sapEtiLaenge?: InputMaybe<Scalars["String"]>;
  sapEtiQty?: InputMaybe<Scalars["String"]>;
  /** sapMaktx input for default locale (de) */
  sapMaktx?: InputMaybe<Scalars["String"]>;
  sapMatkl?: InputMaybe<Scalars["String"]>;
  sapMstae?: InputMaybe<Scalars["String"]>;
  sapPdbEg?: InputMaybe<Scalars["String"]>;
  sapPdbPf?: InputMaybe<Scalars["String"]>;
  sapProdh?: InputMaybe<Scalars["String"]>;
  sapUrlIfu?: InputMaybe<Scalars["String"]>;
  /** slug input for default locale (de) */
  slug?: InputMaybe<Scalars["String"]>;
};

export type ProductUpdateManyLocalizationDataInput = {
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  sapMaktx?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type ProductUpdateManyLocalizationInput = {
  data: ProductUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type ProductUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<ProductUpdateManyLocalizationInput>>;
};

export type ProductUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ProductUpdateManyInput;
  /** Document search */
  where: ProductWhereInput;
};

export type ProductUpdateOneInlineInput = {
  /** Connect existing Product document */
  connect?: InputMaybe<ProductWhereUniqueInput>;
  /** Create and connect one Product document */
  create?: InputMaybe<ProductCreateInput>;
  /** Delete currently connected Product document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected Product document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single Product document */
  update?: InputMaybe<ProductUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Product document */
  upsert?: InputMaybe<ProductUpsertWithNestedWhereUniqueInput>;
};

export type ProductUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ProductUpdateInput;
  /** Unique document search */
  where: ProductWhereUniqueInput;
};

export type ProductUpsertInput = {
  /** Create document if it didn't exist */
  create: ProductCreateInput;
  /** Update document if it exists */
  update: ProductUpdateInput;
};

export type ProductUpsertLocalizationInput = {
  create: ProductCreateLocalizationDataInput;
  locale: Locale;
  update: ProductUpdateLocalizationDataInput;
};

export type ProductUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ProductUpsertInput;
  /** Unique document search */
  where: ProductWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ProductWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type ProductWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  attributeValues_every?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_none?: InputMaybe<AttributeValueWhereInput>;
  attributeValues_some?: InputMaybe<AttributeValueWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countryCodes_contains_all?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countryCodes_contains_none?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countryCodes_contains_some?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countryCodes_not?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ProductWhereStageInput>;
  documentInStages_none?: InputMaybe<ProductWhereStageInput>;
  documentInStages_some?: InputMaybe<ProductWhereStageInput>;
  downloads_every?: InputMaybe<AssetWhereInput>;
  downloads_none?: InputMaybe<AssetWhereInput>;
  downloads_some?: InputMaybe<AssetWhereInput>;
  gtin13?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  gtin13_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  gtin13_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  gtin13_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  gtin13_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  gtin13_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  gtin13_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  gtin13_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  gtin13_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  gtin13_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  image?: InputMaybe<AssetWhereInput>;
  links_every?: InputMaybe<LinkWhereInput>;
  links_none?: InputMaybe<LinkWhereInput>;
  links_some?: InputMaybe<LinkWhereInput>;
  name?: InputMaybe<Scalars["String"]>;
  nameSuffix?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  nameSuffix_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  nameSuffix_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  nameSuffix_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  nameSuffix_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  nameSuffix_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  nameSuffix_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  nameSuffix_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  nameSuffix_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  nameSuffix_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  paragraphs_every?: InputMaybe<ParagraphWhereInput>;
  paragraphs_none?: InputMaybe<ParagraphWhereInput>;
  paragraphs_some?: InputMaybe<ParagraphWhereInput>;
  pfs_every?: InputMaybe<PfWhereInput>;
  pfs_none?: InputMaybe<PfWhereInput>;
  pfs_some?: InputMaybe<PfWhereInput>;
  productGroup?: InputMaybe<ProductGroupWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  ranking?: InputMaybe<Scalars["Int"]>;
  /** All values greater than the given value. */
  ranking_gt?: InputMaybe<Scalars["Int"]>;
  /** All values greater than or equal the given value. */
  ranking_gte?: InputMaybe<Scalars["Int"]>;
  /** All values that are contained in given list. */
  ranking_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  /** All values less than the given value. */
  ranking_lt?: InputMaybe<Scalars["Int"]>;
  /** All values less than or equal the given value. */
  ranking_lte?: InputMaybe<Scalars["Int"]>;
  /** All values that are not equal to given value. */
  ranking_not?: InputMaybe<Scalars["Int"]>;
  /** All values that are not contained in given list. */
  ranking_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]>>>;
  sapEtiDurchmesser?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiDurchmesser_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiDurchmesser_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiDurchmesser_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiDurchmesser_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiDurchmesser_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiDurchmesser_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiDurchmesser_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiDurchmesser_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiDurchmesser_starts_with?: InputMaybe<Scalars["String"]>;
  sapEtiHoehe?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiHoehe_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiHoehe_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiHoehe_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiHoehe_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiHoehe_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiHoehe_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiHoehe_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiHoehe_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiHoehe_starts_with?: InputMaybe<Scalars["String"]>;
  sapEtiLaenge?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiLaenge_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiLaenge_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiLaenge_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiLaenge_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiLaenge_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiLaenge_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiLaenge_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiLaenge_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiLaenge_starts_with?: InputMaybe<Scalars["String"]>;
  sapEtiQty?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapEtiQty_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapEtiQty_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapEtiQty_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapEtiQty_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapEtiQty_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapEtiQty_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapEtiQty_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapEtiQty_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapEtiQty_starts_with?: InputMaybe<Scalars["String"]>;
  sapMaktx?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapMaktx_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapMaktx_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapMaktx_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapMaktx_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapMaktx_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapMaktx_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapMaktx_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapMaktx_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapMaktx_starts_with?: InputMaybe<Scalars["String"]>;
  sapMatkl?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapMatkl_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapMatkl_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapMatkl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapMatkl_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapMatkl_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapMatkl_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapMatkl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapMatkl_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapMatkl_starts_with?: InputMaybe<Scalars["String"]>;
  sapMstae?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapMstae_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapMstae_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapMstae_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapMstae_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapMstae_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapMstae_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapMstae_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapMstae_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapMstae_starts_with?: InputMaybe<Scalars["String"]>;
  sapPdbEg?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapPdbEg_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapPdbEg_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapPdbEg_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapPdbEg_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapPdbEg_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapPdbEg_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapPdbEg_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapPdbEg_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapPdbEg_starts_with?: InputMaybe<Scalars["String"]>;
  sapPdbPf?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapPdbPf_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapPdbPf_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapPdbPf_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapPdbPf_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapPdbPf_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapPdbPf_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapPdbPf_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapPdbPf_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapPdbPf_starts_with?: InputMaybe<Scalars["String"]>;
  sapProdh?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapProdh_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapProdh_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapProdh_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapProdh_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapProdh_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapProdh_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapProdh_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapProdh_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapProdh_starts_with?: InputMaybe<Scalars["String"]>;
  sapUrlIfu?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sapUrlIfu_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sapUrlIfu_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sapUrlIfu_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sapUrlIfu_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sapUrlIfu_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sapUrlIfu_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sapUrlIfu_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sapUrlIfu_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sapUrlIfu_starts_with?: InputMaybe<Scalars["String"]>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sku?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  sku_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  sku_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  sku_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  sku_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  sku_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  sku_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  sku_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  sku_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  sku_starts_with?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  slug_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ProductWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ProductWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ProductWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ProductWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ProductWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Product record uniquely */
export type ProductWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
  sku?: InputMaybe<Scalars["String"]>;
};

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type Query = {
  __typename?: "Query";
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single attribute */
  attribute?: Maybe<Attribute>;
  /** Retrieve a single attributeValue */
  attributeValue?: Maybe<AttributeValue>;
  /** Retrieve document version */
  attributeValueVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple attributeValues */
  attributeValues: Array<AttributeValue>;
  /** Retrieve multiple attributeValues using the Relay connection interface */
  attributeValuesConnection: AttributeValueConnection;
  /** Retrieve document version */
  attributeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple attributes */
  attributes: Array<Attribute>;
  /** Retrieve multiple attributes using the Relay connection interface */
  attributesConnection: AttributeConnection;
  /** Retrieve multiple categories */
  categories: Array<Category>;
  /** Retrieve multiple categories using the Relay connection interface */
  categoriesConnection: CategoryConnection;
  /** Retrieve a single category */
  category?: Maybe<Category>;
  /** Retrieve document version */
  categoryVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single link */
  link?: Maybe<Link>;
  /** Retrieve document version */
  linkVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple links */
  links: Array<Link>;
  /** Retrieve multiple links using the Relay connection interface */
  linksConnection: LinkConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve a single paragraph */
  paragraph?: Maybe<Paragraph>;
  /** Retrieve document version */
  paragraphVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple paragraphs */
  paragraphs: Array<Paragraph>;
  /** Retrieve multiple paragraphs using the Relay connection interface */
  paragraphsConnection: ParagraphConnection;
  /** Retrieve a single pf */
  pf?: Maybe<Pf>;
  /** Retrieve document version */
  pfVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pfs */
  pfs: Array<Pf>;
  /** Retrieve multiple pfs using the Relay connection interface */
  pfsConnection: PfConnection;
  /** Retrieve a single product */
  product?: Maybe<Product>;
  /** Retrieve a single productGroup */
  productGroup?: Maybe<ProductGroup>;
  /** Retrieve document version */
  productGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single productGroupViewType */
  productGroupViewType?: Maybe<ProductGroupViewType>;
  /** Retrieve document version */
  productGroupViewTypeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple productGroupViewTypes */
  productGroupViewTypes: Array<ProductGroupViewType>;
  /** Retrieve multiple productGroupViewTypes using the Relay connection interface */
  productGroupViewTypesConnection: ProductGroupViewTypeConnection;
  /** Retrieve multiple productGroups */
  productGroups: Array<ProductGroup>;
  /** Retrieve multiple productGroups using the Relay connection interface */
  productGroupsConnection: ProductGroupConnection;
  /** Retrieve document version */
  productVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple products */
  products: Array<Product>;
  /** Retrieve multiple products using the Relay connection interface */
  productsConnection: ProductConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single user */
  user?: Maybe<User>;
  /** Retrieve a single userNotification */
  userNotification?: Maybe<UserNotification>;
  /** Retrieve document version */
  userNotificationVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple userNotifications */
  userNotifications: Array<UserNotification>;
  /** Retrieve multiple userNotifications using the Relay connection interface */
  userNotificationsConnection: UserNotificationConnection;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
};

export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};

export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAttributeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttributeWhereUniqueInput;
};

export type QueryAttributeValueArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AttributeValueWhereUniqueInput;
};

export type QueryAttributeValueVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttributeValuesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AttributeValueOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<AttributeValueWhereInput>;
};

export type QueryAttributeValuesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AttributeValueOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<AttributeValueWhereInput>;
};

export type QueryAttributeVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAttributesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AttributeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<AttributeWhereInput>;
};

export type QueryAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AttributeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<AttributeWhereInput>;
};

export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CategoryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<CategoryWhereInput>;
};

export type QueryCategoryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CategoryWhereUniqueInput;
};

export type QueryCategoryVersionArgs = {
  where: VersionWhereInput;
};

export type QueryLinkArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: LinkWhereUniqueInput;
};

export type QueryLinkVersionArgs = {
  where: VersionWhereInput;
};

export type QueryLinksArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LinkOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<LinkWhereInput>;
};

export type QueryLinksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LinkOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<LinkWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"];
  locales?: Array<Locale>;
  stage?: Stage;
};

export type QueryParagraphArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ParagraphWhereUniqueInput;
};

export type QueryParagraphVersionArgs = {
  where: VersionWhereInput;
};

export type QueryParagraphsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ParagraphOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ParagraphWhereInput>;
};

export type QueryParagraphsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ParagraphOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ParagraphWhereInput>;
};

export type QueryPfArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PfWhereUniqueInput;
};

export type QueryPfVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPfsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PfOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<PfWhereInput>;
};

export type QueryPfsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PfOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<PfWhereInput>;
};

export type QueryProductArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductWhereUniqueInput;
};

export type QueryProductGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductGroupWhereUniqueInput;
};

export type QueryProductGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductGroupViewTypeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ProductGroupViewTypeWhereUniqueInput;
};

export type QueryProductGroupViewTypeVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductGroupViewTypesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProductGroupViewTypeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ProductGroupViewTypeWhereInput>;
};

export type QueryProductGroupViewTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProductGroupViewTypeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ProductGroupViewTypeWhereInput>;
};

export type QueryProductGroupsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type QueryProductGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProductGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ProductGroupWhereInput>;
};

export type QueryProductVersionArgs = {
  where: VersionWhereInput;
};

export type QueryProductsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ProductWhereInput>;
};

export type QueryProductsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ProductWhereInput>;
};

export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};

export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};

export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};

export type QueryUserNotificationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserNotificationWhereUniqueInput;
};

export type QueryUserNotificationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryUserNotificationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserNotificationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryUserNotificationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserNotificationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<UserNotificationWhereInput>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: "RGBA";
  a: Scalars["RGBATransparency"];
  b: Scalars["RGBAHue"];
  g: Scalars["RGBAHue"];
  r: Scalars["RGBAHue"];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars["RGBATransparency"];
  b: Scalars["RGBAHue"];
  g: Scalars["RGBAHue"];
  r: Scalars["RGBAHue"];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: "RichText";
  /** Returns HTMl representation */
  html: Scalars["String"];
  /** Returns Markdown representation */
  markdown: Scalars["String"];
  /** Returns AST representation */
  raw: Scalars["RichTextAST"];
  /** Returns plain-text contents of RichText */
  text: Scalars["String"];
};

/** Scheduled Operation system model */
export type ScheduledOperation = Node & {
  __typename?: "ScheduledOperation";
  affectedDocuments: Array<ScheduledOperationAffectedDocument>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Operation description */
  description?: Maybe<Scalars["String"]>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledOperation>;
  /** Operation error message */
  errorMessage?: Maybe<Scalars["String"]>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars["Json"];
  /** The release this operation is scheduled for */
  release?: Maybe<ScheduledRelease>;
  /** System stage field */
  stage: Stage;
  /** operation Status */
  status: ScheduledOperationStatus;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
};

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument =
  | Asset
  | Attribute
  | AttributeValue
  | Category
  | Link
  | Paragraph
  | Pf
  | Product
  | ProductGroup
  | ProductGroupViewType
  | UserNotification;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: "ScheduledOperationConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: "ScheduledOperationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars["String"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars["Json"]>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  StatusAsc = "status_ASC",
  StatusDesc = "status_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars["String"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars["Json"]>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Node & {
  __typename?: "ScheduledRelease";
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Release description */
  description?: Maybe<Scalars["String"]>;
  /** Get the document in other stages */
  documentInStages: Array<ScheduledRelease>;
  /** Release error message */
  errorMessage?: Maybe<Scalars["String"]>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** Whether scheduled release should be run */
  isActive: Scalars["Boolean"];
  /** Whether scheduled release is implicit */
  isImplicit: Scalars["Boolean"];
  /** Operations to run with this release */
  operations: Array<ScheduledOperation>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  /** Release date and time */
  releaseAt?: Maybe<Scalars["DateTime"]>;
  /** System stage field */
  stage: Stage;
  /** Release Status */
  status: ScheduledReleaseStatus;
  /** Release Title */
  title?: Maybe<Scalars["String"]>;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: "ScheduledReleaseConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  description?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: "ScheduledReleaseEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]>;
  isImplicit?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  isImplicit_not?: InputMaybe<Scalars["Boolean"]>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  releaseAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  title_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  DescriptionAsc = "description_ASC",
  DescriptionDesc = "description_DESC",
  ErrorMessageAsc = "errorMessage_ASC",
  ErrorMessageDesc = "errorMessage_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsActiveAsc = "isActive_ASC",
  IsActiveDesc = "isActive_DESC",
  IsImplicitAsc = "isImplicit_ASC",
  IsImplicitDesc = "isImplicit_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  ReleaseAtAsc = "releaseAt_ASC",
  ReleaseAtDesc = "releaseAt_DESC",
  StatusAsc = "status_ASC",
  StatusDesc = "status_DESC",
  TitleAsc = "title_ASC",
  TitleDesc = "title_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  title?: InputMaybe<Scalars["String"]>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  description_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]>;
  errorMessage?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]>;
  isImplicit?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  isImplicit_not?: InputMaybe<Scalars["Boolean"]>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  releaseAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** All values that are not equal to given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  title_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  Draft = "DRAFT",
  /** The Published stage is where you can publish your content to. */
  Published = "PUBLISHED",
}

export enum SystemDateTimeFieldVariation {
  Base = "BASE",
  Combined = "COMBINED",
  Localization = "LOCALIZATION",
}

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** User system model */
export type User = Node & {
  __typename?: "User";
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** Get the document in other stages */
  documentInStages: Array<User>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** Flag to determine if user is active or not */
  isActive: Scalars["Boolean"];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: UserKind;
  /** The username */
  name: Scalars["String"];
  /** Profile Picture url */
  picture?: Maybe<Scalars["String"]>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** System stage field */
  stage: Stage;
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
};

/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: "UserConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  Member = "MEMBER",
  Pat = "PAT",
  Public = "PUBLIC",
  Webhook = "WEBHOOK",
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** All values that are not equal to given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  picture_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
};

export type UserNotification = Node & {
  __typename?: "UserNotification";
  countryCodes: Array<CountryCode>;
  /** The time the document was created */
  createdAt: Scalars["DateTime"];
  /** User that created this document */
  createdBy?: Maybe<User>;
  /** Get the document in other stages */
  documentInStages: Array<UserNotification>;
  /** List of UserNotification versions */
  history: Array<Version>;
  /** The unique identifier */
  id: Scalars["ID"];
  /** System Locale field */
  locale: Locale;
  /** Get the other localizations for this document */
  localizations: Array<UserNotification>;
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars["DateTime"]>;
  /** User that last published this document */
  publishedBy?: Maybe<User>;
  scheduledIn: Array<ScheduledOperation>;
  /** System stage field */
  stage: Stage;
  text: Scalars["String"];
  /** The time the document was updated */
  updatedAt: Scalars["DateTime"];
  /** User that last updated this document */
  updatedBy?: Maybe<User>;
};

export type UserNotificationCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type UserNotificationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type UserNotificationDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"];
  inheritLocale?: Scalars["Boolean"];
  stages?: Array<Stage>;
};

export type UserNotificationHistoryArgs = {
  limit?: Scalars["Int"];
  skip?: Scalars["Int"];
  stageOverride?: InputMaybe<Stage>;
};

export type UserNotificationLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"];
  locales?: Array<Locale>;
};

export type UserNotificationPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type UserNotificationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type UserNotificationScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]>;
  before?: InputMaybe<Scalars["String"]>;
  first?: InputMaybe<Scalars["Int"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  last?: InputMaybe<Scalars["Int"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type UserNotificationUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

export type UserNotificationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type UserNotificationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserNotificationWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserNotificationConnection = {
  __typename?: "UserNotificationConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserNotificationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserNotificationCreateInput = {
  countryCodes?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<UserNotificationCreateLocalizationsInput>;
  /** text input for default locale (de) */
  text: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UserNotificationCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  text: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UserNotificationCreateLocalizationInput = {
  /** Localization input */
  data: UserNotificationCreateLocalizationDataInput;
  locale: Locale;
};

export type UserNotificationCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<UserNotificationCreateLocalizationInput>>;
};

export type UserNotificationCreateManyInlineInput = {
  /** Connect multiple existing UserNotification documents */
  connect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  /** Create and connect multiple existing UserNotification documents */
  create?: InputMaybe<Array<UserNotificationCreateInput>>;
};

export type UserNotificationCreateOneInlineInput = {
  /** Connect one existing UserNotification document */
  connect?: InputMaybe<UserNotificationWhereUniqueInput>;
  /** Create and connect one UserNotification document */
  create?: InputMaybe<UserNotificationCreateInput>;
};

/** An edge in a connection. */
export type UserNotificationEdge = {
  __typename?: "UserNotificationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: UserNotification;
};

/** Identifies documents */
export type UserNotificationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserNotificationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserNotificationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserNotificationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countryCodes_contains_all?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countryCodes_contains_none?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countryCodes_contains_some?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countryCodes_not?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<UserNotificationWhereStageInput>;
  documentInStages_none?: InputMaybe<UserNotificationWhereStageInput>;
  documentInStages_some?: InputMaybe<UserNotificationWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum UserNotificationOrderByInput {
  CountryCodesAsc = "countryCodes_ASC",
  CountryCodesDesc = "countryCodes_DESC",
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  TextAsc = "text_ASC",
  TextDesc = "text_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type UserNotificationUpdateInput = {
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Manage document localizations */
  localizations?: InputMaybe<UserNotificationUpdateLocalizationsInput>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars["String"]>;
};

export type UserNotificationUpdateLocalizationDataInput = {
  text?: InputMaybe<Scalars["String"]>;
};

export type UserNotificationUpdateLocalizationInput = {
  data: UserNotificationUpdateLocalizationDataInput;
  locale: Locale;
};

export type UserNotificationUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<UserNotificationCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<UserNotificationUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<UserNotificationUpsertLocalizationInput>>;
};

export type UserNotificationUpdateManyInlineInput = {
  /** Connect multiple existing UserNotification documents */
  connect?: InputMaybe<Array<UserNotificationConnectInput>>;
  /** Create and connect multiple UserNotification documents */
  create?: InputMaybe<Array<UserNotificationCreateInput>>;
  /** Delete multiple UserNotification documents */
  delete?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  /** Disconnect multiple UserNotification documents */
  disconnect?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing UserNotification documents */
  set?: InputMaybe<Array<UserNotificationWhereUniqueInput>>;
  /** Update multiple UserNotification documents */
  update?: InputMaybe<Array<UserNotificationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple UserNotification documents */
  upsert?: InputMaybe<Array<UserNotificationUpsertWithNestedWhereUniqueInput>>;
};

export type UserNotificationUpdateManyInput = {
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<UserNotificationUpdateManyLocalizationsInput>;
  /** text input for default locale (de) */
  text?: InputMaybe<Scalars["String"]>;
};

export type UserNotificationUpdateManyLocalizationDataInput = {
  text?: InputMaybe<Scalars["String"]>;
};

export type UserNotificationUpdateManyLocalizationInput = {
  data: UserNotificationUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type UserNotificationUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<UserNotificationUpdateManyLocalizationInput>>;
};

export type UserNotificationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: UserNotificationUpdateManyInput;
  /** Document search */
  where: UserNotificationWhereInput;
};

export type UserNotificationUpdateOneInlineInput = {
  /** Connect existing UserNotification document */
  connect?: InputMaybe<UserNotificationWhereUniqueInput>;
  /** Create and connect one UserNotification document */
  create?: InputMaybe<UserNotificationCreateInput>;
  /** Delete currently connected UserNotification document */
  delete?: InputMaybe<Scalars["Boolean"]>;
  /** Disconnect currently connected UserNotification document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
  /** Update single UserNotification document */
  update?: InputMaybe<UserNotificationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single UserNotification document */
  upsert?: InputMaybe<UserNotificationUpsertWithNestedWhereUniqueInput>;
};

export type UserNotificationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: UserNotificationUpdateInput;
  /** Unique document search */
  where: UserNotificationWhereUniqueInput;
};

export type UserNotificationUpsertInput = {
  /** Create document if it didn't exist */
  create: UserNotificationCreateInput;
  /** Update document if it exists */
  update: UserNotificationUpdateInput;
};

export type UserNotificationUpsertLocalizationInput = {
  create: UserNotificationCreateLocalizationDataInput;
  locale: Locale;
  update: UserNotificationUpdateLocalizationDataInput;
};

export type UserNotificationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: UserNotificationUpsertInput;
  /** Unique document search */
  where: UserNotificationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserNotificationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type UserNotificationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserNotificationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserNotificationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserNotificationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  countryCodes?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains *all* items provided to the filter */
  countryCodes_contains_all?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  countryCodes_contains_none?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array contains at least one item provided to the filter */
  countryCodes_contains_some?: InputMaybe<Array<CountryCode>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  countryCodes_not?: InputMaybe<Array<CountryCode>>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<UserNotificationWhereStageInput>;
  documentInStages_none?: InputMaybe<UserNotificationWhereStageInput>;
  documentInStages_some?: InputMaybe<UserNotificationWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  text?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  text_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserNotificationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserNotificationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserNotificationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserNotificationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserNotificationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References UserNotification record uniquely */
export type UserNotificationWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export enum UserOrderByInput {
  CreatedAtAsc = "createdAt_ASC",
  CreatedAtDesc = "createdAt_DESC",
  IdAsc = "id_ASC",
  IdDesc = "id_DESC",
  IsActiveAsc = "isActive_ASC",
  IsActiveDesc = "isActive_DESC",
  KindAsc = "kind_ASC",
  KindDesc = "kind_DESC",
  NameAsc = "name_ASC",
  NameDesc = "name_DESC",
  PictureAsc = "picture_ASC",
  PictureDesc = "picture_DESC",
  PublishedAtAsc = "publishedAt_ASC",
  PublishedAtDesc = "publishedAt_DESC",
  UpdatedAtAsc = "updatedAt_ASC",
  UpdatedAtDesc = "updatedAt_DESC",
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars["Boolean"]>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values that are not equal to given value. */
  id_not?: InputMaybe<Scalars["ID"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  /** All values that are not equal to given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** All values that are not equal to given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  name_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars["String"]>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values that are not equal to given value. */
  picture_not?: InputMaybe<Scalars["String"]>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars["String"]>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars["String"]>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars["String"]>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars["String"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not equal to given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]>;
};

export type Version = {
  __typename?: "Version";
  createdAt: Scalars["DateTime"];
  id: Scalars["ID"];
  revision: Scalars["Int"];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars["ID"];
  revision: Scalars["Int"];
  stage: Stage;
};

export enum _FilterKind {
  And = "AND",
  Not = "NOT",
  Or = "OR",
  Contains = "contains",
  ContainsAll = "contains_all",
  ContainsNone = "contains_none",
  ContainsSome = "contains_some",
  EndsWith = "ends_with",
  Eq = "eq",
  EqNot = "eq_not",
  Gt = "gt",
  Gte = "gte",
  In = "in",
  JsonPathExists = "json_path_exists",
  JsonValueRecursive = "json_value_recursive",
  Lt = "lt",
  Lte = "lte",
  NotContains = "not_contains",
  NotEndsWith = "not_ends_with",
  NotIn = "not_in",
  NotStartsWith = "not_starts_with",
  RelationalEvery = "relational_every",
  RelationalNone = "relational_none",
  RelationalSingle = "relational_single",
  RelationalSome = "relational_some",
  Search = "search",
  StartsWith = "starts_with",
}

export enum _MutationInputFieldKind {
  Enum = "enum",
  Relation = "relation",
  RichText = "richText",
  RichTextWithEmbeds = "richTextWithEmbeds",
  Scalar = "scalar",
  Union = "union",
  Virtual = "virtual",
}

export enum _MutationKind {
  Create = "create",
  Delete = "delete",
  DeleteMany = "deleteMany",
  Publish = "publish",
  PublishMany = "publishMany",
  SchedulePublish = "schedulePublish",
  ScheduleUnpublish = "scheduleUnpublish",
  Unpublish = "unpublish",
  UnpublishMany = "unpublishMany",
  Update = "update",
  UpdateMany = "updateMany",
  Upsert = "upsert",
}

export enum _OrderDirection {
  Asc = "asc",
  Desc = "desc",
}

export enum _RelationInputCardinality {
  Many = "many",
  One = "one",
}

export enum _RelationInputKind {
  Create = "create",
  Update = "update",
}

export enum _RelationKind {
  Regular = "regular",
  Union = "union",
}

export enum _SystemDateTimeFieldVariation {
  Base = "base",
  Combined = "combined",
  Localization = "localization",
}
