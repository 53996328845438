import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { setCookiesAction } from "../actions/layoutActions";

const CookieBanner: React.FC = () => {
  const layoutCookies = useSelector((state: State) => state.layout.cookies);

  if (layoutCookies === "no" || layoutCookies === "yes") {
    return null;
  }

  const setCookiesYes = function (e) {
    setCookiesAction("yes");
  };
  const setCookiesNo = function (e) {
    setCookiesAction("no");
  };

  return (
    <div className="cookie-banner">
      <div className="cookie-banner__container">
        <p>
          {`${i18next.t("WE CARE ABOUT YOUR DATA")} `}
          <a
            href={i18next.t("URL PATH TERMS OF USE")}
            title={i18next.t("TERMS OF USE")}
            target="_blank"
            rel="noreferrer"
          >
            <strong>{i18next.t("TERMS OF USE")}</strong>
          </a>
        </p>

        <div className="btn-wrap">
          <button
            type="submit"
            onClick={setCookiesNo}
            className="btn transparent"
            value={`${i18next.t("NO THANKS")}`}
          >
            {`${i18next.t("NO THANKS")}.`}
          </button>

          <button
            type="submit"
            onClick={setCookiesYes}
            className="btn "
            value={`${i18next.t("ACCEPT")}`}
          >
            {`${i18next.t("ACCEPT")}!`}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
