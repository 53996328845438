import React from "react";
import ProductTable from "./ProductTable";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";
import DetailTableWrapper from "./DetailTableWrapper";
import Paragraphs from "./Paragraphs";
import ProductInfo from "./ProductInfo";
import { getProductGroupTitle } from "../../graphcms/scripts/productGroup";
import { getMatchingProducts } from "../scripts/product";

interface Props {
  productGroup: ProductGroup;
  relatedCategory?: Category;
}

const DetailRelated: React.FC<Props> = ({ productGroup, relatedCategory }) => {
  if (!productGroup) {
    return null;
  }

  // prevent render when no matching products are found
  // https://intelliact.zendesk.com/agent/tickets/6142
  if (getMatchingProducts(productGroup, relatedCategory).length === 0) {
    return null;
  }

  return (
    <div className="related-product">
      <div className="block-subtitle">{getProductGroupTitle(productGroup)}</div>
      <DetailTableWrapper productGroup={productGroup}>
        <ProductTable productGroup={productGroup} relatedCategory={relatedCategory} />
        <Paragraphs item={productGroup} />
        <ProductInfo productGroup={productGroup} />
      </DetailTableWrapper>
    </div>
  );
};

export default DetailRelated;
