import { Batch, ConsignmentStockResponse, InventoryItem } from "../vo/ConsignmentStock";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useInventoryQuery } from "../services/consignmentStockApi";
import { FetchBaseQueryError, skipToken } from "@reduxjs/toolkit/query";
import { initConsignmentStockCheckout } from "consignment-stock/actions/consignmentStockActions";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { PARAMS } from "router/constants/Params";
import { SerializedError } from "@reduxjs/toolkit";
import { getLineItemsQuantityMap, QuantityRecord } from "consignment-stock/scripts/inventory";
import { getIdFromSkuAndBatchNumber } from "consignment-stock/scripts/id";
import { getCountry } from "../../router/scripts/params";

function evalIsInitChecked(product, batch: Batch, skuBatchNumberCount: QuantityRecord, i: number) {
  // id from product and batch
  const id = getIdFromSkuAndBatchNumber(product?.sku, batch?.batch_number ?? "");
  // calculate initChecked
  let initChecked = false;
  if (skuBatchNumberCount[id]) {
    if (skuBatchNumberCount[id] >= i + 1) {
      initChecked = true;
    }
  }
  return initChecked;
}

export function useInventory(options: any = undefined): {
  consignmentStock: ConsignmentStockResponse | undefined;
  inventoryItems: InventoryItem[];
  isFetching: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
} {
  const graphcmsProductsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const customerId = useSelector((state: State) => state.account.customer?.id);
  const lineItemsSkus = useSelector(
    (state: State) => state?.consignmentStock?.order?.lineItemsSkus,
  );
  const {
    data: consignmentStock = [],
    isFetching,
    error,
  } = useInventoryQuery(customerId ?? skipToken, options);

  const inventoryItems: InventoryItem[] = useMemo(
    function mapInventoryPositions() {
      if (consignmentStock.length === 0) return [];
      if (!graphcmsProductsIndex) return [];

      const inventoryItems: InventoryItem[] = [];

      // build skuBatchNumberCount for deciding if checkbox is initially checked
      const skuBatchNumberCount = getLineItemsQuantityMap(lineItemsSkus ?? []);

      consignmentStock.forEach(position => {
        const product = graphcmsProductsIndex?.[position?.attributes?.sku_code];
        if (!product) return;

        position.attributes.metadata.batches.forEach(batch => {
          for (let i = 0; i < batch.quantity; i++) {
            const isInitChecked = evalIsInitChecked(product, batch, skuBatchNumberCount, i);

            inventoryItems.push({
              ...position,
              name: product?.name ?? "",
              sku_code: position.attributes.sku_code,
              expiry_date: batch.expiry_date,
              batch_number: batch.batch_number,
              pfs: product.pfs,
              // handle "PF 3.5" to return only "3.5"
              pfsByNumber: product.pfs.map(pf => pf.name.split(" ")[1]),
              isInitChecked,
            });
          }
        });
      });

      return inventoryItems;
    },
    [consignmentStock, lineItemsSkus, graphcmsProductsIndex],
  );

  return { consignmentStock, inventoryItems, isFetching, error };
}

function useHasCst(): boolean {
  // customer without a consignment stock won't have a "cst" attribute in metadata
  const cst = useSelector((state: State) => state.account.customer?.attributes?.metadata?.cst);
  return Boolean(cst);
}

export function useShowConsignmentStock(): boolean {
  const hasCst = useHasCst();
  const cstCountryCodesString =
    process.env.REACT_APP_FEATURE_FLAG_CONSIGNMENT_STOCK_COUNTRY_CODES || "";
  const cstCountryCodes = cstCountryCodesString.split(",");
  if (cstCountryCodes.includes(getCountry())) {
    return hasCst;
  }
  return false;
}

export function useInitConsignmentStockCheckout(): void {
  const params = useParams();
  const consignmentStockOrderId = params[PARAMS.ORDER_ID];

  useEffect(() => {
    if (consignmentStockOrderId) {
      initConsignmentStockCheckout(consignmentStockOrderId).then();
    }
  }, [consignmentStockOrderId]);
}
