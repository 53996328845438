import Locale from "../../common/constants/Locale";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import DropdownListItem from "../../common/components/DropdownListItem";
import { langSwitch } from "../actions/layoutActions";
import { PARAMS } from "../../router/constants/Params";
import i18next from "i18next";

const Languages: React.FC = () => {
  const params = useParams();

  const [open, setOpen] = useState(false);

  async function handleSwitch(lang: string) {
    setOpen(false);

    await langSwitch(lang);
  }

  return (
    <li
      className={"nav-list__item has-dropdown " + (open ? "open" : "")}
      onBlur={() => setOpen(false)}
      tabIndex={0}
    >
      <div
        className="dropdown-link"
        onClick={() => setOpen(!open)}
        title={i18next.resolvedLanguage}
      >
        {params?.[PARAMS.LANGUAGE]?.toUpperCase() ?? Locale.GERMAN.toUpperCase()}
        <span className="dropdown-link-font icon-font icon-font__arrow-down" />
      </div>

      {open && (
        <div className="dropdown-list-wrapper">
          <ul className="dropdown-list">
            {Object.values(Locale).map(locale => {
              if (locale !== (params?.[PARAMS.LANGUAGE] ?? Locale.GERMAN)) {
                return (
                  <DropdownListItem
                    text={i18next.t(`LOCALE_${String(locale).toUpperCase()}`)}
                    value={locale}
                    key={locale}
                    onClick={handleSwitch}
                  />
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </li>
  );
};

export default Languages;
