export enum PATH {
  ACCOUNT = "account",
  ADVANCE_SALE = "advance-sale",
  CATEGORY = "category",
  CART = "cart",
  CUSTOMERS = "customers",
  CHECKOUT = "checkout",
  CONFIGURATOR = "configurator",
  SHIPPING_PAYMENT = "shipping-payment",
  LOGIN = "login",
  ONBOARDING = "onboarding",
  ORDER = "order",
  ORDERS = "orders",
  PRODUCTGROUP = "productgroup",
  PRODUCT = "product",
  RESET_PASSWORD = "reset-password",
  REPORT = "report",
  SALES_AGENT = "sales-agent",
  SALES_AGENT_AUTH_CALLBACK = "sales-agent-auth-callback",
  SCANTOCART = "scan-to-cart",
  SEARCH = "search",
  SIGNUP = "signup",
  VERIFICATION = "verification",
  CONSIGNMENT_STOCK = "consignment-stock",
  ADDRESS = "address",
}
