import React from "react";
import i18next from "i18next";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { PATH } from "../../router/constants/Path";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { useShowConsignmentStock } from "consignment-stock/hooks";
import UserNotifications from "../../layout/components/UserNotifications";

interface Props {
  activeLi: string;
  customerId: string;
}

const MobileHeaderAccount: React.FC<Props> = ({ activeLi, customerId }) => {
  const langCountry = useLangCountry();
  const onChange = function (e) {
    console.log(e);
    if (e.target.value === PATH.ACCOUNT) {
      boundReplaceLocObj({ pathname: `${langCountry}/${PATH.ACCOUNT}/${customerId}` });
    }
    if (e.target.value === PATH.ORDERS) {
      boundReplaceLocObj({
        pathname: `${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.ORDERS}`,
      });
    }
    if (e.target.value === PATH.REPORT) {
      boundReplaceLocObj({
        pathname: `${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.REPORT}`,
      });
    }
    if (e.target.value === PATH.ADVANCE_SALE) {
      boundReplaceLocObj({
        pathname: `${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.ADVANCE_SALE}`,
      });
    }
    if (e.target.value === PATH.CONSIGNMENT_STOCK) {
      boundReplaceLocObj({
        pathname: `${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.CONSIGNMENT_STOCK}`,
      });
    }
  };

  return (
    <div className="mobile-header">
      <div className="select-wrap">
        <select
          className="select"
          id="select-category"
          name="select-category"
          aria-expanded="false"
          aria-hidden="false"
          onChange={onChange}
          value={activeLi}
        >
          <option key={PATH.ACCOUNT} value={PATH.ACCOUNT} aria-selected={false}>
            {i18next.t("ACCOUNT")}
          </option>
          <option key={PATH.ORDERS} value={PATH.ORDERS} aria-selected={false}>
            {i18next.t("ORDER", { count: 0 })}
          </option>
          <option key={PATH.REPORT} value={PATH.REPORT} aria-selected={false}>
            {i18next.t("REPORT")}
          </option>
          {process.env.REACT_APP_FEATURE_FLAG_ADVANCE_SALE && (
            <option key={PATH.ADVANCE_SALE} value={PATH.ADVANCE_SALE} aria-selected={false}>
              {i18next.t("ADVANCE SALE")}
            </option>
          )}
          {useShowConsignmentStock() && (
            <option
              key={PATH.CONSIGNMENT_STOCK}
              value={PATH.CONSIGNMENT_STOCK}
              aria-selected={false}
            >
              {i18next.t("CONSIGNMENT STOCK")}
            </option>
          )}
        </select>
        <UserNotifications />
      </div>
    </div>
  );
};

export default MobileHeaderAccount;
