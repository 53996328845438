import React from "react";
import i18next from "i18next";

const LoadingText: React.FC = () => {
  return (
    <div className="lead">
      <div className="lead__label">{i18next.t("LOADING") + " ..."}</div>
    </div>
  );
};

export default LoadingText;
