import React from "react";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";

const GridText: React.FC<{ item: Category | ProductGroup }> = ({ item }) => {
  const content = item?.tileDescription?.split("\n").map((el, i) => {
    // case to convert to data table
    if (el.includes("|")) {
      const [title, data] = el.split("|");

      return (
        <React.Fragment key={i}>
          <dt className="dl-sml__dt">{title}</dt>
          <dd className="dl-sml__dd">{data}</dd>
        </React.Fragment>
      );
    }

    // default text case
    return (
      <div key={i} className="grid__text">
        {el}
      </div>
    );
  });

  let title = item?.name ?? "";
  if (item && "tileTitle" in item) {
    title = item?.tileTitle ?? "";
  }

  return (
    <div className="grid__text-wrap">
      <h3 className="grid__title">{title}</h3>
      <dl className="dl-sml">{content}</dl>
    </div>
  );
};

export default GridText;
