import React, { useLayoutEffect } from "react";
import { PATH } from "../../constants/Path";
import { useLangCountry } from "../../hooks/useLangCountry";
import { useNavigate } from "react-router-dom";

const ReLogin: React.FC = () => {
  const langCountry = useLangCountry();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    navigate(`${langCountry}/${PATH.LOGIN}`);
  }, [langCountry, navigate]);

  return null;
};

export default ReLogin;
