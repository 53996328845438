import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import MainHeader from "../../layout/components/Col/MainHeader";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { loadOrder } from "../actions/orderActions";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import Order from "../../state/vo/Order";
import { Addresses } from "../../commercelayer/vo/addresses";
import { getBillingAddress, getShippingAddress } from "../../checkout/scripts/addresses";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import { getPaymentMethod, getShippingMethod } from "../../checkout/scripts/shippingpayment";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import OrderAddressSection from "./OrderAddressSection";
import OrderShippingPaymentSection from "./OrderShippingPaymentSection";
import CartHeader from "../../cart/components/CartHeader";
import LineItemList from "../../cart/components/LineItemList";
import OrderSumList from "./OrderSumList";
import OrderTotal from "./OrderTotal";
import { getDateFormatted } from "../../common/scripts/format/date";
import OrderStatusSection from "./OrderStatusSection";
import AvailabilityAlertItem from "../../common/components/AvailabilityAlertItem";
import LoadingPage from "../../layout/components/LoadingPage";
import { showPaymentButton } from "../scripts/payment";
import { OrderType } from "../../common/constants/OrderType";
import ConsignmentStockOrderHeader from "../../consignment-stock/components/ConsignmentStockOrderHeader";

const OrderDetail: React.FC = () => {
  const params = useParams();
  const orderId = params[PARAMS.ORDER_ID];
  const order: Order = useSelector((state: State) => state.order);
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);

  const shippingAddress: Addresses | null = getShippingAddress(order, order.addresses);
  const billingAddress: Addresses | null = getBillingAddress(order, order.addresses);

  const shippingMethod: ShippingMethods | null = getShippingMethod(order);
  const paymentMethod: PaymentMethods | null = getPaymentMethod(order);

  useEffect(() => {
    if (orderId) {
      loadOrder(orderId);
    }
  }, [orderId]);

  if (order.attributes === null || shippingMethod === null || paymentMethod === null) {
    return <LoadingPage />;
  }

  // Fallback orderType is ORDER
  const orderType = order.attributes?.metadata?.type || OrderType.ORDER;

  let showUnitAmount = true;
  let showBatchNumber = false;
  if (orderType === OrderType.CST_ORDER) {
    showUnitAmount = false;
    showBatchNumber = true;
  }

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <MainHeader
        title={`${i18next.t(orderType, { count: 1 })} #${order.attributes?.number} ${i18next.t(
          "FROM",
        )} ${getDateFormatted(order.attributes?.placed_at)}`}
        desktopShowClose={false}
        toHomeOnClose={true}
      />
      <Body>
        <Middle>
          <OrderStatusSection
            orderId={order.id}
            orderAttributes={order.attributes}
            showPaymentButton={showPaymentButton(order)}
          />
          <OrderAddressSection
            orderId={orderId}
            orderType={orderType}
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            showEditLink={false}
            customerId={null}
          />
          <OrderShippingPaymentSection
            orderId={orderId}
            orderType={orderType}
            shippingMethod={shippingMethod}
            paymentMethod={paymentMethod}
            showEditLink={false}
            customerId={null}
          />

          <div className="grid-wrap grid-wrap--checkout">
            <div className="checkout-account-wrap">
              {orderType === OrderType.ORDER && <h2 className="col-title">{i18next.t("CART")}</h2>}
              {orderType === OrderType.CST_ORDER && (
                <h2 className="col-title">{i18next.t("PRODUCT LIST")}</h2>
              )}
              <div className="cart cart--checkout cart--confirmation ">
                <form className="form cart__form" action="#">
                  <div className="cart-wrap">
                    {orderType === OrderType.ORDER && <CartHeader />}
                    {orderType === OrderType.CST_ORDER && <ConsignmentStockOrderHeader />}
                    {categoriesTree.map((category, index) => (
                      <LineItemList
                        cartId={order.id}
                        category={category}
                        lineItems={order.lineItemsSkus}
                        showUnitAmount={showUnitAmount}
                        showBatchNumber={showBatchNumber}
                        showAvailabilityAlert={false}
                        quantityIsEditable={false}
                        key={index}
                      />
                    ))}
                    <LineItemList
                      cartId={order.id}
                      category={null}
                      lineItems={order.lineItemsSkus}
                      showUnitAmount={showUnitAmount}
                      showBatchNumber={showBatchNumber}
                      showAvailabilityAlert={false}
                      quantityIsEditable={false}
                      key={-1}
                    />
                  </div>
                </form>

                <div className="cart-checkout">
                  <div className="grid-1-2">
                    {order.attributes?.metadata["s_sdo_hdr-bstnk"] && (
                      <div className="text">
                        <p className="text__title">{i18next.t("ORDER REFERENCE")}</p>
                        <p className="text__text">
                          {order.attributes?.metadata["s_sdo_hdr-bstnk"]}
                        </p>
                      </div>
                    )}
                    {order.attributes?.metadata?.skuCodesWithAvailabilityAlert?.length > 0 && (
                      <AvailabilityAlertItem />
                    )}
                  </div>
                  <div className="grid-1-2">
                    <OrderSumList order={order} />
                  </div>
                  <div className="cart-checkout__total-wrap">
                    <OrderTotal order={order} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default OrderDetail;
