import { AttributeValue, Product, ProductGroup } from "../vo/graphCMS";
import { devLogDebugOnce } from "../../common/scripts/logger/log";

export function getAttributeValueValue(attrValue: AttributeValue): string {
  const value = attrValue?.numberValue?.toFixed(1) ?? attrValue?.stringValue ?? "";

  return String(value);
}

export function getAttributeValueWithUnit(product: Product, attrName: string): string {
  let value = "";

  const attributeValue = product?.attributeValues?.find(
    attributeValue => attributeValue?.attribute?.name === attrName,
  );

  if (!attributeValue) {
    return value;
  }

  // assign with unit when available (not always the case)
  const unit = attributeValue?.attribute?.unit;

  if (unit) {
    value = `${getAttributeValueValue(attributeValue)}\u202f${unit}`;
  } else {
    value = getAttributeValueValue(attributeValue);
  }

  return value;
}

export function getAttributeValue(product: Product, attrName: string): AttributeValue | null {
  const attributeValues = product?.attributeValues ?? [];

  // find attributeValue
  const attributeValue = attributeValues?.find(attributeValue => {
    return attributeValue?.attribute?.name === attrName;
  });

  if (!attributeValue) {
    devLogDebugOnce(
      `no attributeValue matching for attributeName: (${attrName}) found for product: `,
      product?.name,
      product?.id,
    );

    return null;
  }

  return attributeValue;
}

export function getUniqueSortedAttrValues(productGroup: ProductGroup, attrName: string): string[] {
  // fill out every value of the sorting attribute from the product list
  const allSortingAttrValues: string[] = [];

  productGroup?.products?.forEach(product => {
    const attributeValue = getAttributeValue(product, attrName);

    if (!attributeValue) {
      return;
    }

    const sortedValue = getAttributeValueValue(attributeValue);

    if (!sortedValue) {
      return;
    }

    allSortingAttrValues.push(sortedValue);
  });

  // sort in asc order
  allSortingAttrValues.sort((a, b) => Number(a) - Number(b));

  // get only the unique ones
  return [...new Set(allSortingAttrValues)];
}

export function getUniqueSortedAttrValuesWithUnit(
  productGroup: ProductGroup,
  attrName: string,
): string[] {
  // get first sorted by the value without the unit
  const uniqueSortedAttrValues = getUniqueSortedAttrValues(productGroup, attrName);

  // get the unit if available. for that we look up in the first product's attributeValue
  const product = productGroup?.products?.[0];
  const attributeValue = product?.attributeValues?.find(
    attributeValue => attributeValue?.attribute?.name === attrName,
  );

  // assign with unit when available (not always the case)
  const unit = attributeValue?.attribute?.unit ?? "";

  // assign unit when available
  if (unit) {
    return uniqueSortedAttrValues.map(attrValue => attrValue + " " + unit);
  }

  return uniqueSortedAttrValues;
}
