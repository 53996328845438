import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Account from "../../state/vo/Account";
import { Customer } from "../../commercelayer/vo/customers";
import { Order } from "../../commercelayer/vo/orders";
import { Addresses } from "../../commercelayer/vo/addresses";
import Report from "../../state/vo/Report";
import { Promotion } from "../../commercelayer/vo/promotions";

const name = "account";
const initialState = store.account;

const accountSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCustomer(state: Account, action: PayloadAction<Customer | null>) {
      state.customer = action.payload;
    },
    setOrders(state: Account, action: PayloadAction<Order[]>) {
      state.orders = action.payload;
    },
    setAddresses(state: Account, action: PayloadAction<Addresses[]>) {
      state.addresses = action.payload;
    },
    setPreferences(state: Account, action: PayloadAction<string[] | null>) {
      state.preferences = action.payload;
    },
    setReport(state: Account, action: PayloadAction<Report>) {
      state.report = action.payload;
    },
    setReportLoading(state: Account, action: PayloadAction<boolean>) {
      state.reportLoading = action.payload;
    },
    setAdvanceSalePromotions(state: Account, action: PayloadAction<Promotion[]>) {
      state.advanceSalePromotions = action.payload;
    },
  },
});

export const setCustomer = accountSlice.actions.setCustomer;
export const setOrders = accountSlice.actions.setOrders;
export const setAddresses = accountSlice.actions.setAddresses;
export const setPreferences = accountSlice.actions.setPreferences;
export const setReport = accountSlice.actions.setReport;
export const setReportLoading = accountSlice.actions.setReportLoading;
export const setAdvanceSalePromotions = accountSlice.actions.setAdvanceSalePromotions;

export default accountSlice.reducer;
