import { getState } from "../../state/scripts/state";
import { FALLBACK, PARAMS } from "../constants/Params";
import { Locale } from "../../graphcms/vo/graphCMS";
import Country from "../../common/constants/Country";

// IMPORTANT NOTE:
// these functions here are helpers functions to get url params in places likes actions or other scripts
// they must not be used inside a react component. use `useParams` inside a react component
// in a react render cycle they 'lag' behind one step and therefore `useParams` will be the right choice.
function getParamFromState(param: PARAMS): string {
  return getState()?.urlParams?.params?.[param] ?? FALLBACK[param];
}

export function getLang(): string {
  return getParamFromState(PARAMS.LANGUAGE);
}

export function getCountry(): string {
  const paramsCountry = getParamFromState(PARAMS.COUNTRY) || FALLBACK[PARAMS.COUNTRY];
  return paramsCountry.toUpperCase();
}

export function getCategoryId(): string {
  return getParamFromState(PARAMS.CATEGORY_ID);
}

export function getCategorySlug(): string {
  return getParamFromState(PARAMS.CATEGORY_SLUG);
}

export function getProductGroupId(): string {
  return getParamFromState(PARAMS.PRODUCTGROUP_ID);
}

export function getProductGroupSlug(): string {
  return getParamFromState(PARAMS.PRODUCTGROUP_SLUG);
}

export function getSku(): string {
  return getParamFromState(PARAMS.SKU);
}

export function getProductSlug(): string {
  return getParamFromState(PARAMS.PRODUCT_SLUG);
}

// begin verification for certain params
export function isValidLanguage(): boolean {
  return String(Object.values(Locale)).toUpperCase().includes(getLang().toUpperCase());
}

export function isValidCountry(): boolean {
  return String(Object.values(Country)).toUpperCase().includes(getCountry());
}
