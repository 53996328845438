import React from "react";
import { ThommenLogoHeader } from "../../common/components/logo/Thommen";
import Languages from "./Languages";
import Countries from "./Countries";
import NavService from "./NavService";
import NavListItemIconA from "./NavListItemIconA";
import Search from "./Search";
import NavMetaBig from "./NavMetaBig";
import i18next from "i18next";
import NavListItemAccount from "./NavListItemAccount";
import CartButton from "./CartButton";
import SalesAgentTopBar from "./SalesAgentTopBar";

const Header: React.FC = props => (
  <>
    <SalesAgentTopBar />
    <header className="header">
      <div className="header__top">
        <div className="header-container">
          <NavService />
          <nav className="nav-meta">
            <ul className="nav-list">
              <NavListItemIconA
                text={i18next.t("DOWNLOAD CENTER")}
                href={
                  i18next.t("URL WEB ROOT") +
                  i18next.t("URL PATH ABOUT US") +
                  i18next.t("URL PATH DOWNLOAD")
                }
                icon={"download"}
              />
              <NavListItemIconA
                text={i18next.t("CONTACT", { count: 1 })}
                href={
                  i18next.t("URL WEB ROOT") +
                  i18next.t("URL PATH ABOUT US") +
                  i18next.t("URL PATH CONTACT")
                }
                icon={"contact"}
              />
              <Languages />
              <NavListItemAccount />
              <Countries />
            </ul>
          </nav>
          <CartButton />
        </div>
      </div>
      <div className="header__bottom">
        <div className="header-container">
          <div className="logo header__logo">
            <ThommenLogoHeader />
          </div>
          <Search />
          <NavMetaBig />
        </div>
      </div>
    </header>
  </>
);

export default Header;
