import { toast } from "react-toastify";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import LayerIo from "../../common/services/LayerIo";
import { AddressesAttributes } from "../../commercelayer/vo/addresses";
import i18next from "i18next";
import { gtmPushCustomerRegistration } from "../../common/scripts/tagManager/dataLayer";

export async function signup(email: string, password: string, address?: AddressesAttributes) {
  const response = await LayerIo.requestJsonRpc<any, any>(
    "commercelayer-login",
    "serviceCreateCustomer",
    {
      email: email,
      password: password,
      address: address,
    },
  );
  if (response.data.success) {
    toast.success(i18next.t("REGISTRATION CUSTOMER MAIL TEXT"), {
      position: "top-center",
      autoClose: false,
    });
    boundReplaceLocObj({ pathname: getLangCountry() });

    gtmPushCustomerRegistration();
  } else {
    const errors = response.data.errors;
    for (let i = 0; i < errors.length; i++) {
      console.error(errors[i].detail);
      const message = i18next.t(`CL_ERROR ${errors[i].detail.toUpperCase()}`);
      toast.warning(message, {
        position: "top-center",
        autoClose: 10000,
      });
    }
    boundReplaceLocObj({ pathname: getLangCountry() });
  }
}
