import React from "react";
import { useSelectedProduct } from "../hooks/product";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import Paragraphs from "./Paragraphs";
import ProductAttributes from "./ProductAttributes";
import ProductLinkList from "./ProductLinkList";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import Price, { PriceUsage } from "../../common/components/Price";
import ProductInfoHeading from "./ProductInfoHeading";

interface Props {
  productGroup: ProductGroup;
}

const ProductInfo: React.FC<Props> = ({ productGroup }) => {
  const product = useSelectedProduct();
  const cartLineItemsSkus = useSelector((state: State) => state.cart.lineItemsSkus);

  if (!product) {
    return null;
  }

  // the selected product belongs not to this productGroup
  if (product?.productGroup?.id !== productGroup?.id) {
    return null;
  }

  return (
    <div className="product-info open">
      <div className="product-info__body">
        <ProductInfoHeading product={product} />
        <div className="list-item__text">{product?.nameSuffix}</div>

        <Price
          usage={PriceUsage.PRODUCT_INFO}
          product={product}
          cartLineItems={cartLineItemsSkus}
        />

        {/* @see: https://3.basecamp.com/3101128/buckets/19695186/todos/3887523110
          product.image && (
          <div className="img-wrap product-info__image">
            <CommonImg handle={product?.image?.handle} alt={product?.name} />
          </div>
        )
        */}

        <ProductAttributes product={product} />

        <Paragraphs item={product} />

        <ProductLinkList />
      </div>
    </div>
  );
};

export default ProductInfo;
