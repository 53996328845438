import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { serviceGetSkuByCode, serviceGetSkuById } from "../services/skus";
import { ResponseSkus, ResponseSkusListWithIncludes } from "../vo/skus";
import { setSkusIndex, setSkusLoading } from "../slices/commercelayerSlice";
import { Price } from "../vo/prices";
import { getState } from "../../state/scripts/state";
import i18next from "i18next";
import { logConsoleOnce } from "../../common/scripts/logger/log";

export async function loadSku(skuCode: string) {
  const accountState = getState().account;
  const commercelayerState = getState().commercelayer;

  // check if skuCode is already loading
  if (commercelayerState.skusLoading[skuCode]) {
    return;
  }
  await dispatch(setSkusLoading({ code: skuCode, loading: true }));

  if (accountState.customer?.id) {
    const responseByCode: ResponseSkusListWithIncludes | null = await serviceGetSkuByCode(skuCode);
    if (responseByCode === null) {
      return;
    }
    const responseByCodeIncluded = responseByCode.included ?? [];
    let price: Price | null = null;
    const responsePriceItems: Price[] = responseByCodeIncluded.filter(function (data) {
      return data.type === "prices";
    });
    if (responsePriceItems.length === 0) {
      logConsoleOnce(i18next.t("NO PRICE DEFINED FOR THE PRODUCT", { skuCode }), "info");
    } else {
      price = responsePriceItems[0];
    }

    let id: string | null = responseByCode.data[0]?.id ?? null;
    let nonBackordersQuantity: number | null = null;
    if (id !== null) {
      const responseById: ResponseSkus | null = await serviceGetSkuById(id);
      if (responseById === null) {
        return;
      }
      const inventory = responseById.data?.attributes?.inventory ?? null;
      if (inventory !== null) {
        const levels = inventory.levels;
        const nonBackordersQuantities: number[] = levels.map(level => {
          const delivery_lead_times = level.delivery_lead_times;
          for (let delivery_lead_time of delivery_lead_times) {
            if (delivery_lead_time.shipping_method) {
              if (delivery_lead_time.shipping_method.name.toUpperCase().includes("BACKORDERS")) {
                return 0;
              }
            }
          }
          return level.quantity;
        });
        nonBackordersQuantity = nonBackordersQuantities.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
        );
      }
    }

    await dispatch(
      setSkusIndex({
        code: skuCode,
        id: id,
        price: price,
        nonBackordersQuantity: nonBackordersQuantity,
      }),
    );
  }
  await dispatch(setSkusLoading({ code: skuCode, loading: false }));
}
