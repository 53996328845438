import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import Order from "../../state/vo/Order";

export function getPaymentMethod(order: Order): PaymentMethods | null {
  const paymentMethods = order.paymentMethods;

  const paymentMethodId = order.relationships?.payment_method?.data?.id;
  if (paymentMethodId) {
    const paymentMethodsFiltered = paymentMethods.filter(function (data) {
      return data.id === paymentMethodId;
    });
    if (paymentMethodsFiltered.length > 0) {
      return paymentMethodsFiltered[0];
    }
  }
  return null;
}

export function getShippingMethod(order: Order): ShippingMethods | null {
  if (order.shipments.length > 0) {
    const shipment = order.shipments[0];
    const shippingMethodId = shipment.relationships?.shipping_method?.data?.id;
    if (shippingMethodId) {
      const shippingMethodsFiltered = order.shippingMethods.filter(function (data) {
        return data.id === shippingMethodId;
      });
      if (shippingMethodsFiltered.length > 0) {
        return shippingMethodsFiltered[0];
      }
    }
  }
  return null;
}

export function filterShippingMethodsNonBackorder(
  shippingMethods: ShippingMethods[],
): ShippingMethods[] {
  return shippingMethods.filter(
    shippingMethod => !shippingMethod.attributes.name.toUpperCase().includes("BACKORDERS"),
  );
}
