import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import { useParams } from "react-router-dom";
import { searchForQPage } from "../actions/searchActions";
import { PARAMS } from "../../router/constants/Params";
import Right from "../../layout/components/Col/Right";
import CartOverlay from "../../cart/components/CartOverlay";
import RightCategory from "../../category/components/RightCategory";
import SearchHit from "./SearchHit";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

const Search: React.FC = () => {
  const params = useParams();
  const searchQ = useSelector((state: State) => state.search.q);
  const searchResult = useSelector((state: State) => state.search.result);
  useEffect(() => {
    searchForQPage(params[PARAMS.SEARCH_TERM]);
  }, [params]);

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <Body>
        <Middle>
          <div className="col-middle">
            <h1 className="col-title">
              {`${i18next.t("RESULTS FOR")}:\u00A0`}
              <strong>{searchQ}</strong>
            </h1>
            <SearchHit
              maxProductResults={0}
              maxProductGroupResults={0}
              searchNbHits={searchResult.nbHits}
              searchProductHits={searchResult.productHits}
              searchProductGroupHits={searchResult.productGroupHits}
            />
          </div>
        </Middle>
        <Right>
          <CartOverlay />
          <RightCategory />
        </Right>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Search;
