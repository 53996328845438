import React from "react";
import { useSelectedProduct } from "../hooks/product";

const ProductLinks: React.FC = () => {
  const product = useSelectedProduct();

  return (
    <>
      {product?.links.map((link, i) => {
        return (
          <li key={i}>
            <a
              href={link?.url}
              className="icon-link"
              target="_blank"
              rel="noreferrer"
              title={link?.name ?? ""}
            >
              <span className="icon-link-font icon-font icon-font__video" />
              {link?.name}
            </a>
          </li>
        );
      })}
    </>
  );
};

export default ProductLinks;
