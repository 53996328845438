import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import CstMessage from "../../cart/components/CstMessage";
import { useShowConsignmentStock } from "../../consignment-stock/hooks";

const CstMessageCheckout: React.FC = () => {
  const showConsignmentStock = useShowConsignmentStock();
  const accountCustomer = useSelector((state: State) => state.account.customer);

  if (showConsignmentStock && accountCustomer?.id) {
    return (
      <p className="item-alert" style={{ color: "red" }}>
        <CstMessage />
      </p>
    );
  }
  return null;
};

export default CstMessageCheckout;
