import { ResponseOrdersWithIncludes } from "../vo/orders";
import { LineItems } from "../vo/lineItems";
import { PaymentMethods } from "../vo/paymentMethods";
import { ShippingMethods } from "../vo/shippingMethods";
import { ResponseShipmentsListWithIncludes } from "../vo/shipments";
import { Addresses } from "../vo/addresses";
import { SkusIndex } from "../../state/vo/CommerceLayer";
import { getState } from "../../state/scripts/state";
import { Customer } from "../vo/customers";
import { customerIsDistributor } from "../../account/scripts/customer";
import { OWNER_TYPE } from "../../login/constants/ownerType";

export function filterIncludedLineItems(order: ResponseOrdersWithIncludes): LineItems[] {
  const responseIncluded = order.included ?? [];
  return responseIncluded.filter(function (data) {
    return data.type === "line_items";
  });
}

export function filterIncludedPaymentMethods(order: ResponseOrdersWithIncludes): PaymentMethods[] {
  const responseIncluded = order.included ?? [];
  return responseIncluded.filter(function (data) {
    return data.type === "payment_methods";
  });
}

export function filterAllowedPaymentMethods(paymentMethods: PaymentMethods[]): PaymentMethods[] {
  const accountState = getState().account;
  const loginState = getState().login;

  if (accountState.customer?.attributes.metadata["s_dbt_hdr-lifsd"]) {
    if (accountState.customer?.attributes.metadata["s_dbt_hdr-lifsd"] === "Y1") {
      return paymentMethods.filter(paymentMethod => {
        return paymentMethodIdIsOfCreditcardPaymentMethod(paymentMethod.id, paymentMethods);
      });
    }
  }

  if (loginState.token !== null) {
    if (loginState.token.owner_type === OWNER_TYPE.USER) {
      return paymentMethods.filter(paymentMethod => {
        return !paymentMethodIdIsOfCreditcardPaymentMethod(paymentMethod.id, paymentMethods);
      });
    }
  }

  return paymentMethods;
}

export function filterIncludedShippingMethods(
  shipments: ResponseShipmentsListWithIncludes,
): ShippingMethods[] {
  const responseShipmentsIncluded = shipments.included ?? [];
  const responseShipmentsShippingMethods: ShippingMethods[] = responseShipmentsIncluded.filter(
    function (data) {
      return data.type === "shipping_methods";
    },
  );
  responseShipmentsShippingMethods.sort(function (a: ShippingMethods, b: ShippingMethods) {
    if (a.attributes.name.toUpperCase() < b.attributes.name.toUpperCase()) {
      return -1;
    }
    return 1;
  });
  return responseShipmentsShippingMethods;
}

export function filterIncludedAddresses(order: ResponseOrdersWithIncludes): Addresses[] {
  const responseIncluded = order.included ?? [];
  return responseIncluded.filter(function (data) {
    return data.type === "addresses";
  });
}

export function filterSkuCodesWithAvailabilityAlert(
  lineItems: LineItems[],
  commercelayerSkusIndex: SkusIndex,
  customer: Customer | null,
) {
  const lineItemsWithAvailabilityAlert = lineItems.filter(lineItem => {
    return skuIsNotAvailable(
      lineItem.attributes.sku_code,
      lineItem.attributes.quantity,
      commercelayerSkusIndex,
      customer,
    );
  });
  return lineItemsWithAvailabilityAlert.map(lineItem => {
    return lineItem.attributes.sku_code;
  });
}

export function skuIsNotAvailable(
  skuCode: string,
  quantity: number,
  commercelayerSkusIndex: SkusIndex,
  customer: Customer | null,
) {
  const nonBackordersQuantity: number | null =
    commercelayerSkusIndex[skuCode]?.nonBackordersQuantity ?? null;

  // Show availability alert always for quantity > 99
  if (quantity > 99) {
    return true;
  }

  // Show availability alert always for Distributors
  if (customerIsDistributor(customer)) {
    return true;
  }

  // See also AvailabilityAlert
  if (nonBackordersQuantity !== null) {
    if (nonBackordersQuantity === 0) {
      return true;
    }
    if (nonBackordersQuantity < quantity) {
      return true;
    }
  }
  return false;
}

export function paymentMethodIdIsOfCreditcardPaymentMethod(
  id: string,
  paymentMethods: PaymentMethods[],
) {
  for (let i = 0; i < paymentMethods.length; i++) {
    if (id === paymentMethods[i].id) {
      if ("CREDITCARD" === paymentMethods[i].attributes.reference) {
        return true;
      }
    }
  }
  return false;
}
