export enum StorageKeys {
  ACCOUNT = "ACCOUNT",
  BASE_HREF = "BASE_HREF",
  CST_ORDER_ID = "CST_ORDER_ID",
  CART_ID = "CART_ID",
  CART_COUNTER = "CART_COUNTER",
  CART_UPDATED_AT = "CART_UPDATED_AT",
  COOKIES = "COOKIES",
  CUSTOMER = "CUSTOMER",
  LOGIN_TOKEN = "LOGIN_TOKEN",
  LOGIN_TOKEN_MARKET_ALL = "LOGIN_TOKEN_MARKET_ALL",
  SHIPPING_METHOD_ID = "SHIPPING_METHOD_ID",
  TEMPORARY_CLEAR_APP_CACHE = "TEMPORARY_CLEAR_APP_CACHE",
}
