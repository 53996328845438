import { getState } from "../../state/scripts/state";
import { createCart, loadCart, resetCart, setCartData } from "../../cart/actions/cartActions";
import { setPathnameAfterLogin } from "../../login/slices/loginSlice";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { addCustomerAddresses, loadCustomerAddresses } from "../../account/actions/accountActions";
import { getMainAddress } from "../../account/scripts/addresses";
import { Addresses, AddressesAttributes } from "../../commercelayer/vo/addresses";
import { serviceAddRelationToOrder, serviceUpdateOrder } from "../../commercelayer/services/orders";
import { serviceAddShippingMethodToShipments } from "../../commercelayer/services/shipments";
import { ResponseOrdersWithIncludes } from "../../commercelayer/vo/orders";
import { getPaymentMethod, getShippingMethod } from "../scripts/shippingpayment";
import {
  setBstnk,
  setCouponCode,
  setCouponCodeError,
  setGtc,
  setGtcError,
  setOrderProcessing,
  setOrderUpdating,
} from "../slices/checkoutSlice";
import i18next from "i18next";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { serviceCreateWireTransfer } from "../../commercelayer/services/wireTransfers";
import { setCartOpen } from "../../layout/slices/layoutSlice";
import {
  filterSkuCodesWithAvailabilityAlert,
  paymentMethodIdIsOfCreditcardPaymentMethod,
} from "../../commercelayer/scripts/filter";
import LayerIo from "../../common/services/LayerIo";
import { getAccessToken } from "../../commercelayer/scripts/token";
import { startPaymentProcess } from "../../order/actions/orderActions";
import { toast } from "react-toastify";
import { scrollTop } from "../../layout/scripts/scroll";
import { customerIsDistributor } from "../../account/scripts/customer";
import { getLang } from "../../router/scripts/params";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { gtmPushBeginCheckout, gtmPushPurchase } from "../../common/scripts/tagManager/dataLayer";
import { syncShippingMethod } from "../scripts/shippingMethods";
import { logSentryAndConsole, logSentryAndConsoleError } from "../../common/scripts/logger/log";
import { OrderType } from "../../common/constants/OrderType";

export async function initCheckout(cartId?: string) {
  const loginState = getState().login;
  if (!cartId) {
    return;
  }
  if (loginState.token === null) {
    dispatch(setPathnameAfterLogin(window.location.pathname));
    boundReplaceLocObj({ pathname: `${getLangCountry()}/${PATH.LOGIN}` });
    return;
  }

  await loadCart(cartId, false);
  await loadCustomerAddresses();

  // set addresses only if no addresses are set
  let cartState = getState().cart;

  // Add to Google Analytics
  // shipments_count is not 0 after first init
  if (cartState.attributes?.shipments_count === 0) {
    gtmPushBeginCheckout(cartState);
  }

  // check of id is still pending
  if (cartState.attributes?.status !== "pending") {
    const orderNumber: number | undefined = cartState.attributes?.number;
    toast.warning(i18next.t("ORDER CAN NOT BE CHANGED ANYMORE", { orderNumber }), {
      position: "top-center",
    });
    boundReplaceLocObj({ pathname: `${getLangCountry()}` });
    await resetCheckout();
    await resetCart();
    await createCart();
  }

  const mainAddress: Addresses | null = getMainAddress(getState().account.addresses);
  if (mainAddress && cartState.relationships) {
    let responseAddress: ResponseOrdersWithIncludes | null = null;
    if (!cartState.relationships.shipping_address.data) {
      responseAddress = await serviceAddRelationToOrder(
        cartId,
        mainAddress.id,
        "shipping_address",
        "addresses",
      );
    }
    if (!cartState.relationships.billing_address.data) {
      responseAddress = await serviceAddRelationToOrder(
        cartId,
        mainAddress.id,
        "billing_address",
        "addresses",
      );
    }
    if (responseAddress !== null) {
      await setCartData(responseAddress, false);
    }
  }

  // set initial shipping method only if none are set
  if (cartState.shipments && cartState.shippingMethods.length > 0) {
    if (getShippingMethod(cartState) === null) {
      await syncShippingMethod(OrderType.ORDER);
    }
  }

  // set payment method only if no payment method is set
  if (cartState.paymentMethods.length > 0 && cartState.relationships) {
    let responsePaymentMethod: ResponseOrdersWithIncludes | null = null;
    if (!cartState.relationships.payment_method.data) {
      let paymentMethodsIndexToSet = 0;
      // use INVOICE as default if it exists
      for (let i = 0; i < cartState.paymentMethods.length; i++) {
        if (cartState.paymentMethods[i].attributes.reference === "INVOICE") {
          paymentMethodsIndexToSet = i;
          break;
        }
      }
      responsePaymentMethod = await serviceAddRelationToOrder(
        cartId,
        cartState.paymentMethods[paymentMethodsIndexToSet].id,
        "payment_method",
        "payment_methods",
      );
    }
    if (responsePaymentMethod !== null) {
      await setCartData(responsePaymentMethod, false);
    }
  }
}

export async function setShippingAddress(addressId: string) {
  dispatch(setOrderUpdating(true));
  const cartState = getState().cart;
  if (cartState.id) {
    await serviceAddRelationToOrder(cartState.id, addressId, "shipping_address", "addresses");
    await loadCart(cartState.id);
  }
  dispatch(setOrderUpdating(false));
}

export async function addCustomerAddressesAndSetShippingAddress(
  addressesAttributes: AddressesAttributes,
) {
  const addressId = await addCustomerAddresses(addressesAttributes);
  if (addressId !== null) {
    await setShippingAddress(addressId);
  }
}

export async function setShippingMethodToShipmentsOfCart(shippingMethodId: string) {
  dispatch(setOrderUpdating(true));
  const cartState = getState().cart;
  if (cartState.id) {
    const backorderShippingMethod = cartState.shippingMethods.filter(shippingMethod =>
      shippingMethod.attributes.name.toUpperCase().includes("BACKORDERS"),
    )[0];
    const shipments = cartState.shipments;
    for (let i = 0; i < shipments.length; i++) {
      if (i === 0) {
        await serviceAddShippingMethodToShipments(shipments[i].id, shippingMethodId);
      } else {
        await serviceAddShippingMethodToShipments(shipments[i].id, backorderShippingMethod.id);
      }
    }
    await loadCart(cartState.id);
  }
  dispatch(setOrderUpdating(false));
}

export async function setPaymentMethod(paymentMethodId: string) {
  dispatch(setOrderUpdating(true));
  const cartState = getState().cart;
  if (cartState.id !== null) {
    const responsePaymentMethod = await serviceAddRelationToOrder(
      cartState.id,
      paymentMethodId,
      "payment_method",
      "payment_methods",
    );
    if (responsePaymentMethod !== null) {
      await setCartData(responsePaymentMethod, false);
    }
  }
  dispatch(setOrderUpdating(false));
}

export function setGtcAction(gtcChecked: boolean) {
  dispatch(setGtc(gtcChecked));
}

export function setBstnkAction(bstnk: string) {
  dispatch(setBstnk(bstnk));
}

export function setCouponCodeAction(couponCode: string) {
  dispatch(setCouponCode(couponCode));
}

export async function setCouponToOrder() {
  const checkoutState = getState().checkout;
  const cartState = getState().cart;
  dispatch(setCouponCodeError(null));
  const couponCode = checkoutState.couponCode;
  if (couponCode.length === 0) {
    return;
  }

  if (cartState.id) {
    dispatch(setOrderUpdating(true));
    try {
      await serviceUpdateOrder(cartState.id, {
        coupon_code: couponCode,
      });
    } catch (e) {
      const err = e as any;
      console.warn(err.message);
      const errors = err.response.errors ?? [];
      for (let i = 0; i < errors.length; i++) {
        if (errors[i].code === "VALIDATION_ERROR") {
          const couponCodeError = `${i18next.t("INVALID COUPON CODE")} ${couponCode}`;
          dispatch(setCouponCodeError(couponCodeError));
          break;
        }
      }
    }
    await loadCart(cartState.id);
    dispatch(setOrderUpdating(false));
  }
}

export async function removeCouponFromOrder() {
  const cartState = getState().cart;
  dispatch(setCouponCodeError(null));
  if (cartState.id) {
    dispatch(setOrderUpdating(true));
    dispatch(setCouponCode(""));
    await serviceUpdateOrder(cartState.id, {
      coupon_code: null,
    });
    await loadCart(cartState.id);
    dispatch(setOrderUpdating(false));
  }
}

export async function placeOrder() {
  const layoutState = getState().layout;
  const loginState = getState().login;
  const accountState = getState().account;
  const checkoutState = getState().checkout;
  const cartState = getState().cart;
  const commercelayerState = getState().commercelayer;
  const paymentMethod: PaymentMethods | null = getPaymentMethod(cartState);

  if (layoutState.sapMaintenanceEnabled) {
    toast.warn(i18next.t("SAP_MAINTENANCE"), { position: "top-center" });
    return;
  }

  if (!checkoutState.gtc) {
    dispatch(setGtcError(i18next.t("GTC MANDATORY")));
    return;
  }

  if (paymentMethod === null) {
    console.error("no payment_method defined");
    return;
  }

  await syncShippingMethod(OrderType.ORDER);

  dispatch(setOrderProcessing(true));
  if (cartState.id) {
    let metadata: any = Object.assign({}, cartState.attributes?.metadata ?? {});
    // update bstnk
    if (checkoutState.bstnk !== "") {
      metadata["s_sdo_hdr-bstnk"] = checkoutState.bstnk;
    }

    // skusWithAvailabilityAlert
    const skuCodesWithAvailabilityAlert = filterSkuCodesWithAvailabilityAlert(
      cartState.lineItemsSkus,
      commercelayerState.skusIndex,
      accountState.customer,
    );
    if (skuCodesWithAvailabilityAlert.length > 0) {
      metadata["skuCodesWithAvailabilityAlert"] = skuCodesWithAvailabilityAlert;
    }
    // AvailabilityAlert for distributors (Grosskunden)
    if (customerIsDistributor(accountState.customer)) {
      metadata["skuCodesWithAvailabilityAlert"] = cartState.lineItemsSkus.length;
    }

    // Save owner id of sales agent
    if (loginState.token?.owner_type === OWNER_TYPE.USER) {
      metadata["sales-agent"] = loginState.token.owner_id;
    }

    // Add metadata
    await serviceUpdateOrder(cartState.id, {
      metadata: metadata,
      language_code: getLang(),
    });

    // Add wire transfer object
    await serviceCreateWireTransfer(cartState.id);

    try {
      const orderResponse = await LayerIo.requestJsonRpc<any, any>("order", "create", {
        orderId: cartState.id,
        token: await getAccessToken(),
      });

      if (orderResponse.success) {
        const creditCardPaymentMethod = paymentMethodIdIsOfCreditcardPaymentMethod(
          paymentMethod.id,
          cartState.paymentMethods,
        );

        // Add to Google Analytics
        gtmPushPurchase(cartState);

        // onSuccess of order
        await resetCheckout();
        await resetCart();
        await createCart();
        dispatch(setCartOpen(false));
        scrollTop();

        // Redirect to payment or order page
        if (creditCardPaymentMethod) {
          await startPaymentProcess(cartState.id);
        } else {
          boundReplaceLocObj({
            pathname: `${getLangCountry()}/${PATH.ORDER}/${cartState.id}`,
          });
        }
      } else {
        const message = "orderResponse.success was not true";
        logSentryAndConsole(message, "fatal");
        throw new Error(message);
      }
    } catch (e: any) {
      const orderNumber = cartState.attributes?.number ?? "-";
      const message = i18next.t("ORDER CREATION FAILED", { orderNumber });
      toast.error(message, { position: "top-center" });

      logSentryAndConsole(message, "warning");
      logSentryAndConsoleError(e, "fatal");

      boundReplaceLocObj({
        pathname: `${getLangCountry()}/${PATH.ORDER}/${cartState.id}`,
      });
    }
  }
  dispatch(setOrderProcessing(false));
}

export async function resetCheckout() {
  dispatch(setGtc(false));
  dispatch(setGtcError(null));
  dispatch(setBstnk(""));
  dispatch(setCouponCode(""));
  dispatch(setCouponCodeError(null));
  dispatch(setOrderProcessing(false));
}
