import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { PARAMS } from "../constants/Params";
import Router from "./Router";
import { PATH } from "../constants/Path";
import ReLogin from "./redirect/ReLogin";
import ReOrder from "./redirect/ReOrder";

const SalesAgentAuthCallback = lazy(
  () => import("../../salesagent/components/SalesAgentAuthCallback"),
);

// the intention of this Wrapper is for Router.tsx to include useInitGraphCMS and have useParams available
const RouterWrapper: React.FC = () => (
  <Routes>
    {/*General redirect on root path*/}
    <Route path={`/${PATH.LOGIN}`} element={<ReLogin />} />
    <Route path={`/${PATH.ORDER}/:${PARAMS.ORDER_ID}`} element={<ReOrder />} />
    <Route path={`/${PATH.SALES_AGENT_AUTH_CALLBACK}`} element={<SalesAgentAuthCallback />} />
    <Route path={`/:${PARAMS.LANGUAGE}/:${PARAMS.COUNTRY}/*`} element={<Router />} />
    <Route path="*" element={<Router />} />;
  </Routes>
);

export default RouterWrapper;
