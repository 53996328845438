import { getCountry } from "../../../router/scripts/params";

export async function getCountryCodeByApi(): Promise<string> {
  const url = `https://ipgeolocation.abstractapi.com/v1/?api_key=${process?.env?.REACT_APP_ABSTRACTAPI_API_KEY}`;
  try {
    const controller = new AbortController();
    // abort when timeout reached
    const fetchId = setTimeout(() => {
      controller.abort();
    }, 1000);
    const res = await fetch(url, {
      signal: controller.signal,
    });
    clearTimeout(fetchId);
    const result: any = await res.json();
    // return country_code or fallback
    const countryCode = result?.country_code ?? getCountry();
    return countryCode.toUpperCase();
  } catch (e) {
    console.error(e);
    // fallback
    return getCountry();
  }
}
