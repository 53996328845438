import React, { useEffect } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import MainHeader from "../../layout/components/Col/MainHeader";
import i18next from "i18next";
import CheckoutBreadcrumb from "./CheckoutBreadcrumb";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { initCheckout } from "../actions/checkoutActions";
import Shipping from "./Shipping";
import Payment from "./Payment";
import { scrollTop } from "../../layout/scripts/scroll";
import { getCheckoutLinks } from "../scripts/links";
import Cart from "../../state/vo/Cart";
import Checkout from "../../state/vo/Checkout";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SapMaintenance from "../../layout/components/SapMaintenance";
import UserNotifications from "../../layout/components/UserNotifications";

const ShippingPayment: React.FC = () => {
  const params = useParams();
  const cartId = params[PARAMS.ORDER_ID];
  const cart: Cart = useSelector((state: State) => state.cart);
  const checkoutLinks = getCheckoutLinks(cartId);
  const checkout: Checkout = useSelector((state: State) => state.checkout);

  useEffect(() => {
    if (cartId !== null) {
      initCheckout(cartId);
    }
  }, [cartId]);

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <MainHeader title={i18next.t("CHECKOUT")} desktopShowClose={false} toHomeOnClose={true} />
      <Body>
        <Middle>
          <CheckoutBreadcrumb activeStep={1} links={checkoutLinks} />
          <SapMaintenance />
          <UserNotifications />

          <div className="grid-wrap grid-wrap--checkout">
            <Shipping cart={cart} orderUpdating={checkout.orderUpdating} />
            <Payment cart={cart} orderUpdating={checkout.orderUpdating} />
          </div>

          <div className="btn-wrap btn-wrap--line">
            <Link
              to={checkoutLinks[0]}
              className="btn transparent"
              role="button"
              tabIndex={0}
              onClick={() => scrollTop()}
            >
              <span className="btn__text">{i18next.t("ADDRESS", { count: 2 })}</span>
            </Link>

            <Link
              to={checkoutLinks[2]}
              className="btn"
              role="button"
              tabIndex={0}
              onClick={() => scrollTop()}
            >
              <span className="btn__text">{i18next.t("VERIFICATION")}</span>
            </Link>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default ShippingPayment;
