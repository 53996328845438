import React from "react";
import { Category } from "graphcms/vo/graphCMS";
import { useInventory } from "../hooks";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import InventoryTitle from "./InventoryTitle";
import InventoryItem from "consignment-stock/components/InventoryItem";
import { InventoryItem as InventoryItemVo } from "../vo/ConsignmentStock";
import { ProductsIndex } from "state/vo/GraphCMS";

interface Props {
  category: Category;
}

export enum SortBy {
  None = "None",
  Pf = "Pf",
  ExpiryDate = "ExpiryDate",
  Batch = "Batch",
}

export enum SortDirection {
  None = "None",
  Ascending = "Ascending",
  Descending = "Descending",
}

export type Sort = {
  sortBy: SortBy;
  sortDirection: SortDirection;
};

interface UseInventorySortRes {
  sort: Sort;
  sortColumns: (sortBy: SortBy) => void;
}

const useInventorySort = (): UseInventorySortRes => {
  const [sortBy, setSortBy] = React.useState<SortBy>(SortBy.None);
  const [sortDirection, setSortDirection] = React.useState<SortDirection>(SortDirection.None);
  const sort: Sort = {
    sortBy,
    sortDirection,
  };

  function handleColClick(_sortBy: SortBy) {
    // case to reset
    if (sortBy === _sortBy && sortDirection === SortDirection.Descending) {
      setSortDirection(SortDirection.None);
      setSortBy(SortBy.None);
    }

    // case to toggle, set to Descending
    if (_sortBy === sortBy) {
      if (sortDirection === SortDirection.None) {
        setSortDirection(SortDirection.Ascending);
      }
      if (sortDirection === SortDirection.Ascending) {
        setSortDirection(SortDirection.Descending);
      }
    }

    if (_sortBy !== sortBy) {
      setSortDirection(SortDirection.Ascending);
      setSortBy(_sortBy);
    }
  }

  return { sort, sortColumns: handleColClick };
};

const sorter: Record<SortBy, (a: InventoryItemVo, b: InventoryItemVo) => number> = {
  [SortBy.None]: () => 0,
  [SortBy.Pf]: (a, b) => {
    // don't sort on items with multiple pfs
    if (a.pfsByNumber.length > 1 || b.pfsByNumber.length > 1) {
      return 0;
    }
    return a.pfsByNumber[0] > b.pfsByNumber[0] ? 1 : -1;
  },
  [SortBy.ExpiryDate]: (a, b) => (a.expiry_date > b.expiry_date ? 1 : -1),
  [SortBy.Batch]: (a, b) => (a.batch_number > b.batch_number ? 1 : -1),
};

function filterFromUserInput(item: InventoryItemVo, filter: string): boolean {
  // only perform when user filter is active
  if (!filter) return true;

  //sanitize broken pfs
  const pfsName = item.pfs[0]?.name || "";
  const pfsByNumber = item.pfsByNumber[0] || "";

  if (
    item.name.toLowerCase().includes(filter.toLowerCase()) ||
    item.sku_code.includes(filter) ||
    item.batch_number.includes(filter) ||
    item.pfsByNumber.includes(filter) ||
    RegExp(filter).test(pfsName) ||
    RegExp(filter).test(pfsByNumber)
  ) {
    return true;
  }

  // no filter match
  return false;
}

function getFirstBreadcrumbCategoryId(item: InventoryItemVo, productsIndex: ProductsIndex): string {
  return productsIndex[item.attributes.sku_code]?.productGroup?.breadcrumbCategories?.[0] ?? "";
}

// see: https://intelliact.zendesk.com/agent/tickets/6393
function sortSkuCode(sortBy: SortBy, sortDirection: SortDirection) {
  return (a, b) => {
    if (SortDirection.Ascending === sortDirection) {
      return a.sku_code < b.sku_code ? 1 : -1;
    }
    return a.sku_code < b.sku_code ? -1 : 1;
  };
}

function getFilteredInventoryItems(
  inventoryItems: InventoryItemVo[],
  filter: string,
  category: Category,
  productsIndex: ProductsIndex,
  { sortBy, sortDirection }: Sort,
): InventoryItemVo[] {
  const _inventoryItems = inventoryItems
    // make sure sku_code exist and valid
    .filter(item => !!item.sku_code)
    .filter(item => getFirstBreadcrumbCategoryId(item, productsIndex) === category.id)
    .filter(item => filterFromUserInput(item, filter))
    .sort(sortSkuCode(sortBy, sortDirection))
    .sort((a, b) => sorter[sortBy](a, b));

  return sortDirection === SortDirection.Descending ? _inventoryItems.reverse() : _inventoryItems;
}

const useFilteredInventoryItems = (category: Category, sort: Sort): InventoryItemVo[] => {
  const { inventoryItems } = useInventory();
  const productsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const filter = useSelector((state: State) => state?.consignmentStock?.filter ?? "");

  return getFilteredInventoryItems(inventoryItems, filter, category, productsIndex, sort);
};

const InventoryCategory: React.FC<Props> = ({ category }) => {
  const { sort, sortColumns } = useInventorySort();
  const inventoryItems = useFilteredInventoryItems(category, sort);

  return (
    <div className="list-wrap stock-list">
      <InventoryTitle title={category.name} sort={sort} sortColumns={sortColumns} />
      {inventoryItems.map((position, i) => (
        <InventoryItem key={position.id + i} item={position} i={i} />
      ))}
    </div>
  );
};

export default InventoryCategory;
