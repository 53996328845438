import { getState } from "../../state/scripts/state";
import React, { useState } from "react";
import { addLineItem, deleteLineItem } from "../actions/consignmentStockActions";
import i18next from "i18next";
import { getIdFromSkuAndBatchNumber } from "../scripts/id";
import { logSentryAndConsole } from "common/scripts/logger/log";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

const findOneMatchingLineItemId = (sku: string, batchNumber: string): string => {
  const { lineItemsSkus } = getState().consignmentStock.order;

  const lineItemId =
    lineItemsSkus.find(lineItem => {
      const { attributes } = lineItem;
      if (attributes.sku_code !== sku) return false;
      if (attributes.metadata.batchNumber !== batchNumber) return false;

      // found a match
      return true;
    })?.id ?? "";

  return lineItemId;
};

interface Props {
  sku: string;
  batchNumber: string;
  isInitChecked: boolean;
  orderId: string | null;
  i: number;
}

const Batch: React.FC<Props> = ({ sku, batchNumber, isInitChecked = false, orderId, i }) => {
  const id = `${getIdFromSkuAndBatchNumber(sku, batchNumber)}-${i}`;
  const [checked, setChecked] = useState<boolean>(isInitChecked);
  const { orderChangeInProgress } = useSelector((state: State) => state.consignmentStock);

  async function handleOnChange() {
    if (checked) {
      const lineItemId = findOneMatchingLineItemId(sku, batchNumber);

      if (!lineItemId) {
        const msg = `no lineItemId found with sku ${sku}, batch ${batchNumber} in orderId ${orderId}`;
        logSentryAndConsole(msg, "error");
        return;
      }

      await deleteLineItem(orderId ?? "", lineItemId);
    } else {
      await addLineItem(sku, batchNumber);
    }
    // let async operation finish before toggle checked
    // regarding UX this is less optimal
    // but consignmentStockActions sequence might fail otherwise when a user checks / unchecks quickly
    setChecked(!checked);
  }

  return (
    <div className="input--checkbox-wrap reverse">
      <input
        type="checkbox"
        className="input--checkbox"
        id={id}
        name={id}
        aria-required="false"
        aria-checked={checked}
        onChange={handleOnChange}
        checked={checked}
        disabled={orderChangeInProgress}
      />
      <label className="label label--checkbox checkbox--fill" htmlFor={id}>
        {i18next.t("BATCH")} {batchNumber}
      </label>
    </div>
  );
};

export default Batch;
