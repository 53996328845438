import React, { Dispatch, SetStateAction } from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { useParams } from "react-router-dom";
import { getLinkToProductGroup } from "../../category/scripts/link";
import { getRelatedCategoryIdFromProductGroup } from "../../graphcms/scripts/category";
import { FALLBACK, PARAMS } from "../../router/constants/Params";
import { getProductGroupIdWhileDirectLink } from "../../graphcms/scripts/productGroup";
import { isDirectProductView } from "../../router/scripts/path";
import { BreadcrumbDropdownValue } from "./BreadcrumbDropdown";
import BreadcrumbItem from "./BreadcrumbItem";

interface Props {
  mainProductGroup: ProductGroup;
  relatedProductGroups: ProductGroup[];
  setDropDownOpen: Dispatch<SetStateAction<boolean>>;
}

const BreadcrumbProductGroup: React.FC<Props> = ({
  mainProductGroup,
  relatedProductGroups = [],
  setDropDownOpen,
}) => {
  const params = useParams();

  function getRelatedCategoryId(): string {
    let categoryId = "";

    if (isDirectProductView()) {
      const productGroupId = getProductGroupIdWhileDirectLink(params);

      if (!productGroupId) {
        return "";
      }

      categoryId = getRelatedCategoryIdFromProductGroup(productGroupId);
    } else {
      categoryId = params[PARAMS.CATEGORY_ID] ?? FALLBACK[PARAMS.CATEGORY_ID];
    }

    return categoryId;
  }

  const getDropdownValues = (): BreadcrumbDropdownValue[] =>
    [mainProductGroup, ...relatedProductGroups].map(productGroup => ({
      title: productGroup.name ?? "",
      to: getLinkToProductGroup(getRelatedCategoryId(), productGroup),
      active: mainProductGroup.id === productGroup.id,
    }));

  return (
    <BreadcrumbItem
      title={mainProductGroup?.name ?? ""}
      setDropDownOpen={setDropDownOpen}
      dropdownValues={getDropdownValues()}
    />
  );
};

export default BreadcrumbProductGroup;
