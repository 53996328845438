import i18next, { InitOptions } from "i18next";
import LanguageDetector, { DetectorOptions } from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import Locale from "../../common/constants/Locale";
import { loadhash } from "./loadhash";
import { PATH } from "../../router/constants/Path";
import { FALLBACK, PARAMS } from "../../router/constants/Params";

function updateHtmlElement() {
  document.documentElement.lang = i18next?.resolvedLanguage ?? FALLBACK[PARAMS.LANGUAGE];
}

// add side effects on certain events
i18next.on("initialized", updateHtmlElement);
i18next.on("languageChanged", updateHtmlElement);

// https://github.com/i18next/i18next-http-backend#backend-options
const backend = {
  loadPath: "/static/i18next/thommen-medical-{{lng}}.json?loadhash=" + loadhash,
};

// https://github.com/i18next/i18next-browser-languageDetector#detector-options
const detection: DetectorOptions = {
  // application will have /lang/country/ in the pathname
  // therefore the lookup index will be the first one
  lookupFromPathIndex: 0,
  order: ["path", "localStorage", "navigator"],

  // excluding for certain links, ex: links created in the BE for an email including a link
  // BE is unaware of users language and therefore exclude to not overwrite the cache
  excludeCacheFor: [PATH.LOGIN, PATH.ORDER],
};

const options: InitOptions = {
  backend,
  detection,
  debug:
    process.env.NODE_ENV !== "production" &&
    process.env.REACT_APP_DEV_DISABLE_I18NEXT_DEBUG_LOG !== "true",
  fallbackLng: FALLBACK[PARAMS.LANGUAGE],
  supportedLngs: Object.values(Locale),
  load: "languageOnly",
  react: {
    useSuspense: false,
  },
};

// initializer
i18next
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options)
  .catch(e => console.error(e));

export default i18next;
