import React from "react";
import i18next from "i18next";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import InputRadioCustom from "./InputRadioCustom";
import { setShippingMethodToShipmentsOfCart } from "../actions/checkoutActions";
import MethodInfoText from "./MethodInfoText";
import Cart from "../../state/vo/Cart";
import { filterShippingMethodsNonBackorder } from "../scripts/shippingpayment";
import { StorageKeys } from "../../common/constants/StorageKeys";
import { useSyncShippingMethod } from "../hooks/shippingMethods";
import { OrderType } from "../../common/constants/OrderType";
import { setShippingMethodToShipmentsOfConsignmentStockOrder } from "../../consignment-stock/actions/consignmentStockActions";
import { useCountry } from "../../router/hooks/useCountry";
import CountrySpecific from "../../common/constants/CountrySpecific";

interface Props {
  cart: Cart;
  orderUpdating: boolean;
}

const Shipping: React.FC<Props> = ({ cart, orderUpdating }) => {
  const country = useCountry();
  const onChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    if (cart.attributes?.metadata.type === OrderType.CST_ORDER) {
      setShippingMethodToShipmentsOfConsignmentStockOrder(e.target.value).then();
    } else {
      setShippingMethodToShipmentsOfCart(e.target.value).then();
    }

    localStorage.setItem(StorageKeys.SHIPPING_METHOD_ID, e.target.value);
  };

  const filteredShippingMethodsNonBackorder = filterShippingMethodsNonBackorder(
    cart.shippingMethods,
  );

  // localStorage will be null at the start for new browser
  const selectedShippingMethodId =
    localStorage.getItem(StorageKeys.SHIPPING_METHOD_ID) ??
    filteredShippingMethodsNonBackorder?.[0]?.id ??
    "";

  const orderType: OrderType = cart.attributes?.metadata.orderType || OrderType.ORDER; // OrderType.ORDER ist Fallback
  useSyncShippingMethod(orderType);

  return (
    <div className="grid-1-2">
      <h2 className="col-title">{i18next.t("SHIPPING")}</h2>
      <form>
        <fieldset className="form-fieldset">
          <legend className="visuallyhidden">{i18next.t("SHIPPING")}</legend>
          {filteredShippingMethodsNonBackorder.map((shippingMethod: ShippingMethods, i) => {
            return CountrySpecific[country].hasOwnProperty("checkoutHideShippingCost") &&
              CountrySpecific[country].checkoutHideShippingCost ? (
              <InputRadioCustom
                key={i}
                checked={shippingMethod.id === selectedShippingMethodId}
                inputId={shippingMethod.id}
                inputValue={shippingMethod.id}
                label={i18next.t(`CL_METHOD_${shippingMethod.attributes.reference}`)}
                info={<MethodInfoText method={shippingMethod} />}
                showPrice={false}
                price_amount_float={shippingMethod.attributes.price_amount_float}
                currency_code={shippingMethod.attributes.currency_code}
                onChange={onChange}
                priceInfo={i18next.t("CALCULATED_AFTER_ORDER")}
                disabled={orderUpdating}
              />
            ) : (
              <InputRadioCustom
                key={i}
                checked={shippingMethod.id === selectedShippingMethodId}
                inputId={shippingMethod.id}
                inputValue={shippingMethod.id}
                label={i18next.t(`CL_METHOD_${shippingMethod.attributes.reference}`)}
                info={<MethodInfoText method={shippingMethod} />}
                showPrice={true}
                price_amount_float={shippingMethod.attributes.price_amount_float}
                currency_code={shippingMethod.attributes.currency_code}
                onChange={onChange}
                disabled={orderUpdating}
              />
            );
          })}
        </fieldset>
      </form>
    </div>
  );
};

export default Shipping;
