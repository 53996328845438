import React from "react";
import i18next from "i18next";
import { PATH } from "../../router/constants/Path";
import { Link } from "react-router-dom";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { useShowConsignmentStock } from "../../consignment-stock/hooks";

interface Props {
  activeLi: string;
  customerId: string;
}

const NavAccount: React.FC<Props> = ({ activeLi, customerId }) => {
  const langCountry = useLangCountry();
  return (
    <nav className="nav-account">
      <ul className="nav-list">
        <li className={`nav-list__item ${activeLi === PATH.ACCOUNT ? "active" : ""}`}>
          <Link to={`${langCountry}/${PATH.ACCOUNT}/${customerId}`} onClick={() => scrollTop()}>
            <div className="nav-list__link nav-list__link--bold" title={i18next.t("ACCOUNT")}>
              {i18next.t("ACCOUNT")}
            </div>
          </Link>
        </li>
        <li className={`nav-list__item ${activeLi === PATH.ORDERS ? "active" : ""}`}>
          <Link
            to={`${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.ORDERS}`}
            onClick={() => scrollTop()}
          >
            <div
              className="nav-list__link nav-list__link--bold"
              title={i18next.t("ORDER", { count: 0 })}
            >
              {i18next.t("ORDER", { count: 0 })}
            </div>
          </Link>
        </li>
        <li className={`nav-list__item ${activeLi === PATH.REPORT ? "active" : ""}`}>
          <Link
            to={`${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.REPORT}`}
            onClick={() => scrollTop()}
          >
            <div className="nav-list__link nav-list__link--bold" title={i18next.t("REPORT")}>
              {i18next.t("REPORT", { count: 0 })}
            </div>
          </Link>
        </li>
        {process.env.REACT_APP_FEATURE_FLAG_ADVANCE_SALE && (
          <li className={`nav-list__item ${activeLi === PATH.ADVANCE_SALE ? "active" : ""}`}>
            <Link
              to={`${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.ADVANCE_SALE}`}
              onClick={() => scrollTop()}
            >
              <div
                className="nav-list__link nav-list__link--bold"
                title={i18next.t("ADVANCE SALE")}
              >
                {i18next.t("ADVANCE SALE")}
              </div>
            </Link>
          </li>
        )}
        {useShowConsignmentStock() && (
          <li className={`nav-list__item ${activeLi === PATH.CONSIGNMENT_STOCK ? "active" : ""}`}>
            <Link
              to={`${langCountry}/${PATH.ACCOUNT}/${customerId}/${PATH.CONSIGNMENT_STOCK}`}
              onClick={() => scrollTop()}
            >
              <div
                className="nav-list__link nav-list__link--bold"
                title={i18next.t("CONSIGNMENT STOCK")}
              >
                {i18next.t("CONSIGNMENT STOCK")}
              </div>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavAccount;
