import React from "react";
import { InventoryItem as InventoryItemVo } from "consignment-stock/vo/ConsignmentStock";
import { useSelector } from "react-redux";
import State from "state/vo/State";
import PfClassName from "product/const/PfClassName";
import i18next from "i18next";
import Batch from "./Batch";
import { getDateFormatted } from "common/scripts/format/date";

interface Props {
  item: InventoryItemVo;
  i: number;
}

const getClsPfs = (item: InventoryItemVo) => {
  const clsPfs = ["item-title"];

  // pf-dot only for exactly one pf
  if (item?.pfs.length === 1) {
    clsPfs.push("pf-dot");
    // transform "PF 4.0" to "pf-4-0" for css class
    const pfNumber = `pf-${PfClassName?.[item?.pfs?.[0]?.name]}`;
    clsPfs.push(pfNumber);
  }

  return clsPfs;
};

const InventoryItem: React.FC<Props> = ({ item, i }) => {
  const graphcmsProductsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const orderId = useSelector((state: State) => state.consignmentStock.order.id);
  const product = graphcmsProductsIndex?.[item?.attributes?.sku_code];
  const clsPfs = getClsPfs(item);

  return (
    <div className="list-3cols ">
      <div className="list-3cols__item">
        <div className="list-item__text-wrap">
          <h3 className={clsPfs.join(" ")}>
            {product?.sku} {product?.name}
          </h3>

          <div className="list-item__text">{product?.nameSuffix}</div>
        </div>
      </div>
      <div className="list-3cols__item pr-14">
        <span className="label--expiry-date">{i18next.t("EXPIRES AT")}:</span>
        &nbsp;{getDateFormatted(item?.expiry_date)}
      </div>
      <div className="list-3cols__item pr-14">
        <Batch
          sku={product?.sku}
          batchNumber={item.batch_number}
          isInitChecked={item.isInitChecked}
          orderId={orderId}
          i={i}
        />
      </div>
    </div>
  );
};

export default InventoryItem;
