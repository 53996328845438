import React from "react";
import i18next from "i18next";
import OverlayPopup from "../../layout/components/OverlayPopup";
import QRCode from "./QRCode";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { OVERLAY } from "../../layout/constants/Overlay";

interface Props {
  scanId: string;
  shutdownScanning: () => void;
}

const OverlayPopupQRCode: React.FC<Props> = ({ scanId, shutdownScanning }) => {
  const layoutOverlayOpen = useSelector((state: State) => state.layout.overlayOpen);

  if (layoutOverlayOpen !== OVERLAY.QRSCAN) {
    return null;
  }

  return (
    <OverlayPopup
      showClose={true}
      activeButtonShow={true}
      activeButtonLabel={i18next.t("CLOSE")}
      activeButtonOnClick={shutdownScanning}
    >
      <div className="overlay-popup__subtitle">{i18next.t("SCAN TO CART QR CODE TILE")}:</div>
      <QRCode scanId={scanId} />
      <div className="cms overlay-popup__qr-code-text">
        <p>{i18next.t("SCAN TO CART QR CODE DESCRIPTION")}</p>
      </div>
    </OverlayPopup>
  );
};

export default OverlayPopupQRCode;
