import Locale from "../../common/constants/Locale";
import Country from "../../common/constants/Country";

export enum PARAMS {
  ACCOUNT_ID = "accountId",
  CATEGORY_ID = "categoryId",
  CATEGORY_SLUG = "categorySlug",
  CUSTOMER_PASSWORD_RESETS_ID = "customerPasswordResetsId",
  COUNTRY = "country",
  EMAIL = "email",
  LANGUAGE = "lang",
  ORDER_ID = "orderId",
  PRODUCT_SLUG = "productSlug",
  PRODUCTGROUP_ID = "productgroupId",
  PRODUCTGROUP_SLUG = "productgroupSlug",
  SEARCH_TERM = "searchTerm",
  SKU = "sku",
  SCAN_ID = "scanId",
  TOKEN = "token",
}

// https://stackoverflow.com/a/58812812/6138965
export const FALLBACK: { [key in PARAMS]: string } = {
  [PARAMS.ACCOUNT_ID]: "MISSING-ACCOUNT-ID",
  [PARAMS.CATEGORY_ID]: "MISSING-CATEGORY-ID",
  [PARAMS.CATEGORY_SLUG]: "MISSING-CATEGORY-SLUG",
  [PARAMS.CUSTOMER_PASSWORD_RESETS_ID]: "MISSING-CUSTOMER-PASSWORD-RESETS-ID",
  [PARAMS.COUNTRY]: Country.SWITZERLAND,
  [PARAMS.EMAIL]: "MISSING-EMAIL",
  [PARAMS.LANGUAGE]: Locale.GERMAN,
  [PARAMS.ORDER_ID]: "MISSING-ORDER-ID",
  [PARAMS.PRODUCTGROUP_ID]: "MISSING-PRODUCT-GROUP-ID",
  [PARAMS.PRODUCTGROUP_SLUG]: "MISSING-PRODUCT-GROUP-SLUG",
  [PARAMS.PRODUCT_SLUG]: "MISSING-PRODUCT-SLUG",
  [PARAMS.SEARCH_TERM]: "MISSING-SEARCH-TERM",
  [PARAMS.SKU]: "MISSING-SKU",
  [PARAMS.SCAN_ID]: "MISSING-SCAN-ID",
  [PARAMS.TOKEN]: "MISSING-TOKEN",
};
