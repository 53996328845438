import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { CellYTd } from "./CellYTd";
import { getAttributeValueWithUnit } from "../../graphcms/scripts/attribute";
import { ProductRow } from "../vo/sku";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { filterXSS } from "xss";

export const YAxisAttributeContent: React.FC<{
  productGroup: ProductGroup;
  productRow: ProductRow;
}> = ({ productGroup, productRow }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  const yAxisAttributes = productGroup?.yAxisAttributes;

  // desktop
  if (!isMobile) {
    const content: any[] = [];

    yAxisAttributes?.forEach(yAxisAttribute => {
      const product = productRow[yAxisAttribute?.name];
      const value = getAttributeValueWithUnit(product, yAxisAttribute?.name);
      content.push(<CellYTd key={yAxisAttribute?.name} value={value} />);
    });
    return <>{content}</>;
  }

  // mobile
  if (isMobile && yAxisAttributes?.length > 0) {
    let html = "";

    yAxisAttributes?.forEach((yAxisAttribute, i) => {
      if (i > 0) {
        html += "<br>";
      }

      const product = productRow[yAxisAttribute?.name];
      html += getAttributeValueWithUnit(product, yAxisAttribute?.name);
    });

    return <td className="cell-y" dangerouslySetInnerHTML={{ __html: filterXSS(html) }} />;
  }

  return null;
};
