import React from "react";

export interface CommonImgProps {
  handle?: string;
  alt?: string | null;
}

export const ImgOneCol: React.FC<CommonImgProps> = ({ handle = null, alt = "" }) => {
  if (handle === null) {
    return null;
  }

  return (
    <picture>
      <source
        media="screen and (min-width:1440px)"
        srcSet={`https://media.graphassets.com/auto_image/resize=height:70,width:70/${handle} 1x, https://media.graphassets.com/auto_image/resize=height:140,width:140/${handle} 2x`}
      />
      <source
        media="screen and (min-width:1200px) and (max-width:1439px)"
        srcSet={`https://media.graphassets.com/auto_image/resize=height:64,width:64/${handle} 1x, https://media.graphassets.com/auto_image/resize=height:128,width:128/${handle} 2x`}
      />
      <source
        media="screen and (max-width:1199px)"
        srcSet={`https://media.graphassets.com/auto_image/resize=height:280,width:280/${handle} 1x, https://media.graphassets.com/auto_image/resize=height:560,width:560/${handle} 2x`}
      />
      <img
        className="img"
        alt={alt ?? ""}
        src={`https://media.graphassets.com/auto_image/resize=height:70,width:70/${handle}`}
      />
    </picture>
  );
};
