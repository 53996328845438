import React from "react";
import PfClassName from "../const/PfClassName";
import { Product } from "../../graphcms/vo/graphCMS";

interface Props {
  product: Product;
}

const ProductInfoHeading: React.FC<Props> = ({ product }) => {
  // only render when exactly one pf (https://3.basecamp.com/3101128/buckets/19695186/todos/3947673461)
  const classNamePf =
    product?.pfs?.length === 1 ? `pf-${PfClassName?.[product?.pfs?.[0]?.name]}` : "";

  return (
    <h1 className={`item-title pf-dot ${classNamePf}`}>{`${product?.sku} ${product?.name}`}</h1>
  );
};

export default ProductInfoHeading;
