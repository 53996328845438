import React, { useEffect, useState } from "react";
import { useScrollTop } from "../hooks/scroll";
import UrlParamsUpdater from "../../router/components/UrlParamsUpdater";
import {
  isCheckoutView,
  isConsignmentStockView,
  isScanToCartView,
} from "../../router/scripts/path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { Link, useParams } from "react-router-dom";
import { setOverlayOpenAction, setShowConsignmentStockInfoAction } from "../actions/layoutActions";
import { PARAMS } from "../../router/constants/Params";
import { OVERLAY } from "../constants/Overlay";
import PageMetaData from "./PageMetaData";
import EnvironmentFlag from "./EnvironmentFlag";
import OverlayPopupUnsupportedCountry from "./OverlayPopupUnsupportedCountry";
import { Helmet } from "react-helmet-async";
import { APP_COUNTRIES, APP_TEST_COUNTRIES } from "../constants/Countries";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import i18next from "i18next";
import { scrollTop } from "../scripts/scroll";
import { toast } from "react-toastify";
import { useShowConsignmentStock } from "../../consignment-stock/hooks";

function useAutoCloseOverlay(layoutOverlayOpen: OVERLAY | null, unsupportedCountry: string | null) {
  const params = useParams();

  useEffect(
    function autoCloseOverlay() {
      // do nothing for views allowed to have overlay open
      if (isScanToCartView()) {
        return;
      }
      if (isConsignmentStockView()) {
        return;
      }
      // do nothing for unsupported country
      if (unsupportedCountry !== null) {
        return;
      }

      // only close when open
      if (layoutOverlayOpen) {
        setOverlayOpenAction(null);
      }
    },
    [params, unsupportedCountry, layoutOverlayOpen],
  );
}

type Props = {
  children: React.ReactNode;
};

const Layout: React.FC<Props> = props => {
  const params = useParams();
  const [unsupportedCountry, setUnsupportedCountry] = useState<string | null>(null);
  const layoutOverlayOpen = useSelector((state: State) => state.layout.overlayOpen);
  const layoutIsMobile = useSelector((state: State) => state.layout.isMobile);

  useScrollTop();
  useAutoCloseOverlay(layoutOverlayOpen, unsupportedCountry);

  const langCountry = useLangCountry();
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const showConsignmentStock = useShowConsignmentStock();
  const showConsignmentStockInfo = useSelector(
    (state: State) => state.layout.showConsignmentStockInfo,
  );

  useEffect(() => {
    if (!accountCustomer) return;
    if (!langCountry) return;
    if (!showConsignmentStock) return;
    if (!showConsignmentStockInfo) return;

    const message = (
      <div>
        {i18next.t("CONSIGNMENT STOCK MESSAGE")}&nbsp;
        <Link
          to={`${langCountry}/account/${accountCustomer.id}/consignment-stock`}
          onClick={() => scrollTop()}
        >
          {i18next.t("CONSIGNMENT STOCK")}
        </Link>
      </div>
    );
    toast.warning(message, {
      position: "top-center",
    });
    setShowConsignmentStockInfoAction(false);
  }, [langCountry, accountCustomer, showConsignmentStock, showConsignmentStockInfo]);

  let addClass = "";

  if (isCheckoutView()) {
    addClass += " no-mobile-menu";
  }
  if (layoutOverlayOpen) {
    addClass += " overlay-open";
  }

  useEffect(() => {
    const countryParam = params[PARAMS.COUNTRY];
    if (countryParam === "string") {
      if (
        !APP_COUNTRIES.includes(countryParam.toUpperCase()) &&
        !APP_TEST_COUNTRIES.includes(countryParam.toUpperCase())
      ) {
        setUnsupportedCountry(countryParam);
        setOverlayOpenAction(OVERLAY.UNSUPPORTED_COUNTRY);
      }
    }
  }, [params]);

  return (
    <div className={`page-container ${addClass}`}>
      {layoutIsMobile && (
        <Helmet>
          <meta
            name="viewport"
            content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
        </Helmet>
      )}
      <PageMetaData />
      <EnvironmentFlag />
      {unsupportedCountry !== null && (
        <OverlayPopupUnsupportedCountry unsupportedCountry={unsupportedCountry} />
      )}
      {props.children}
      <UrlParamsUpdater />
    </div>
  );
};

export default Layout;
