import React from "react";
import ProductTable from "./ProductTable";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import DetailTableWrapper from "./DetailTableWrapper";
import Paragraphs from "./Paragraphs";
import ProductInfo from "./ProductInfo";
import { getProductGroupTitle } from "../../graphcms/scripts/productGroup";

interface Props {
  productGroup: ProductGroup;
}

const DetailMain: React.FC<Props> = ({ productGroup }) => {
  if (!productGroup) {
    return null;
  }

  return (
    <div className="product-detail">
      <p className="col-title col-title--product">{getProductGroupTitle(productGroup)}</p>
      <DetailTableWrapper productGroup={productGroup}>
        <ProductTable productGroup={productGroup} />
        <Paragraphs item={productGroup} />
        <ProductInfo productGroup={productGroup} />
      </DetailTableWrapper>
    </div>
  );
};

export default DetailMain;
