import React, { Dispatch, SetStateAction } from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getLinkToCategory } from "../../category/scripts/link";
import { BreadcrumbDropdownValue } from "./BreadcrumbDropdown";
import BreadcrumbItem from "./BreadcrumbItem";
import { hasDisplayableItems } from "../../category/scripts/filter";
import { useParams } from "react-router-dom";

interface Props {
  mainProductGroup: ProductGroup;
  setDropDownOpen: Dispatch<SetStateAction<boolean>>;
}

const BreadcrumbMainCategory: React.FC<Props> = ({ mainProductGroup, setDropDownOpen }) => {
  const params = useParams();
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const { breadcrumbCategories } = mainProductGroup;

  if (!breadcrumbCategories?.[0]) {
    return null;
  }

  const selectedCategory = categoriesTree.find(category => category.id === breadcrumbCategories[0]);

  const getDropdownValues = (): BreadcrumbDropdownValue[] =>
    categoriesTree
      .filter(category => hasDisplayableItems(category, params))
      .map(category => ({
        title: category.name,
        to: getLinkToCategory(category),
        active: category.id === breadcrumbCategories[0],
      }));

  return (
    <BreadcrumbItem
      title={selectedCategory?.name || ""}
      setDropDownOpen={setDropDownOpen}
      dropdownValues={getDropdownValues()}
    />
  );
};

export default BreadcrumbMainCategory;
