import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import PfClassName from "../const/PfClassName";
import { YAxisAttribute } from "./YAxisAttribute";
import { useSelectedProduct } from "../hooks/product";
import { ProductRow } from "../vo/sku";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import Sku from "./Sku";
import { getUniqueSortedAttrValues } from "../../graphcms/scripts/attribute";
import { getValuesInUrlList } from "../../router/scripts/search";
import { SEARCH } from "../../router/constants/Search";

interface Props {
  productRow: ProductRow;
  productGroup: ProductGroup;
}

// used to target the right product column when touching a sku that has multiple pfs
// only relevance in mobile view
let skuColumn = 0;

export const SkuDataRow: React.FC<Props> = ({ productRow, productGroup }) => {
  const selectedProduct = useSelectedProduct();
  const cartLineItemsSkus = useSelector((state: State) => state.cart.lineItemsSkus);
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  const activeSkuColumn = useSelector((state: State) => state.product.activeSkuColumn);

  const pfs = productGroup?.pfs ?? [];

  const skuRows: any[] = [];

  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);
  skuColumn = 0;

  function getOpen(product): string {
    let open = selectedProduct?.sku === product?.sku ? "open" : "";

    // overwrite open when open is active, on mobile and not the activeSkuColumn
    if (open && isMobile && activeSkuColumn !== skuColumn) {
      open = "";
    }

    return open;
  }

  // render when pfs are available
  for (let i = 0; i < pfs.length; i++) {
    const pf = pfs[i];
    const key = pf?.name;

    if (!key) {
      continue;
    }

    const product = productRow[pf?.name];

    if (!product) {
      // blur only when there is focus active and the current pf.name is not in focus
      const isBlur = focus?.length > 0 && !focus?.includes(pf.name) ? "is-blur" : "";

      skuRows.push(<td key={key} className={`td-prdct td-empty ${isBlur}`} />);
      continue;
    }

    const pfsLength = isMobile ? 1 : product.pfs.length;
    const colSpan = isMobile ? 1 : product?.pfs?.length;
    const tdClassName = pfsLength > 1 ? "td-combined" : `td-pf-${PfClassName[key]}`;

    let open = getOpen(product);
    // reset open when open should be active, on mobile and not the activeSkuColumn
    if (open && isMobile && activeSkuColumn !== skuColumn) {
      open = "";
    }

    // counter will be adjusted to skip over the combined productRowSku
    // this has only relevance when pfsLength > 1
    // no relevance since pfsLength is always 1 when isMobile
    i = i + pfsLength - 1;

    skuRows.push(
      <Sku
        key={key}
        product={product}
        productGroup={productGroup}
        colSpan={colSpan}
        tdClassName={tdClassName}
        open={open}
        cartLineItems={cartLineItemsSkus}
        skuColumn={skuColumn}
      />,
    );

    skuColumn++;
  }

  // this will include the render of sku that have not a PF Row
  if (pfs?.length === 0) {
    // case when no xAxisAttributes
    if (productGroup?.xAxisAttributes?.length === 0) {
      const product = productRow[Object.keys(productRow)[0]];

      if (product) {
        const open = getOpen(product);

        skuRows.push(
          <Sku
            key={product?.sku}
            product={product}
            productGroup={productGroup}
            colSpan={6}
            tdClassName={"td-combined"}
            open={open}
            cartLineItems={cartLineItemsSkus}
          />,
        );
      }
    }

    // case no xAxisAttributes available
    if (productGroup?.pfs?.length === 0 && productGroup?.xAxisAttributes?.length > 0) {
      const attributeName = productGroup.xAxisAttributes[0]?.name;

      if (!attributeName) {
        return null;
      }

      const uniqueSortedSortingAttributes = getUniqueSortedAttrValues(productGroup, attributeName);

      uniqueSortedSortingAttributes.forEach(sortedAttribute => {
        const index = attributeName + "-" + sortedAttribute;

        const product = productRow?.[index];

        if (!product) {
          skuRows.push(
            <td
              key={index}
              className={`td-prdct td-empty ${focus?.length > 0 ? "is-blur" : ""}`}
            />,
          );
          return;
        }

        const open = getOpen(product);

        skuRows.push(
          <Sku
            key={product?.sku}
            product={product}
            productGroup={productGroup}
            colSpan={1}
            tdClassName={"td-combined"}
            open={open}
            cartLineItems={cartLineItemsSkus}
          />,
        );
      });
    }
  }

  // will include include the sku by the sorted value
  return (
    <tr>
      <YAxisAttribute productGroup={productGroup} productRow={productRow} />
      {skuRows}
    </tr>
  );
};
