import React from "react";
import i18next from "i18next";
import imgListIcon from "../../templates/public/images/layout/icon.svg";

const SignupBenefits: React.FC = () => {
  return (
    <>
      <div className="grid__text">
        {i18next.t("APPLY FOR A CUSTOMER ACCOUNT")}
        <br />
        {i18next.t("YOUR BENEFITS AT A GLANCE")}:
      </div>
      <div className="text text__col-1-2">
        <div className="text-col">
          <img src={imgListIcon} className="text__icon" alt={`${i18next.t("BENEFIT")} 1`} />
        </div>
        <div className="text-col">
          <p className="text__title">{`${i18next.t("BENEFIT")} 1`}</p>
          <p className="text__text">{i18next.t("SIGNUP BENEFIT 1")}</p>
        </div>
      </div>

      <div className="text text__col-1-2">
        <div className="text-col">
          <img src={imgListIcon} className="text__icon" alt={`${i18next.t("BENEFIT")} 2`} />
        </div>
        <div className="text-col">
          <p className="text__title">{`${i18next.t("BENEFIT")} 2`}</p>
          <p className="text__text">{i18next.t("SIGNUP BENEFIT 2")}</p>
        </div>
      </div>

      <div className="text text__col-1-2">
        <div className="text-col">
          <img src={imgListIcon} className="text__icon" alt={`${i18next.t("BENEFIT")} 3`} />
        </div>
        <div className="text-col">
          <p className="text__title">{`${i18next.t("BENEFIT")} 3`}</p>
          <p className="text__text">{i18next.t("SIGNUP BENEFIT 3")}</p>
        </div>
      </div>
    </>
  );
};

export default SignupBenefits;
