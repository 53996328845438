import React from "react";
import i18next from "i18next";
import { useForm } from "react-hook-form";

import { resetPassword } from "../actions/loginActions";

interface Props {
  onSubmit: () => void;
  handleClickForgotPassword: () => void;
}
type ForgotPasswordData = {
  email: string;
};

const ForgotPassword: React.FC<Props> = ({ handleClickForgotPassword }) => {
  const methods = useForm<ForgotPasswordData>();

  const onSubmit = methods.handleSubmit(({ email }) => {
    handleClickForgotPassword();
    resetPassword(email);
  });
  return (
    <>
      <h2 className="col-title">{i18next.t("FORGOT PASSWORD")}</h2>
      <div className="grid__text">{i18next.t("SEND EMAIL ADDRESS TO RESET PASSWORD")}</div>
      <form className="form register-form" onSubmit={onSubmit}>
        <label className="label " htmlFor="email">
          {`${i18next.t("EMAIL")} *`}
        </label>
        <input
          type="email"
          className="input--text"
          defaultValue=""
          placeholder={i18next.t("EMAIL")}
          aria-required="true"
          aria-label={i18next.t("EMAIL")}
          {...methods.register("email", { required: true })}
        />
        <div className="btn-wrap">
          <button type="button" className="btn transparent" onClick={handleClickForgotPassword}>
            {i18next.t("CANCEL")}
          </button>

          <button type="submit" className="btn ">
            {i18next.t("SEND")}
          </button>
        </div>
      </form>
    </>
  );
};
export default ForgotPassword;
