import React from "react";
import { ProductGroupViewType } from "../../graphcms/constants/ProductGroupViewType";
import { CellYTh } from "./CellYTh";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { getProductGroupViewType } from "../../graphcms/scripts/productGroup";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

interface Props {
  productGroup: ProductGroup;
}

export const HeaderYLeft: React.FC<Props> = ({ productGroup }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);

  if (isMobile) {
    return null;
  }

  const leftCells: any[] = [];
  const PGVT = getProductGroupViewType(productGroup);

  // for desktop view
  if (!isMobile) {
    if (PGVT === ProductGroupViewType["4i"] || PGVT === ProductGroupViewType["4I"]) {
      leftCells.push(<CellYTh key={1} />);
      leftCells.push(<CellYTh key={2} />);
      leftCells.push(<CellYTh key={3} />);
      leftCells.push(<CellYTh key={4} />);
    }

    if (PGVT === ProductGroupViewType["3I-1d"] || PGVT === ProductGroupViewType["3i-1d"]) {
      leftCells.push(<CellYTh key={1} />);
      leftCells.push(<CellYTh key={2} />);
      leftCells.push(<CellYTh key={3} />);
    }

    if (PGVT === ProductGroupViewType["2i-2d"] || PGVT === ProductGroupViewType["2I-2d"]) {
      leftCells.push(<CellYTh key={1} />);
      leftCells.push(<CellYTh key={2} />);
    }

    if (PGVT === ProductGroupViewType["1i-3d"] || PGVT === ProductGroupViewType["1I-3d"]) {
      leftCells.push(<CellYTh key={1} />);
    }

    if (PGVT === ProductGroupViewType["4d"]) {
    }
  }

  return <>{leftCells}</>;
};
