import React from "react";
import i18next from "i18next";
import Breadcrumb, { BreadcrumbType } from "common/components/Breadcrumb";
import { PATH } from "../../router/constants/Path";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useLangCountry } from "../../router/hooks/useLangCountry";

interface Props {
  activeStep: number;
}

const useGetLinks = (): string[] => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const order = useSelector((state: State) => state.consignmentStock.order);
  const langCountry = useLangCountry();
  const accountId = accountCustomer?.id ?? "";

  // remove link functionality when no items in order
  if (order.lineItemsSkus.length === 0) {
    return [``, ``, ``, ``];
  }

  const basePath = `${langCountry}/${PATH.ACCOUNT}/${accountId}/${PATH.CONSIGNMENT_STOCK}`;
  return [
    `${basePath}`,
    `${basePath}/${order.id}/${PATH.ADDRESS}`,
    `${basePath}/${order.id}/${PATH.SHIPPING_PAYMENT}`,
    `${basePath}/${order.id}/${PATH.VERIFICATION}`,
  ];
};

const ConsignmentStockBreadcrumb: React.FC<Props> = ({ activeStep }) => {
  const stepTitles = [
    i18next.t("PRODUCT SELECTION"),
    i18next.t("ADDRESS", { count: 2 }),
    `${i18next.t("SHIPPING")} & ${i18next.t("PAYMENT")}`,
    i18next.t("VERIFICATION"),
  ];

  return (
    <Breadcrumb
      activeStep={activeStep}
      links={useGetLinks()}
      stepTitles={stepTitles}
      type={BreadcrumbType.steps}
    />
  );
};

export default ConsignmentStockBreadcrumb;
