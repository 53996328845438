import React from "react";
import i18next from "i18next";
import Scan from "./Scan";
import Filter from "consignment-stock/components/Filter";

const ScanAndFilter: React.FC = () => {
  return (
    <div className="col-subheader">
      <h2 className="col-title">{i18next.t("PRODUCT", { count: 2 })}</h2>
      <Scan />
      <Filter />
    </div>
  );
};

export default ScanAndFilter;
