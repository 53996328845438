import React from "react";
import i18next from "i18next";
import Order from "../../state/vo/Order";
import { getNumberWithCurrency } from "../../common/scripts/format/number";
import Cart from "../../state/vo/Cart";
import { useCountry } from "../../router/hooks/useCountry";
import CountrySpecific from "../../common/constants/CountrySpecific";

interface Props {
  order: Order | Cart | null;
}

const OrderTotal: React.FC<Props> = ({ order }) => {
  const country = useCountry();
  if (order === null || order.attributes === null) {
    return null;
  }

  return (
    <div className="cart-checkout__total">
      <p className="cart-checkout-total__label">{i18next.t("TOTAL")}</p>
      <p className="cart-checkout-total__price">
        {CountrySpecific[country].hasOwnProperty("checkoutHideShippingCost") &&
        CountrySpecific[country].checkoutHideShippingCost === true &&
        CountrySpecific[country].hasOwnProperty("checkoutHideTax") &&
        CountrySpecific[country].checkoutHideTax === true ? (
          <>
            <span>
              {getNumberWithCurrency(
                order.attributes.total_amount_with_taxes_float,
                order.attributes.currency_code,
              )}
            </span>
            <span className={"cart-checkout-total__label"}>
              {" "}
              {i18next.t("PLUS_SHIPPING_AND_TAXES")}
            </span>
          </>
        ) : (
          getNumberWithCurrency(
            order.attributes.total_amount_with_taxes_float,
            order.attributes.currency_code,
          )
        )}
      </p>
    </div>
  );
};

export default OrderTotal;
