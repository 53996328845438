// todo: This is temporary solution and should be removed over time
// https://3.basecamp.com/3101128/buckets/19695186/todos/8078445398
import React, { useEffect } from "react";
import { StorageKeys } from "../../common/constants/StorageKeys";

async function clearAllCaches() {
  // Clear Cache API
  if ("caches" in window) {
    const cacheNames = await caches.keys();
    await Promise.all(cacheNames.map(cache => caches.delete(cache)));
    console.log("All caches cleared.");
  }

  // Unregister Service Workers
  if ("serviceWorker" in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    await Promise.all(registrations.map(reg => reg.unregister()));
    console.log("Service workers unregistered.");
  }

  // Clear Storage
  localStorage.clear();
  sessionStorage.clear();
  console.log("LocalStorage and SessionStorage cleared.");

  localStorage.setItem(StorageKeys.TEMPORARY_CLEAR_APP_CACHE, "false");
}

// In case not everything has loaded
const TIMEOUT_TO_CLEAR_CACHE = 3000;

const TemporaryClearAllCaches: React.FC = () => {
  const temporaryClearAppCache = localStorage.getItem(StorageKeys.TEMPORARY_CLEAR_APP_CACHE);

  useEffect(() => {
    if (temporaryClearAppCache !== "false") {
      setTimeout(() => {
        clearAllCaches().then(() => {
          localStorage.setItem(StorageKeys.TEMPORARY_CLEAR_APP_CACHE, "false");
          // Reload app
          // @ts-ignore
          window.location.reload(true);
        });
      }, TIMEOUT_TO_CLEAR_CACHE);
    }
  }, [temporaryClearAppCache]);

  return null;
};

export default TemporaryClearAllCaches;
