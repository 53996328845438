import React, { Dispatch, SetStateAction } from "react";
import { Category, ProductGroup } from "../../graphcms/vo/graphCMS";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getLinkToProductGroup } from "../../category/scripts/link";
import BreadcrumbItem from "./BreadcrumbItem";
import { BreadcrumbDropdownValue } from "./BreadcrumbDropdown";

function getHiddenCategories(categories: Category[], breadcrumbCategories): Category[] {
  let subSubCategories: Category[] = [];

  const topCategory = categories.find(category => category?.id === breadcrumbCategories?.[0]);
  const subCategory = topCategory?.categories?.find(
    category => category?.id === breadcrumbCategories?.[1],
  );

  if (subCategory?.categories) {
    subSubCategories = subCategory?.categories;
  }

  if (subSubCategories?.length === 0 && topCategory?.categories) {
    subSubCategories = topCategory?.categories;
  }

  return subSubCategories;
}

interface Props {
  mainProductGroup: ProductGroup;
  setDropDownOpen: Dispatch<SetStateAction<boolean>>;
}

const BreadcrumbHiddenCategory: React.FC<Props> = ({ mainProductGroup, setDropDownOpen }) => {
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const { breadcrumbCategories } = mainProductGroup;

  const hiddenCategories: Category[] = getHiddenCategories(categoriesTree, breadcrumbCategories);

  if (!hiddenCategories || hiddenCategories?.length === 0) {
    return null;
  }

  const getTitle = () =>
    hiddenCategories.find(category => category.id === mainProductGroup?.categories?.[0]?.id)
      ?.name || "";

  const getDropdownValues = (): BreadcrumbDropdownValue[] =>
    hiddenCategories
      // productGroups has items
      .filter(category => category.productGroups?.[0])
      .map(category => ({
        title: category.name,
        to: getLinkToProductGroup(category.id, category.productGroups?.[0]),
        active: category.id === mainProductGroup?.categories?.[0]?.id,
      }));

  return (
    <BreadcrumbItem
      title={getTitle()}
      setDropDownOpen={setDropDownOpen}
      dropdownValues={getDropdownValues()}
    />
  );
};

export default BreadcrumbHiddenCategory;
