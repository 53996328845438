import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { getUniqueSortedAttrValuesWithUnit } from "../../graphcms/scripts/attribute";
import { CellYTh } from "./CellYTh";
import ProductTableHeadLeftCells from "./ProductTableHeadLeftCells";

interface Props {
  productGroup: ProductGroup;
}

const ProductTableHeadNoPf: React.FC<Props> = ({ productGroup }) => {
  const pfs = productGroup?.pfs ?? [];

  // stop render when pfs are available
  if (pfs?.length !== 0) {
    return null;
  }

  const xAxisAttributes = productGroup?.xAxisAttributes?.map((xAttribute, i) => {
    const xAttrName = xAttribute?.name;

    if (!xAttrName) {
      return null;
    }

    // sorted with the similar logic like the productRow but added the unit when available
    const sortedValuesWithUnit = getUniqueSortedAttrValuesWithUnit(productGroup, xAttrName);

    return (
      <tr key={i} className="thead__row">
        <ProductTableHeadLeftCells />
        <CellYTh value={xAttribute?.label} />
        {sortedValuesWithUnit.map(valueWithUnit => (
          <td key={valueWithUnit}>{valueWithUnit}</td>
        ))}
      </tr>
    );
  });

  return <>{xAxisAttributes}</>;
};

export default ProductTableHeadNoPf;
