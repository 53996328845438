import React from "react";
import { toggleSearchReplace } from "../../router/actions/routesActions";
import { SEARCH } from "../../router/constants/Search";
import PfClassName from "../../product/const/PfClassName";

const PfTag: React.FC<{ pfName: string }> = ({ pfName }) => {
  const className = PfClassName[pfName] ?? "";

  return (
    <p className="tag" onClick={() => toggleSearchReplace(SEARCH.FOCUS, pfName)}>
      <span className={`tag__dot tag-dot--pf-${className}`} />
      <span className="tag__text">
        <strong>{pfName}</strong>
      </span>
      <span
        className="tag__remover"
        aria-label={`Remove Tag ${pfName}`}
        role="button"
        tabIndex={0}
      />
    </p>
  );
};

export default PfTag;
