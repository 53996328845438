import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { getAttributeValueWithUnit } from "../../graphcms/scripts/attribute";
import { CellYTh } from "./CellYTh";
import { getValuesInUrlList } from "../../router/scripts/search";
import { SEARCH } from "../../router/constants/Search";
import ProductTableHeadLeftCells from "./ProductTableHeadLeftCells";

interface Props {
  productGroup: ProductGroup;
}

const ProductTableHeadWithPf: React.FC<Props> = ({ productGroup }) => {
  const pfs = productGroup?.pfs ?? [];
  const pfsNames = pfs?.map(pf => pf?.name);
  const cellValuesByPfAndAttrName = {};

  // build cellValuesByPfAndAttrName
  pfsNames.forEach(pfName => {
    // find product by pfName
    const product = productGroup?.products?.find(product =>
      product?.pfs?.find(pf => pf?.name === pfName),
    );

    cellValuesByPfAndAttrName[pfName] = {};

    product?.attributeValues?.forEach(attributeValue => {
      const attrName = attributeValue?.attribute?.name ?? "";
      const value = getAttributeValueWithUnit(product, attrName);
      cellValuesByPfAndAttrName[pfName][attrName] = `${value}`;
    });
  });

  const xAxisAttributes = productGroup?.xAxisAttributes?.map((attribute, i) => {
    let data: any[] = [];

    pfsNames.forEach(pfName => {
      data.push(
        <td key={pfName} className={getClassNameIsBlur(pfName)}>
          {cellValuesByPfAndAttrName[pfName][attribute.name]}
        </td>,
      );
    });

    return (
      <tr key={i} className="thead__row">
        <ProductTableHeadLeftCells />
        <CellYTh value={attribute?.label} />
        {data}
      </tr>
    );
  });

  return <>{xAxisAttributes}</>;
};

const classNameIsBlur = "is-blur";

function getClassNameIsBlur(pfName: string): string {
  return getIsPfNameInFocus(pfName) ? "" : classNameIsBlur;
}

function getIsPfNameInFocus(pfName: string): boolean {
  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);

  // no focus filter means everything is in focus
  if (focus.length === 0) {
    return true;
  }

  return focus.includes(pfName);
}

export default ProductTableHeadWithPf;
