import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ElstrJsonRpcResult } from "common/services/LayerIo";
import { getAccessToken } from "../../commercelayer/scripts/token";
import { ConsignmentStockResponse } from "../vo/ConsignmentStock";

export async function prepareHeaderToken(headers: Headers): Promise<Headers> {
  const token = await getAccessToken();
  if (token) {
    headers.set("Authorization", `Bearer ${token}`);
  }
  return headers;
}

function transformResponse(response: ElstrJsonRpcResult<any>) {
  return response?.data ?? null;
}

export const consignmentStockApi = createApi({
  reducerPath: `consignmentStockApi`,
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/customer/consignment-stock",
    prepareHeaders: prepareHeaderToken,
  }),
  endpoints: builder => ({
    inventory: builder.query<ConsignmentStockResponse, string>({
      query: (clientId = "") => `inventory/${clientId}`,
      transformResponse,
    }),
  }),
});

export const { useInventoryQuery } = consignmentStockApi;
