import React, { useEffect, useState } from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import MainHeader from "../../layout/components/Col/MainHeader";
import i18next from "i18next";
import CheckoutBreadcrumb from "./CheckoutBreadcrumb";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import {
  initCheckout,
  placeOrder,
  removeCouponFromOrder,
  setBstnkAction,
  setCouponCodeAction,
  setCouponToOrder,
  setGtcAction,
  setShippingMethodToShipmentsOfCart,
} from "../actions/checkoutActions";
import { Addresses } from "../../commercelayer/vo/addresses";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getPaymentMethod, getShippingMethod } from "../scripts/shippingpayment";
import Cart from "../../state/vo/Cart";
import { getBillingAddress, getShippingAddress } from "../scripts/addresses";
import Checkout from "../../state/vo/Checkout";
import LineItemList from "../../cart/components/LineItemList";
import CartHeader from "../../cart/components/CartHeader";
import OrderAddressSection from "../../order/components/OrderAddressSection";
import OrderShippingPaymentSection from "../../order/components/OrderShippingPaymentSection";
import OrderSumList from "../../order/components/OrderSumList";
import OrderTotal from "../../order/components/OrderTotal";
import { filterSkuCodesWithAvailabilityAlert } from "../../commercelayer/scripts/filter";
import AvailabilityAlertItem from "../../common/components/AvailabilityAlertItem";
import { scrollTop } from "../../layout/scripts/scroll";
import { getCheckoutLinks } from "../scripts/links";
import { Customer } from "../../commercelayer/vo/customers";
import OrderProcessingButton from "./OrderProcessingButton";
import { useSyncShippingMethod } from "../hooks/shippingMethods";
import CountrySpecific from "../../common/constants/CountrySpecific";
import { useCountry } from "../../router/hooks/useCountry";
import { OrderType } from "../../common/constants/OrderType";
import { setSapMaintenance } from "../../layout/actions/layoutActions";
import SapMaintenance from "../../layout/components/SapMaintenance";
import UserNotifications from "../../layout/components/UserNotifications";
import CstMessageCheckout from "./CstMessageCheckout";
import { APP_TEST_COUNTRIES } from "../../layout/constants/Countries";

const Verification: React.FC = () => {
  const params = useParams();
  const country = useCountry();
  const cartId = params[PARAMS.ORDER_ID];
  const cart: Cart = useSelector((state: State) => state.cart);
  const checkout: Checkout = useSelector((state: State) => state.checkout);
  const customer: Customer | null = useSelector((state: State) => state.account.customer);
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const commercelayerSkusIndex = useSelector((state: State) => state.commercelayer.skusIndex);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState<ShippingMethods | null>(
    getShippingMethod(cart),
  );

  const shippingAddress: Addresses | null = getShippingAddress(cart, cart.addresses);
  const billingAddress: Addresses | null = getBillingAddress(cart, cart.addresses);

  const shippingMethod: ShippingMethods | null = getShippingMethod(cart);
  const paymentMethod: PaymentMethods | null = getPaymentMethod(cart);

  const checkoutLinks = getCheckoutLinks(cartId);

  const skuCodesWithAvailabilityAlert = filterSkuCodesWithAvailabilityAlert(
    cart.lineItemsSkus,
    commercelayerSkusIndex,
    customer,
  );

  let isTestingCountry = false;
  if (APP_TEST_COUNTRIES.includes(country)) {
    isTestingCountry = true;
  }

  useSyncShippingMethod(OrderType.ORDER);

  useEffect(() => {
    async function handleUpdateShippingMethod() {
      if (shippingMethod === null && selectedShippingMethod !== null) {
        await setShippingMethodToShipmentsOfCart(selectedShippingMethod.id);
      }
      if (shippingMethod !== null) {
        if (shippingMethod !== selectedShippingMethod) {
          setSelectedShippingMethod(shippingMethod);
        }
      }
    }
    handleUpdateShippingMethod().then();
  }, [shippingMethod, selectedShippingMethod]);

  useEffect(() => {
    if (cartId !== null) {
      initCheckout(cartId);
    }
  }, [cartId]);

  useEffect(() => {
    setSapMaintenance();
  }, []);

  const onSubmitFormCoupon = function (e) {
    setCouponToOrder();
    e.preventDefault();
  };

  const onSubmitFormReference = function (e) {
    e.preventDefault();
  };

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <MainHeader title={i18next.t("CHECKOUT")} desktopShowClose={false} toHomeOnClose={true} />
      <Body>
        <Middle>
          <CheckoutBreadcrumb activeStep={2} links={checkoutLinks} />
          <SapMaintenance />
          <CstMessageCheckout />
          <UserNotifications />

          <OrderAddressSection
            orderId={cartId}
            orderType={OrderType.ORDER}
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            showEditLink={true}
            customerId={null}
          />

          <OrderShippingPaymentSection
            orderId={cartId}
            orderType={OrderType.ORDER}
            shippingMethod={shippingMethod}
            paymentMethod={paymentMethod}
            showEditLink={true}
            customerId={null}
          />

          <div className="grid-wrap grid-wrap--checkout">
            <div className="checkout-account-wrap">
              <h2 className="col-title">{i18next.t("CART")}</h2>
              <div className="cart cart--checkout ">
                <div className="cart-wrap">
                  <CartHeader />
                  {categoriesTree.map((category, index) => (
                    <LineItemList
                      cartId={cart.id}
                      category={category}
                      lineItems={cart.lineItemsSkus}
                      showUnitAmount={true}
                      showBatchNumber={false}
                      showAvailabilityAlert={true}
                      quantityIsEditable={true}
                      key={index}
                    />
                  ))}
                  <LineItemList
                    cartId={cart.id}
                    category={null}
                    lineItems={cart.lineItemsSkus}
                    showUnitAmount={true}
                    showBatchNumber={false}
                    showAvailabilityAlert={true}
                    quantityIsEditable={true}
                    key={-1}
                  />
                </div>

                {cart.attributes !== null && (
                  <div className="cart-checkout">
                    <div className="grid-1-2">
                      <form
                        style={{ display: "none" }}
                        className="form form__voucher"
                        onSubmit={onSubmitFormCoupon}
                      >
                        {cart.attributes.coupon_code === null && (
                          <>
                            <label className="label " htmlFor="voucher">
                              {i18next.t("COUPON")}
                            </label>
                            <input
                              type="text"
                              className="input--text"
                              id="voucher"
                              name="voucher"
                              value={checkout.couponCode}
                              placeholder={i18next.t("COUPON CODE")}
                              aria-required="false"
                              aria-label={i18next.t("COUPON CODE")}
                              onChange={e => setCouponCodeAction(e.target.value)}
                            />

                            <div
                              className="icon-btn icon-btn__refresh"
                              role="button"
                              onClick={() => setCouponToOrder()}
                              tabIndex={0}
                            >
                              <span className="visuallyhidden">{i18next.t("ADD COUPON")}</span>
                            </div>

                            {checkout.couponCode && checkout.couponCodeError !== null && (
                              <span className="form-error">{checkout.couponCodeError}</span>
                            )}
                          </>
                        )}

                        {cart.attributes.coupon_code !== null && (
                          <div
                            className="link-bold"
                            title={i18next.t("REMOVE COUPON")}
                            onClick={() => removeCouponFromOrder()}
                          >
                            {`${i18next.t("REMOVE COUPON")} ${cart.attributes.coupon_code}`}
                          </div>
                        )}
                      </form>

                      <form className="form form__reference" onSubmit={onSubmitFormReference}>
                        <label className="label " htmlFor="reference">
                          {i18next.t("ORDER REFERENCE")}
                        </label>
                        <input
                          type="text"
                          className="input--text"
                          id="reference"
                          name="reference"
                          maxLength={20}
                          placeholder={i18next.t("ORDER REFERENCE")}
                          aria-label={i18next.t("ORDER REFERENCE")}
                          aria-required="false"
                          value={checkout.bstnk}
                          onChange={e => setBstnkAction(e.target.value)}
                        />
                      </form>
                    </div>
                    <div className="grid-1-2">
                      <OrderSumList order={cart} />
                    </div>
                    <div className="cart-checkout__total-wrap">
                      <div className="grid-1-2">
                        {skuCodesWithAvailabilityAlert.length > 0 && <AvailabilityAlertItem />}
                      </div>

                      <div className="grid-1-2">
                        <OrderTotal order={cart} />
                        <div className="input--checkbox-wrap reverse">
                          <input
                            type="checkbox"
                            className="input--checkbox"
                            id="gtc"
                            name="gtc"
                            aria-label={`${i18next.t("AGREE TO GTC 0")}${i18next.t(
                              "AGREE TO GTC 1",
                            )}${i18next.t("AGREE TO GTC 2")}`}
                            aria-required="true"
                            aria-checked={checkout.gtc}
                            checked={checkout.gtc}
                            onChange={e => setGtcAction(e.target.checked)}
                          />
                          <label className="label label--checkbox" htmlFor="gtc">
                            {i18next.t("AGREE TO GTC 0")}
                            {i18next.exists(CountrySpecific[country].gtcUrlKey) ? (
                              <a
                                href={i18next.t(CountrySpecific[country].gtcUrlKey)}
                                target="_blank"
                                rel="noreferrer"
                                title={i18next.t("AGREE TO GTC 1")}
                              >
                                <span>{i18next.t("AGREE TO GTC 1")}</span>
                              </a>
                            ) : (
                              <span>{i18next.t("AGREE TO GTC 1")}</span>
                            )}
                            {i18next.t("AGREE TO GTC 2")}
                          </label>
                        </div>
                        {!checkout.gtc && checkout.gtcError !== null && (
                          <span className="form-error">{checkout.gtcError}</span>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="btn-wrap btn-wrap--line">
            <Link
              to={checkoutLinks[1]}
              className="btn transparent"
              role="button"
              tabIndex={0}
              onClick={() => scrollTop()}
            >
              <span className="btn__text">{`${i18next.t("SHIPPING")} & ${i18next.t(
                "PAYMENT",
              )}`}</span>
            </Link>

            {!checkout.orderProcessing && !checkout.orderUpdating && !isTestingCountry && (
              <button className="btn " onClick={placeOrder} tabIndex={0}>
                <span className="btn__text">{i18next.t("SEND ORDER")}</span>
              </button>
            )}
            {checkout.orderProcessing && <OrderProcessingButton />}
            {checkout.orderUpdating && (
              <button className="btn transparent" tabIndex={0}>
                <span className="btn__text">{`${i18next.t("CHECKOUT IS UPDATED")} ...`}</span>
              </button>
            )}
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default Verification;
