import Country from "../../common/constants/Country";
import React, { useState } from "react";
import DropdownListItem from "../../common/components/DropdownListItem";
import { countrySwitch } from "../actions/layoutActions";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useCountry } from "../../router/hooks/useCountry";
import { APP_COUNTRIES, APP_TEST_COUNTRIES } from "../constants/Countries";

const Countries: React.FC = () => {
  const [open, setOpen] = useState(false);
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const countryCode = useCountry();

  async function handleSwitch(country: string) {
    setOpen(false);
    await countrySwitch(country);
  }

  if (accountCustomer !== null) {
    return null;
  }

  return (
    <li
      className={"nav-list__item has-dropdown " + (open ? "open" : "")}
      onBlur={() => setOpen(false)}
      tabIndex={0}
    >
      <div className="dropdown-link" onClick={() => setOpen(!open)} title="Schweiz">
        {i18next.t("ISO_3166-1 " + countryCode)}
        <span className="dropdown-link-font icon-font icon-font__arrow-down" />
      </div>
      {open && (
        <div className="dropdown-list-wrapper">
          <ul className="dropdown-list">
            {Object.values(Country).map(country => {
              if (
                (APP_COUNTRIES.includes(country) && country.toUpperCase() !== countryCode) ||
                (APP_TEST_COUNTRIES.includes(country) && country.toUpperCase() !== countryCode)
              ) {
                return (
                  <DropdownListItem
                    text={i18next.t("ISO_3166-1 " + country)}
                    value={country}
                    key={country}
                    onClick={handleSwitch}
                  />
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </li>
  );
};

export default Countries;
