import { ResponseCustomersWithIncludes } from "../vo/customers";
import { fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { handleError401 } from "../error/error";
import { getAccessToken } from "../scripts/token";

export async function serviceGetCustomer(
  customerId: string,
): Promise<ResponseCustomersWithIncludes | null> {
  try {
    return await fetchService(
      HTTP_METHOD.GET,
      `api/customers/${customerId}?include=customer_addresses`,
      await getAccessToken(),
    );
  } catch (e) {
    handleError401(e);
    return null;
  }
}
