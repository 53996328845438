import * as Sentry from "@sentry/react";
import { getAppEnv } from "../config/app";
// init sentry client, see https://docs.sentry.io/platforms/javascript/#integrating-the-sdk

const dsnLocal = "https://3d2f18355e6645628f7dfc59c9ee0377@o109081.ingest.sentry.io/1273531";
const dsnProd = "https://be712c8fc8c047b0be2f8d95fc627875@o109081.ingest.sentry.io/5707782";

const dsn = process.env.NODE_ENV === "production" ? dsnProd : dsnLocal;

const environment = getAppEnv();

Sentry.init({
  dsn,
  environment,
});
