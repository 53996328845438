// helper for logging on sentry and console without repeating same messages all the time
// too many requests on sentry will cause a http status "429 Too many requests"
import * as Sentry from "@sentry/react";
import { SeverityLevel } from "@sentry/types";

const messagesLog: string[] = [];

function messageAlreadyLogged(message: string): boolean {
  return messagesLog.includes(message);
}

export function logSentryAndConsole(message: string, severity: SeverityLevel = "log"): void {
  // prevent when already logged this msg
  if (messageAlreadyLogged(message)) {
    return;
  }
  messagesLog.push(message);
  logBrowser(message, severity);
  Sentry.captureMessage(message, severity);
}

export function logSentryAndConsoleError(err: Error, severity: SeverityLevel = "log"): void {
  const message = err.message;
  messagesLog.push(message);
  logBrowser(message, severity);
  Sentry.captureException(err);
}

export function logConsoleOnce(message: string, severity: SeverityLevel = "log"): void {
  // prevent when already logged this msg
  if (messageAlreadyLogged(message)) {
    return;
  }
  messagesLog.push(message);
  logBrowser(message, severity);
}

export function devLogDebug(message?: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_ENABLE_DEV_LOG !== "true") {
    return;
  }

  console.debug(message, ...optionalParams);
}

export function devLogInfo(message?: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_ENABLE_DEV_LOG !== "true") {
    return;
  }

  console.info(message, ...optionalParams);
}

export function devLogDebugOnce(message?: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_ENABLE_DEV_LOG !== "true") {
    return;
  }

  // prevent when already logged this msg
  if (messageAlreadyLogged(message + JSON.stringify(optionalParams))) {
    return;
  }

  messagesLog.push(message + JSON.stringify(optionalParams));

  console.debug(message, ...optionalParams);
}

export function devLogInfoOnce(message?: any, ...optionalParams: any[]): void {
  if (process.env.REACT_APP_ENABLE_DEV_LOG !== "true") {
    return;
  }

  // prevent when already logged this msg
  if (messageAlreadyLogged(message + JSON.stringify(optionalParams))) {
    return;
  }

  messagesLog.push(message + JSON.stringify(optionalParams));

  console.info(message, ...optionalParams);
}

function logBrowser(message: string, severity: SeverityLevel = "log"): void {
  if (severity === "log") {
    console.log(message);
  }

  if (severity === "error") {
    console.error(message);
  }

  if (severity === "fatal") {
    console.error(message);
  }

  if (severity === "info") {
    console.info(message);
  }

  if (severity === "debug") {
    console.debug(message);
  }

  if (severity === "warning") {
    console.warn(message);
  }
}
