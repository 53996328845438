import { Category } from "../../graphcms/vo/graphCMS";
import { PARAMS } from "../../router/constants/Params";

export function isOpenCategory(category: Category, params): boolean {
  // open when parent category in url params
  let open = category.id === params[PARAMS.CATEGORY_ID];

  // also open when one of the children is selected
  category.categories.forEach(category => {
    if (category.id === params[PARAMS.CATEGORY_ID]) {
      open = true;
    }
  });

  return open;
}
