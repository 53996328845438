import React from "react";

import { isDirectProductView, isIndirectProductView } from "../../router/scripts/path";
import { getLinkToDirectProduct } from "../../category/scripts/link";

import { useSelectedProduct } from "../../product/hooks/product";
import { Helmet } from "react-helmet-async";

const Canonical: React.FC = () => {
  const product = useSelectedProduct();
  const domain: string = window.location.origin;
  const url: string = window.location.href;

  if (isIndirectProductView() && product !== null) {
    const href = `${domain}${getLinkToDirectProduct(product)}`;

    return (
      <Helmet>
        <link rel="canonical" href={href} />
      </Helmet>
    );
  }
  if (isDirectProductView() && product !== null && !url.includes(`/${product.slug}`)) {
    const href = `${domain}${getLinkToDirectProduct(product)}`;

    return (
      <Helmet>
        <link rel="canonical" href={href} />
      </Helmet>
    );
  } else return null;
};

export default Canonical;
