import React, { useRef } from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import SearchHit from "./SearchHit";
import { useOnClickOutside } from "../../common/scripts/hooks/useOnClickOutside";
import { setLiveSearchOpenAction } from "../../layout/actions/layoutActions";

const SearchOverlay: React.FC = () => {
  const layoutLiveSearchOpen = useSelector((state: State) => state.layout.liveSearchOpen);
  const searchQ = useSelector((state: State) => state.search.q);
  const searchResult = useSelector((state: State) => state.search.resultLive);

  const refSearchOverlayMain = useRef<HTMLDivElement>(null);
  useOnClickOutside(refSearchOverlayMain, () => {
    setLiveSearchOpenAction(false);
  });

  if (!layoutLiveSearchOpen) {
    return null;
  }

  if (searchQ.length === 0) {
    return null;
  }

  return (
    <div className="search-overlay">
      <div className="search-overlay__main" ref={refSearchOverlayMain}>
        <SearchHit
          maxProductResults={5}
          maxProductGroupResults={5}
          searchNbHits={searchResult.nbHits}
          searchProductHits={searchResult.productHits}
          searchProductGroupHits={searchResult.productGroupHits}
        />
      </div>
    </div>
  );
};

export default SearchOverlay;
