import Token from "../../login/vo/Token";
import { fetchService } from "./fetch";
import { HTTP_METHOD } from "../constants/HttpMethod";
import { getAccessTokenMarketAll } from "../scripts/token";
import { handleError401 } from "../error/error";

const COMMERCELAYER_AUTH_URL = process.env.REACT_APP_COMMERCELAYER_AUTH_URL;

export async function serviceGetToken(
  username: string,
  password: string,
  scope: string,
): Promise<Token> {
  const data = {
    grant_type: "password",
    username: username,
    password: password,
    client_id: `${process.env.REACT_APP_COMMERCELAYER_SALES_CHANNEL_CLIENT_ID}`,
    scope: scope,
  };
  const response = await fetch(`${COMMERCELAYER_AUTH_URL}/token`, {
    method: "POST",
    headers: {
      Accept: `application/json`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
  }
  return await response.json();
}

export async function serviceRefreshToken(refresh_token: string): Promise<Token> {
  const data = {
    grant_type: "refresh_token",
    refresh_token: refresh_token,
    client_id: `${process.env.REACT_APP_COMMERCELAYER_SALES_CHANNEL_CLIENT_ID}`,
  };
  const response = await fetch(`${COMMERCELAYER_AUTH_URL}/token`, {
    method: "POST",
    headers: {
      Accept: `application/json`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
  }
  return await response.json();
}

export async function serviceRevokeToken(refresh_token: string): Promise<any> {
  const data = {
    client_id: `${process.env.REACT_APP_COMMERCELAYER_SALES_CHANNEL_CLIENT_ID}`,
    token: refresh_token,
  };
  const response = await fetch(`${COMMERCELAYER_AUTH_URL}/revoke`, {
    method: "POST",
    headers: {
      Accept: `application/json`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    const message = `An error has occurred: ${response.status}`;
    throw new Error(message);
  }
  return;
}

export async function serviceGetUserinfo(): Promise<any> {
  try {
    return await fetchService(HTTP_METHOD.GET, `oauth/userinfo`, await getAccessTokenMarketAll());
  } catch (e) {
    await handleError401(e);
    return null;
  }
}

export async function serviceGetTokenInfo(): Promise<any> {
  try {
    return await fetchService(HTTP_METHOD.GET, `oauth/tokeninfo `, await getAccessTokenMarketAll());
  } catch (e) {
    await handleError401(e);
    return null;
  }
}
