import React from "react";
import i18next from "i18next";
import Order from "../../state/vo/Order";
import { getNumberWithCurrency, getVat } from "../../common/scripts/format/number";
import Cart from "../../state/vo/Cart";
import { useCountry } from "../../router/hooks/useCountry";
import CountrySpecific from "../../common/constants/CountrySpecific";

interface Props {
  order: Order | Cart | null;
}

const OrderSumList: React.FC<Props> = ({ order }) => {
  const country = useCountry();
  if (order === null || order.attributes === null) {
    return null;
  }

  let taxRateString = "";
  if (parseFloat(order.attributes.tax_rate) > 0) {
    taxRateString = ` ${getVat(order.attributes.tax_rate)}%`;
  }

  return (
    <dl className="dl-account dl-account--checkout">
      <dt className="dl-account__dt">
        <strong>{i18next.t("SUM")}</strong>
      </dt>
      <dd className="dl-account__dd">
        {getNumberWithCurrency(
          order.attributes.subtotal_amount_float,
          order.attributes.currency_code,
        )}
      </dd>
      {order.attributes.discount_amount_cents !== 0 && (
        <>
          <dt className="dl-account__dt">
            <strong>{i18next.t("DISCOUNT")}</strong>
          </dt>
          <dd className="dl-account__dd">
            {getNumberWithCurrency(
              order.attributes.discount_amount_float,
              order.attributes.currency_code,
            )}
          </dd>
        </>
      )}
      <dt className="dl-account__dt">
        <strong>{i18next.t("SHIPPING")}</strong>
      </dt>
      <dd className="dl-account__dd">
        {CountrySpecific[country].hasOwnProperty("checkoutHideShippingCost") &&
        CountrySpecific[country].checkoutHideShippingCost === true
          ? order.attributes.status === "placed" || order.attributes.status === "approved"
            ? i18next.t("SEE_INVOICE")
            : i18next.t("CALCULATED_AFTER_ORDER")
          : getNumberWithCurrency(
              order.attributes.shipping_amount_float,
              order.attributes.currency_code,
            )}
      </dd>
      {!CountrySpecific[country].hasOwnProperty("checkoutHideTax") ||
      CountrySpecific[country].checkoutHideTax !== true ? (
        <>
          <dt className="dl-account__dt">
            <strong>{i18next.t("SUBTOTAL WITHOUT VAT")}</strong>
          </dt>
          <dd className="dl-account__dd">
            {getNumberWithCurrency(
              order.attributes.total_amount_float,
              order.attributes.currency_code,
            )}
          </dd>
        </>
      ) : (
        <></>
      )}
      <dt className="dl-account__dt">
        {country === "US" ? (
          <strong>{`${i18next.t("TAXES")}${taxRateString}`}</strong>
        ) : (
          <strong>{`${i18next.t("VAT")}${taxRateString}`}</strong>
        )}
      </dt>
      <dd className="dl-account__dd">
        {CountrySpecific[country].hasOwnProperty("checkoutHideTax") ||
        CountrySpecific[country].checkoutHideTax === true
          ? order.attributes.status === "placed" || order.attributes.status === "approved"
            ? i18next.t("SEE_INVOICE")
            : i18next.t("CALCULATED_AFTER_ORDER")
          : getNumberWithCurrency(
              order.attributes.total_tax_amount_float,
              order.attributes.currency_code,
            )}
      </dd>
    </dl>
  );
};

export default OrderSumList;
