import React from "react";
import Layout from "../../layout/components/Layout";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Footer from "../../layout/components/Footer";
import Header from "../../layout/components/Header";
import MobileMenu from "../../layout/components/MobileMenu";
import SearchOverlay from "../../search/components/SearchOverlay";
import MainHeader from "../../layout/components/Col/MainHeader";
import i18next from "i18next";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { scrollTop } from "../../layout/scripts/scroll";
import { AccountFallback } from "../../account/components/AccountFallback";
import { Customer } from "../../commercelayer/vo/customers";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import MobileHeaderAccount from "../../account/components/MobileHeaderAccount";
import { PATH } from "../../router/constants/Path";
import Left from "../../layout/components/Col/Left";
import NavAccount from "../../account/components/NavAccount";
import ConsignmentStockBreadcrumb from "./ConsignmentStockBreadcrumb";
import { getLangCountry } from "../../router/scripts/link";
import Shipping from "../../checkout/components/Shipping";
import Payment from "../../checkout/components/Payment";
import ConsignmentStock from "../../state/vo/ConsignmentStock";
import { useInitConsignmentStockCheckout } from "consignment-stock/hooks";
import Checkout from "../../state/vo/Checkout";

const ConsignmentStockShippingPayment: React.FC = () => {
  const params = useParams();
  const consignmentStockOrderId = params[PARAMS.ORDER_ID];
  const consignmentStock: ConsignmentStock = useSelector((state: State) => state.consignmentStock);
  const customer: Customer | null = useSelector((state: State) => state.account.customer);
  const checkout: Checkout = useSelector((state: State) => state.checkout);

  useInitConsignmentStockCheckout();

  if (customer === null) {
    return <AccountFallback />;
  }

  return (
    <Layout>
      <Header />
      <MobileMenu />
      <SearchOverlay />
      <MainHeader title={i18next.t("CHECKOUT")} desktopShowClose={false} toHomeOnClose={true} />
      <Body>
        <MobileHeaderAccount activeLi={PATH.CONSIGNMENT_STOCK} customerId={customer.id} />
        <Left>
          <NavAccount activeLi={PATH.CONSIGNMENT_STOCK} customerId={customer.id} />
        </Left>
        <Middle>
          <h1 className="col-title">{i18next.t("CONSIGNMENT STOCK")}</h1>
          <ConsignmentStockBreadcrumb activeStep={2} />

          <div className="grid-wrap grid-wrap--checkout">
            <Shipping cart={consignmentStock.order} orderUpdating={checkout.orderUpdating} />
            <Payment cart={consignmentStock.order} orderUpdating={checkout.orderUpdating} />
          </div>

          <div className="btn-wrap btn-wrap--line">
            <div className="grid-1-2">
              <Link
                to={`${getLangCountry()}/${PATH.ACCOUNT}/${customer.id}/${PATH.CONSIGNMENT_STOCK}`}
                className="btn transparent"
                role="button"
                tabIndex={0}
                onClick={() => scrollTop()}
              >
                <span className="btn__text">{i18next.t("PRODUCT SELECTION")}</span>
              </Link>
            </div>
            <div className="grid-1-2 grid-1-2--right">
              <Link
                className="btn"
                aria-disabled="false"
                role="button"
                tabIndex={0}
                to={`${getLangCountry()}/${PATH.ACCOUNT}/${customer.id}/${
                  PATH.CONSIGNMENT_STOCK
                }/${consignmentStockOrderId}/${PATH.VERIFICATION}`}
                onClick={() => scrollTop()}
              >
                <span className="btn__text">{i18next.t("VERIFICATION")}</span>
              </Link>
            </div>
          </div>
        </Middle>
      </Body>
      <Footer />
    </Layout>
  );
};

export default ConsignmentStockShippingPayment;
