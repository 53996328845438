import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { ProductGroupViewType } from "../../graphcms/constants/ProductGroupViewType";
import { CellYTd } from "./CellYTd";
import { getProductGroupViewType } from "../../graphcms/scripts/productGroup";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

export const YAxisAttributeLeft: React.FC<{ productGroup: ProductGroup }> = ({ productGroup }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  const leftCells: any[] = [];
  const PGVT = getProductGroupViewType(productGroup);

  // case desktop and big picture
  if (!isMobile) {
    if (PGVT === ProductGroupViewType["3I-1d"]) {
      leftCells.push(<CellYTd key={1} />);
      leftCells.push(<CellYTd key={2} />);
      leftCells.push(<CellYTd key={3} />);
    }

    if (PGVT === ProductGroupViewType["1I-3d"]) {
      leftCells.push(<CellYTd key={1} />);
    }

    if (PGVT === ProductGroupViewType["2I-2d"]) {
      leftCells.push(<CellYTd key={1} />);
      leftCells.push(<CellYTd key={2} />);
    }

    if (PGVT === ProductGroupViewType["4I"]) {
      leftCells.push(<CellYTd key={1} />);
      leftCells.push(<CellYTd key={2} />);
      leftCells.push(<CellYTd key={3} />);
      leftCells.push(<CellYTd key={4} />);
    }
  }

  // case mobile
  if (isMobile) {
    if (PGVT === ProductGroupViewType["4I"]) {
      leftCells.push(<CellYTd key={1} />);
    }

    if (PGVT === ProductGroupViewType["4i"]) {
      leftCells.push(<CellYTd key={1} />);
    }
  }

  return <>{leftCells}</>;
};
