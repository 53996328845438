import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { ProductGroupViewType } from "../../graphcms/constants/ProductGroupViewType";
import { CommonImg } from "../../common/components/CommonImg";
import { ImgTwoCols } from "./ImgTwoCols";
import { ImgOneCol } from "./ImgOneCol";

interface Props {
  productGroup: ProductGroup;
  children: React.ReactNode;
}

const DetailTableWrapper: React.FC<Props> = ({ productGroup, children }) => {
  const PGVT = productGroup?.productGroupViewType?.name;

  // case for one large image
  if (
    PGVT === ProductGroupViewType["3I-1d"] ||
    PGVT === ProductGroupViewType["1I-3d"] ||
    PGVT === ProductGroupViewType["2I-2d"] ||
    PGVT === ProductGroupViewType["4I"]
  ) {
    // default case for ProductGroupViewType["3I-1d"]
    let className = "";
    let img = <></>;

    if (PGVT === ProductGroupViewType["3I-1d"]) {
      img = <CommonImg handle={productGroup?.image?.handle} alt={productGroup?.name} />;
    }

    if (PGVT === ProductGroupViewType["1I-3d"]) {
      className = "img1col";
      img = <ImgOneCol handle={productGroup?.image?.handle} alt={productGroup?.name} />;
    }

    if (PGVT === ProductGroupViewType["2I-2d"]) {
      className = "img2cols";
      img = <ImgTwoCols handle={productGroup?.image?.handle} alt={productGroup?.name} />;
    }

    if (PGVT === ProductGroupViewType["4I"]) {
      className = "img4cols";
      img = <CommonImg handle={productGroup?.image?.handle} alt={productGroup?.name} />;
    }

    return (
      <div className={`product-table-wide-img-wrap ${className}`}>
        <div className="product-table-wide-img">
          <div className="img-wrap">{img}</div>
        </div>
        {children}
      </div>
    );
  }

  // other ProductGroupViewType are handled by the row by row render components
  return <>{children}</>;
};

export default DetailTableWrapper;
