import React from "react";
import { Category } from "../../graphcms/vo/graphCMS";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import i18next from "i18next";
import { LineItems } from "../../commercelayer/vo/lineItems";
import LineItem from "./LineItem";
import { Customer } from "../../commercelayer/vo/customers";

interface Props {
  cartId: string | null;
  category: Category | null;
  lineItems: LineItems[];
  showUnitAmount: boolean;
  showBatchNumber: boolean;
  showAvailabilityAlert: boolean;
  quantityIsEditable: boolean;
}

const LineItemList: React.FC<Props> = ({
  cartId,
  category,
  lineItems,
  showUnitAmount,
  showBatchNumber,
  showAvailabilityAlert,
  quantityIsEditable,
}) => {
  const graphcmsProductsIndex = useSelector((state: State) => state.graphcms.productsIndex);
  const customer: Customer | null = useSelector((state: State) => state.account.customer);

  if (cartId == null) {
    return null;
  }

  const cartCategoryLineItems = lineItems.filter(lineItem => {
    const sku = lineItem.attributes.sku_code;
    const product = graphcmsProductsIndex[sku];
    if (product === null || product === undefined) {
      return false;
    }
    const productGroup = graphcmsProductsIndex[sku].productGroup;
    if (productGroup === null || productGroup === undefined) {
      // Has no productGroup -> render in category = null
      return category === null;
    }

    if (productGroup?.breadcrumbCategories.length > 0) {
      if (category === null) {
        // Has a breadcrumbCategory -> do not render in category = null
        return false;
      }
      return productGroup?.breadcrumbCategories[0] === category.id;
    }
    // Has no breadcrumbCategory -> render in category = null
    return category === null;
  });

  if (cartCategoryLineItems.length === 0) {
    return null;
  }

  let categoryTitle = i18next.t("OTHER");
  if (category !== null) {
    categoryTitle = category.name;
  }

  return (
    <>
      <div className="block-title">{categoryTitle}</div>
      <ul className="cart-list">
        {cartCategoryLineItems.map((lineItem, index) => (
          <LineItem
            cartId={cartId}
            lineItem={lineItem}
            product={graphcmsProductsIndex[lineItem.attributes.sku_code]}
            showUnitAmount={showUnitAmount}
            showBatchNumber={showBatchNumber}
            showAvailabilityAlert={showAvailabilityAlert}
            quantityIsEditable={quantityIsEditable}
            customer={customer}
            key={lineItem.id}
          />
        ))}
      </ul>
    </>
  );
};

export default LineItemList;
