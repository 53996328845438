import LayerIo from "../../common/services/LayerIo";
import { getCountry, getLang } from "../../router/scripts/params";
import { setCustomers, setExports, setLogin, setRole } from "../slices/salesagentSlice";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import {
  logout,
  redirectCountryByPltyp,
  setTokenMarketAllStateAndStorage,
  setTokenStateAndStorage,
} from "../../login/actions/loginActions";
import { toast } from "react-toastify";
import { boundPushLocObj, boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import i18next from "i18next";
import { getState } from "../../state/scripts/state";
import SalesAgentCustomer from "../vo/SalesAgentCustomer";
import { getCustomerWithId, resetAccount } from "../../account/actions/accountActions";
import { initCart, resetCart } from "../../cart/actions/cartActions";
import { setCartOpen } from "../../layout/slices/layoutSlice";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { serviceGetCustomerAddressesSalesAgent } from "../../commercelayer/services/addresses";
import {
  CustomerAddresses,
  ResponseCustomerAddressesListWithIncludes,
} from "../../commercelayer/vo/customerAddresses";
import { urlLoginDashboard } from "../scripts/urls";
import { gtmPushLoginSalesAgent } from "../../common/scripts/tagManager/dataLayer";
import {
  initConsignmentStockOrder,
  resetConsignmentStockOrder,
} from "../../consignment-stock/actions/consignmentStockActions";
import { serviceGetTokenInfo } from "../../commercelayer/services/token";
import { serviceCreateExportOrders, serviceGetExports } from "../../commercelayer/services/exports";
import { ResponseExportsList } from "../../commercelayer/vo/exports";

export async function getLoginMarketAndState(email: string = "", customerId: string = "") {
  try {
    const response = await LayerIo.requestJsonRpc<any, any>(
      "sales-agent",
      "getLoginMarketAndState",
      {
        country: getCountry(),
        lang: getLang(),
        email: email,
        customerId: customerId,
      },
    );
    dispatch(setLogin(response.data));
    return response.data;
  } catch (e) {
    // Log error to console
    console.log(e);
  }
}

export async function getSalesAgentToken(code: string, stateId: string) {
  try {
    const response = await LayerIo.requestJsonRpc<any, any>("sales-agent", "getSalesAgentToken", {
      code,
      stateId,
    });
    if (!response.data.success) {
      toast.error(i18next.t("LOGIN WAS NOT SUCCESSFUL"), {
        position: "top-center",
        autoClose: 10000,
      });
      await logout();
      boundReplaceLocObj({
        pathname: `${getLangCountry()}/${PATH.SALES_AGENT}/${PATH.LOGIN}`,
      });
    }

    if (response.data.success) {
      // Login was successful
      if (response.data.token.scope === "market:all") {
        await setTokenMarketAllStateAndStorage(response.data.token);
        gtmPushLoginSalesAgent(response.data.token);
      } else {
        await setTokenStateAndStorage(response.data.token);
      }

      const customerId = response.data.customerId ?? "";
      if (customerId === "") {
        boundReplaceLocObj({
          pathname: `${getLangCountry()}/${PATH.SALES_AGENT}/${PATH.CUSTOMERS}`,
        });
      } else {
        await redirectToCustomer(customerId);
      }
    }
  } catch (e) {
    // Log error to console
    console.log(e);
  }
}

export async function getRoleInfo() {
  const responseTokenInfo = await serviceGetTokenInfo();
  dispatch(setRole(responseTokenInfo.role));
}

export async function getCustomers(filterStrings: string[]) {
  const loginState = getState().login;
  if (!loginState.tokenMarketAll || loginState.tokenMarketAll.owner_type !== OWNER_TYPE.USER) {
    return;
  }

  let salesagentState = getState().salesagent;
  if (salesagentState.role === null) {
    await getRoleInfo();
    salesagentState = getState().salesagent;
  }

  const response: ResponseCustomerAddressesListWithIncludes | null =
    await serviceGetCustomerAddressesSalesAgent(filterStrings, salesagentState.role?.name || "");
  if (response === null) {
    return;
  }

  const data = response.data;
  const included = response.included ?? [];

  const responseCustomers = included.filter(d => {
    return d.type === "customers";
  });
  const responseAddresses = included.filter(d => {
    return d.type === "addresses";
  });

  const customers: SalesAgentCustomer[] = data.map((customerAddresses: CustomerAddresses) => {
    const customerId = customerAddresses.relationships.customer.data?.id;
    const addressId = customerAddresses.relationships.address.data?.id;

    const customer = responseCustomers.filter(customer => {
      return customer.id === customerId;
    })[0];
    const address = responseAddresses.filter(address => {
      return address.id === addressId;
    })[0];

    const salesAgentCustomer: SalesAgentCustomer = {
      customerId: customerId ?? "",
      status: customer.status,
      email: customer.attributes.email,
      reference: customer.attributes.reference,
      name: customerAddresses.attributes.name,
      company: address.attributes.company,
      notes: address.attributes.notes,
      zip_code: address.attributes.zip_code,
      city: address.attributes.city,
      sort1: customer.attributes.metadata["s_dbt_hdr-sort1"] ?? address.attributes.reference,
      metadata: customer.attributes.metadata,
    };

    return salesAgentCustomer;
  });

  dispatch(setCustomers(customers));
}

export async function createExportOrders(dateFrom: Date, dateTo: Date) {
  const dateFromHours: Date = new Date(dateFrom.setUTCHours(0, 0, 0, 0));
  const dateToHours: Date = new Date(dateTo.setUTCHours(23, 59, 59, 999));
  await serviceCreateExportOrders(dateFromHours, dateToHours);
}

export async function getExports() {
  const loginState = getState().login;
  if (!loginState.tokenMarketAll || loginState.tokenMarketAll.owner_type !== OWNER_TYPE.USER) {
    return;
  }

  const response: ResponseExportsList | null = await serviceGetExports();
  if (response === null) {
    return;
  }

  dispatch(setExports(response.data));
}

export async function resetLogin() {
  dispatch(setLogin(null));
}

export async function resetCustomers() {
  dispatch(setCustomers([]));
}

export async function startAsCustomer(customer: SalesAgentCustomer) {
  const loginState = getState().login;
  if (!loginState.tokenMarketAll || loginState.tokenMarketAll.owner_type !== OWNER_TYPE.USER) {
    return;
  }

  const scopeResponse = await LayerIo.requestJsonRpc<any, any>(
    "commercelayer-login",
    "getScopeByUser",
    {
      email: customer.email,
    },
  );

  const marketId = scopeResponse?.data?.marketId;
  if (!marketId) {
    return;
  }

  const scopeCurrent = loginState.token?.scope ?? null;
  if (`market:id:${marketId}` !== scopeCurrent) {
    const salesagentLogin = await getLoginMarketAndState(customer.email, customer.customerId);

    window.location.href = urlLoginDashboard(salesagentLogin);
    return;
  }

  await redirectToCustomer(customer.customerId);
}

export async function redirectToCustomer(customerId: string) {
  await getCustomerWithId(customerId);
  const accountCustomer = getState().account.customer;
  boundPushLocObj({
    pathname: `${getLangCountry()}/${PATH.ACCOUNT}/${customerId}`,
  });
  await redirectCountryByPltyp(accountCustomer);
  await initCart();
  await initConsignmentStockOrder();
}

export async function exitAsCustomer() {
  await resetAccount();
  await resetCart();
  await resetConsignmentStockOrder();
  dispatch(setCartOpen(false));
}
