import React from "react";
import { Link } from "react-router-dom";

const ArrowDown: React.FC = () => <span className="icon-font icon-font__arrow-down" />;

export interface BreadcrumbDropdownValue {
  title: string;
  to: string;
  active: boolean;
}

interface Props {
  dropdownValues: BreadcrumbDropdownValue[];
}

const BreadcrumbDropdownList: React.FC<Props> = ({ dropdownValues }) => (
  <ul className="dropdown-list">
    {dropdownValues.map((dropdownValue, i) => (
      <li className="dropdown-list__item" key={i}>
        <Link
          className={`dropdown-list__link ${dropdownValue.active ? "active" : ""}`}
          title={dropdownValue.title}
          to={dropdownValue.to}
        >
          {dropdownValue.title}
        </Link>
      </li>
    ))}
  </ul>
);

const BreadcrumbDropdown: React.FC<Props> = ({ dropdownValues }) => (
  <div className="dropdown-list-wrapper">
    <div className="dropdown-list-close">
      <ArrowDown />
    </div>
    <BreadcrumbDropdownList dropdownValues={dropdownValues} />
  </div>
);

export default BreadcrumbDropdown;
