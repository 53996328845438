import React from "react";
import i18next from "i18next";
import imgListIcon from "../../templates/public/images/layout/icon.svg";

interface Props {
  email: string;
}

const OnboardingSteps: React.FC<Props> = ({ email }) => {
  return (
    <>
      <div className="grid__text">
        {i18next.t("APPLY FOR A CUSTOMER ACCOUNT")}
        <br />
        {i18next.t("YOUR STEPS AT A GLANCE")}:
      </div>
      <div className="text text__col-1-2">
        <div className="text-col">
          <img src={imgListIcon} className="text__icon" alt={`${i18next.t("STEP")} 1`} />
        </div>
        <div className="text-col">
          <p className="text__title">{`${i18next.t("STEP")} 1`}</p>
          <p className="text__text">{i18next.t("ONBOARDING STEP 1", { email })}</p>
        </div>
      </div>

      <div className="text text__col-1-2">
        <div className="text-col">
          <img src={imgListIcon} className="text__icon" alt={`${i18next.t("STEP")} 2`} />
        </div>
        <div className="text-col">
          <p className="text__title">{`${i18next.t("STEP")} 2`}</p>
          <p className="text__text">{i18next.t("ONBOARDING STEP 2")}</p>
        </div>
      </div>

      <div className="text text__col-1-2">
        <div className="text-col">
          <img src={imgListIcon} className="text__icon" alt={`${i18next.t("STEP")} 3`} />
        </div>
        <div className="text-col">
          <p className="text__title">{`${i18next.t("STEP")} 3`}</p>
          <p className="text__text">{i18next.t("ONBOARDING STEP 3")}</p>
        </div>
      </div>
    </>
  );
};

export default OnboardingSteps;
