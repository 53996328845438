import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { getNrOfPfs } from "../../graphcms/scripts/productGroup";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getUniqueSortedAttrValues } from "../../graphcms/scripts/attribute";

interface Props {
  productGroup: ProductGroup;
}

export const HeaderYRight: React.FC<Props> = ({ productGroup }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);

  // abort for mobile for layout / design reasons
  if (isMobile) {
    return null;
  }

  // add as many tdElements as pfs to fill cell gaps
  let nrOfTdElementsToFill = getNrOfPfs(productGroup);

  // special case for product groups that have no pfs
  if (nrOfTdElementsToFill === 0) {
    // defaulting to 6 cells when no productGroup.xAxisAttribute a
    nrOfTdElementsToFill = 6;

    productGroup?.xAxisAttributes?.forEach(xAttribute => {
      const xAttrName = xAttribute?.name;

      if (!xAttrName) {
        return 0;
      }

      // sorted with the similar logic like the productRow but added the unit when available
      const sortedValuesWithUnit = getUniqueSortedAttrValues(productGroup, xAttrName);

      nrOfTdElementsToFill = sortedValuesWithUnit.length;
    });
  }

  const tdElements: any[] = [];

  for (let i = 0; i < nrOfTdElementsToFill; i++) {
    tdElements.push(<td key={i} />);
  }

  return <>{tdElements}</>;
};
