import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import MethodInfoText from "./MethodInfoText";
import { getLang } from "../../router/scripts/params";

interface Props {
  method: ShippingMethods | PaymentMethods | null;
}

const OptionShippingPaymentDisplay: React.FC<Props> = ({ method }) => {
  const [info, setInfo] = useState<React.ReactNode | null>(null);
  const [reference, setReference] = useState("");
  const lang = getLang();

  useEffect(() => {
    if (!method) {
      return;
    }

    if (!method?.attributes?.reference) {
      return;
    }
    setReference(method.attributes.reference);
  }, [method]);

  useEffect(() => {
    setInfo(<MethodInfoText method={method} />);
  }, [method, lang]);

  return (
    <div className="text">
      {reference && <p className="text__title">{i18next.t(`CL_METHOD_${reference}`)}</p>}
      {info && <p className="text__text">{info}</p>}
    </div>
  );
};

export default OptionShippingPaymentDisplay;
