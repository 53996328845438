import React from "react";

import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { loadReport } from "../actions/accountActions";

import { useForm } from "react-hook-form";

type ReportData = {
  dateFrom: Date;
  dateTo: Date;
};

interface Props {
  customerId: string;
}

const AccountReportForm: React.FC<Props> = ({ customerId }) => {
  const { register, handleSubmit } = useForm<ReportData>();
  const accountReportLoading = useSelector((state: State) => state.account.reportLoading);

  const onSubmit = handleSubmit((data: ReportData) => {
    loadReport(customerId, data.dateFrom, data.dateTo);
  });

  return (
    <form onSubmit={onSubmit} autoComplete="off">
      <div className="date-filter">
        <div className="date-input-wrap">
          <label className="label label--date" htmlFor="date-start">
            {i18next.t("FROM")}
          </label>
          <input
            type="date"
            className="input--date"
            defaultValue={new Date(
              new Date().getFullYear(),
              0,
              1,
              0,
              -new Date().getTimezoneOffset(),
              0,
            )
              .toISOString()
              .slice(0, 10)}
            required
            aria-required="true"
            aria-label={i18next.t("FROM")}
            {...register("dateFrom", { required: true, valueAsDate: true })}
          />
        </div>
        <div className="date-input-wrap">
          <label className="label label--date" htmlFor="date-end">
            {i18next.t("TO")}
          </label>
          <input
            type="date"
            className="input--date"
            defaultValue={new Date().toISOString().slice(0, 10)}
            required
            aria-required="true"
            aria-label={i18next.t("TO")}
            {...register("dateTo", { required: true, valueAsDate: true })}
          />
        </div>
        {accountReportLoading && (
          <button className="btn transparent">{`${i18next.t("LOADING")}...`}</button>
        )}
        {!accountReportLoading && (
          <button type="submit" className="btn ">
            {i18next.t("LOAD NOW")}
          </button>
        )}
      </div>
    </form>
  );
};

export default AccountReportForm;
