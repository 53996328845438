import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Layout from "../../state/vo/Layout";
import { OVERLAY } from "../constants/Overlay";
import { getState } from "../../state/scripts/state";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";

const name = "layout";
const initialState = store.layout;

const layoutSlice = createSlice({
  name,
  initialState,
  reducers: {
    setIsMobile(state: Layout, action: PayloadAction<boolean>) {
      state.isMobile = action.payload;
    },
    setCookies(state: Layout, action: PayloadAction<string>) {
      state.cookies = action.payload;
    },
    setCartOpen(state: Layout, action: PayloadAction<boolean>) {
      state.cartOpen = action.payload;
    },
    setLiveSearchOpen(state: Layout, action: PayloadAction<boolean>) {
      state.liveSearchOpen = action.payload;
    },
    setOverlayOpen(state: Layout, action: PayloadAction<OVERLAY | null>) {
      state.overlayOpen = action.payload;
    },
    setShowConsignmentStockInfo(state: Layout, action: PayloadAction<boolean>) {
      state.showConsignmentStockInfo = action.payload;
    },
    setSapMaintenanceEnabled(state: Layout, action: PayloadAction<boolean>) {
      state.sapMaintenanceEnabled = action.payload;
    },
  },
});

export function setIsMobile(isMobile: boolean) {
  if (getState().layout.isMobile === isMobile) {
    return;
  }
  dispatch(layoutSlice.actions.setIsMobile(isMobile));
}
export const setCookies = layoutSlice.actions.setCookies;
export const setCartOpen = layoutSlice.actions.setCartOpen;
export const setLiveSearchOpen = layoutSlice.actions.setLiveSearchOpen;
export const setOverlayOpen = layoutSlice.actions.setOverlayOpen;
export const setShowConsignmentStockInfo = layoutSlice.actions.setShowConsignmentStockInfo;
export const setSapMaintenanceEnabled = layoutSlice.actions.setSapMaintenanceEnabled;

export default layoutSlice.reducer;
