import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { Link } from "react-router-dom";
import { PATH } from "../../router/constants/Path";
import { exitAsCustomer } from "../../salesagent/actions/salesagentActions";
import i18next from "i18next";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const SalesAgentTopBar: React.FC = () => {
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const login = useSelector((state: State) => state.login);
  const langCountry = useLangCountry();

  if (login.token == null) {
    return null;
  }
  if (login.token.owner_type === OWNER_TYPE.CUSTOMER) {
    return null;
  }
  if (accountCustomer === null) {
    return null;
  }

  return (
    <div className="top-bar ">
      <div className="top-container">
        <img src="../../../images/layout/alert.svg" alt="" className="top-bar__icon" />
        <p className="top-bar__text">
          <strong>{i18next.t("LOGGED IN AS")}</strong>
          {` ${accountCustomer.attributes.email}, ${i18next.t("CUSTOMER ID")}: ${
            accountCustomer.attributes.reference
          }`}
        </p>
        <Link
          to={`${langCountry}/${PATH.SALES_AGENT}/${PATH.CUSTOMERS}`}
          onClick={() => exitAsCustomer()}
          className="top-bar__link"
        >
          Exit
          <span className="icon-font icon-font__exit" aria-hidden="true" />
        </Link>
      </div>
    </div>
  );
};

export default SalesAgentTopBar;
