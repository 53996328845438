import ElstrCore from "elstr-frontend-4/dist/ElstrCore";
import { SEARCH } from "../constants/Search";
import { PayloadAction } from "@reduxjs/toolkit";

function getSearchAction(search: string, action: PayloadAction<any>) {
  ElstrCore.history.replace({ search });

  return {
    ...action,
    payload: {
      ...action.payload,
      location: {
        ...action.payload.location,
        search,
      },
    },
  };
}

// this handles browser forward / backward and keeping focus= in the search of the url
export const retainFocusSearchParam = store => next => action => {
  // only relevant for router actions
  if (action.type !== "@@router/LOCATION_CHANGE") {
    return next(action);
  }

  const search: string | undefined = store.getState()?.router?.location?.search;
  const payloadSearch: string = action?.payload?.location?.search;

  if (search?.includes(`${SEARCH.FOCUS}=`) || payloadSearch.includes(`${SEARCH.FOCUS}=`)) {
    // only update search if it changed
    if (search !== payloadSearch) {
      if (["PUSH", "POP"].includes(action.payload.action)) {
        return next(getSearchAction(search ?? payloadSearch, action));
      }
    }
  }

  return next(action);
};
