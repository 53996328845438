import { LineItems } from "../../commercelayer/vo/lineItems";
import { Category, CountryCode, Product, ProductGroup } from "../../graphcms/vo/graphCMS";
import { getCountry } from "../../router/scripts/params";

export function countProductInLineItems(sku: string, lineItems: LineItems[]): number {
  let counter = 0;

  for (const lineItem of lineItems) {
    if (lineItem.attributes.sku_code === sku) {
      counter += lineItem.attributes.quantity;
    }
  }

  return counter;
}

export function isInCountryCodes(product: Product, country = getCountry()): boolean {
  country = String(country).toUpperCase();
  return product?.countryCodes?.includes(country as CountryCode);
}

interface MatchingRestriction {
  productGroupId: string;
  skus: string[];
}

function getMatchingRestrictionSkus(
  productGroup: ProductGroup,
  relatedCategory?: Category,
): string[] {
  let matchingRestrictionSkus: string[] = [];

  // an empty matchingRestrictionSkus means that no further match is needed
  if (relatedCategory && relatedCategory?.matchingRestrictions?.length > 0) {
    relatedCategory.matchingRestrictions.forEach((matchingRestriction: MatchingRestriction) => {
      // nothing to do when not matching productGroupId
      if (productGroup?.id !== matchingRestriction?.productGroupId) {
        return;
      }

      matchingRestrictionSkus = matchingRestriction.skus;
    });
  }

  return matchingRestrictionSkus;
}

export function getMatchingProducts(
  productGroup: ProductGroup,
  relatedCategory?: Category,
): Product[] {
  const matchingRestrictionSkus = getMatchingRestrictionSkus(productGroup, relatedCategory);

  // fallback for wrong data
  if (!matchingRestrictionSkus) {
    return productGroup?.products ?? [];
  }

  // an empty matchingRestrictionSkus means that no further match is needed
  // and all products are matching
  if (matchingRestrictionSkus.length === 0 && productGroup?.products) {
    return productGroup?.products ?? [];
  }

  // only return matchingRestrictionSkus
  // only add when product.sku is in matchingRestrictionSkus List
  const products =
    productGroup?.products?.filter(product => matchingRestrictionSkus?.includes(product?.sku)) ??
    [];

  return products;
}
