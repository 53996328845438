import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Login from "../../state/vo/Login";
import Token from "../vo/Token";

const name = "login";
const initialState = store.login;

const loginSlice = createSlice({
  name,
  initialState,
  reducers: {
    setToken(state: Login, action: PayloadAction<Token | null>) {
      state.token = action.payload;
    },
    setTokenMarketAll(state: Login, action: PayloadAction<Token | null>) {
      state.tokenMarketAll = action.payload;
    },
    setPathnameAfterLogin(state: Login, action: PayloadAction<string | null>) {
      state.pathnameAfterLogin = action.payload;
    },
  },
});

export const setToken = loginSlice.actions.setToken;
export const setTokenMarketAll = loginSlice.actions.setTokenMarketAll;
export const setPathnameAfterLogin = loginSlice.actions.setPathnameAfterLogin;

export default loginSlice.reducer;
