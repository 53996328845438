import React from "react";
import Body from "../../layout/components/Body";
import Middle from "../../layout/components/Col/Middle";
import Right from "../../layout/components/Col/Right";
import DetailMain from "./DetailMain";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import CartOverlay from "../../cart/components/CartOverlay";
import { devLogDebugOnce } from "../../common/scripts/logger/log";
import {
  getRelatedCategory,
  getRelatedCategoryIdFromProductGroup,
} from "../../graphcms/scripts/category";
import Focus from "../../category/components/Focus";
import { getProductGroupIdWhileDirectLink } from "../../graphcms/scripts/productGroup";
import DetailRelatedWrapper from "./DetailRelatedWrapper";
import { toast } from "react-toastify";
import i18next from "i18next";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { useSelectedProduct } from "../hooks/product";
import { gtmPushViewItem } from "../../common/scripts/tagManager/dataLayer";
import { CountryCode, ProductGroup } from "../../graphcms/vo/graphCMS";
import { useCountry } from "../../router/hooks/useCountry";
import RepSingleProduct from "../../router/components/replace/RepSingleProduct";
import BreadcrumbInline from "./BreadcrumbInline";
import BreadcrumbCategories from "./BreadcrumbCategories";

const ProductMainBody: React.FC = () => {
  const params = useParams();
  const categoriesTree = useSelector((state: State) => state.graphcms.categoriesTree);
  const productGroupsIndex = useSelector((state: State) => state.graphcms.productGroupsIndex);
  const product = useSelectedProduct();

  // @ts-ignore
  const countryCode: CountryCode = useCountry();

  React.useEffect(() => {
    if (product) {
      gtmPushViewItem(product);
    }
  }, [product]);

  // this mainProductGroupId is the main leader for deciding which is the top shown productGroup
  const mainProductGroupId =
    params?.[PARAMS.PRODUCTGROUP_ID] ?? getProductGroupIdWhileDirectLink(params);

  if (!mainProductGroupId) {
    toastAndRedirect();
    return null;
  }

  const mainProductGroup = productGroupsIndex?.[mainProductGroupId];

  if (!mainProductGroup) {
    toastAndRedirect();
    return null;
  }

  const relatedCategoryId =
    params?.[PARAMS.CATEGORY_ID] ?? getRelatedCategoryIdFromProductGroup(mainProductGroupId);

  if (!relatedCategoryId) {
    toastAndRedirect();
    return null;
  }

  const relatedCategory = getRelatedCategory(categoriesTree, relatedCategoryId);

  const relatedProductGroups: ProductGroup[] =
    relatedCategory?.productGroups
      // don't include the mainProductGroupId
      .filter((productGroup: ProductGroup) => productGroup?.id !== mainProductGroupId)
      // only including country
      .filter((productGroup: ProductGroup) => productGroup?.countryCodes?.includes(countryCode))
      // does actually exist (important when changing countries and during state / data transitions)
      .filter((productGroup: ProductGroup) => productGroupsIndex[productGroup?.id])
      // assign from productGroupsIndex
      .map((productGroup: ProductGroup) => productGroupsIndex[productGroup?.id]) ?? [];

  devLogDebugOnce("mainProductGroup", mainProductGroup);
  devLogDebugOnce("relatedProductGroups", relatedProductGroups);
  devLogDebugOnce("relatedCategory", relatedCategory);

  return (
    <Body>
      {mainProductGroup ? (
        <Middle>
          <BreadcrumbInline>
            <BreadcrumbCategories
              mainProductGroup={mainProductGroup}
              relatedProductGroups={relatedProductGroups}
              setDropDownOpen={() => {}}
            />
          </BreadcrumbInline>
          {/*<BreadcrumbOld mainProductGroup={mainProductGroup} />*/}
          <Focus />
          <DetailMain productGroup={mainProductGroup} />
          <DetailRelatedWrapper
            relatedProductGroups={relatedProductGroups}
            relatedCategory={relatedCategory}
          />
          <RepSingleProduct mainProductGroup={mainProductGroup} />
        </Middle>
      ) : null}
      <Right>
        <CartOverlay />
      </Right>
    </Body>
  );
};

export default ProductMainBody;

function toastAndRedirect() {
  const delayTime = 10000;

  toast.warn(i18next.t("NO PRODUCT DATA FOUND AND REDIRECTING"), {
    position: "top-center",
    toastId: "missing-data",
    autoClose: delayTime,
  });

  setTimeout(function redirectToStart() {
    boundPushLocObj({ pathname: getLangCountry() });
  }, delayTime);
}
