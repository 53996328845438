import React from "react";
import NavListItemLink from "./NavListItemLink";
import i18next from "i18next";
import { PATH } from "../../router/constants/Path";
import { useLangCountry } from "../../router/hooks/useLangCountry";

const NavMetaBig: React.FC = () => {
  const langCountry = useLangCountry();
  return (
    <nav className="nav-meta--big">
      <ul className="nav-list">
        <NavListItemLink
          text={i18next.t("CONFIGURATOR")}
          to={`${langCountry}/${PATH.CONFIGURATOR}`}
        />
        <NavListItemLink
          text={i18next.t("SCAN-TO-CART")}
          to={`${langCountry}/${PATH.SCANTOCART}`}
        />
      </ul>
    </nav>
  );
};

export default NavMetaBig;
