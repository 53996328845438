import { filterShippingMethodsNonBackorder } from "./shippingpayment";
import { StorageKeys } from "../../common/constants/StorageKeys";
import { setShippingMethodToShipmentsOfCart } from "../actions/checkoutActions";
import { OrderType } from "../../common/constants/OrderType";
import { getState } from "../../state/scripts/state";
import { setShippingMethodToShipmentsOfConsignmentStockOrder } from "../../consignment-stock/actions/consignmentStockActions";

export async function syncShippingMethod(orderType: OrderType) {
  let cart;
  if (orderType === OrderType.CST_ORDER) {
    cart = getState().consignmentStock.order;
  } else {
    cart = getState().cart;
  }
  const shippingMethodsNonBackorder = filterShippingMethodsNonBackorder(cart.shippingMethods);
  const shippingMethodsNonBackorderIds = shippingMethodsNonBackorder.map(method => method?.id);
  const shippingMethodLocalStorageId = localStorage.getItem(StorageKeys.SHIPPING_METHOD_ID);

  if (
    shippingMethodLocalStorageId &&
    shippingMethodsNonBackorderIds.includes(shippingMethodLocalStorageId)
  ) {
    // this means the user has a valid entry in the localStorage and commercelayer will be updated

    if (orderType === OrderType.CST_ORDER) {
      await setShippingMethodToShipmentsOfConsignmentStockOrder(shippingMethodLocalStorageId);
    } else {
      await setShippingMethodToShipmentsOfCart(shippingMethodLocalStorageId);
    }
  } else {
    // this means the current localStorage is either not initialized
    // or has a shipping method selected that doesn't exist anymore (i.e. different sales agent)
    // in this case the first shippingMethodId will be selected as a default

    if (shippingMethodsNonBackorder.length === 0) {
      return;
    }

    const defaultShippingMethod = shippingMethodsNonBackorder[0].id;
    if (orderType === OrderType.CST_ORDER) {
      await setShippingMethodToShipmentsOfConsignmentStockOrder(defaultShippingMethod);
    } else {
      await setShippingMethodToShipmentsOfCart(defaultShippingMethod);
    }
    localStorage.setItem(StorageKeys.SHIPPING_METHOD_ID, defaultShippingMethod);
  }
}
