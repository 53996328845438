import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { ProductRow } from "../vo/sku";
import { YAxisAttributeLeft } from "./YAxisAttributeLeft";
import { YAxisAttributeImg } from "./YAxisAttributeImg";
import { YAxisAttributeContent } from "./YAxisAttributeContent";

export const YAxisAttribute: React.FC<{ productGroup: ProductGroup; productRow: ProductRow }> = ({
  productGroup,
  productRow,
}) => {
  return (
    <>
      <YAxisAttributeLeft productGroup={productGroup} />
      <YAxisAttributeImg productGroup={productGroup} productRow={productRow} />
      <YAxisAttributeContent productGroup={productGroup} productRow={productRow} />
    </>
  );
};
