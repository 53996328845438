import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { ProductGroupViewType } from "../../graphcms/constants/ProductGroupViewType";
import { ProductRow } from "../vo/sku";
import { CellYImg } from "./CellYImg";
import { getProductGroupViewType } from "../../graphcms/scripts/productGroup";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

export const YAxisAttributeImg: React.FC<{
  productGroup: ProductGroup;
  productRow: ProductRow;
}> = ({ productGroup, productRow }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);

  // defined to have no cell img for mobile
  if (isMobile) {
    return null;
  }

  const PGVT = getProductGroupViewType(productGroup);

  if (PGVT === ProductGroupViewType["3i-1d"]) {
    return <CellYImg productRow={productRow} colSpan={3} />;
  }

  if (PGVT === ProductGroupViewType["2i-2d"]) {
    return <CellYImg productRow={productRow} colSpan={2} />;
  }

  if (PGVT === ProductGroupViewType["1i-3d"]) {
    return <CellYImg productRow={productRow} colSpan={1} />;
  }

  if (PGVT === ProductGroupViewType["4i"]) {
    return <CellYImg productRow={productRow} colSpan={4} />;
  }

  return null;
};
