import React from "react";
import i18next from "i18next";

const CartEmpty: React.FC = () => {
  const cartEmptyText = i18next.t("USE THE CART SYMBOL");

  return (
    <div className="cart-empty__text cms">
      {
        <p
          dangerouslySetInnerHTML={{
            __html: cartEmptyText.replace("🛒", '<span class="icon-font icon-font__cart"></span>'),
          }}
        />
      }
      <p>
        {`${i18next.t("MORE INFORMATION")}: `}
        <a
          href={i18next.t("URL PATH FAQ")}
          target="_blank"
          rel="noreferrer"
          title={i18next.t("ORDER PROCESS CUSTOMER ACCOUNTS")}
        >
          <span>{i18next.t("ORDER PROCESS CUSTOMER ACCOUNTS")}</span>
        </a>
      </p>
    </div>
  );
};

export default CartEmpty;
