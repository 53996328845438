import React from "react";
import i18next from "i18next";
import { useSelector } from "react-redux";
import State from "state/vo/State";
import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { setFilter } from "consignment-stock/slices/consignmentStockSlice";

const Filter: React.FC = () => {
  const filter = useSelector((state: State) => state?.consignmentStock?.filter ?? "");

  return (
    <div className="search ">
      <form className="form search__form" onSubmit={e => e.preventDefault()}>
        <input
          type="text"
          className="input--text"
          id="search--order"
          name="search--order"
          placeholder={i18next.t("FILTER CONSIGNMENT STOCK")}
          aria-required="false"
          aria-label={i18next.t("FILTER CONSIGNMENT STOCK")}
          value={filter}
          onChange={e => dispatch(setFilter(e?.target?.value ?? ""))}
        />

        <div className="icon-btn icon-btn__search" role="button" tabIndex={0}>
          <span className="visuallyhidden">{i18next.t("SEARCH")}</span>
        </div>
      </form>
    </div>
  );
};

export default Filter;
