import React from "react";
import i18next from "i18next";
import Breadcrumb, { BreadcrumbType } from "common/components/Breadcrumb";

interface Props {
  activeStep: number;
  links: string[];
}

const CheckoutBreadcrumb: React.FC<Props> = ({ activeStep, links }) => {
  const stepTitles = [
    i18next.t("ADDRESS", { count: 2 }),
    `${i18next.t("SHIPPING")} & ${i18next.t("PAYMENT")}`,
    i18next.t("VERIFICATION"),
  ];

  return (
    <Breadcrumb
      activeStep={activeStep}
      links={links}
      stepTitles={stepTitles}
      type={BreadcrumbType.checkout}
    />
  );
};

export default CheckoutBreadcrumb;
