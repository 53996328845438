import { getAccessToken } from "../../commercelayer/scripts/token";
import OrderDocument from "../../state/vo/OrderDocument";

export async function serviceLoadReport(
  customerId: string,
  dateFrom: Date,
  dateTo: Date,
): Promise<ResponseReport | null> {
  try {
    const dateFromString = dateFrom.toISOString().slice(0, 10).replace(/-/g, "");
    const dateToString = dateTo.toISOString().slice(0, 10).replace(/-/g, "");
    const response = await fetch(
      `/api/customer/${customerId}/billing-documents/${dateFromString}/${dateToString}`,
      {
        method: "GET",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${(await getAccessToken()) ?? "NO-ACCESS-TOKEN"}`,
        },
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      //const responseJson = await response.json();
      //const message = `An error has occured: ${response.status}`;
      throw new Error();
    }
  } catch (e) {
    return null;
  }
}

export interface ResponseReport {
  data: OrderDocument[];
}
