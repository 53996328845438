import React from "react";
import { Category } from "../../graphcms/vo/graphCMS";
import { Link, useParams } from "react-router-dom";
import { PARAMS } from "../../router/constants/Params";
import { getLinkToCategory, getLinkToProductGroup } from "../scripts/link";
import { scrollTop } from "../../layout/scripts/scroll";
import { hasDisplayableItems } from "../scripts/filter";

const NavListItemSub: React.FC<{ category: Category }> = ({ category }) => {
  const params = useParams();

  if (!hasDisplayableItems(category, params)) {
    return null;
  }

  return (
    <ul className="nav-list--sub">
      {/* non hidden categories */}
      {category?.categories
        .filter(category => !category.hidden)
        .filter(category => hasDisplayableItems(category, params))
        .map(category => {
          const active = params[PARAMS.CATEGORY_ID] === category.id;

          return (
            <li key={category.id} className={`nav-list__item--sub ${active ? "active" : ""}`}>
              {active ? (
                <h1 className="nav-list__title">{category.name}</h1>
              ) : (
                <Link
                  to={getLinkToCategory(category)}
                  className="nav-list__link"
                  onClick={() => scrollTop()}
                >
                  {category.name}
                </Link>
              )}
            </li>
          );
        })}
      {/* hidden categories */}
      {category?.categories
        .filter(category => !!category.hidden)
        .filter(category => hasDisplayableItems(category, params))
        .map(category => {
          return (
            <li key={category.id} className="nav-list__item--sub">
              <Link
                to={getLinkToProductGroup(category?.id, category?.productGroups[0])}
                className="nav-list__link"
                onClick={() => scrollTop()}
              >
                {category?.productGroups[0]?.name ?? ""}
              </Link>
            </li>
          );
        })}
      {/* product groups */}
      {category?.productGroups.map(productGroup => (
        <li key={productGroup?.id} className="nav-list__item--sub">
          <Link
            to={getLinkToProductGroup(category?.id, productGroup)}
            className="nav-list__link"
            onClick={() => scrollTop()}
          >
            {productGroup?.name ?? ""}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default NavListItemSub;
