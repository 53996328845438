import React from "react";
import { useSupportedCountry } from "../../router/hooks/useCountry";
import { useGetUserNotificationsQuery } from "../../graphcms/services/userNotificationsApi";

const UserNotifications: React.FC = props => {
  const countryCode = useSupportedCountry();
  const { data: userNotifications } = useGetUserNotificationsQuery([countryCode]);

  if (!userNotifications) return null;

  return (
    <>
      {userNotifications.map((userNotification, i) => {
        return (
          <p
            key={`userNotification-${userNotification.id}`}
            className="item-alert"
            style={{ color: "red" }}
          >
            {userNotification.text}
            <span className="item-alert__icon icon-font icon-font__alert" aria-hidden="true"></span>
          </p>
        );
      })}
    </>
  );
};

export default UserNotifications;
