import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import Cart from "../../state/vo/Cart";
import { LineItems } from "../../commercelayer/vo/lineItems";
import { OrderAttributes, OrderRelationships } from "../../commercelayer/vo/orders";
import SkuQuantityItem from "../vo/SkuQuantityItem";
import { Shipments } from "../../commercelayer/vo/shipments";
import { PaymentMethods } from "../../commercelayer/vo/paymentMethods";
import { ShippingMethods } from "../../commercelayer/vo/shippingMethods";
import { Addresses } from "../../commercelayer/vo/addresses";

const name = "cart";
const initialState = store.cart;

const cartSlice = createSlice({
  name,
  initialState,
  reducers: {
    setCartId(state: Cart, action: PayloadAction<string | null>) {
      state.id = action.payload;
    },
    setCartAttributes(state: Cart, action: PayloadAction<OrderAttributes | null>) {
      state.attributes = action.payload;
    },
    setCartRelationships(state: Cart, action: PayloadAction<OrderRelationships | null>) {
      state.relationships = action.payload;
    },
    setCartCounter(state: Cart, action: PayloadAction<number>) {
      state.counter = action.payload;
    },
    setCartAnimate(state: Cart, action: PayloadAction<boolean>) {
      state.animate = action.payload;
    },
    setCartLineItems(state: Cart, action: PayloadAction<LineItems[]>) {
      state.lineItemsSkus = action.payload.filter(
        lineItems => lineItems.attributes.item_type === "skus",
      );
      state.lineItemsNonSkus = action.payload.filter(
        lineItems => lineItems.attributes.item_type !== "skus",
      );
    },
    setCartShipments(state: Cart, action: PayloadAction<Shipments[]>) {
      state.shipments = action.payload;
    },
    setCartShippingMethods(state: Cart, action: PayloadAction<ShippingMethods[]>) {
      state.shippingMethods = action.payload;
    },
    setCartPaymentMethods(state: Cart, action: PayloadAction<PaymentMethods[]>) {
      state.paymentMethods = action.payload;
    },
    setCartAddresses(state: Cart, action: PayloadAction<Addresses[]>) {
      state.addresses = action.payload;
    },
    resetBuffer(state: Cart) {
      state.bufferBeforeLogin = [];
    },
    addProductsToBuffer(state: Cart, action: PayloadAction<SkuQuantityItem[]>) {
      state.bufferBeforeLogin = state.bufferBeforeLogin.concat(action.payload);
    },
  },
});

export const setCartId = cartSlice.actions.setCartId;
export const setCartAttributes = cartSlice.actions.setCartAttributes;
export const setCartRelationships = cartSlice.actions.setCartRelationships;
export const setCartCounter = cartSlice.actions.setCartCounter;
export const setCartAnimate = cartSlice.actions.setCartAnimate;
export const setCartLineItems = cartSlice.actions.setCartLineItems;
export const setCartShipments = cartSlice.actions.setCartShipments;
export const setCartShippingMethods = cartSlice.actions.setCartShippingMethods;
export const setCartPaymentMethods = cartSlice.actions.setCartPaymentMethods;
export const setCartAddresses = cartSlice.actions.setCartAddresses;
export const resetBuffer = cartSlice.actions.resetBuffer;
export const addProductsToBuffer = cartSlice.actions.addProductsToBuffer;

export default cartSlice.reducer;
