import React from "react";
import { boundPushLocObj } from "../../router/actions/routesActions";
import { scrollTop } from "../../layout/scripts/scroll";

interface Props {
  activeStep: number;
  links: string[];
  stepTitles: string[];
  type: BreadcrumbType;
}

export enum BreadcrumbType {
  checkout,
  steps,
}

function onClickBreadcrumbItem(link: string) {
  boundPushLocObj({ pathname: link });
  scrollTop();
}

const Breadcrumb: React.FC<Props> = ({
  activeStep,
  links,
  stepTitles,
  type = BreadcrumbType.checkout,
}) => {
  const cls = ["breadcrumb"];

  if (type === BreadcrumbType.checkout) cls.push("breadcrumb--checkout");
  if (type === BreadcrumbType.steps) cls.push("breadcrumb--steps");

  return (
    <div className={cls.join(" ")}>
      {stepTitles.map((step, i) => {
        // only have links that are maximum 1 step ahead from current breadcrumb
        let link: string | null = null;
        if (i <= activeStep + 1) {
          if (activeStep !== i) {
            link = links[i];
          }
        }

        return (
          <div
            key={i}
            className={`breadcrumb-item ${activeStep === i ? "active" : ""}
            ${link ? "link" : ""}`}
            onClick={() => {
              if (link) {
                onClickBreadcrumbItem(link);
              }
            }}
          >
            <span className="breadcrumb-item__number">{`${i + 1}.`}</span>
            <span className="breadcrumb-item__text">{step}</span>
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
