import { useInventory } from "consignment-stock/hooks";
import React, { useEffect } from "react";
import LoadingText from "../../layout/components/LoadingText";
import ScanAndFilter from "consignment-stock/components/ScanAndFilter";
import Bottom from "./Bottom";
import Inventory from "./Inventory";
import { useScrollTop } from "layout/hooks/scroll";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { getScopeByUser } from "../../login/actions/loginActions";

const ConsignmentStockMain: React.FC = () => {
  useScrollTop();
  const accountCustomer = useSelector((state: State) => state.account.customer);
  const [scopeMarketId, setScopeMarketId] = React.useState<string | null>(null);
  const [scopeByUserConcluded, setScopeByUserConcluded] = React.useState<boolean>(false);

  // To make sure data on commerce layer is loaded we trigger getScopeByUser
  // https://3.basecamp.com/3101128/buckets/19695186/todos/6672577799
  useEffect(
    function initScope() {
      async function loadScopeByUser() {
        if (accountCustomer?.attributes?.email) {
          const email = accountCustomer?.attributes?.email;
          const scopeResponse = await getScopeByUser(email);
          const marketId = scopeResponse?.data?.marketId ?? null;
          setScopeMarketId(marketId);
        }
        setScopeByUserConcluded(true);
      }

      loadScopeByUser().then();
    },
    [setScopeMarketId, accountCustomer, setScopeByUserConcluded],
  );

  const { isFetching } = useInventory({ skip: !scopeMarketId, keepUnusedDataFor: 0 });

  // To show Loading Text while fetching scopeMarketId
  if (!scopeByUserConcluded) {
    return <LoadingText />;
  }

  // Something went wrong and we don't show inventory
  if (scopeByUserConcluded && !scopeMarketId) {
    return null;
  }

  if (isFetching) {
    return <LoadingText />;
  }

  return (
    <>
      <ScanAndFilter />
      <Inventory />
      <Bottom />
    </>
  );
};

export default ConsignmentStockMain;
