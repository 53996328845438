import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Product, ProductGroup } from "../../graphcms/vo/graphCMS";
import { addLineItem } from "../../cart/actions/cartActions";
import i18next from "i18next";
import { countProductInLineItems } from "../scripts/product";
import { PARAMS } from "../../router/constants/Params";
import { getValuesInUrlList } from "../../router/scripts/search";
import { SEARCH } from "../../router/constants/Search";
import { useDispatch, useSelector } from "react-redux";
import State from "../../state/vo/State";
import { useOnClickOutside } from "../../common/scripts/hooks/useOnClickOutside";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLinkToProductGroup } from "../../category/scripts/link";
import { elementOrAncestorHasClass, elementOrAncestorHasTagName } from "../scripts/dom";
import { Animate } from "react-simple-animate";
import { getProductLink } from "../../router/scripts/link";
import { setActiveSkuColumn } from "../slices/productSlice";

interface Props {
  product: Product;
  productGroup: ProductGroup;
  cartLineItems: any;
  open: string;
  tdClassName: string;
  colSpan: number;
  skuColumn?: number;
}

const Sku: React.FC<Props> = ({
  product,
  productGroup,
  open,
  tdClassName,
  cartLineItems,
  colSpan,
  skuColumn = 0,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const cartRef = React.useRef(null);
  const layoutIsMobile = useSelector((state: State) => state.layout.isMobile);
  const productGroupsIndex = useSelector((state: State) => state.graphcms.productGroupsIndex);
  const [animate, setAnimate] = React.useState<boolean>(false);
  const [translate, setTranslate] = React.useState<any>({ x: 0, y: 0 });

  const onClickCart = function () {
    startAnimation();
    addLineItem(product.sku, 1).then();
  };

  const startAnimation = function () {
    let cartIconTop = 0;
    let cartIconLeft = 0;
    let cartIcon = document.querySelector(".header-container .icon-btn__cart");
    if (layoutIsMobile) {
      cartIcon = document.querySelector(".mobile-menu .icon-btn__cart");
    }

    let clickedIconTop = 0;
    let clickedIconLeft = 0;

    if (cartIcon !== null && cartRef.current !== null) {
      const cartIconPos = cartIcon.getBoundingClientRect();
      cartIconTop = cartIconPos.top;
      cartIconLeft = cartIconPos.left;
      if (layoutIsMobile) {
        //mobile view position adjust
        cartIconTop = cartIconTop + 14;
        cartIconLeft = cartIconLeft + 20;
      }

      // @ts-ignore
      clickedIconTop = cartRef.current.getBoundingClientRect().top;
      // @ts-ignore
      clickedIconLeft = cartRef.current.getBoundingClientRect().left;
    }

    setTranslate({
      x: cartIconLeft - clickedIconLeft,
      y: cartIconTop + -1 * clickedIconTop,
    });
    setAnimate(true);
  };

  // Hide product with add to cart on click outside
  const refTdPrdct = useRef<HTMLDivElement | null>(null);
  useOnClickOutside(refTdPrdct, e => {
    if (
      open.length > 0 &&
      !layoutIsMobile &&
      !elementOrAncestorHasClass(e.target, "product-info") &&
      !elementOrAncestorHasTagName(e.target, "a")
    ) {
      boundReplaceLocObj({
        // params are set if it is not the first block
        // first product group table <- before && after -> other product group tables
        pathname: getLinkToProductGroup(
          params[PARAMS.CATEGORY_ID] ?? productGroup.categories[0].id ?? "",
          params[PARAMS.PRODUCTGROUP_ID]
            ? productGroupsIndex[params[PARAMS.PRODUCTGROUP_ID]]
            : productGroup,
        ),
      });
    }
  });

  return (
    <td
      className={`td-prdct ${tdClassName} ${open} ${getClassNameIsBlur(product)}`}
      colSpan={colSpan}
    >
      <Link
        to={getProductLink(product, params)}
        replace={true}
        onClick={() => {
          dispatch(setActiveSkuColumn(skuColumn));
        }}
      >
        <div className="td-prdct__data-wrap" ref={refTdPrdct}>
          <div className="td-prdct__data">
            <div className="td-prdct-data__text-wrap">
              <span className="td-prdct-data__text">{product?.sku}</span>
              {open ? (
                <span className="td-prdct-data__counter">
                  {countProductInLineItems(product.sku, cartLineItems)}
                </span>
              ) : null}
            </div>
            <div
              className={`td-prdct-data__cart icon-font icon-font__add-to-cart ${
                animate ? "animate" : ""
              }`}
              onClick={() => onClickCart()}
              aria-label={i18next.t("ADD TO CART")}
              ref={cartRef}
            />
            {animate && (
              <div className="cart-flying-circle animate">
                {/* https://react-simple-animate.vercel.app/animate/ */}
                <Animate
                  play={true} // set play true to start the animation
                  duration={0.4} // how long is the animation duration
                  delay={0.2} // how many delay seconds will apply before the animation start
                  start={{ opacity: 1, transform: "translate(0px, 0px)" }}
                  end={{
                    opacity: 0.2,
                    transform: `translate(${translate.x}px, ${translate.y}px)`,
                  }}
                  easeType="linear" //cubic-bezier(0.445, 0.05, 0.55, 0.95)
                  onComplete={() => setAnimate(false)}
                >
                  <span className="icon-font icon-font__add-to-cart" />
                </Animate>
              </div>
            )}
          </div>
        </div>
      </Link>
    </td>
  );
};

export default Sku;

const classNameIsBlur = "is-blur";

function getClassNameIsBlur(product: Product): string {
  return getIsProductInFocus(product) ? "" : classNameIsBlur;
}

function getIsProductInFocus(product: Product): boolean {
  const focus: string[] = getValuesInUrlList(SEARCH.FOCUS);

  // no focus filter means everything is in focus
  if (focus.length === 0) {
    return true;
  }

  // check if at least one of the pfs that product has is in focus
  const nameInFocus = product?.pfs.find(pf => focus.includes(pf.name));

  if (nameInFocus) {
    return true;
  }

  return false;
}
