import React, { useState } from "react";
import i18next from "i18next";
import { useForm } from "react-hook-form";
import { updatePassword } from "../../account/actions/accountActions";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { PATH } from "../../router/constants/Path";
import { useLangCountry } from "../../router/hooks/useLangCountry";

interface Props {
  customerPasswordResetsId: string;
  token: string;
}
type PasswordChangeData = {
  password: string;
  passwordRepeated: string;
  customerPasswordResetsId: string;
  token: string;
};

const ChangePassword: React.FC<Props> = ({ token, customerPasswordResetsId }) => {
  const langCountry = useLangCountry();
  const methods = useForm<PasswordChangeData>();
  const [pwdInputType, setPwdInputType] = useState("password");
  const onClickEye = function () {
    pwdInputType === "password" ? setPwdInputType("text") : setPwdInputType("password");
  };

  const onSubmit = methods.handleSubmit(data => {
    updatePassword(customerPasswordResetsId, token, data.password);
    methods.setValue("password", "");
    methods.setValue("passwordRepeated", "");
  });

  const handleClick = () => {
    boundReplaceLocObj({ pathname: `${langCountry}/${PATH.LOGIN}` });
  };

  return (
    <>
      <h2 className="col-title">{i18next.t("RESET PASSWORD")}</h2>
      <div className="grid__text">
        {i18next.t("THE PASSWORD MUST BE A MINIMUM OF 6 CHARACTERS")}
      </div>
      <form className="form register-form" onSubmit={onSubmit}>
        <label className="label " htmlFor="password">
          {`${i18next.t("PASSWORD")} *`}
        </label>
        <div className="input--password-wrap">
          <input
            type={pwdInputType}
            className="input--password"
            placeholder={i18next.t("PASSWORD")}
            required
            aria-required="true"
            aria-label={i18next.t("PASSWORD")}
            autoComplete="off"
            {...methods.register("password", {
              required: true,
              minLength: {
                value: 6,
                message: `${i18next.t("THE PASSWORD MUST BE A MINIMUM OF 6 CHARACTERS")}`,
              },
            })}
          />
          <span onClick={onClickEye} className="input--password__icon icon-font icon-font__eye" />
        </div>
        {methods.formState.errors.password && (
          <span className="form-error">{methods.formState.errors.password.message}</span>
        )}
        <label className="label " htmlFor="passwordRepeated">
          {`${i18next.t("PLEASE REPEAT PASSWORD")} *`}
        </label>
        <div className="input--password-wrap">
          <input
            type={pwdInputType}
            className="input--password"
            placeholder={i18next.t("PLEASE REPEAT PASSWORD")}
            required
            aria-required="true"
            aria-label={i18next.t("PLEASE REPEAT PASSWORD")}
            autoComplete="off"
            {...methods.register("passwordRepeated", {
              required: true,
              validate: value =>
                value === methods.getValues("password") ||
                `${i18next.t("THE PASSWORD DOES NOT MATCH THE PASSWORD FIELD")} `,
            })}
          />
          <span onClick={onClickEye} className="input--password__icon icon-font icon-font__eye" />
        </div>
        {methods.formState.errors.passwordRepeated && (
          <span className="form-error">{methods.formState.errors.passwordRepeated.message}</span>
        )}
        <input
          type="hidden"
          defaultValue={customerPasswordResetsId}
          {...methods.register("customerPasswordResetsId")}
        />
        <input type="hidden" defaultValue={token} {...methods.register("token")} />

        <div className="btn-wrap">
          <button type="button" className="btn transparent" onClick={handleClick}>
            {i18next.t("CANCEL")}
          </button>

          <button type="submit" className="btn ">
            {i18next.t("SAVE")}
          </button>
        </div>
      </form>
    </>
  );
};
export default ChangePassword;
