import React, { useEffect } from "react";
import i18next from "i18next";
import { addLineItem } from "../../cart/actions/cartActions";
import { countProductInLineItems } from "../../product/scripts/product";
import { LineItems } from "../../commercelayer/vo/lineItems";
import PriceTag from "./PriceTag";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { loadSku } from "../../commercelayer/actions/commercelayerActions";
import AvailabilityAlert from "./AvailabilityAlert";
import { Product } from "../../graphcms/vo/graphCMS";
import { Customer } from "../../commercelayer/vo/customers";

export enum PriceUsage {
  PRODUCT_INFO = "product-info",
  LIST_ITEM = "list-item",
}

interface Props {
  usage: PriceUsage;
  product: Product;
  cartLineItems: LineItems[];
}

const Price: React.FC<Props> = ({ usage, product, cartLineItems }) => {
  const commercelayerSkusIndex = useSelector((state: State) => state.commercelayer.skusIndex);
  const customer: Customer | null = useSelector((state: State) => state.account.customer);
  const { sku } = product;

  useEffect(() => {
    if (usage === PriceUsage.PRODUCT_INFO && !commercelayerSkusIndex[sku]) {
      loadSku(sku).then();
    }
  });

  const countLineItems: number = countProductInLineItems(sku, cartLineItems);

  return (
    <>
      {usage === PriceUsage.PRODUCT_INFO && (
        <AvailabilityAlert sku={sku} requestQuantity={countLineItems} customer={customer} />
      )}
      <div className={`${usage}__price-wrap`}>
        {usage === PriceUsage.PRODUCT_INFO && <PriceTag product={product} />}

        <span
          onClick={() => addLineItem(sku, 1)}
          className="icon-btn icon-btn__add-to-cart"
          role="button"
          tabIndex={0}
        >
          {countLineItems > 0 && <span className="icon-btn__counter">{countLineItems}</span>}
          <span className="visuallyhidden">{i18next.t("ADD TO CART")}</span>
        </span>
      </div>
    </>
  );
};

export default Price;
