import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Addresses } from "commercelayer/vo/addresses";
import { LineItems } from "commercelayer/vo/lineItems";
import { OrderAttributes, OrderRelationships } from "commercelayer/vo/orders";
import { PaymentMethods } from "commercelayer/vo/paymentMethods";
import { Shipments } from "commercelayer/vo/shipments";
import { ShippingMethods } from "commercelayer/vo/shippingMethods";
import store from "../../state/init/store";
import ConsignmentStock from "../../state/vo/ConsignmentStock";
import { ScannedInventory } from "../vo/ConsignmentStock";

export const consignmentStockSlice = createSlice({
  name: "consignmentStock",
  initialState: store.consignmentStock,
  reducers: {
    setConsignmentStockOrderAddresses(state: ConsignmentStock, action: PayloadAction<Addresses[]>) {
      state.order.addresses = action.payload;
    },
    setConsignmentStockOrderAttributes(
      state: ConsignmentStock,
      action: PayloadAction<OrderAttributes | null>,
    ) {
      state.order.attributes = action.payload;
    },
    setConsignmentStockOrderId(state: ConsignmentStock, action: PayloadAction<string | null>) {
      state.order.id = action.payload;
    },
    setConsignmentStockOrderLineItems(state: ConsignmentStock, action: PayloadAction<LineItems[]>) {
      state.order.lineItemsSkus = action.payload.filter(
        lineItems => lineItems.attributes.item_type === "skus",
      );
      state.order.lineItemsNonSkus = action.payload.filter(
        lineItems => lineItems.attributes.item_type !== "skus",
      );
    },
    setConsignmentStockOrderPaymentMethods(
      state: ConsignmentStock,
      action: PayloadAction<PaymentMethods[]>,
    ) {
      state.order.paymentMethods = action.payload;
    },
    setConsignmentStockOrderShipments(state: ConsignmentStock, action: PayloadAction<Shipments[]>) {
      state.order.shipments = action.payload;
    },
    setConsignmentStockOrderShippingMethods(
      state: ConsignmentStock,
      action: PayloadAction<ShippingMethods[]>,
    ) {
      state.order.shippingMethods = action.payload;
    },
    setConsignmentStockOrderRelationships(
      state: ConsignmentStock,
      action: PayloadAction<OrderRelationships | null>,
    ) {
      state.order.relationships = action.payload;
    },
    setFilter(state: ConsignmentStock, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    setScanId(state: ConsignmentStock, action: PayloadAction<string | null>) {
      state.scanId = action.payload;
    },
    setScannedReceivedInventory(
      state: ConsignmentStock,
      action: PayloadAction<ScannedInventory[]>,
    ) {
      state.scannedReceivedInventory = action.payload;
    },
    resetScannedReceivedInventory(state: ConsignmentStock) {
      state.scannedReceivedInventory = [];
    },
    setConsignmentStockCheckoutGtc(state: ConsignmentStock, action: PayloadAction<boolean>) {
      state.checkout.gtc = action.payload;
    },
    setConsignmentStockCheckoutGtcError(
      state: ConsignmentStock,
      action: PayloadAction<string | null>,
    ) {
      state.checkout.gtcError = action.payload;
    },
    setConsignmentStockCheckoutBstnk(state: ConsignmentStock, action: PayloadAction<string>) {
      state.checkout.bstnk = action.payload;
    },
    setConsignmentStockCheckoutCouponCode(state: ConsignmentStock, action: PayloadAction<string>) {
      state.checkout.couponCode = action.payload;
    },
    setConsignmentStockCheckoutCouponCodeError(
      state: ConsignmentStock,
      action: PayloadAction<string | null>,
    ) {
      state.checkout.couponCodeError = action.payload;
    },
    setConsignmentStockCheckoutOrderProcessing(
      state: ConsignmentStock,
      action: PayloadAction<boolean>,
    ) {
      state.checkout.orderProcessing = action.payload;
    },
    setConsignmentStockCheckoutOrderUpdating(
      state: ConsignmentStock,
      action: PayloadAction<boolean>,
    ) {
      state.checkout.orderUpdating = action.payload;
    },
    setConsignmentStockOrderChangeInProgress(
      state: ConsignmentStock,
      action: PayloadAction<boolean>,
    ) {
      state.orderChangeInProgress = action.payload;
    },
  },
});

export const setConsignmentStockOrderAddresses =
  consignmentStockSlice.actions.setConsignmentStockOrderAddresses;
export const setConsignmentStockOrderAttributes =
  consignmentStockSlice.actions.setConsignmentStockOrderAttributes;
export const setConsignmentStockOrderId = consignmentStockSlice.actions.setConsignmentStockOrderId;
export const setConsignmentStockOrderLineItems =
  consignmentStockSlice.actions.setConsignmentStockOrderLineItems;
export const setConsignmentStockOrderPaymentMethods =
  consignmentStockSlice.actions.setConsignmentStockOrderPaymentMethods;
export const setConsignmentStockOrderShipments =
  consignmentStockSlice.actions.setConsignmentStockOrderShipments;
export const setConsignmentStockOrderShippingMethods =
  consignmentStockSlice.actions.setConsignmentStockOrderShippingMethods;
export const setConsignmentStockOrderRelationships =
  consignmentStockSlice.actions.setConsignmentStockOrderRelationships;
export const setFilter = consignmentStockSlice.actions.setFilter;
export const setScanId = consignmentStockSlice.actions.setScanId;
export const setScannedReceivedInventory =
  consignmentStockSlice.actions.setScannedReceivedInventory;
export const resetScannedReceivedInventory =
  consignmentStockSlice.actions.resetScannedReceivedInventory;
export const setConsignmentStockCheckoutGtc =
  consignmentStockSlice.actions.setConsignmentStockCheckoutGtc;
export const setConsignmentStockCheckoutGtcError =
  consignmentStockSlice.actions.setConsignmentStockCheckoutGtcError;
export const setConsignmentStockCheckoutBstnk =
  consignmentStockSlice.actions.setConsignmentStockCheckoutBstnk;
export const setConsignmentStockCheckoutCouponCode =
  consignmentStockSlice.actions.setConsignmentStockCheckoutCouponCode;
export const setConsignmentStockCheckoutCouponCodeError =
  consignmentStockSlice.actions.setConsignmentStockCheckoutCouponCodeError;
export const setConsignmentStockCheckoutOrderProcessing =
  consignmentStockSlice.actions.setConsignmentStockCheckoutOrderProcessing;
export const setConsignmentStockCheckoutOrderUpdating =
  consignmentStockSlice.actions.setConsignmentStockCheckoutOrderUpdating;
export const setConsignmentStockOrderChangeInProgress =
  consignmentStockSlice.actions.setConsignmentStockOrderChangeInProgress;

export default consignmentStockSlice.reducer;
