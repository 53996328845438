import React from "react";
import { Addresses } from "../../commercelayer/vo/addresses";
import i18next from "i18next";
import CountrySpecific from "../../common/constants/CountrySpecific";
import { useCountry } from "../../router/hooks/useCountry";

interface Props {
  address: Addresses;
  allowDelete: boolean;
}

const Address: React.FC<Props> = ({ address, allowDelete }) => {
  const country = useCountry();
  return (
    <dl className="dl-account">
      <dt className="dl-account__dt">{`${i18next.t("COMPANY NAME")}:`}</dt>
      <dd className="dl-account__dd">
        <strong>
          {address.attributes.company}
          {address.attributes.notes && address.attributes.notes?.length > 0 && (
            <>
              <br />
              {address.attributes.notes}
            </>
          )}
        </strong>
      </dd>
      <dt className="dl-account__dt">{`${i18next.t("ADDRESS", { count: 1 })}:`}</dt>
      <dd className="dl-account__dd">
        {CountrySpecific[country]?.addressFormat === "US" ? (
          <strong>
            {address.attributes.line_1}
            <br />
            {`${address.attributes.country_code} ${address.attributes.city}, ${address.attributes.state_code} ${address.attributes.zip_code}`}
          </strong>
        ) : (
          <strong>
            {address.attributes.line_1}
            <br />
            {`${address.attributes.country_code} ${address.attributes.zip_code} ${address.attributes.city}`}
          </strong>
        )}
      </dd>
      {address.attributes.email && address.attributes.email.length > 0 && (
        <>
          <dt className="dl-account__dt">{`${i18next.t("EMAIL")}:`}</dt>
          <dd className="dl-account__dd">
            <strong>{address.attributes.email}</strong>
          </dd>
        </>
      )}
      {address.attributes.phone && address.attributes.phone.length > 2 && (
        <>
          <dt className="dl-account__dt">{`${i18next.t("PHONE")}:`}</dt>
          <dd className="dl-account__dd">
            <strong>{address.attributes.phone}</strong>
          </dd>
        </>
      )}
    </dl>
  );
};

export default Address;
