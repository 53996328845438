import React from "react";

interface Props {
  text: string;
  href: string;
  icon: string;
}

const NavListItemIconA: React.FC<Props> = ({ text, href, icon }) => {
  return (
    <li className="nav-list__item">
      <a href={href} className="icon-link nav-list__link icon-link--underline" title={text}>
        {icon && (
          <>
            <span className={"icon-link-font icon-font icon-font__" + icon} />
            &nbsp;
          </>
        )}
        {text}
      </a>
    </li>
  );
};

export default NavListItemIconA;
