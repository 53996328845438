import { useParams } from "react-router-dom";
import { FALLBACK, PARAMS } from "../constants/Params";
import Country from "../../common/constants/Country";

export function useCountry(): Country {
  const params = useParams();
  const country = params[PARAMS.COUNTRY] ?? FALLBACK[PARAMS.COUNTRY];
  return country.toUpperCase() as Country;
}

export const useSupportedCountry = (): Country => {
  const country = useCountry();
  if (Object.values(Country).includes(country)) {
    return country;
  } else {
    return FALLBACK[PARAMS.COUNTRY] as Country;
  }
};
