import { getCountry, getLang } from "../../../router/scripts/params";

export function getNumberWithCurrency(number: number, currency: string): string {
  return new Intl.NumberFormat(`${getLang()}-${getCountry()}`, {
    style: "currency",
    currency: currency,
  }).format(number);
}

export function getVat(taxRate: string): string {
  const taxRateNumber: number = parseFloat(taxRate);
  return new Intl.NumberFormat(`${getLang()}-${getCountry()}`).format(taxRateNumber * 100);
}
