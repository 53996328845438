import React from "react";
import i18next from "i18next";
import OverlayPopup from "../../layout/components/OverlayPopup";
import { setOverlayOpenAction } from "../actions/layoutActions";
import { getLang } from "../../router/scripts/params";
import { FALLBACK, PARAMS } from "../../router/constants/Params";
import imgAlert from "../../templates/public/images/layout/alert.svg";

interface Props {
  unsupportedCountry: string;
}

const activeButtonOnClick = function (e) {
  window.location.replace(
    i18next.t("URL WEB ROOT") + i18next.t("URL PATH ABOUT US") + i18next.t("URL PATH CONTACT"),
  );
};

const transparentButtonOnClick = function (e) {
  setOverlayOpenAction(null);
  window.location.replace(`/${getLang()}/${FALLBACK[PARAMS.COUNTRY]}`);
};

const OverlayPopupUnsupportedCountry: React.FC<Props> = ({ unsupportedCountry }) => {
  const message = i18next.t("UNSUPPORTED COUNTRY MESSAGE", {
    unsupportedCountry: i18next.t("ISO_3166-1 " + unsupportedCountry),
  });

  return (
    <OverlayPopup
      showClose={false}
      activeButtonShow={true}
      activeButtonLabel={i18next.t("CONTACT", { count: 1 })}
      activeButtonOnClick={activeButtonOnClick}
      transparentButtonShow={true}
      transparentButtonLabel={i18next.t("CLOSE")}
      transparentButtonOnClick={transparentButtonOnClick}
    >
      <p className="overlay-popup__title overlay-popup__title--alert">
        <img src={imgAlert} alt={message} className="overlay-popup-title__icon" />
        {message}
      </p>
    </OverlayPopup>
  );
};

export default OverlayPopupUnsupportedCountry;
