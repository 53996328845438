import { gql } from "graphql-request";
import { hygraphApi } from "./hygraphApi";
import { UserNotification } from "../vo/graphCMS";

export const userNotificationsApi = hygraphApi.injectEndpoints({
  endpoints: builder => ({
    getUserNotifications: builder.query<UserNotification[] | null, string[]>({
      providesTags: ["languageDependent", "countryCodeDependent"],
      query: (countryCodes: string[]) => {
        return {
          document: gql`
            query LoadUserNotifications($countryCodes: [CountryCode!]) {
              data: userNotifications(where: { countryCodes_contains_some: $countryCodes }) {
                id
                text
              }
            }
          `,
          variables: { countryCodes },
        };
      },
      transformResponse: (response: { data: UserNotification[] | null }) => response?.data ?? null,
    }),
  }),
});

export const { useGetUserNotificationsQuery } = userNotificationsApi;
