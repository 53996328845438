import { Category } from "../vo/graphCMS";
import { getState } from "../../state/scripts/state";

// find the category where this id belongs to related to the hierarchy in that categoriesTree
export function getRelatedCategory(
  categories: Category[],
  relatedCategoryId: string,
): Category | undefined {
  if (!categories) {
    return;
  }

  // try to find relatedCategory in main categories
  let relatedCategory = categories.find(category => category?.id === relatedCategoryId);

  // when not a main relatedCategory is selected search in subcategories
  if (!relatedCategory) {
    categories.forEach(subCategory => {
      // finish when the relatedCategory has been found
      if (relatedCategory) {
        return null;
      }

      relatedCategory = subCategory.categories.find(category => category?.id === relatedCategoryId);
    });
  }

  // when not a main relatedCategory or a direct child of main category, search one level deeper
  if (!relatedCategory) {
    categories.forEach(subCategory => {
      // finish when the relatedCategory has been found
      if (relatedCategory) {
        return null;
      }

      subCategory.categories.forEach(subSubCategory => {
        // finish when the relatedCategory has been found
        if (relatedCategory) {
          return null;
        }

        relatedCategory = subSubCategory.categories?.find(
          category => category?.id === relatedCategoryId,
        );
      });
    });
  }

  return relatedCategory;
}

export function getRelatedCategoryIdFromProductGroup(productGroupId: string): string {
  return getState()?.graphcms?.productGroupsIndex?.[productGroupId]?.categories?.[0]?.id ?? "";
}
