import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import store from "../../state/init/store";
import { SkuPrepared } from "../vo/skus";
import CommerceLayer from "../../state/vo/CommerceLayer";

const name = "commercelayer";
const initialState = store.commercelayer;

type SkuLoading = { code: string; loading: boolean };

const commercelayerSlice = createSlice({
  name,
  initialState,
  reducers: {
    setSkusIndex(state: CommerceLayer, action: PayloadAction<SkuPrepared>) {
      const code = action.payload.code;
      state.skusIndex[code] = action.payload;
    },
    setSkusLoading(state: CommerceLayer, action: PayloadAction<SkuLoading>) {
      const code = action.payload.code;
      state.skusLoading[code] = action.payload.loading;
    },
  },
});

export const setSkusIndex = commercelayerSlice.actions.setSkusIndex;
export const setSkusLoading = commercelayerSlice.actions.setSkusLoading;

export default commercelayerSlice.reducer;
