import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import ProductTableHeadWithPf from "./ProductTableHeadWithPf";
import ProductTableHeadNoPf from "./ProductTableHeadNoPf";

interface Props {
  productGroup: ProductGroup;
}

const ProductTableHead: React.FC<Props> = ({ productGroup }) => {
  return (
    <thead>
      <ProductTableHeadWithPf productGroup={productGroup} />
      <ProductTableHeadNoPf productGroup={productGroup} />
    </thead>
  );
};

export default ProductTableHead;
