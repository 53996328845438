import { LineItems } from "../../commercelayer/vo/lineItems";
import { ScannedInventory } from "../vo/ConsignmentStock";

export const SKU_BATCH_DELIMITER = "_";

export function getIdFromSkuAndBatchNumber(sku: string, batchNumber: string): string {
  // ex: "4.23.112_23460"
  return `${sku}${SKU_BATCH_DELIMITER}${batchNumber}`;
}

export function getIdFromScannedInventory(scannedInventory: ScannedInventory): string {
  const { sku, batchNumber } = scannedInventory;
  return getIdFromSkuAndBatchNumber(sku, batchNumber);
}

export function getIdFromLineItem(lineItem: LineItems): string {
  const { attributes } = lineItem;
  return `${attributes?.sku_code}${SKU_BATCH_DELIMITER}${attributes?.metadata?.batchNumber}`;
}
