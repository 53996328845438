import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";
import { Link } from "react-router-dom";
import i18next from "i18next";

const CstMessage: React.FC = () => {
  return (
    <div>
      {i18next.t("CONSIGNMENT STOCK MESSAGE")} <CstLink />
    </div>
  );
};

export default CstMessage;

const CstLink: React.FC = () => {
  const langCountry = useLangCountry();
  const accountCustomer = useSelector((state: State) => state.account.customer);

  if (accountCustomer === null) {
    return null;
  }

  return (
    <Link
      to={`${langCountry}/account/${accountCustomer.id}/consignment-stock`}
      onClick={() => scrollTop()}
    >
      {i18next.t("CONSIGNMENT STOCK")}
    </Link>
  );
};
