import React from "react";
import ReactMarkdown from "react-markdown";
import { Product, ProductGroup } from "../../graphcms/vo/graphCMS";

interface Props {
  item: ProductGroup | Product;
}

const Paragraphs: React.FC<Props> = ({ item }) => {
  return (
    <>
      {item?.paragraphs?.map((paragraph, i) => (
        <div className="product-extra-info" key={i}>
          {paragraph?.image?.handle ? (
            <span className="img-wrap">
              <img
                src={`https://media.graphassets.com/auto_image/${paragraph?.image?.handle}`}
                alt={paragraph?.image?.name ?? ""}
                title={paragraph?.image?.name ?? ""}
                className="img"
              />
            </span>
          ) : null}
          <div className="product-extra-info__text cms">
            <ReactMarkdown key={i}>{paragraph?.description ?? ""}</ReactMarkdown>
          </div>
        </div>
      ))}
    </>
  );
};

export default Paragraphs;
