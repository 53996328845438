import { GraphQLClient, RequestDocument } from "graphql-request";
import i18next from "i18next";
import { Locale, Stage } from "../vo/graphCMS";
import { getCountry, isValidCountry, isValidLanguage } from "../../router/scripts/params";
import { devLogInfoOnce } from "../../common/scripts/logger/log";

export function getStage(): Stage.Draft | Stage.Published {
  return window.location.href.includes("preview=true") ? Stage.Draft : Stage.Published;
}

const endpoint = process.env.REACT_APP_GRAPHCMS_ENDPOINT ?? "";

function getClient() {
  return new GraphQLClient(endpoint, {
    headers: {
      "gcms-stage": getStage(),
      "gcms-locales": `${i18next.resolvedLanguage},${Locale.De}`,
    },
  });
}

// todo: improve return type
export async function graphCMSQuery(query: RequestDocument, variables?: any): Promise<any> {
  try {
    // abort on invalid country
    if (!isValidCountry()) {
      devLogInfoOnce(`aborted graphql request due to invalid country: (${getCountry()})`);
      return;
    }

    // abort on invalid language
    if (!isValidLanguage()) {
      devLogInfoOnce(
        `aborted graphql request due to invalid language: (${i18next.resolvedLanguage})`,
      );
      return;
    }

    return await getClient().request(query, variables);
  } catch (e) {
    console.error(e);
  }
}
