import { getAccessToken } from "../../commercelayer/scripts/token";

export async function serviceValidateAddress(
  addressStreetNo: string,
  addressCity: string,
  addressZip: string,
  addressState: string,
  addressCountry: string,
): Promise<any> {
  try {
    const response = await fetch(
      `/api/avalara/` +
        addressCountry +
        `/` +
        addressZip +
        `/` +
        addressCity +
        `/` +
        addressState +
        `/` +
        addressStreetNo,
      {
        method: "GET",
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${(await getAccessToken()) ?? "NO-ACCESS-TOKEN"}`,
        },
      },
    );

    if (response.ok) {
      return await response.json();
    } else {
      //const responseJson = await response.json();
      //const message = `An error has occured: ${response.status}`;
      throw new Error();
    }
  } catch (e) {
    return null;
  }
}
