import React from "react";
import { ProductGroup } from "../../graphcms/vo/graphCMS";
import { CellYTh } from "./CellYTh";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import { filterXSS } from "xss";

interface Props {
  productGroup: ProductGroup;
}

export const HeaderYContent: React.FC<Props> = ({ productGroup }) => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);
  const yAxisAttributes = productGroup?.yAxisAttributes;

  // desktop
  if (!isMobile) {
    const headerY: any[] = [];

    yAxisAttributes?.forEach((yAxisAttribute, i) => {
      headerY.push(<CellYTh key={i} value={yAxisAttribute?.label ?? ""} />);
    });

    return <>{headerY}</>;
  }

  // mobile
  if (isMobile) {
    let html = "";

    if (yAxisAttributes?.length > 0) {
      yAxisAttributes?.forEach((yAxisAttribute, i) => {
        if (i > 0) {
          html += "<br>";
        }

        html += `${yAxisAttribute?.label ?? ""}`;
      });
    }

    return <th className="cell-y" dangerouslySetInnerHTML={{ __html: filterXSS(html) }} />;
  }

  return null;
};
