import { Addresses } from "../../commercelayer/vo/addresses";

export function getMainAddress(addresses: Addresses[]): Addresses | null {
  const mainAddresses = addresses.filter(function (address) {
    return address.attributes.reference && address.attributes.reference.length > 0;
  });

  return mainAddresses[0];
}

export function getOtherAddresses(addresses: Addresses[]): Addresses[] {
  const otherAddresses = addresses.filter(function (address) {
    return address.attributes.reference === null || address.attributes.reference?.length === null;
  });
  return otherAddresses;
}

export function getAllAddresses(addresses: Addresses[]): Addresses[] {
  return addresses;
}
