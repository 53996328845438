import i18next from "i18next";
import { FormProvider, useForm } from "react-hook-form";
import AddressFields from "./AddressFields";
import React from "react";
import { AddressesAttributes } from "../../commercelayer/vo/addresses";
import { addCustomerAddresses } from "../../account/actions/accountActions";
import { addCustomerAddressesAndSetShippingAddress } from "../../checkout/actions/checkoutActions";
import { isCheckoutView } from "../../router/scripts/path";
import { useCountry } from "../../router/hooks/useCountry";
import CountrySpecific from "../../common/constants/CountrySpecific";
import { serviceValidateAddress } from "../../avalara/services/addressValidation";
import { toast } from "react-toastify";

type AddressData = {
  email: string;
  company: string;
  notes: string;
  line_1: string;
  zip_code: string;
  city: string;
  phone: string;
  country_code: string;
  state_code: string;
  customerAddressId: string;
};

interface Props {
  onSubmit: () => void;
  handleClick: () => void;
}

const AddressFormAdd: React.FC<Props> = ({ onSubmit, handleClick }) => {
  const country = useCountry();
  const methods = useForm<AddressData>();

  const onSubmitAdd = methods.handleSubmit((data: AddressData) => {
    const addAddress: AddressesAttributes = {
      business: true,
      email: data.email,
      company: data.company,
      notes: data.notes,
      line_1: data.line_1,
      zip_code: data.zip_code,
      city: data.city,
      phone: data.phone,
      country_code: data.country_code,
      state_code: data.state_code,
    };

    if (
      CountrySpecific[country].hasOwnProperty("checkoutCheckAddress") &&
      CountrySpecific[country].checkoutCheckAddress === true
    ) {
      if (!addAddress.state_code) {
        return;
      }
      serviceValidateAddress(
        addAddress.line_1,
        addAddress.city,
        addAddress.zip_code,
        addAddress.state_code,
        addAddress.country_code,
      ).then(result => {
        if (result.data.valid === true) {
          if (result.data.result.validatedAddresses.length > 0) {
            if (result.data.result.validatedAddresses.length === 1) {
              const validAddr = result.data.result.validatedAddresses[0];
              addAddress.city = validAddr.city;
              addAddress.country_code = validAddr.country;
              addAddress.line_1 = validAddr.line1;
              //addAddress.notes = validAddr.line2;
              addAddress.zip_code = validAddr.postalCode;
              addAddress.state_code = validAddr.region;
            } else {
              const message = i18next.t("CONTACT SALES SUPPORT FOR DELIVERY ADDRESS");
              toast.error(message, {
                position: "top-center",
                autoClose: 10000,
              });
            }
          }
          if (isCheckoutView()) {
            addCustomerAddressesAndSetShippingAddress(addAddress);
          } else {
            addCustomerAddresses(addAddress);
          }
          onSubmit();
        } else {
          const message = result.data.reason;
          toast.error(message, {
            position: "top-center",
            autoClose: 10000,
          });
        }
      });
    } else {
      if (isCheckoutView()) {
        addCustomerAddressesAndSetShippingAddress(addAddress);
      } else {
        addCustomerAddresses(addAddress);
      }
      onSubmit();
    }
  });

  return (
    <>
      <h2 className="col-title">{i18next.t("ADD NEW DELIVERY ADDRESS")}</h2>

      <FormProvider {...methods}>
        <form className="form register-form" onSubmit={onSubmitAdd}>
          <AddressFields
            emailOptional
            address={null}
            setValues={false}
            allowCountrySwitch={false}
          />
          <div className="btn-wrap">
            <button type="button" className="btn transparent" onClick={handleClick}>
              {i18next.t("CANCEL")}
            </button>

            <button type="submit" className="btn ">
              {i18next.t("SAVE")}
            </button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};
export default AddressFormAdd;
