import { dispatch } from "elstr-frontend-4/dist/ElstrCore";
import { getState } from "../../state/scripts/state";
import {
  setAddresses,
  setAdvanceSalePromotions,
  setCustomer,
  setOrders,
  setPreferences,
  setReport,
  setReportLoading,
} from "../slices/accountSlice";
import { serviceGetCustomer } from "../../commercelayer/services/customers";
import { StorageKeys } from "../../common/constants/StorageKeys";
import { Customer, ResponseCustomersWithIncludes } from "../../commercelayer/vo/customers";
import {
  serviceGetCustomerOrders,
  serviceGetCustomerOrdersPreferences,
} from "../../commercelayer/services/orders";
import {
  Order,
  ResponseOrdersList,
  ResponseOrdersListWithIncludes,
} from "../../commercelayer/vo/orders";
import {
  serviceCreateAddresses,
  serviceCreateCustomerAddresses,
  serviceDeleteCustomerAddresses,
  serviceGetCustomerAddresses,
  serviceUpdateAddresses,
} from "../../commercelayer/services/addresses";
import {
  Addresses,
  AddressesAttributes,
  ResponseAddresses,
} from "../../commercelayer/vo/addresses";
import {
  CustomerAddresses,
  ResponseCustomerAddressesListWithIncludes,
} from "../../commercelayer/vo/customerAddresses";
import LayerIo from "../../common/services/LayerIo";
import { toast } from "react-toastify";
import i18next from "i18next";
import { boundReplaceLocObj } from "../../router/actions/routesActions";
import { getLangCountry } from "../../router/scripts/link";
import { PATH } from "../../router/constants/Path";
import { ResponseReport, serviceLoadReport } from "../services/report";
import OrderDocument from "../../state/vo/OrderDocument";
import { serviceGetPromotions } from "../../commercelayer/services/promotions";
import { ResponsePromotionsList } from "../../commercelayer/vo/promotions";

export async function getCustomer() {
  const loginState = getState().login;
  if (!loginState.token) {
    return;
  }
  await getCustomerWithId(loginState.token.owner_id);
}

export async function getCustomerWithId(customerId: string) {
  const response: ResponseCustomersWithIncludes | null = await serviceGetCustomer(customerId);
  if (response === null) {
    return;
  }

  await setCustomerStateAndStorage(response.data);

  await loadCustomerAddresses();
}

export async function setCustomerStateAndStorage(customer: Customer | null) {
  dispatch(setCustomer(customer));
  if (customer === null) {
    localStorage.removeItem(StorageKeys.CUSTOMER);
    localStorage.removeItem(StorageKeys.ACCOUNT);
  } else {
    localStorage.setItem(StorageKeys.CUSTOMER, customer.attributes.email);
    localStorage.setItem(StorageKeys.ACCOUNT, customer.id);
    localStorage.setItem(StorageKeys.BASE_HREF, `${window.location.origin}${getLangCountry()}`);
  }
}

export async function resetAccount() {
  await setCustomerStateAndStorage(null);
  dispatch(setAddresses([]));
  dispatch(setOrders([]));
  dispatch(setPreferences(null));
}

export async function loadCustomerAddresses() {
  const accountState = getState().account;
  if (accountState.customer?.id) {
    const response: ResponseCustomerAddressesListWithIncludes | null =
      await serviceGetCustomerAddresses(accountState.customer.id);
    if (response === null) {
      return;
    }
    const responseIncluded = response.included ?? [];
    const responseAddresses: Addresses[] = responseIncluded.filter(function (data) {
      return data.type === "addresses";
    });
    dispatch(setAddresses(responseAddresses));
  }
}

export async function addCustomerAddresses(
  addressesAttributes: AddressesAttributes,
): Promise<string | null> {
  const accountState = getState().account;
  if (accountState.customer?.id) {
    const responseAddress: ResponseAddresses | null =
      await serviceCreateAddresses(addressesAttributes);
    if (responseAddress === null) {
      return null;
    }
    await serviceCreateCustomerAddresses(accountState.customer.id, responseAddress.data.id);
    await loadCustomerAddresses();
    return responseAddress.data.id;
  }
  return null;
}

export async function updateAddresses(addressId: string, addressesAttributes: AddressesAttributes) {
  const accountState = getState().account;
  if (accountState.customer?.id) {
    await serviceUpdateAddresses(addressId, addressesAttributes);
    await loadCustomerAddresses();
  }
}

export async function deleteCustomerAddress(addressId: string) {
  const accountState = getState().account;
  if (accountState.customer?.id) {
    const response: ResponseCustomerAddressesListWithIncludes | null =
      await serviceGetCustomerAddresses(accountState.customer.id);
    if (response === null) {
      return;
    }

    const customerAddresses: CustomerAddresses[] = response.data;
    for (let i = 0; i < customerAddresses.length; i++) {
      if (addressId === customerAddresses[i]?.relationships?.address?.data?.id) {
        // Delete customer address
        await serviceDeleteCustomerAddresses(customerAddresses[i].id);
      }
    }
    await loadCustomerAddresses();
  }
}

export async function getCustomerOrders() {
  const accountState = getState().account;
  if (accountState.customer?.id) {
    const response: ResponseOrdersList | null = await serviceGetCustomerOrders(
      accountState.customer?.id,
    );
    if (response === null) {
      return;
    }

    const orders = response.data;
    orders.sort((a: Order, b: Order) => {
      if (a.attributes.placed_at < b.attributes.placed_at) {
        return 1;
      }
      return -1;
    });

    dispatch(setOrders(response.data));
  }
}

export async function getCustomerPreferences() {
  const accountState = getState().account;
  if (accountState.customer === null) {
    dispatch(setPreferences([]));
    return;
  }
  const response: ResponseOrdersListWithIncludes | null = await serviceGetCustomerOrdersPreferences(
    accountState.customer?.id,
  );
  if (response === null) {
    dispatch(setPreferences([]));
    return;
  }
  const responseIncluded = response.included ?? [];
  const skus = responseIncluded
    .filter(include => {
      if (include.type === "line_items") {
        if (include.attributes.item_type === "skus") {
          return true;
        }
      }
      return false;
    })
    .map(lineItem => {
      return lineItem.attributes.sku_code;
    });
  dispatch(setPreferences(skus));
}

export async function updatePassword(
  customerPasswordResetsId: string,
  token: string,
  password: string,
) {
  try {
    const response = await LayerIo.requestJsonRpc<any, any>("customer#setPassword", "setPassword", {
      customerPasswordResetsId: customerPasswordResetsId,
      token: token,
      password: password,
    });

    if (response) {
      boundReplaceLocObj({ pathname: `${getLangCountry()}/${PATH.LOGIN}` });
      toast.success(i18next.t("THE PASSWORD WAS CHANGED SUCCESSFULLY"), {
        position: "top-center",
        autoClose: 10000,
      });
    } else {
      toast.error(i18next.t("ERROR WHILE RESETTING THE PASSWORD"), {
        position: "top-center",
      });
    }
  } catch (e) {
    toast.error(i18next.t("ERROR WHILE RESETTING THE PASSWORD"), {
      position: "top-center",
    });
    // Log error to console
    console.log(e);
  }
}

export async function loadReport(customerId: string, dateFrom: Date, dateTo: Date) {
  dispatch(setReportLoading(true));
  const response: ResponseReport | null = await serviceLoadReport(customerId, dateFrom, dateTo);
  if (response === null) {
    toast.warn(i18next.t("ERROR LOADING DATA"), {
      position: "top-center",
    });
    dispatch(setReportLoading(false));
    return;
  }

  const orderDocuments = response.data;

  orderDocuments.sort((a: OrderDocument, b: OrderDocument) => {
    if (a.billingDocumentDate < b.billingDocumentDate) {
      return -1;
    }
    return 1;
  });

  const totalCurrency = orderDocuments.reduce(
    (accumulator: string, orderDocument: OrderDocument) => {
      if (!accumulator.includes(orderDocument.salesDocumentCurrency)) {
        if (accumulator === "") {
          return orderDocument.salesDocumentCurrency;
        }
        return `${accumulator}, ${orderDocument.salesDocumentCurrency}`;
      }
      return accumulator;
    },
    "",
  );
  const totalInvestmentGoodsAmount = orderDocuments.reduce(
    (accumulator: number, orderDocument: OrderDocument) => {
      return accumulator + orderDocument.investmentGoodsAmount;
    },
    0,
  );
  const totalConsumableMaterialsAmount = orderDocuments.reduce(
    (accumulator: number, orderDocument: OrderDocument) => {
      return accumulator + orderDocument.consumableMaterialsAmount;
    },
    0,
  );
  const grandTotalAmount = orderDocuments.reduce(
    (accumulator: number, orderDocument: OrderDocument) => {
      return accumulator + orderDocument.totalAmount;
    },
    0,
  );

  dispatch(
    setReport({
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
      data: orderDocuments,
      totalCurrency: totalCurrency,
      totalInvestmentGoodsAmount: totalInvestmentGoodsAmount,
      totalConsumableMaterialsAmount: totalConsumableMaterialsAmount,
      grandTotalAmount: grandTotalAmount,
    }),
  );
  dispatch(setReportLoading(false));
}

// TODO: External Promotion cannot fulfil the requirement
// TODO: Replace promotions with contracts
export async function getPromotions() {
  const accountState = getState().account;
  if (accountState.customer?.id) {
    const response: ResponsePromotionsList | null = await serviceGetPromotions();
    if (response === null) {
      return;
    }
    dispatch(
      setAdvanceSalePromotions(
        response.data.filter(function (data) {
          const subtype = data.attributes.metadata.subtype || null;
          return data.type === "external_promotions" && subtype === "SAP_DBT_ADVANCESALE";
        }),
      ),
    );
  }
}
