import React from "react";
import {
  isCartView,
  isCheckoutView,
  isLoginView,
  isOrderView,
  isPasswordResetView,
  isProductRelatedView,
  isScanToCartView,
  isSignupView,
} from "../../../router/scripts/path";

type Props = {
  children?: React.ReactNode;
};

const Middle: React.FC<Props> = ({ children }) => {
  let addClass = "";
  if (isProductRelatedView()) {
    addClass += " main--product";
  }
  if (
    isCartView() ||
    isLoginView() ||
    isSignupView() ||
    isCheckoutView() ||
    isOrderView() ||
    isPasswordResetView()
  ) {
    addClass += " col-10";
  }
  if (isScanToCartView()) {
    addClass += " col-2-1";
  }

  return <main className={`col-middle ${addClass}`}>{children ?? null}</main>;
};

export default Middle;
