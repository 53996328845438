import React from "react";
import QR from "qrcode.react";

interface Props {
  scanId: string;
}

const QRCode: React.FC<Props> = ({ scanId }) => {
  const value = `${window.location.href}/${scanId}`;

  return (
    <div className="img-wrap img-wrap--qr-code">
      <QR value={value} size={368} includeMargin={true} level="M" renderAs="canvas" />
    </div>
  );
};

export default QRCode;
