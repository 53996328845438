import React from "react";
import { CellYTd } from "./CellYTd";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";

const ProductTableHeadLeftCells: React.FC = () => {
  const isMobile = useSelector((state: State) => state.layout.isMobile);

  // push left layout space when not in mobile
  if (!isMobile) {
    return (
      <>
        <CellYTd />
        <CellYTd />
        <CellYTd />
      </>
    );
  }

  return null;
};

export default ProductTableHeadLeftCells;
