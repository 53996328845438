import React from "react";
import { useSelector } from "react-redux";
import State from "../../state/vo/State";
import i18next from "i18next";
import { OWNER_TYPE } from "../../login/constants/ownerType";
import { Customer } from "../../commercelayer/vo/customers";
import { Link } from "react-router-dom";
import { scrollTop } from "../../layout/scripts/scroll";
import { useLangCountry } from "../../router/hooks/useLangCountry";

interface Props {
  customer: Customer;
}

const InternalInformation: React.FC<Props> = ({ customer }) => {
  const login = useSelector((state: State) => state.login);
  const langCountry = useLangCountry();

  if (login.token === null) {
    return null;
  }
  if (login.token.owner_type !== OWNER_TYPE.USER) {
    return null;
  }

  return (
    <div className="grid-wrap grid-wrap--account grid-wrap--color">
      <div className="grid-1-2">
        <h2 className="col-title">{i18next.t("INTERNAL INFORMATION")}</h2>
        <dl className="dl-account">
          <dt className="dl-account__dt">SORT1:</dt>
          <dd className="dl-account__dd">
            <strong>{customer.attributes.metadata["s_dbt_hdr-sort1"]}</strong>
          </dd>
          <dt className="dl-account__dt">SORT2:</dt>
          <dd className="dl-account__dd">
            <strong>{customer.attributes.metadata["s_dbt_hdr-sort2"]}</strong>
          </dd>
          <dt className="dl-account__dt">LIFSD:</dt>
          <dd className="dl-account__dd">
            <strong>{customer.attributes.metadata["s_dbt_hdr-lifsd"]}</strong>
          </dd>
          <dt className="dl-account__dt">KALKS:</dt>
          <dd className="dl-account__dd">
            <strong>{customer.attributes.metadata["s_dbt_sal-kalks"]}</strong>
          </dd>
          <dt className="dl-account__dt">VTWEG:</dt>
          <dd className="dl-account__dd">
            <strong>{customer.attributes.metadata["s_dbt_sal_key-vtweg"]}</strong>
          </dd>
        </dl>
      </div>
      <div className="grid-1-2">
        <Link
          to={`${langCountry}`}
          onClick={() => {
            scrollTop();
          }}
          className="btn "
          role="button"
          tabIndex={0}
        >
          <span className="btn__text">{i18next.t("CREATE ORDER FOR CUSTOMER")}</span>
        </Link>
      </div>
    </div>
  );
};
export default InternalInformation;
